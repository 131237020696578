import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      video={videos.news.videoUrl}
      annotations={videos.news.annotations}
      title="Follow the day’s big stories"
      subtitle="STREAM LIVE NEWS FROM ANY DEVICE"
      tagline="Plus, get our News add-on for even more coverage"
      networkSet="news"
    />
  )
}
