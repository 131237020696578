import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'
export default (props) => <RedirectTemplate location={props.location} destination="/tv/" />

// import React, { useEffect, useState } from 'react'
// import USTemplateDefault from 'src/components/template/us'
// import { videos } from 'src/constants/videos'
// import { getURIParamValue } from 'src/modules/utils/analytics'

// export default props => {
//   const [friendName, setFriendName] = useState('Your Friend')
//   const offerCode = getURIParamValue(props.location.search, 'friendName')

//   useEffect(() => {
//     if (offerCode) {
//       setFriendName(offerCode)
//     }
//   }, [offerCode])

//   return (
//     <USTemplateDefault
//       location={props.location}
//       lang="en"
//       market="us"
//       offerBanner={
//         <>
//           Special Offer From {friendName}
//           <br />
//           Your reward credit is waiting. Learn how to redeem below.
//         </>
//       }
//       video={videos.seasonal_us.videoUrl}
//       videoMobile={videos.seasonal_us.videoMobileUrl}
//       annotations={videos.seasonal_us.annotations}
//       title="Watch Live TV & Sports"
//       subtitle="CLAIM YOUR REWARD CREDIT TODAY"
//       tagline="Stream anytime. Anywhere. From any device."
//       highlightImage="https://assets-imgx.fubo.tv/marketing/lp/images/devices/LP_group_fubo-devices-min-v2.png?ch=width&auto=format,compress"
//       highlightHeadline={
//         <>
//           Get $30 Off <br />
//           Your First Bill!
//         </>
//       }
//       highlightSubtitle="Once you sign up, you will automatically receive $15 or $30 off your bill. Get $15 for plans less than $45. Get $30 for plans $45 or more."
//     />
//   )
// }
