import React from 'react'

import styled from 'styled-components'
import {
  CanadaDefaultCtaText,
  USDefaultCtaText,
  LatinoDefaultCtaText_ES,
} from 'src/modules/utils/constants'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import { createUrlParams } from 'src/modules/utils/createUrl'
import { replaceSlugParam } from 'src/modules/utils/replaceUrlParam'
import { networkNames } from 'src/components/common/LivePreviewPlayer/networkNames'
import FuboLivePreviewWidget from './player'

const LivePreviewPlayer = ({
  ctaText,
  ctaSubhead,
  callsign,
  lang,
  networkName,
  loadingText,
  overrideTimer,
  market,
}) => {
  const parsedParams = useSelector(selectCTAParams)
  const params = replaceSlugParam(createUrlParams(parsedParams), 'comp', 'end_previews')

  const ctaCopy = ctaText
    ? ctaText
    : lang === 'es'
      ? LatinoDefaultCtaText_ES
      : market === 'canada'
        ? CanadaDefaultCtaText
        : USDefaultCtaText

  const cta_headline = lang === 'es' ? 'La vista previa ha finalizado.' : 'Your preview has ended.'
  const cta_subhead =
    ctaSubhead ||
    (lang === 'es' ? 'Prueba gratuita para seguir viendo.' : 'Keep watching with your free trial.')
  // const cta_subhead = "Keep watching with Fubo.";
  const callsignUpper = callsign && callsign.toUpperCase()
  let networkLabel = networkName || networkNames[callsignUpper]

  let preview_channel_tag = networkLabel
    ? lang === 'es'
      ? `Estás viendo ${networkLabel} en vivo. `
      : `You are watching ${networkLabel} live. `
    : ''
  const preview_timer_template =
    lang === 'es' ? 'La vista previa termina en {time}s' : 'Preview ends in {time}s'
  const cta_button = lang === 'es' ? 'Sigue Viendo' : 'Keep Watching'

  return (
    <StyledLivePreviewPlayer>
      <FuboLivePreviewWidget
        callsign={callsign}
        ctaTrackingParams={params}
        ctaHeadline={cta_headline}
        ctaSubhead={cta_subhead}
        ctaButton={cta_button}
        ctaText={ctaCopy}
        previewTimerTemplate={preview_channel_tag + preview_timer_template}
        autoplay={true}
        loadingText={loadingText}
        overrideTimer={overrideTimer}
      />
    </StyledLivePreviewPlayer>
  )
}

export default LivePreviewPlayer

const StyledLivePreviewPlayer = styled.div`
  .live-preview-player {
    width: 100%;
    padding-bottom: 56.25%;
    ${'' /* aspect-ratio: 16/9; */}
    position: relative;
    background: #000;
    #fubo-livepreview-widget {
      color: #fff;
      width: 100% !important;
      height: 100% !important;
      position: absolute !important;
      .live-player-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      .video-js {
        width: 100%;
        height: 100%;
      }
    }
  }
`
