export const START_ZVA = 'zva/START'
export type ZVAStartAction = {
  type: 'zva/START'
}
export const ZVA_LOADING = 'zva/LOADING'
export type ZVALoadingAction = {
  type: 'zva/LOADING'
}
export const OPEN_ZVA = 'zva/OPEN'
export type ZVAOpenAction = {
  type: 'zva/OPEN'
}
export const ZVA_INITIALIZED = 'zva/INITIALIZED'
export type ZVAInitializedAction = {
  type: 'zva/INITIALIZED'
}
export const ZVA_UPDATED = 'zva/UPDATED'
export type ZVAUpdatedAction = {
  type: 'zva/UPDATED'
}
export const ZVA_FAILED = 'zva/FAILED'
export type ZVAFailedAction = {
  type: 'zva/FAILED'
  error: Error
}
export type ZVAAction =
  | ZVAStartAction
  | ZVALoadingAction
  | ZVAOpenAction
  | ZVAInitializedAction
  | ZVAUpdatedAction
  | ZVAFailedAction
