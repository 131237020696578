import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={
        'https://assets.fubo.tv/marketing/lp/videos/compressed/RickAndMorty_1280x720_LP_15s.mp4'
      }
      staticAnnotation={{
        logoUrl:
          'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/stacktv_adultswim.png',
        height: 80,
      }}
      title="Watch Rick and Morty with Fubo"
      subtitle="NEW SEASON NOW AVAILABLE ON ADULT SWIM"
      tagline="Stream it live from your TV and other devices"
      plan="entertainment"
      networkSet="canada"
    />
  )
}
