import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
export default (props) => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="BETHD"
    title="Watch BET Live Online with Fubo"
    networkName="BET"
  />
)
