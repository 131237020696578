import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
    hasOfferInput={true}
    video={videos.pittsburgh_penguins.videoUrl}
    staticAnnotation={videos.pittsburgh_penguins.staticAnnotation}
    pageTitle="Watch Penguins® hockey"
    title={
      <>
        Watch Penguins<sup>®</sup> hockey
      </>
    }
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    tagline="Stream SportsNet Pittsburgh from any device. No cable required. Regional restrictions apply."
    networkSet="hockey"
    customNetworks={['sportsnetpittsburgh']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-pittsburgh-penguins-v2.png?ch=width&auto=format,compress"
    logoHeight="100px"
    sportId={sportIds.Hockey}
    leagueId={leagueIds.NHL}
    teamId={teamIds['Pittsburgh Penguins']}
  />
)
