import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

const RedirectTemplate = ({ location, destination }) => {
  useEffect(() => {
    const query = location.search
    if (destination) {
      navigate(destination + query)
    } else {
      navigate('https://www.fubo.tv/welcome' + query)
    }
  }, [location, destination])

  return <></>
}

export default RedirectTemplate
