import React from 'react'
import LiveWidgetTemplate from 'src/components/template/live-widget'
import { parseQueryParams } from 'src/modules/utils/parseQueryParams'

export default (props) => {
  // Get preview network slug
  let slug
  if (props && props.location && props.location.href) {
    slug = parseQueryParams(props.location.href).page_slug
  }

  return <LiveWidgetTemplate location={props.location} lang="en" market="all" />
}
