import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/32Logos_CA_Disney_LP_3.jpg?ch=width&auto=format,compress"
      title="Watch Disney channels with Fubo"
      subtitle="ENJOY POPULAR SHOWS FOR THE WHOLE FAMILY"
      tagline="Stream them live & on demand from any device"
      plan="entertainment"
      networkSet="canada"
      customNetworks={['disney', 'disneyjunior', 'disneyxd']}
    />
  )
}
