import React from 'react'
import LiveTemplateDefault from 'src/components/template/live/canada'
export default (props) => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="canada"
    callsign="MLBHD"
    title="Get MLB.TV with Fubo"
    subtitle="WATCH +2000 LIVE GAMES AND MUCH MORE"
    customNetworks={['mlbtv', 'mlbnetwork']}
    networkName="MLB Network"
    highlightImage="https://assets-imgx.fubo.tv/marketing/lp/images/devices/mlb-tv-phone.png?ch=width&auto=format,compress"
    highlightHeadline="Get MLB.TV with Fubo"
    highlightSubtitle="See every out-of-market MLB team in action in over 2,000 live games this season. Watch them all live from your TV, phone & other devices."
    highlightBackground="radial-gradient(98.95% 98.95% at 50% 1.05%, #333F51 0%, #0D1B31 92%)"
    networkSubtitle="Watch Premier League, Serie A, MLB & so much more?"
    networkSet="canadaEssentials"
    plan="sportsOnly"
    plansSubtitle="Canada’s home to streaming live sports and top entertainment"
    league="mlb"
    planCardOverride={'mlbtvOffer'}
    soccerPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/canada/ca_card_mlb-tv.jpg?w=750&ch=width&auto=format,compress"
    forceAnchorLink={true}
  />
)
