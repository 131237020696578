import React from 'react'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSectionEs'
import FeatureBoxes from 'src/components/common/FeatureBoxesEs'
import ShowCarousel from 'src/components/common/ShowCarousel'
import Layout from 'src/components/common/Layout'
import HeroSectionNew from 'src/components/common/HeroSectionNew'
import ChannelSection from 'src/components/common/ChannelSectionNew'
import { LogoSyncContextProvider } from 'src/components/common/AnnotationContext'

const SpainTemplateDefault = ({
  lang = 'es',
  market = 'spain',
  ctaText = 'COMENZAR 7 DÍAS GRATIS',
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  title,
  subtitle,
  tagline,
  video,
  staticAnnotation,
  annotations,
  poster,
  // Carousel
  carouselTitle,
  carouselImages,
}) => {
  let metaDescription =
    pageDescription ||
    'Disfruta de la mejor TV online. Las mejores series, dibujos infantiles de Nickelodeon y Nick Jr., realities de MTV, programas de entretenimiento para toda la familia y muchos más. En cualquier dispositivo: Ordenador, iOS, Android, Tablet…'

  return (
    <Layout lang={lang} market={market}>
      <LogoSyncContextProvider annotations={annotations}>
        <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
        <HeroSectionNew
          lang={lang}
          market={market}
          heroBgVideo={video}
          title={title}
          subtitle={subtitle}
          tagline={tagline}
          staticAnnotation={staticAnnotation}
          posterImg={poster}
          ctaText={ctaText}
        />
        <ShowCarousel headline={carouselTitle} imagesArray={carouselImages} ctaText={ctaText} />
        <ChannelSection
          lang={lang}
          market={market}
          title={'AHORA PUEDES VER TUS CANALES FAVORITOS DE UNA FORMA DIFERENTE'}
          showAllChannels={true}
        />
        <FeatureBoxes />
        <PlatformSection lang={lang} market={market} ctaText={ctaText} />
        <FAQSection lang={lang} market={market} />
      </LogoSyncContextProvider>
    </Layout>
  )
}

export default SpainTemplateDefault
