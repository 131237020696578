import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
export default (props) => {
  return (
    <StyleContainer>
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        offerBanner={
          <>
            ✔ Special offer activated
            <br />
            Watch the Buffalo Sabres live for as low as $10.83/mo.
          </>
        }
        video={videos.buffalo_sabres.videoUrl}
        videoMobile={videos.buffalo_sabres.videoMobileUrl}
        poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/20231012__JH_4682_1.jpg?w=4096&ch=width&auto=format,compress"
        staticAnnotation={networkLogos.fsn}
        pageTitle="Watch Buffalo Sabres hockey"
        title={
          <>
            Watch Buffalo Sabres<sup>®</sup> hockey
          </>
        }
        subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
        tagline={
          'Stream Fubo Sports Niagara from any device. No cable required. Regional restrictions apply.'
        }
        forceAnchorLink={true}
        preselectPlanLength="quarterly"
        plan="soccer"
        plansSubtitle="Canada’s home of the Buffalo Sabres and exclusive live soccer"
        networkSet="canada"
        customNetworks={['fsn']}
        planCardOverride={'sabresPriceTest'}
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-buffalo-sabres-v2.png?ch=width&auto=format,compress"
        logoHeight="100px"
        soccerPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_buffalo_sabres.jpg?w=451&ch=width&auto=format,compress"
        premiumPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_premium-sabres.jpg?w=451&ch=width&auto=format,compress"
        sportId={sportIds.Hockey}
        leagueId={leagueIds.NHL}
        teamId={teamIds['Buffalo Sabres']}
      />
    </StyleContainer>
  )
}

const StyleContainer = styled.div`
  .content-container .hero-content {
    max-width: 590px;
  }
`
