import { environment } from 'src/config/env'
// import zvaCloseButton from '~/components/ZvaCloseButton/index.js'

/* eslint-disable camelcase */
const MAX_ATTEMPTS = 6
const WAIT_TIME = 500
type ZVAConfig = {
  authenticated: boolean
  location_info: {
    current_country: string
    current_zip: string | null | undefined
  }
}

/**
 * The logic here matches the retry logic in frontend-web.
 *
 * This is how we wait for the window.zoomCampaignSdk functions to become available,
 * so know we'll be able to call them once we are in an initialized state.
 * This is tricky because the zoomCampaignSdk script we explicitly load must
 * load 2 other scripts and make an api call before it's functions are available.
 *
 * Typically with a good network connection, it takes less than a second for everything to initialize,
 * but we will check MAX_ATTEMPTS for things to be ready and wait longer after each failed check
 * before checking again, up to a generous 14 seconds (according to the above constants).
 *
 * If we fail all attempts, then the user can click the button again, which should
 * be less likely to fail this time due to the scripts likely now being cached.
 *
 * ZVA documentation: https://developers.zoom.us/docs/virtual-agent/web/reference/
 */
const ZVAAwaiter = (
  resolver: () => void,
  rejecter: (reason?: any) => void,
  config: ZVAConfig,
  attempt: number
) => {
  // Ensure all functions we plan to call are available, just checking window.Solvvy wasn't actually adequate
  // @ts-expect-error - TS2339 - Property 'zoomCampaignSdk' does not exist on type 'Window & typeof globalThis'.
  if (window.zoomCampaignSdk?.open) {
    resolver()
  } else if (attempt < MAX_ATTEMPTS) {
    setTimeout(() => ZVAAwaiter(resolver, rejecter, config, attempt + 1), WAIT_TIME * MAX_ATTEMPTS)
  } else {
    const errorMessage = `ZVA still not available after ${MAX_ATTEMPTS} attempts`
    console.error(errorMessage)
    rejecter(new Error(errorMessage))
  }
}
export const initZVA = (config: ZVAConfig): Promise<void> =>
  new Promise(
    (resolve: (result: Promise<undefined> | undefined) => void, reject: (error?: any) => void) => {
      try {
        const zvaEl = document.createElement('script')
        zvaEl.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/chat-client.js'
        zvaEl.setAttribute('data-apikey', environment.zva.apiKey)
        zvaEl.setAttribute('data-env', environment.zva.dataEnv)

        zvaEl.async = true

        zvaEl.onload = () => {
          window.addEventListener('zoomCampaignSdk:ready', () => {
            // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
            resolve()
          })
        }
        zvaEl.onerror = (err: any) => {
          console.error('ZVA: error loading script', err)
          reject(err)
        }
        document && document.body && document.body.appendChild(zvaEl)
      } catch (err) {
        console.error('ZVA: error loading script', err)
        reject(err)
      }
    }
  ).then(() => {
    return new Promise(
      (
        resolve: (result: Promise<undefined> | undefined) => void,
        reject: (error?: any) => void
      ) => {
        // @ts-expect-error - TS2345 - Argument of type '(result: Promise<undefined>) => void' is not assignable to parameter of type '() => void'.
        ZVAAwaiter(resolve, reject, config, 1)
      }
    )
  })

export const openZVAWithConfig = (config: ZVAConfig): Promise<void> =>
  new Promise(
    (resolve: (result: Promise<undefined> | undefined) => void, reject: (error?: any) => void) => {
      // As an async function, in order to catch errors has to have it's own promise block. Could be replaced with async await for readability
      try {
        // @ts-expect-error - TS2339 - Property 'fubo_zva_userData' does not exist on type 'Window & typeof globalThis'.
        window.fubo_zva_userData = config
        // @ts-expect-error - TS2339 - Property 'zoomCampaignSdk' does not exist on type 'Window & typeof globalThis'.
        window.zoomCampaignSdk?.open()
        // @ts-expect-error - TS2339 - Property 'zoomCampaignSdk' does not exist on type 'Window & typeof globalThis'.
        window.zoomCampaignSdk?.show()
        // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
        resolve()
      } catch (e) {
        console.error('ZVA: error opening', e)
        reject(e)
      }
    }
  ).then(() => {
    return new Promise(
      (
        resolve: (result: Promise<undefined> | undefined) => void,
        reject: (error?: any) => void
      ) => {
        /**ZVA chat bot doesn't provide all the necessary functionality out of the box so once chat is loaded and open,
         *  we make necessary adjustments (styling, add close button)
         **/
        try {
          // const zvaLauncherBubble = document.getElementsByClassName('livesdk__invitation')?.[0]
          //TODO: ZVA: Add close button
          // if (zvaLauncherBubble && zvaCloseButton) {
          //   zvaCloseButton.addEventListener('click', () => {
          //     // @ts-expect-error - TS2339 - Property 'zoomCampaignSdk' does not exist on type 'Window & typeof globalThis'.
          //     window.zoomCampaignSdk?.hide()
          //   })
          //   // @ts-expect-error - TS2339 -  Property 'style' does not exist on type 'ParentNode'.
          //   zvaLauncherBubble.parentNode.style.right = '5px'
          //   zvaLauncherBubble.parentNode.insertBefore(zvaCloseButton, zvaLauncherBubble.nextSibling)
          // }
          // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
          resolve()
        } catch (err) {
          console.error('ZVA: error opening', err)
          reject(err)
        }
      }
    )
  })

/**
 * Meant to push updates to the chat bot whenever state changes.
 * Things like the the users's market and authentication should trigger this.
 */
export const updateZVA = (config: ZVAConfig): Promise<void> =>
  new Promise(
    (resolve: (result: Promise<undefined> | undefined) => void, reject: (error?: any) => void) => {
      try {
        // @ts-expect-error - TS2339 - Property 'fubo_zva_userData' does not exist on type 'Window & typeof globalThis'.
        window.fubo_zva_userData = config
        // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
        resolve()
      } catch (err) {
        console.error('ZVA: error updating user data', err)
        reject(err)
      }
    }
  )
