import React from 'react'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_rays.videoUrl}
    staticAnnotation={videos.bally_rays.staticAnnotation}
    title="Watch Tampa Bay baseball live"
    subtitle="STREAM THE RAYS ON FANDUEL SPORTS SUN"
    teamId={teamIds['Tampa Bay Rays']}
  />
)
