import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/ComedyCentral.mp4'}
    staticAnnotation={networkLogos.comedycentral}
    title="Watch Comedy Central with Fubo"
    subtitle="SOUTH PARK, THE DAILY SHOW AND MORE"
    tagline="Stream it all from your TV, phone and other devices"
    networkSet="entertainment"
    customNetworks={['comedycentral']}
  />
)
