import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'

//REPLACED: univision_removed
export default (props) => <RedirectTemplate location={props.location} destination="/latino-es/" />

//REMOVED: univision_removed
// export default props => {
//   return (
//     <LatinoTemplateDefault
//       location={props.location}
//       lang="es"
//       market="latino"
//       video={videos.super_bowl.videoUrl}
//       staticAnnotation={videos.super_bowl.staticAnnotation}
//       title="MIRA EL SUPER BOWL LVIII"
//       // subtitle="En vivo en Univision"
//       tagline="Accede a Univision y a más de 60 canales con deportes en vivo y en español"
//       networkSet="entertainmentLatino"
//       customNetworks={['univision']}
//     />
//   )
// }
