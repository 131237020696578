import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      noRedirect={true}
      video={videos.buffalo_sabres.videoUrl}
      videoMobile={videos.buffalo_sabres.videoMobileUrl}
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/20231012__JH_4682_1.jpg?w=4096&ch=width&auto=format,compress"
      staticAnnotation={networkLogos.msg}
      pageTitle="Watch Buffalo Sabres hockey"
      title={
        <>
          Watch Buffalo Sabres<sup>®</sup> hockey
        </>
      }
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream MSG Networks from any device. No cable required. Regional restrictions apply."
      networkSet="hockey"
      customNetworks={['msg']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-buffalo-sabres-v2.png?ch=width&auto=format,compress"
      logoHeight="100px"
      sportId={sportIds.Hockey}
      leagueId={leagueIds.NHL}
      teamId={teamIds['Buffalo Sabres']}
    />
  )
}
