import React from 'react'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <BallySportsMLB
    location={props.location}
    video={videos.bally_braves.videoUrl}
    staticAnnotation={videos.bally_braves.staticAnnotation}
    title="Watch Atlanta baseball live"
    subtitle="STREAM THE BRAVES ON FANDUEL SPORTS SOUTH & SOUTHEAST"
    teamId={teamIds['Atlanta Braves']}
  />
)
