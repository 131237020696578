import type { BillingInterval } from '@fubotv/types/api/UP/billing'
import type { Product, RatePlan } from '@fubotv/types/api/UP/products'

import { getFormattedUnit } from 'src/modules/utils/billing/'

import { MappedProductsKey } from './types'

type Key = Exclude<BillingInterval, 'day'>
const keyMap: Record<Key, MappedProductsKey> = {
  month: 'monthly',
  quarter: 'quarterly',
  year: 'annual',
}

/**
 * The value of each property is a products map of the provided products where
 * the keys are the rate plan codes and the value of each entry is the product
 * object with the appropriate rate plan mapped to the root of the object as
 * the property ratePlan. This provides use with an iterable that matches the
 * order of the response and treats each available rate plan as a unique entry.
 * This allows us to have an iterable of products base on a desired billing interval
 * and allow use to get a product using a ratePlanCode value.
 * @param products - array of base plans or add-ons from the UP products endpoint
 * @returns An object with the properties all, monthly, quarterly, and annual.
 */
export function mapProducts<T>(products: Product[]): Record<MappedProductsKey, Map<string, T>> {
  return products.reduce(
    (acc: any, product: Product) => {
      product.ratePlans.forEach((ratePlan: RatePlan) => {
        acc.allByProductCode.set(product.code, {
          ...product,
          ratePlan,
        })

        acc.all.set(ratePlan.code, {
          ...product,
          ratePlan,
        })
        const billingInterval = getFormattedUnit(
          ratePlan.billingCycleUnit,
          ratePlan.billingCycleLength
        )
        billingInterval &&
          acc[keyMap[billingInterval]].set(ratePlan.code, {
            ...product,
            ratePlan,
          })
      })
      return acc
    },
    {
      allByProductCode: new Map<string, T>(),
      all: new Map<string, T>(),
      monthly: new Map<string, T>(),
      quarterly: new Map<string, T>(),
      annual: new Map<string, T>(),
    }
  )
}
