import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import LiveTemplateDefault from 'src/components/template/live'
import { selectParams } from 'src/modules/reducers/params'
export default (props) => {
  // Detect for fox referral and show banner if redirect from fox domain
  const [foxRedirect, setFoxRedirect] = useState(false)
  const params = useSelector(selectParams)

  useEffect(() => {
    const docReferrer = document && document.referrer
    if ((docReferrer && docReferrer.includes('fox')) || params?.fox_redirect === 'true') {
      setFoxRedirect(true)
    }
  }, [params])

  const offerBanner = (
    <>
      You’ve been redirected to Fubo.
      <br />
      Follow the biggest stories on America’s most watched news network.
    </>
  )

  return (
    <LiveTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      offerBanner={foxRedirect ? offerBanner : null}
      callsign="FNCHD"
      title="Watch FOX News Live with Fubo"
      foxRedirect={foxRedirect}
      networkName="FOX News"
    />
  )
}
