import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'

import AmandaJackImage from 'src/assets/images/ca/fubo-movie-network/AmandaJack.png'
import HellbabyImage from 'src/assets/images/ca/fubo-movie-network/Hellbaby.png'
import HighlanderImage from 'src/assets/images/ca/fubo-movie-network/Highlander.png'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/FMN_2022_LP_23s.mp4'}
      annotations={[
        {
          startSecs: '0',
          endSecs: '8.1',
          alt: '',
          logoUrl: HellbabyImage,
          height: 100,
        },
        {
          startSecs: '8.1',
          endSecs: '13.3',
          alt: '',
          logoUrl: HighlanderImage,
          height: 100,
        },
        {
          startSecs: '13.3',
          endSecs: '24.0',
          alt: '',
          logoUrl: AmandaJackImage,
          height: 100,
        },
      ]}
      title="Watch movies with Fubo"
      subtitle="STREAM HIT CLASSICS AND MORE"
      tagline="Enjoy them all on demand from any device"
      networkSet="canada"
    />
  )
}
