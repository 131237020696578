import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/CBC_2022_LP_1920x680_12s.mp4'}
      title="Watch CBC live with Fubo"
      subtitle="ENJOY YOUR FAVOURITE SHOWS AND MORE"
      tagline="Stream it from your TV and other devices"
      networkSet="canada"
    />
  )
}
