import { ChannelsMap } from '@fubotv/js-client/dist/esm/domains/UP/channels/types'

import type { Params as FetchChannelsByLocationParams } from '@fubotv/js-client/dist/esm/domains/UP/channels/fetchChannelsByLocation'
import type { Params as FetchProductByRatePlanParams } from '@fubotv/js-client/dist/esm/domains/UP/subscriptions/products/fetchProductByRatePlan'
import type { Product } from '@fubotv/types/api/UP/products'

import { ApiError } from '~/types/domain'

import { FreeTrialInfoResponse } from '../types'

export const TRY_FETCH_PRODUCTS = 'products/TRY_FETCH_PRODUCTS'
export type TryFetchProductsAction = {
  type: 'products/TRY_FETCH_PRODUCTS'
}

export const FETCH_PRODUCTS_SUCCESS = 'products/FETCH_PRODUCTS_SUCCESS'
export type FetchProductsSuccessAction = {
  type: 'products/FETCH_PRODUCTS_SUCCESS'
  plans
  addOns
}

export const FETCH_PRODUCTS_FAIL = 'products/FETCH_PRODUCTS_FAIL'
export type FetchProductsFailAction = {
  type: 'products/FETCH_PRODUCTS_FAIL'
  error: ApiError
}

type FetchProductsAction =
  | TryFetchProductsAction
  | FetchProductsSuccessAction
  | FetchProductsFailAction

export const TRY_FETCH_CHANNELS_BY_LOCATION = 'products/TRY_FETCH_CHANNELS_BY_LOCATION'
export type TryFetchChannelsByLocationAction = {
  type: 'products/TRY_FETCH_CHANNELS_BY_LOCATION'
  params: FetchChannelsByLocationParams
}

export const FETCH_CHANNELS_BY_LOCATION_SUCCESS = 'products/FETCH_CHANNELS_BY_LOCATION_SUCCESS'
export type FetchChannelsByLocationSuccessAction = {
  type: 'products/FETCH_CHANNELS_BY_LOCATION_SUCCESS'
  payload
}

export const FETCH_CHANNELS_BY_LOCATION_FAIL = 'products/FETCH_CHANNELS_BY_LOCATION_FAIL'
export type FetchChannelsByLocationFailAction = {
  type: 'products/FETCH_CHANNELS_BY_LOCATION_FAIL'
  error: ApiError
}

type FetchChannelsByLocationAction =
  | TryFetchChannelsByLocationAction
  | FetchChannelsByLocationSuccessAction
  | FetchChannelsByLocationFailAction

export const TRY_FETCH_PRODUCT_BY_RATE_PLAN = 'products/TRY_FETCH_PRODUCT_BY_RATE_PLAN'
export type TryFetchProductByRatePlanAction = {
  type: 'products/TRY_FETCH_PRODUCT_BY_RATE_PLAN'
  params: FetchProductByRatePlanParams
}

export const FETCH_PRODUCT_BY_RATE_PLAN_SUCCESS = 'products/FETCH_PRODUCT_BY_RATE_PLAN_SUCCESS'
export type FetchProductByRatePlanSuccessAction = {
  type: 'products/FETCH_PRODUCT_BY_RATE_PLAN_SUCCESS'
  plan: Product
}

export const FETCH_PRODUCT_BY_RATE_PLAN_FAIL = 'products/FETCH_PRODUCT_BY_RATE_PLAN_FAIL'

type FetchProductByRatePlanFailAction = {
  type: 'products/FETCH_PRODUCT_BY_RATE_PLAN_FAIL'
  error: ApiError
}

export const TRY_FETCH_ADDON_BY_RATE_PLAN = 'products/TRY_FETCH_ADDON_BY_RATE_PLAN'
export type TryFetchAddonByRatePlanAction = {
  type: 'products/TRY_FETCH_ADDON_BY_RATE_PLAN'
  params: FetchProductByRatePlanParams
}

export const FETCH_ADDON_BY_RATE_PLAN_SUCCESS = 'products/FETCH_ADDON_BY_RATE_PLAN_SUCCESS'
export type FetchAddonByRatePlanSuccessAction = {
  type: 'products/FETCH_ADDON_BY_RATE_PLAN_SUCCESS'
  addon: Product
}

export const FETCH_ADDON_BY_RATE_PLAN_FAIL = 'products/FETCH_ADDON_BY_RATE_PLAN_FAIL'
export type FetchAddonByRatePlanFailAction = {
  type: 'products/FETCH_ADDON_BY_RATE_PLAN_FAIL'
  error: ApiError
}

type FetchProductByRatePlanAction =
  | TryFetchProductByRatePlanAction
  | FetchProductByRatePlanSuccessAction
  | FetchProductByRatePlanFailAction
  | TryFetchAddonByRatePlanAction
  | FetchAddonByRatePlanSuccessAction
  | FetchAddonByRatePlanFailAction

export const TRY_FETCH_FREE_TRIAL_AVAILABLE = 'products/TRY_FETCH_FREE_TRIAL_AVAILABLE'
export type TryFetchFreeTrialAvailableAction = {
  type: 'products/TRY_FETCH_FREE_TRIAL_AVAILABLE'
  countryCode: string
  postalCode: string
  seriesId?: number
  networkId?: number
  deviceId?: string
}

export const FETCH_FREE_TRIAL_AVAILABLE_SUCCESS = 'products/FETCH_FREE_TRIAL_AVAILABLE_SUCCESS'
export type FetchFreeTrialAvailableSuccessAction = {
  type: 'products/FETCH_FREE_TRIAL_AVAILABLE_SUCCESS'
  payload: FreeTrialInfoResponse
}

export const FETCH_FREE_TRIAL_AVAILABLE_FAILURE = 'products/FETCH_FREE_TRIAL_AVAILABLE_FAILURE'
export type FetchFreeTrialAvailableFailureAction = {
  type: 'products/FETCH_FREE_TRIAL_AVAILABLE_FAILURE'
  error: ApiError
}

type FetchFreeTrialAvailableAction =
  | TryFetchFreeTrialAvailableAction
  | FetchFreeTrialAvailableSuccessAction
  | FetchFreeTrialAvailableFailureAction

export type ProductsAction =
  | FetchProductByRatePlanAction
  | FetchProductsAction
  | FetchFreeTrialAvailableAction
  | FetchChannelsByLocationAction

export type FetchChannelsPayload = {
  channels: ChannelsMap
  allChannelsFetched: boolean
}
