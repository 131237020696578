import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { leagueIds, sportIds } from 'src/constants/personalizationIds' // Add this import

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/CBB_on_ACC_2021_LP_10s.mp4'}
    title="Stream college hoops on ACC Network"
    subtitle="GET LIVE GAMES, NEWS AND MORE"
    tagline="Stream it all from your TV and other devices"
    networkSet="basketball"
    customNetworks={['acc']}
    league="nba"
    showRSN={true}
    sportId={sportIds.Basketball}
    leagueId={leagueIds["Men's NCAA Basketball"]}
  />
)
