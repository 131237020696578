import { Link } from 'gatsby'
import React from 'react'
import CompareContainer from 'src/components/template/compare/components/modules/Container'
import SectionText from 'src/components/template/compare/components/modules/SectionText'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'

const CompareInterlinkingCard = ({ data }) => {
  return (
    <StyledCompareInterlinkingCard>
      <Link to={data.link}>
        <h4 className="interlinking-title">{data.name}</h4>
        <p className="interlinking-text">Fubo vs {data.competitor}</p>
        <Link href={data.link} className="interlinking-button">
          Learn More
        </Link>
      </Link>
    </StyledCompareInterlinkingCard>
  )
}

const StyledCompareInterlinkingCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  background: radial-gradient(94.79% 94.79% at 50% 5.21%, #0449b0 0%, #00183c 79.06%);
  > a {
    width: 100%;
    height: 100%;
    padding: 30px 10px;
    box-sizing: border-box;
    ${breakpointQuery.mobile} {
      padding: 26px 10px;
    }
  }
  .interlinking-title {
    color: #fff;
    text-align: center;

    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px; /* 184.615% */
    ${breakpointQuery.mobile} {
      font-size: 24px;
    }
  }
  .interlinking-text {
    margin-bottom: 25px;
    color: #5a97f1;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    ${breakpointQuery.mobile} {
      font-size: 12px;
    }
  }
  .interlinking-button {
    display: flex;
    width: 100%;
    padding: 10.34px 13.786px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 86.165px;
    background: rgba(200, 204, 223, 0.1);
    box-sizing: border-box;

    color: var(--white, #fff);
    text-align: center;

    /* Label/Label-M (Bold) */

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.14px;
  }
`

const CompareInterlinkingSection = ({ competitor }) => {
  const comparePageList = [
    {
      competitor: 'Sling TV',
      name: 'Sling TV',
      image: '',
      link: '/compare/fubo-vs-sling/',
    },
    {
      competitor: 'YouTube TV',
      name: 'YouTube TV',
      image: '',
      link: '/compare/fubo-vs-youtubetv/',
    },
    {
      competitor: 'DirecTV Stream',
      name: 'DirecTV',
      image: '',
      link: '/compare/fubo-vs-directv-stream/',
    },
    {
      competitor: 'Philo',
      name: 'Philo',
      image: '',
      link: '/compare/fubo-vs-philo/',
    },
    {
      competitor: 'Hulu + Live TV',
      name: 'Hulu',
      image: '',
      link: '/compare/fubo-vs-hulu-live-tv/',
    },
    {
      competitor: 'Cable',
      name: 'Cable',
      image: '',
      link: '/compare/fubo-vs-cable/',
    },
  ]

  return (
    <StyledCompareInterlinkingSection>
      <CompareContainer className="content">
        <SectionText heading="See how Fubo stacks up against the competition" />
        <div className="interlinking-card-container">
          {comparePageList.map((item, index) => {
            if (competitor !== item.competitor)
              return <CompareInterlinkingCard key={index} data={item} />
          })}
        </div>
      </CompareContainer>
    </StyledCompareInterlinkingSection>
  )
}

export default CompareInterlinkingSection

const StyledCompareInterlinkingSection = styled.section`
  .interlinking-card-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    ${breakpointQuery.mobile} {
      grid-template-columns: repeat(2, 1fr);
      ${'' /* gap: 5px; */}
    }
  }
`
