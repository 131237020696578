import React from 'react'
import LiveTemplateDefault from 'src/components/template/live/canada'
export default (props) => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="canada"
    callsign="PRLITVGD"
    background="https://assets-imgx.fubo.tv/marketing/lp/images/posters/onebox-epl.png?w=1920&ch=width&auto=format,compress"
    title="Watch every Premier League match live exclusively on Fubo"
    subtitle="See your favourite clubs in action. Stream select matches in 4K. Cancel anytime."
    plan="soccer"
    networkSet="canadaEssentials"
    customNetworks={['fsn']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-epl-white.png?ch=width&auto=format,compress"
    logoHeight="90px"
    networkName="Premier League TV"
  />
)
