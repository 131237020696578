import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_thunder.videoUrl}
    staticAnnotation={videos.bally_thunder.staticAnnotation}
    title="Watch Oklahoma City basketball live"
    subtitle="STREAM THE THUNDER ON FANDUEL SPORTS OKLAHOMA"
    teamId={teamIds['Oklahoma City Thunder']}
  />
)
