import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    //nba league pass addon
    // addonCode="nbalp"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/NBA_ESPN_2021_LP_20s.mp4'}
    title="Watch the NBA on ESPN"
    subtitle="LIVE GAMES, EXPERT ANALYSIS AND MORE"
    tagline="Stream ESPN anytime, anywhere, from any device"
    ctaText="Start Watching"
    networkSet="basketball"
    league="nba"
    showRSN={true}
    sportId={sportIds.Basketball}
    leagueId={leagueIds.NBA}
    // matchtickerSportId="59"
    // matchtickerLeagueName="NBA"
    // matchtickerLeagueId="191276"
  />
)
