import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { laChannelCount } from 'src/constants/laPlanData'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
export default (props) => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      poster={`https://assets-imgx.fubo.tv/marketing/lp/images/posters/LA_NBA_LP_Web.jpg`}
      posterMobile={`https://assets-imgx.fubo.tv/marketing/lp/images/posters/LA_NBA_LP_Mobile.jpg`}
      title="Watch NBA live in Spanish"
      subtitle={`${laChannelCount}+ channels of sports, news, novelas, and entertainment in Spanish`}
      networkSet="entertainmentLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/nba-es/"
      sportId={sportIds.Basketball}
      leagueId={leagueIds.NBA}
    />
  )
}
