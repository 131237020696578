import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_hornets.videoUrl}
    staticAnnotation={videos.bally_hornets.staticAnnotation}
    title="Watch Charlotte basketball live"
    subtitle="STREAM THE HORNETS ON FANDUEL SPORTS SOUTHEAST"
    teamId={teamIds['Charlotte Hornets']}
  />
)
