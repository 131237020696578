import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { laDiscountPriceMonthly, laChannelCount } from 'src/constants/laPlanData'
export default (props) => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/equipo-nacional-mx-bg.jpg?ch=width&auto=format,compress"
      title="Mira al equipo de México en vivo"
      subtitle={`Accede ahora por solo $${laDiscountPriceMonthly} el primer mes`}
      tagline={`Más de ${laChannelCount} canales con deportes, noticias, novelas y entretenimiento en español.`}
      networkSet="soccerLatino"
    />
  )
}
