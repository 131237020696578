import { createSelector } from 'reselect'

import { AppState } from '../index.js'

export const selectZvaState = (state: AppState) => state.zva

export const selectIsZvaLoading: (state: AppState) => boolean = createSelector(
  selectZvaState,
  (zva) => zva.isLoading
)
