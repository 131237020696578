import React from 'react'
import NFL from 'src/pages/nfl'
import { teamIds } from 'src/constants/personalizationIds'
export default (props) => (
  <NFL
    {...props}
    team="Los Angeles Chargers"
    teamShort="Chargers"
    teamId={teamIds['Los Angeles Chargers']}
  />
)
