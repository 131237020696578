import React from 'react'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'
import BallySportsNHL from 'src/components/page/bally-sports-nhl'

export default (props) => {
  return (
    <BallySportsNHL
      location={props.location}
      video={videos.bally_hurricanes.videoUrl}
      staticAnnotation={videos.bally_hurricanes.staticAnnotation}
      title="Watch Carolina Hurricanes hockey"
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream FanDuel Sports from any device. No cable required."
      teamId={teamIds['Carolina Hurricanes']}
    />
  )
}
