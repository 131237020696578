import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { ratePlanCodes } from 'src/constants'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    //nba league pass addon
    offerCode={ratePlanCodes.elite}
    addonCode="nbalp"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/NBA_LeaguePass_2021_LP_16s.mp4'}
    title="See the NBA’s biggest stars"
    subtitle="WATCH LIVE GAMES WITH NBA LEAGUE PASS"
    tagline="Stream on any device. Blackout restrictions apply."
    ctaText="Start Watching"
    networkSet="basketball"
    league="nba"
    showRSN={true}
    sportId={sportIds.Basketball}
    leagueId={leagueIds.NBA}
    // matchtickerSportId="59"
    // matchtickerLeagueName="NBA"
    // matchtickerLeagueId="191276"
  />
)
