import { TRANSCEND_INIT_FAILED, TRANSCEND_INIT_SUCCESS } from './actionTypes'

export type TranscendState = {
  isInitialized: boolean
}

const initialState: TranscendState = {
  isInitialized: false,
}

export const transcendReducer = (state = initialState, action): TranscendState => {
  switch (action.type) {
    case TRANSCEND_INIT_SUCCESS:
      return {
        ...state,
        isInitialized: true,
      }
    case TRANSCEND_INIT_FAILED:
      return {
        ...state,
        isInitialized: false,
      }
    default:
      return state
  }
}
