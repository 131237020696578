import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'

export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/NESN_2021_LP_17s.mp4'}
      staticAnnotation={networkLogos.nesn}
      title="Stream Boston Sports Live"
      subtitle="GET FUBO AND START WATCHING NESN"
      tagline="Anytime, anywhere. On your phone, tablet, TV & other devices. Regional restrictions apply."
      networkSet="sports"
      customNetworks={['nesn']}
    />
  )
}
