import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    video={videos.bally_twins.videoUrl}
    title="Watch Minnesota baseball live"
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    networkSet="baseball"
    sportId={sportIds.Baseball}
    leagueId={leagueIds.MLB}
    teamId={teamIds['Minnesota Twins']}
    customNetworks={['fanduelsports']}
  />
)
