import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/CA_ArsenalFanOrg_LP_9s.mp4'}
      title="Watch Arsenal soccer live"
      subtitle="STREAM EVERY PREMIER LEAGUE MATCH"
      tagline="Available all season exclusively on Fubo"
      plan="soccer"
      networkSet="canada"
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-epl-white.png?ch=width&auto=format,compress"
      logoHeight="90px"
      team="Arsenal"
      // hasScheduleBanner={true}
      soccerPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_soccer-arsenal.jpg?w=451&ch=width&auto=format,compress"
    />
  )
}
