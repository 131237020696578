import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'

//REPLACED: univision_removed
export default (props) => <RedirectTemplate location={props.location} destination="/latino-es/" />

//REMOVED: univision_removed
// export default props => {
//   return (
//     <LatinoTemplateDefault
//       location={props.location}
//       lang="es"
//       market="latino"
//       video={videos.leagues_cup.videoUrl}
//       title="Mira la Leagues Cup en Univision"
//       subtitle="VE A LOS EQUIPOS DE LA LIGA MX VS. LA MLS"
//       tagline="Accede a los partidos en vivo desde cualquier dispositivo con Fubo"
//       networkSet="soccerLatino"
//       customNetworks={['univision', 'unimas']}
//       noteLink="/la/leagues-cup/"
//     />
//   )
// }
