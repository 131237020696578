import { useCallback, useEffect, useState } from 'react'

const useIsMobile = (breakpoint = 768) => {
  const [isMobile, setIsMobile] = useState(false)

  const checkIsMobile = useCallback(() => {
    return window.innerWidth <= breakpoint
  }, [breakpoint])

  useEffect(() => {
    function handleResize() {
      setIsMobile(checkIsMobile())
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [checkIsMobile])

  return isMobile
}

export default useIsMobile
