import {
  ANALYTICS_INIT_SUCCESS,
  ANALYTICS_PROPERTIES_READY,
  COHORT_EVENT_INITIALIZED,
  PAGE_VIEW_SENT,
  UPDATE_ANALYTICS_PROPERTIES,
  UPDATE_PARAMETERS,
} from './actionsTypes'

const initialState = {
  analyticsReady: false,
  analyticsPropertiesReady: false,
  pageViewSent: false,
  parameters: null,
  properties: {},
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ANALYTICS_INIT_SUCCESS:
      return {
        ...state,
        analyticsReady: true,
      }
    case UPDATE_ANALYTICS_PROPERTIES:
      return {
        ...state,
        properties: {
          ...state.properties,
          ...action.payload,
        },
      }
    case ANALYTICS_PROPERTIES_READY:
      return {
        ...state,
        analyticsPropertiesReady: true,
      }
    case UPDATE_PARAMETERS:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          ...action.payload,
        },
      }
    case PAGE_VIEW_SENT:
      return {
        ...state,
        pageViewSent: true,
      }

    case COHORT_EVENT_INITIALIZED:
      return {
        ...state,
        cohortInitialized: true,
      }
    default:
      return state
  }
}
