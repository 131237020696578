import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import styled from 'styled-components'

export default (props) => {
  return (
    <StyledContainer>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        // video={videos.seasonal_us.videoUrl}
        // videoMobile={videos.seasonal_us.videoMobileUrl}
        // annotations={videos.seasonal_us.annotations}
        // noVideo={true}
        disableFreeTrial={true}
        poster={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/us_logosoup_2.jpg'}
        title="Stream live TV & sports"
        subtitle="Watch and DVR the channels you love."
        showRSN={true}
        highlightImage={'https://assets-imgx.fubo.tv/marketing/lp/images/holidays/Snow-G_LP_v3.png'}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .hero-content {
    h1 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
      }
    }
    h2 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
      }
    }
  }
`
