import React from 'react'
import styled from 'styled-components'
import { Play } from 'src/components/common/ScheduleBannerV2/play'
import { useSelector } from 'react-redux'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { addParams } from 'src/modules/utils/addParams'
import Image from '../Image'

const CarouselItem = ({ lang = 'en', item, index, link, ctaText }) => {
  const stateForProtobuf = useSelector(selectStateForProtobuf)

  const handleOnClick = (item) => {
    fireUIInteractionEvent(
      {
        element: item.title,
        type: item.live ? 'live' : 'upcoming',
        component: 'schedule_banner_match_clicked',
      },
      stateForProtobuf
    )
  }

  let learnMore = ctaText || 'Start for Free'
  let live = 'Live'
  if (lang === 'es') {
    learnMore = 'Pruébalo Gratis'
    live = 'En Vivo'
  }

  return (
    <StyledCarouselItem
      key={index}
      className="carousel-item"
      href={link}
      onClick={() => handleOnClick(item)}
    >
      <div className="carousel-item-thumbnail">
        <div className="carousel-thumbnail-fill"></div>
        <div className="carousel-thumbnail-overlay">
          {' '}
          {item.live ? (
            <div className="play-icon">
              <Play />
            </div>
          ) : (
            <>{ctaText || learnMore}</>
          )}
        </div>
        <div className="carousel-image">
          {item.thumbnail && (
            <Image src={addParams(item.thumbnail, 'w=400&auto=format,compress')} alt={item.text} />
          )}
        </div>
        <div className="carousel-item-meta">{item.meta}</div>
        {/* {item.live && <div className="carousel-badge live">{live}</div>} */}
      </div>
      {/* <div className="carousel-item-title">{item.title}</div> */}
    </StyledCarouselItem>
  )
}

export default CarouselItem

const StyledCarouselItem = styled.a`
  display: flex !important;
  justify-content: space-around;
  flex-direction: column;
  ${'' /* align-items: center; */}
  position: relative;
  box-sizing: border-box;
  width: 200px !important;

  @media (max-width: 768px) {
    width: 140px !important;
  }

  &:hover {
    .carousel-item-thumbnail {
    }
    .carousel-item-thumbnail .carousel-thumbnail-overlay {
      opacity: 1;
    }
  }

  .carousel-item-thumbnail {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    ${'' /* margin-bottom: 12px; */}
    @media (max-width: 768px) {
      ${'' /* margin-bottom: 6px; */}
    }

    .carousel-thumbnail-fill {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      ${'' /* background: linear-gradient(29deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%); */}
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.86) 4.29%, rgba(0, 0, 0, 0.00) 55.07%);
      z-index: 1;
    }

    .carousel-thumbnail-overlay {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 700;
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
      z-index: 4;
      outline: 2px solid #fff;
      outline-offset: -2px;
      border-radius: 7px;
    }

    .play-icon {
      width: 27%;
      aspect-ratio: 1;
      border-radius: 50%;
      background: rgba(229, 230, 230, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 55%;
      }
    }

    img {
      width: 100%;
    }

    .carousel-image {
      width: 100%;
    }
  }
  .carousel-badge {
    position: absolute;
    bottom: 10px;
    left: 10px;
    border-radius: 2px;
    padding: 2px 4px;
    font-size: 14px;
    font-weight: 700;
    z-index: 2;
    @media (max-width: 768px) {
      bottom: 6px;
      left: 6px;
    }
    &.live {
      background: #ff3a03;
    }
    &.upcoming {
      background: #494a55;
    }
  }
  .carousel-item-meta {
    position: absolute;
    bottom: 4px;
    left: 10px;
    color: #d0d2d2;
    font-size: 10px;
    font-weight: 500;
    z-index: 5;
    @media (max-width: 768px) {
      font-size: 8px;
    }
  }
  .carousel-item-title {
    color: #fafafa;
    font-size: 16px;
    font-weight: 700;
    margin-top: 6px;
    @media (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
`
