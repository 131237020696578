import { getFuboEnv } from 'src/config/env'

const qa = {
  amolio: {
    host: 'https://amolio-dev.fubo.tv',
  },
  // segment.io analytics
  segment: {
    publicKey: 'k9vwcm56po',
  },
}

const prod = {
  amolio: {
    host: 'https://amolio.fubo.tv',
  },
  // segment.io analytics
  segment: {
    publicKey: 'jh2q6sqatv',
  },
}

const envConfig = getFuboEnv() === 'prod' ? prod : qa

export default function initAnalytics() {
  vendorScript()
  try {
    window.analytics.load(envConfig.amolio.host, envConfig.segment.publicKey)
  } catch (err) {
    console.error('Analytics script load error!')
  }
}

/* eslint-disable */
function vendorScript() {
  // Create a queue, but don't obliterate an existing one!
  let analytics = (window.analytics = window.analytics || [])
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    console.error('Segment snippet included twice.')
    return
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ]

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      let args = Array.prototype.slice.call(arguments)
      args.unshift(method)
      analytics.push(args)
      return analytics
    }
  }

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    let key = analytics.methods[i]
    analytics[key] = analytics.factory(key)
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (host, key) {
    // Create an async script element based on your key.
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `${host}/scitylana/v1/${key}`

    // Insert our script next to the first script element.
    let first = document.getElementsByTagName('script')[0]
    first.parentNode.insertBefore(script, first)
  }

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.0.0'
}
/* eslint-enable */
