import React, { useEffect, useState } from 'react'
import CompareCardFaq from 'src/components/template/compare/components/modules/CompareCardFaq'
import CompareContainer from 'src/components/template/compare/components/modules/Container'
import Footnote from 'src/components/template/compare/components/modules/Footnote'
import SectionText from 'src/components/template/compare/components/modules/SectionText'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'

const commonData = [
  {
    id: 'fubo-faq-1',
    question: 'How much is Fubo?',
    answer: (
      <p>
        Fubo currently has three plans that range from $84.99 to $104.99 per month. For more details
        about Fubo plans, <a href="">visit here.</a>
      </p>
    ),
  },
  {
    id: 'fubo-faq-2',
    question: 'Is Fubo better than cable TV?',
    answer: (
      <p>
        Since Fubo offers such wide-variety of TV’s most popular live channels with significant
        savings, it is one of the top choices among cord cutters. Fubo’s lineup provides
        well-rounded access to live sports, top TV series, popular movies and breaking news.
      </p>
    ),
  },
  {
    id: 'fubo-faq-3',
    question: 'How many devices can you stream on at once with Fubo?',
    answer: (
      <p>
        Fubo lets users stream on multiple devices at the same time. They currently allow
        simultaneous streaming on up to 10 devices.
      </p>
    ),
  },
  {
    id: 'fubo-faq-4',
    question: 'Which devices can I stream on with Fubo?',
    answer: (
      <p>
        The Fubo app is accessible on every device. Stream live TV from a smart TV, phone, laptop,
        computer, tablet, connected device or select game consoles.
      </p>
    ),
  },
  {
    id: 'fubo-faq-5',
    question: 'What channels does Fubo have',
    //REMOVED: univision_removed
    // answer: (
    //   <p>
    //     Fubo and most cable providers have many of the same channels. However, Fubo has an edge with
    //     their Pro plan vs the average basic cable plan. The Fubo Pro plan includes major sports
    //     networks like NFL Network, Big Ten Network, CBS Sports Network as well as major
    //     entertainment networks like Disney XD and Univision.
    //   </p>
    // ),
    //REPLACED: univision_removed
    answer: (
      <p>
        Fubo and most cable providers have many of the same channels. However, Fubo has an edge with
        their Pro plan vs the average basic cable plan. The Fubo Pro plan includes major sports
        networks like NFL Network, Big Ten Network, CBS Sports Network as well as major
        entertainment networks like Disney XD.
      </p>
    ),
  },
  {
    id: 'fubo-faq-6',
    question: 'Where can I learn more about Fubo?',
    answer: (
      <>
        <p>You can find more comparisons for the Fubo product below.</p>
        <ul>
          <li>
            <a href="/stream/compare/fubo-vs-sling/">Fubo vs Sling TV</a>
          </li>
          <li>
            <a href="/stream/compare/fubo-vs-youtubetv/">Fubo vs YouTube TV</a>
          </li>
          <li>
            <a href="/stream/compare/fubo-vs-directv-stream/">Fubo vs DirecTV</a>
          </li>
          <li>
            <a href="/stream/compare/fubo-vs-philo/">Fubo vs Philo</a>
          </li>
          <li>
            <a href="/stream/compare/fubo-vs-hulu-live-tv/">Fubo vs Hulu + Live TV</a>
          </li>
          <li>
            <a href="/stream/compare/fubo-vs-cable/">Fubo vs cable TV</a>
          </li>
        </ul>
      </>
    ),
  },
]

const CompareFaqs = ({ data }) => {
  const [activeFaqsList, setActiveFaqsList] = useState([])

  useEffect(() => {
    let faqsList = commonData

    if (data && data.qaData) {
      data.qaData.map((item) => {
        const id = item.id
        const faqsListIndex = faqsList.findIndex((item) => item.id === id)
        if (faqsListIndex > -1) {
          faqsList[faqsListIndex] = item
        }
      })
    }

    setActiveFaqsList(faqsList)
  }, [data])

  return (
    <StyledCompareFaqs>
      <CompareContainer className="content">
        <SectionText heading="Frequently Asked Questions" />
        <div className="faq-container">
          {activeFaqsList.map((item, index) => (
            <CompareCardFaq question={item.question} key={index}>
              {item.answer}
            </CompareCardFaq>
          ))}
        </div>
        <Footnote className="footnote">{data.note || '*Up to date as of 6/8/2023'}</Footnote>
      </CompareContainer>
    </StyledCompareFaqs>
  )
}

export default CompareFaqs

const StyledCompareFaqs = styled.section`
  ${'' /* padding-bottom: 100px; */}
  .faq-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;

    margin-bottom: 40px;

    ${breakpointQuery.desktop} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .footnote {
    font-size: 12px;
    line-height: 20px;

    ${breakpointQuery.desktop} {
      font-size: 14px;
    }
  }
`
