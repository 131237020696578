import { combineEpics } from 'redux-observable'
import { AppState } from '../reducers'
import { Action } from '../reducers/actionTypes'
import { stacFlagsEpic } from '../reducers/cohorts/epics'
import { analyticsEpic } from '../reducers/analytics/epics'
import { impactEpic } from '../reducers/impact/epics'
import { zvaEpic } from '../reducers/zva/epics'
export default combineEpics<Action, Action, AppState>(
  stacFlagsEpic,
  analyticsEpic,
  impactEpic,
  zvaEpic
)
