import React from 'react'

const Checkbox = () => {
  return (
    <svg
      className="selected-icon check"
      width="24"
      height="24"
      viewBox="0 0 30 30"
      role="img"
      aria-label="check mark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 16 4 C 9 4 4 9 4 16 C 4 23 9 28 16 28 C 23 28 28 23 28 16 C 28 9 23 4 16 4 Z Z"
        fill="#fff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3ZM22.7044 12.7098C23.0964 12.3207 23.0988 11.6876 22.7098 11.2956C22.3207 10.9036 21.6876 10.9012 21.2956 11.2902L13.9393 18.5911L10.7044 15.3809C10.3124 14.9919 9.67922 14.9943 9.29019 15.3863C8.90116 15.7784 8.90359 16.4115 9.29561 16.8006L13.2349 20.7098C13.6248 21.0967 14.2539 21.0967 14.6437 20.7098L22.7044 12.7098Z"
        fill="rgb(var(--fubo-color-orange100))"
      ></path>
    </svg>
  )
}

export default Checkbox
