export const production = {
  env: 'production',

  default: { host: 'https://www.fubo.tv/stream' },
  api: {
    host: 'https://api.fubo.tv',
  },
  config: {
    host: 'https://www.fubo.tv',
  },
  imgixURI: 'https://fubo-landing-prod.imgix.net/',

  // Fubo Imgix path
  imgix: 'https://assets-imgx.fubo.tv',

  amolio: {
    host: 'https://amolio.fubo.tv',
  },
  // launchDarkly feature toggle
  launchDarkly: '573659fdd755f106d9000374',

  // segment.io analytics
  segment: {
    publicKey: 'jh2q6sqatv',
  },

  impact: {
    url: 'https://trkapi.impact.com/PageLoad',
    campaignId: 5119,
  },
  zva: {
    apiKey: 'dN62y0A6T_GmWyv-1LZFJw',
    dataEnv: 'us01',
  },
}
