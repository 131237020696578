import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
import { videos } from 'src/constants/videos'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.cbb.videoUrl}
    staticAnnotation={videos.cbb.staticAnnotation}
    title="Watch college basketball live"
    subtitle="SEE TOP TEAMS FROM AROUND THE COUNTRY"
    tagline="Stream from any device. Regional restrictions apply."
    networkSet="basketball"
    league="ncaa"
    showRSN={true}
    matchtickerSportId="59"
    matchtickerLeagueName="NCAA"
    matchtickerLeagueId="191260"
    sportId={sportIds.Basketball}
    leagueId={leagueIds["Men's NCAA Basketball"]}
  />
)
