export const ratePlanCodes = {
  canada_cricket_mo: 'ca-recu-ga-crick_sportsp-mo-nt-v1',
  canada_cricket_qt: 'ca-recu-ga-crick_sportsp-qt-nt-v2',
  canada_cricket_yr: 'ca-recu-ga-crick_sportsp-yr-nt-v2',
  canada_ent_mo: 'ca-recu-ga-entertainment-mo-7d-v2',
  canada_ent_qt: 'ca-recu-ga-entertainment-qt-7d-v2',
  canada_premium_mo: 'ca-recu-ga-premium-mo-nt-v2',
  canada_premium_qt: 'ca-recu-ga-premium-qt-nt-v1',
  canada_premium_yr: 'ca-recu-ga-premium-yr-nt-v2',
  canada_sports_mo: 'ca-recu-ga-sports-mo-nt-4amtoff1-v1',
  canada_sports_qt: 'ca-recu-ga-sports-qt-nt-39amtoff2-v1',
  canada_sports_yr: 'ca-recu-ga-sports-yr-nt-63amtoff1-v1',
  elite: 'us-recu-ga-elite-mo-5d-30amtoff1-v1',
  essential: 'us-recu-ga-essential-mo-5d-35amtoff1-v1',
  latino: 'us-recu-ga-latino-mo-5d-5amtoff1-v1',
  latino_baseball_1rsn: 'us-recu-mk-latinobb-1rsn-mo-5d-8amtoff1-v1',
  latino_baseball_2rsn: 'us-recu-mk-latinobb-2rsn-mo-5d-10amtoff1-v1',
  pro: 'us-recu-ga-pro-mo-5d-30amtoff1-v1',
  zee_family_mo: 'us-recu-ga-zee_family-mo-nt-v1',
  zee_family_qt: 'us-recu-ga-zee_fam-qt-nt-5matoff1-v1',
  zee_family_yr: 'us-recu-ga-zee_fam-yr-nt-v1',
  mlbtv_mo: 'us-rc-ga-mlbtv-mo-nt-1',
  mlbtv_mo_w_trial: 'us-rc-ga-mlbtv-mo-7d-1',
}
