import React from 'react'
import { useSelector } from 'react-redux'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { selectParams } from 'src/modules/reducers/params'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
export default (props) => {
  const params = useSelector(selectParams)
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      offerBanner={
        params?.offer
          ? '✔ Special offer activated: Enjoy 30 days FREE.'
          : 'Offer code not found. Please use the link in your email.'
      }
      // hasOfferInput={true}
      video={videos.bally_bucks.videoUrl}
      staticAnnotation={videos.bally_bucks.staticAnnotation}
      title="Watch Milwaukee Bucks basketball"
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream FanDuel Sports from any device. No cable required. Regional restrictions apply."
      networkSet="basketball"
      customNetworks={['fanduelsports']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-milwaukee-bucks-v2.png?ch=width&auto=format,compress"
      logoHeight="120px"
      league="NBA"
      sportId={sportIds.Basketball}
      leagueId={leagueIds.NBA}
      teamId={teamIds['Milwaukee Bucks']}
    />
  )
}
