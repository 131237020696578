import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={
        'https://assets.fubo.tv/marketing/lp/videos/compressed/Winter_Olympics_2022_LP_17s.mp4'
      }
      staticAnnotation={{
        logoUrl:
          'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/cbc-olympic-stacked-white.png',
        height: 100,
      }}
      title="Watch CBC’s Olympic coverage live with Fubo"
      subtitle="SEE CANADA COMPETE IN THE OLYMPIC WINTER GAMES"
      tagline="Watch and DVR all your favorite events."
      ctaText="Watch Now"
      networkSet="canada"
    />
  )
}
