import { createSelector } from 'reselect'
import { filterCTAParams } from 'src/modules/utils/filterCTAParams'
import { ParamsState } from './reducers'

type RootState = {
  params: ParamsState
}

export const selectParams = (state: RootState) => state.params

export const selectCTAParams = createSelector(selectParams, (state) => filterCTAParams(state))
