import React from 'react'
import AdcashTemplate from 'src/components/template/adcash'
export default (props) => (
  <AdcashTemplate
    {...props}
    title={
      <>
        Cricket is <span className="desktop-line-break">better with Fubo</span>
      </>
    }
    ctaURL={'/stream/ca/cricket/'}
    image={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/cricket-better-web-v2.png'}
    imageMobile={
      'https://assets-imgx.fubo.tv/marketing/lp/images/posters/cricket-better-mobile-v2.png'
    }
  />
)
