import {
  CanadaDefaultCtaText,
  defaultCTA,
  LatinoDefaultCtaText,
  LatinoDefaultCtaText_ES,
  LatinoDefaultCtaTextShort,
  USDefaultCtaText,
  USDefaultCtaText_ES,
  USDefaultCtaTextShort,
} from '../constants'

export const getCtaText = ({ lang, market, isTextShort, disableFreeTrial = false }) => {
  market = market === 'all' || market === null ? 'us' : market
  const disableFreeTrialText = disableFreeTrial ? defaultCTA[market].noTrial : null
  const ctaMap = {
    latino: {
      es: disableFreeTrialText?.text_es || LatinoDefaultCtaText_ES,
      short: disableFreeTrialText?.textShort || LatinoDefaultCtaTextShort,
      default: disableFreeTrialText?.text || LatinoDefaultCtaText,
    },
    canada: {
      default: disableFreeTrialText?.text || CanadaDefaultCtaText,
    },
    us: {
      es: disableFreeTrialText?.text_es || USDefaultCtaText_ES,
      short: disableFreeTrialText?.textShort || USDefaultCtaTextShort,
      default: disableFreeTrialText?.text || USDefaultCtaText,
    },
  }

  const marketTexts = ctaMap[market] || ctaMap.us
  if (lang === 'es') return marketTexts.es || marketTexts.default
  if (isTextShort) return marketTexts.short || marketTexts.default
  return marketTexts.default
}
