import Color from 'color'

import { colorTokens } from './colorTokens'
import { COLOR_PREFIX, rgbValues } from './palette'
import {
  fontsValues,
  fontSizeValues,
  lineHeightValues,
  weightsValues,
  letterSpacingValues,
  transformationValues,
  TRANSFORMATION_PREFIX,
  FONT_PREFIX,
  FONT_WEIGHT_PREFIX,
} from './typography'

const BOGUS_COLOR = 'rgba(90,255,90, 0.99)'
export function rgba(p1: number | string, p2: number, p3?: number, p4?: number): string {
  if (typeof p1 === 'string' && typeof p2 === 'number') {
    return `rgba(${unroll(p1)}, ${p2})`
  } else if (
    typeof p1 === 'number' &&
    typeof p2 === 'number' &&
    typeof p3 === 'number' &&
    typeof p4 === 'number'
  ) {
    return `rgba(${p1},${p2},${p3},${p4})`
  } else {
    console.warn('Invalid design-system/rgba arguments', p1, p2, p3, p4)
    return BOGUS_COLOR
  }
}

// Warning! This function modifies provided object values with links to them!
function flattenColorsObject(obj: Record<string, unknown>, accPath = ''): Record<string, string> {
  const retObj: Record<string, any> = {}
  for (const [key, value] of Object.entries(obj)) {
    if (value && typeof value === 'object') {
      // @ts-expect-error - TS2345 - Argument of type 'object' is not assignable to parameter of type 'Record<string, unknown>'.
      const flatObject = flattenColorsObject(value, accPath + '-' + kebab(key))
      for (const [flatKey, flatValue] of Object.entries(flatObject)) {
        const fullKey = kebab(key) + '-' + kebab(flatKey)
        retObj[fullKey] = flatValue
      }
    } else {
      const leafKey = kebab(key)
      const varLink = `var(${COLOR_PREFIX + accPath + '-' + leafKey})`
      retObj[leafKey] = unroll(value)
      // Replace original value with the variable link
      obj[key] = String(value).startsWith('rgba(') ? varLink : `rgb(${varLink})`
    }
  }
  return retObj
}

// Naive implementation camelCase to kebab-case conversion
function kebab(s: string): string {
  return s.replace(/[A-Z]+/g, (v) => '-' + v.toLowerCase())
}

// if using a prefix, expected format includes starting prefix with two dashes/--, e.g. --fubo-color
function setCssVariables(valuesObj: any, prefix?: string) {
  if (typeof window !== 'undefined') {
    const root = document.querySelector(':root')
    for (const [key, value] of Object.entries(valuesObj)) {
      const tokenKey = key.replace(/_/g, '-')
      if (prefix) {
        // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'Element'.
        root?.style.setProperty(`${prefix}-${tokenKey}`, String(value))
      } else {
        // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'Element'.
        root?.style.setProperty(`--${tokenKey}`, String(value))
      }
    }
  }
}

function unroll(s: unknown): string {
  if (typeof s !== 'string') return String(s)
  if (s.match(/^#(([\da-f]{3})|([\da-f]{6}))$/i)) {
    return new Color(s).color.toString() // #123 or #123abc => r,g,b
  }

  const found = s.match(/^rgb\((\S+)\)$/i)
  return found?.[1] || s // rgb(someValue) => someValue
}

export function attachCssVariables() {
  // color Tokens
  setCssVariables(rgbValues, COLOR_PREFIX)
  setCssVariables(flattenColorsObject(colorTokens), COLOR_PREFIX)

  // typography tokens
  setCssVariables(fontsValues, FONT_PREFIX)
  setCssVariables(weightsValues, FONT_WEIGHT_PREFIX)
  setCssVariables(transformationValues, TRANSFORMATION_PREFIX)
  setCssVariables(fontSizeValues)
  setCssVariables(lineHeightValues)
  setCssVariables(letterSpacingValues)
}

export const emToPx = (em: number): number => {
  if (em) {
    return em * 16
  }
  return undefined
}
