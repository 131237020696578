import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import styled from 'styled-components'
export default (props) => (
  <StyledContainer>
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/wnba.jpg?w=1920&ch=width&auto=format,compress"
      title="Watch WNBA with Fubo"
      subtitle="CATCH THE ACTION LIVE ON NBA TV CANADA"
      tagline="Stream games from your TV, computer & other devices"
      networkSet="canada"
      plan="premium"
      customNetworks={['nbatvca']}
      league="WNBA"
      showRSN={true}
      additionalFAQs={[
        {
          question: 'What is NBA TV Canada?',
          answer:
            'Canada’s Home of Basketball. Giving fans 24/7 access to all the hardcourt action. 100s of LIVE games from across the NBA, WNBA, and NBA G-League. Exclusive programming from NBA Gametime, Inside the NBA, NBA360 and NBAXL! Plus, in-depth coverage of the Toronto Raptors! 1 channel. Lots of reasons to subscribe',
        },
      ]}
    />
  </StyledContainer>
)

const StyledContainer = styled.div`
  .videoWrapper {
    @media (max-width: 768px) {
      background-position: 60% center;
    }
  }
`
