import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    video={videos.boston_red_sox.videoUrl}
    title="Watch Boston Red Sox baseball"
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    tagline="Stream it all on NESN from any device. Regional restrictions apply."
    networkSet="baseball"
    customNetworks={['nesn']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-boston-red-sox-v4.png?w=450&ch=width&auto=format,compress"
    logoHeight="100px"
    sportId={sportIds.Baseball}
    leagueId={leagueIds.MLB}
    teamId={teamIds['Boston Red Sox']}
  />
)
