// web

export const WEB_MARKETING_FAQ_MESSAGING = 'web:marketing-faq-messaging-v2'
export const WEB_MARKETING_PROMO_BANNER_US = 'web:marketing-promo-banner-us'
export const WEB_MARKETING_PROMO_BADGE = 'web:marketing-promo-badge'
export const WEB_MARKETING_CTA_COPY_CONTINUE = 'web:marketing-cta-copy-continue'
export const WEB_MARKETING_VALUE_PROPS_HERO = 'web:marketing-value-props-hero'
export const WEB_MARKETING_ADCASH_CTA_URL = 'web:marketing-adcash-cta-url'
export const WEB_MARKETING_FANDUEL_W_PRO = 'web:marketing-fanduel-with-pro'
export const WEB_MARKETING_NO_PROMO_BANNER = 'web:marketing-no-promo-banner'
export const WEB_MARKETING_MLB_TV_W_TRIAL = 'web:marketing-mlbtv-w-trial'
