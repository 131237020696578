import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import HeroTitle from './components/heroTitle'

import { Location } from '@reach/router'
import { getURIParamValue } from 'src/modules/utils/analytics'
import { defaultCTAURI } from 'src/modules/utils/constants'
import CTAButton from 'src/components/common/CTAButton'
import { useSelector } from 'react-redux'
import { selectCTAParams, selectParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { getImageName } from 'src/modules/utils/files'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { getCtaText } from 'src/modules/utils/language/buttons'

const HeroVideoPlayer = (props) => {
  const {
    location,
    title,
    subTitle,
    ctaText,
    background = 'https://assets-imgx.fubo.tv/marketing/lp/images/posters/live_preview_bg-2.jpg?w=1920&ch=width&auto=format,compress',
    logo = 'https://assets-imgx.fubo.tv/marketing/lp/images/logos/Fubo_Primary_Logo_WT_RGB.png?w=300&ch=width&auto=format,compress',
    logoHeight,
    video,
    lang,
    videoUrlBase,
    market,
    disableFreeTrial = false,
  } = props
  // get ctaParams from redux
  const params = useSelector(selectParams)
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  const urlClip = getURIParamValue(location.search, 'clip')

  const stateForProtobuf = useSelector(selectStateForProtobuf)

  const ctaCopy = ctaText || getCtaText({ lang, market, disableFreeTrial })
  const handleOnClick = () => {
    fireUIInteractionEvent(
      {
        element: ctaCopy,
        component: 'hero_section_cta_clicked',
      },
      stateForProtobuf
    )
  }

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'hero')

  const [videoSource, setVideoSource] = useState(video)

  useEffect(() => {
    if (urlClip) {
      setVideoSource(videoUrlBase + urlClip)
    } else {
      setVideoSource(video)
    }

    const videoElement = document.getElementById('sizzleVideo')
    if (videoElement) {
      videoElement.load() // This will reload the video element
    }
  }, [urlClip])

  const [statePosterImg, setStatePosterImg] = React.useState('')

  const getFirstFrame = (urlClip) => {
    if (urlClip !== undefined) {
      let poster = videoUrlBase + getImageName(urlClip) + '?ch=width&auto=format,compress'
      return poster
    }
  }

  React.useEffect(() => {
    // Checks for the custom bgvideo query param used by marketing to override the background video
    const poster = getFirstFrame(videoSource)
    setStatePosterImg(poster)
  }, [videoSource])

  return (
    <Location>
      {({ location }) => (
        <StyledHeroVideoPlayer backgroundVideo={background} logoHeight={logoHeight}>
          <div className="logo-wrapper">
            <img src={logo} alt="Fubo Logo" width="100%" />
          </div>
          <div className="hero-content">
            <HeroTitle title={title} subtitle={subTitle} location={location} />
            <div className="hero-video-container">
              <video
                id="sizzleVideo"
                muted
                controls
                playsInline
                autoPlay={true}
                crossOrigin
                poster={statePosterImg}
              >
                <source src={videoSource} type="video/mp4" />
              </video>
            </div>
            <CTAButton link={ctaURL} onClick={handleOnClick}>
              {ctaCopy}
            </CTAButton>
          </div>
        </StyledHeroVideoPlayer>
      )}
    </Location>
  )
}

export default HeroVideoPlayer

const StyledHeroVideoPlayer = styled.div`
  background: url(${(props) => props.backgroundVideo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  color: #fff;
  font-size: 1.2em;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;

  .logo-wrapper {
    position: relative;
    width: auto;
    height: 100%;
    margin: 15px 15px 15px 15px;
    box-sizing: border-box;

    img {
      max-width: 100%;
      max-height: ${(props) => props.logoHeight || '90px'};
      height: 100%;
      width: auto;
    }

    @media (min-width: 1385px) {
      margin-bottom: -50px;
    }

    @media (max-width: 768px) {
      text-align: center;
      max-height: ${(props) => props.logoHeight || '90px'};
      margin: 10px 10px 10px 10px;
    }
  }

  .hero-content {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding: 0 10px;
    box-sizing: border-box;
    .hero-video-container {
      width: 100%;
      max-width: 900px;
      position: relative;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px;
      margin-top: 30px;
      video {
        width: 100%;
        max-width: 900px;
      }
    }
  }
`
