export const teamData = {
  'Anaheim Ducks': {
    name: 'Anaheim Ducks',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Anaheim_Ducks.png',
    league: 'nhl',
  },
  'Arizona Coyotes': {
    name: 'Arizona Coyotes',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Arizona_Coyotes.png',
    league: 'nhl',
  },
  'Arizona Diamondbacks': {
    name: 'Arizona Diamondbacks',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Arizona_Diamondbacks.png',
    league: 'mlb',
  },
  'Atlanta Braves': {
    name: 'Atlanta Braves',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Atl_Braves.png',
    league: 'mlb',
  },
  'Atlanta Hawks': {
    name: 'Atlanta Hawks',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/AHawks_Primary_Icon.png',
    league: 'nba',
  },
  'Boston Bruins': {
    name: 'Boston Bruins',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Boston_Bruins.png',
    league: 'nhl',
  },
  'Boston Celtics': {
    name: 'Boston Celtics',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/BCeltics_Primary_Icon.png',
    league: 'nba',
  },
  'Boston Red Sox': {
    name: 'Boston Red Sox',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Boston_Red_Sox.png',
    league: 'mlb',
  },
  'Buffalo Sabres': {
    name: 'Buffalo Sabres',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Buffalo_Sabres.png',
    league: 'nhl',
  },
  'Carolina Hurricanes': {
    name: 'Carolina Hurricanes',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Carolina_Hurricanes.png',
    league: 'nhl',
  },
  'Charlotte Hornets': {
    name: 'Charlotte Hornets',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/CHornets_Primary_Icon.png',
    league: 'nba',
  },
  'Chicago Blackhawks': {
    name: 'Chicago Blackhawks',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Chicago_Blackhawks.png',
    league: 'nhl',
  },
  'Chicago Bulls': {
    name: 'Chicago Bulls',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/CBulls_Primary_Icon.png',
    league: 'nba',
  },
  'Chicago Cubs': {
    name: 'Chicago Cubs',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Chicago_Cubs.png',
    league: 'mlb',
  },
  'Chicago White Sox': {
    name: 'Chicago White Sox',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Chicago_Sox.png',
    league: 'mlb',
  },
  'Cincinnati Reds': {
    name: 'Cincinnati Reds',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Cinn_Red.png',
    league: 'mlb',
  },
  'Cleveland Cavaliers': {
    name: 'Cleveland Cavaliers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/CCavaliers_Primary_Icon.png',
    league: 'nba',
  },
  'Cleveland Guardians': {
    name: 'Cleveland Guardians',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Cleveland_Guardians.png',
    league: 'mlb',
  },
  'Colorado Avalanche': {
    name: 'Colorado Avalanche',
    // logo: "https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Colorado_Avalanche.png",
    league: 'nhl',
  },
  'Colorado Rockies': {
    name: 'Colorado Rockies',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Colorado_Rockies.png',
    league: 'mlb',
  },
  'Columbus Blue Jackets': {
    name: 'Columbus Blue Jackets',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Columbus_Blue_Jackets.png',
    league: 'nhl',
  },
  'Dallas Mavericks': {
    name: 'Dallas Mavericks',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/DMavericks_Primary_Icon.png',
    league: 'nba',
  },
  'Dallas Stars': {
    name: 'Dallas Stars',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Dallas_Stars.png',
    league: 'nhl',
  },
  'Denver Nuggets': {
    name: 'Denver Nuggets',
    // logo: "https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/DNuggets_Primary_Icon.png",
    league: 'nba',
  },
  'Detroit Pistons': {
    name: 'Detroit Pistons',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/DPistons_Primary_Icon.png',
    league: 'nba',
  },
  'Detroit Red Wings': {
    name: 'Detroit Red Wings',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Detroit_Red_Wings.png',
    league: 'nhl',
  },
  'Detroit Tigers': {
    name: 'Detroit Tigers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Detroit_Tigers.png',
    league: 'mlb',
  },
  'Florida Panthers': {
    name: 'Florida Panthers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Florida_Panthers.png',
    league: 'nhl',
  },
  'Golden State Warriors': {
    name: 'Golden State Warriors',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/GSWarriors_Primary_Icon.png',
    league: 'nba',
  },
  'Houston Astros': {
    name: 'Houston Astros',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Houston_Astros.png',
    league: 'mlb',
  },
  'Houston Rockets': {
    name: 'Houston Rockets',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/HRockets_Primary_Icon.png',
    league: 'nba',
  },
  'Indiana Pacers': {
    name: 'Indiana Pacers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/IPacers_Primary_Icon.png',
    league: 'nba',
  },
  'Kansas City Royals': {
    name: 'Kansas City Royals',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Kansas_City_Royals.png',
    league: 'mlb',
  },
  'Las Vegas Golden Knights': {
    name: 'Las Vegas Golden Knights',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Vegas_Golden_Knights.png',
    league: 'nhl',
  },
  'Los Angeles Angels': {
    name: 'Los Angeles Angels',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/LA_Angels.png',
    league: 'mlb',
  },
  'Los Angeles Clippers': {
    name: 'Los Angeles Clippers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/LAClippers_Primary_Icon.png',
    league: 'nba',
  },
  'Los Angeles Kings': {
    name: 'Los Angeles Kings',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/LA_Kings.png',
    league: 'nhl',
  },
  'Memphis Grizzlies': {
    name: 'Memphis Grizzlies',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/MGrizzlies_Primary_Icon.png',
    league: 'nba',
  },
  'Miami Heat': {
    name: 'Miami Heat',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/MHeat_Primary_Icon.png',
    league: 'nba',
  },
  'Miami Marlins': {
    name: 'Miami Marlins',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Miami_Marlins.png',
    league: 'mlb',
  },
  'Milwaukee Brewers': {
    name: 'Milwaukee Brewers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Mil_brewers.png',
    league: 'mlb',
  },
  'Milwaukee Bucks': {
    name: 'Milwaukee Bucks',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/MBucks_Primary_Icon.png',
    league: 'nba',
  },
  'Minnesota Timberwolves': {
    name: 'Minnesota Timberwolves',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/MTimberwolves_Primary_Icon.png',
    league: 'nba',
  },
  'Minnesota Twins': {
    name: 'Minnesota Twins',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Min_Twins.png',
    league: 'mlb',
  },
  'Minnesota Wild': {
    name: 'Minnesota Wild',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Minnesota_Wild.png',
    league: 'nhl',
  },
  'Nashville Predators': {
    name: 'Nashville Predators',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Nashville_Predators.png',
    league: 'nhl',
  },
  'New Jersey Devils': {
    name: 'New Jersey Devils',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/NJ_Devils.png',
    league: 'nhl',
  },
  'New Orleans Pelicans': {
    name: 'New Orleans Pelicans',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/NOPelicans_Primary_Icon.png',
    league: 'nba',
  },
  'New York Islanders': {
    name: 'New York Islanders',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/New_York_Islanders.png',
    league: 'nhl',
  },
  'New York Knicks': {
    name: 'New York Knicks',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/NYKnicks_Primary_Icon.png',
    league: 'nba',
  },
  'New York Mets': {
    name: 'New York Mets',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/NY_Mets.png',
    league: 'mlb',
  },
  'New York Rangers': {
    name: 'New York Rangers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/NY_Rangers.png',
    league: 'nhl',
  },
  "Oakland A's": {
    name: "Oakland A's",
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Oakland_As.png',
    league: 'mlb',
  },
  'Oklahoma City Thunder': {
    name: 'Oklahoma City Thunder',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/OKCThunder_Primary_Icon.png',
    league: 'nba',
  },
  'Orlando Magic': {
    name: 'Orlando Magic',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/OMagic_Primary_Icon.png',
    league: 'nba',
  },
  'Philadelphia Flyers': {
    name: 'Philadelphia Flyers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Philadelphia_Flyers.png',
    league: 'nhl',
  },
  'Philadelphia Phillies': {
    name: 'Philadelphia Phillies',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Phi_Phillies.png',
    league: 'mlb',
  },
  'Philadelphia Sixers': {
    name: 'Philadelphia Sixers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/P76ers_Primary_Icon.png',
    league: 'nba',
  },
  'Phoenix Suns': {
    name: 'Phoenix Suns',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/PSuns_Primary_Icon.png',
    league: 'nba',
  },
  'Pittsburgh Penguins': {
    name: 'Pittsburgh Penguins',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Pittsburgh_Penguins.png',
    league: 'nhl',
  },
  'Pittsburgh Pirates': {
    name: 'Pittsburgh Pirates',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Pitt_Pirates.png',
    league: 'mlb',
  },
  'Portland Trailblazers': {
    name: 'Portland Trailblazers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/PTrailBlazers_Primary_Icon.png',
    league: 'nba',
  },
  'Sacramento Kings': {
    name: 'Sacramento Kings',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/SKings_Primary_Icon.png',
    league: 'nba',
  },
  'San Antonio Spurs': {
    name: 'San Antonio Spurs',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/SASpurs_Primary_Icon.png',
    league: 'nba',
  },
  'San Diego Padres': {
    name: 'San Diego Padres',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Sandiego_Padres.png',
    league: 'mlb',
  },
  'San Francisco Giants': {
    name: 'San Francisco Giants',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Sanfrasisco_Giants.png',
    league: 'mlb',
  },
  'San Jose Sharks': {
    name: 'San Jose Sharks',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/San_Jose_Sharks.png',
    league: 'nhl',
  },
  'Seattle Kraken': {
    name: 'Seattle Kraken',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Seattle_Kraken.png',
    league: 'nhl',
  },
  'Seattle Mariners': {
    name: 'Seattle Mariners',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Seattle_Mariners.png',
    league: 'mlb',
  },
  'St. Louis Blues': {
    name: 'St. Louis Blues',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/St_Louis_Blues.png',
    league: 'nhl',
  },
  'St. Louis Cardinals': {
    name: 'St. Louis Cardinals',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/SL_Cardinals.png',
    league: 'mlb',
  },
  'Tampa Bay Lightning': {
    name: 'Tampa Bay Lightning',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Tampa_Bay_Lightning.png',
    league: 'nhl',
  },
  'Tampa Bay Rays': {
    name: 'Tampa Bay Rays',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/TB_Rays.png',
    league: 'mlb',
  },
  'Texas Rangers': {
    name: 'Texas Rangers',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/mlb/Texas_rangers.png',
    league: 'mlb',
  },
  'Utah Jazz': {
    name: 'Utah Jazz',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/UJazz_Primary_Icon.png',
    league: 'nba',
  },
  'Washington Capitals': {
    name: 'Washington Capitals',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nhl/Washington_Capitals.png',
    league: 'nhl',
  },
  'Washington Wizards': {
    name: 'Washington Wizards',
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/teams/nba/WWizards_Primary_Icon.png',
    league: 'nba',
  },
}
