import React from 'react'
import NFL from 'src/pages/nfl'
import { teamIds } from 'src/constants/personalizationIds'
export default (props) => (
  <NFL
    {...props}
    team="Las Vegas Raiders"
    teamShort="Raiders"
    teamId={teamIds['Las Vegas Raiders']}
  />
)
