import { useCallback, useEffect, useState } from 'react'

const useIsDesktop = (breakpoint = 768) => {
  const [isDesktop, setIsDesktop] = useState(false)

  const checkIsDesktop = useCallback(() => {
    return window.innerWidth > breakpoint
  }, [breakpoint])

  useEffect(() => {
    function handleResize() {
      setIsDesktop(checkIsDesktop())
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [checkIsDesktop])

  return isDesktop
}

export default useIsDesktop
