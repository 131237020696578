export const mediaMaxWidth = (width: number): string => `@media(max-width: ${width}px)`
export const mediaMinWidth = (width: number): string => `@media(min-width: ${width}px)`

/**
 * Design System Sizes
 * @see https://www.figma.com/file/voTguimCQczKI2BTTY5hcX/Foundation-Grid?node-id=8%3A10
 */
export const breakpoints = {
  minLess: 359,
  // unsupported width
  min: 360,
  // min is the minimum width supported
  xsLess: 599,
  xs: 600,
  smLess: 1023,
  sm: 1024,
  mdLess: 1439,
  md: 1440,
  lgLess: 1919,
  lg: 1920,
} as const

/**
 * Design System Size Breakdown
 * Because of how CSS media queries work (agnostic of :root), the tokens cannot be used here
 * @see https://www.figma.com/file/voTguimCQczKI2BTTY5hcX/Foundation-Grid?node-id=8%3A10
 */
export const mediaQuery = {
  /** highest unsupported width */
  unsupported: mediaMaxWidth(breakpoints.minLess),
  /** minimum width supported */
  min: mediaMinWidth(breakpoints.min),
  xsLess: mediaMaxWidth(breakpoints.xsLess),
  xs: mediaMinWidth(breakpoints.xs),
  smLess: mediaMaxWidth(breakpoints.smLess),
  sm: mediaMinWidth(breakpoints.sm),
  mdLess: mediaMaxWidth(breakpoints.mdLess),
  md: mediaMinWidth(breakpoints.md),
  lgLess: mediaMaxWidth(breakpoints.lgLess),
  lg: mediaMinWidth(breakpoints.lg),
} as const

export const buildMediaQuery = ({
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
}: {
  minWidth?: number | string
  maxWidth?: number | string
  minHeight?: number | string
  maxHeight?: number | string
}): string => {
  const results: Array<string> = []
  if (minWidth) {
    if (typeof minWidth === 'string') {
      results.push(`(min-width: ${minWidth})`)
    } else {
      results.push(`(min-width: ${minWidth}px)`)
    }
  }
  if (maxWidth) {
    if (typeof maxWidth === 'string') {
      results.push(`(max-width: ${maxWidth})`)
    } else {
      results.push(`(max-width: ${maxWidth}px)`)
    }
  }
  if (minHeight) {
    if (typeof minHeight === 'string') {
      results.push(`(max-width: ${minHeight})`)
    } else {
      results.push(`(max-width: ${minHeight}px)`)
    }
  }
  if (maxHeight) {
    if (typeof maxHeight === 'string') {
      results.push(`(max-width: ${maxHeight})`)
    } else {
      results.push(`(max-width: ${maxHeight}px)`)
    }
  }
  return `@media ${results.join(' and ')}`
}
