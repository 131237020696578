import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default (props) => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      offerBanner="Redeem your 30-day fubo PRO free trial exclusive for Best Buy customers"
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      title="Stream Live TV & Sports"
      subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
      tagline="Watch and DVR the channels you love. No contracts."
      ctaText="Get Started"
      showRSN={true}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/fubo_bestbuy.png?w=450&ch=width&auto=format,compress"
      logoHeight="70px"
    />
  )
}
