import React from 'react'
import BallySportsNHL from 'src/components/page/bally-sports-nhl'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'
export default (props) => {
  return (
    <BallySportsNHL
      location={props.location}
      video={videos.bally_kings.videoUrl}
      staticAnnotation={videos.bally_kings.staticAnnotation}
      title="Watch Los Angeles hockey live"
      subtitle="STREAM THE KINGS ON FANDUEL SPORTS WEST"
      teamId={teamIds['Los Angeles Kings']}
    />
  )
}
