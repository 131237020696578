import React from 'react'
import SEO from 'src/components/common/SEO'
import Layout from 'src/components/common/Layout'

import { LogoSyncContextProvider } from 'src/components/common/AnnotationContext'
import { createUrl } from 'src/modules/utils/createUrl'
import { useSelector } from 'react-redux'
import { selectAnalyticsParameters } from 'src/modules/reducers/analytics'
import { Link } from 'gatsby'
import CharterPlanCards from 'src/components/common/CharterPlanCards'
import OptimumPlanCards from 'src/components/common/OptimumPlanCards'
import HighlightSection from 'src/components/common/HighlightSection'
import HeroSectionNew from 'src/components/common/HeroSectionNew'
import RSNGeolocationBannerNew from 'src/components/common/RSNOnlyBannerNew'
import NetworkSectionNew from 'src/components/common/NetworkSectionNew'
import ChannelSection from 'src/components/common/ChannelSectionNew'
import DealSectionNew from 'src/components/common/DealSectionNew'
import PlatformSectionNew from 'src/components/common/PlatformSectionNew'
import ExtraCTABanner from 'src/components/common/ExtraCTABanner'
import FAQSectionNew from 'src/components/common/FAQSectionNew'
import MatchTicker from 'src/components/common/MatchTicker'
import RedzoneBanner from 'src/components/common/RedzoneBanner'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import FanduelPlanCards from 'src/components/common/FanduelPlanCards'
import EssentialPlanCards from 'src/components/common/EssentialPlanCards'
import {
  selectPromoBannerUSFlag,
  selectValuePropsHeroFlag,
  selectNoPromoBannerFlag,
} from 'src/modules/reducers/cohorts'
import { isUSFreeTrial } from 'src/modules/utils/constants'
import ProPlanCards from 'src/components/common/ProPlanCards'
import ZeeFamilyPlanCard from 'src/components/common/ZeeFamilyPlanCard'
import useIsMobile from 'src/modules/hooks/useIsMobile'
import useIsDesktop from 'src/modules/hooks/useIsDesktop'

const USTemplateDefault = ({
  lang = 'en',
  market = 'us',
  noRedirect,
  miceText,
  // Offer Code
  offerCode,
  offerBanner,
  // Addon Code
  addonCode,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  ctaText,
  video,
  videoMobile,
  staticAnnotation,
  annotations,
  poster,
  posterMobile,
  noteLink,
  heroCtaNote,
  // Optional Highlight Section
  highlightImage,
  highlightHeadline,
  highlightSubtitle,
  highlightNote,
  highlightBackground,
  highlightShowHeadlineTop,
  highlightUrlParams,
  highlightCtaText,
  // Network Section
  networkHeadline,
  networkSubtitle,
  networkSet,
  customNetworks,
  // Others
  team,
  // AB Test for RSN
  showRSN,
  showRedzone,
  redzoneOfferCode,
  // Show offer input for unique codes
  hasOfferInput,
  // AB Test - Video vs Logo Soup
  noVideo = false,
  // Remove references to cable
  removeCable,
  // Charter Plan Cards
  hasCharterPlanCards,
  // Optimum Plan Cards
  hasOptimumPlanCards,
  // Pro Plan Cards
  hasProPlanCards,
  // Essential Plan Cards
  hasEssentialPlanCards,
  //Zee Family Card
  hasZeeFamilyPlanCard,
  // AB Test for MatchTicker
  matchtickerSportId,
  matchtickerLeagueName,
  matchtickerLeagueId,
  // Disable Free Trial
  disableFreeTrial,
  // CA Redirect URL
  caRedirectUrl,
  // Personalization IDs
  sportId,
  leagueId,
  teamId,
  // FanDuel Plan Cards
  hasFanduelPlanCards,
  fanduelRSNData,
  showFanduelWithPro,
  // Additional FAQs
  additionalFAQs,
  platformImage,
  platformHeadline,
  dealSavingsText,
  hideChannelSection = false,
  faqListOverride,
  // Use specific FD offer codes for Reds
  redsOffer,
}) => {
  // Disable Free Trial Modifications
  const modifiedCtaText = ctaText
  const promoBannerUS = useSelector(selectPromoBannerUSFlag)
  const showNoPromoBanner = useSelector(selectNoPromoBannerFlag)
  const showValuePropsHero = useSelector(selectValuePropsHeroFlag)
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  disableFreeTrial = disableFreeTrial ?? !isUSFreeTrial
  const modifiedNetworkSubtitle =
    networkSubtitle ||
    (disableFreeTrial && (lang === 'es' ? 'Cancela cuando quieras.' : 'Cancel online anytime.'))
  let metaDescription =
    pageDescription ||
    'Stream live TV with ABC, CBS, FOX, NBC, ESPN & top channels without cable. DVR included. No contract, cancel anytime.'

  if (team) {
    metaDescription = `Stream ${team} live online on ABC, CBS, FOX, NBC, and ESPN with Fubo. No contract is required - try it for free and cancel anytime.`
  }

  const stateForProtobuf = useSelector(selectStateForProtobuf)

  const handleLocaleSwitch = () => {
    fireUIInteractionEvent(
      {
        element: noteUrl,
        component: 'lp_locale_switch_clicked',
      },
      stateForProtobuf
    )
  }

  const originalParams = useSelector(selectAnalyticsParameters)
  let noteUrl
  if (noteLink) {
    noteUrl = createUrl(noteLink, originalParams)
  }

  let noteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        To learn more in English, <span>click here</span>.
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Para traducir a español, <span>haga clic aquí</span>.
      </Link>
    )

  let charterNoteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        English
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Español
      </Link>
    )

  const hasAnchor =
    hasFanduelPlanCards || hasEssentialPlanCards || hasProPlanCards ? '#select-plan' : null
  const hasOffer = offerBanner || offerCode
  const heroFootnote =
    !disableFreeTrial && showValuePropsHero && 'Free trial | No contract required | Cancel anytime'

  if (hasFanduelPlanCards || offerBanner === ' ') {
    offerBanner = null
  } else if (!hasOffer) {
    if (!showNoPromoBanner && promoBannerUS !== 'default') {
      offerBanner = null
    }
    if (fanduelRSNData?.offerCode) {
      offerCode = fanduelRSNData.offerCode
    }
  }

  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      addonCode={addonCode}
      ctaHrefOverride={hasAnchor}
      noRedirect={noRedirect}
      miceText={miceText}
      caRedirectUrl={caRedirectUrl}
      sportId={sportId}
      leagueId={leagueId}
      teamId={teamId}
    >
      <LogoSyncContextProvider annotations={annotations}>
        <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
        {matchtickerLeagueId && isMobile && (
          <>
            <MatchTicker
              lang={lang}
              market={market}
              sportId={matchtickerSportId}
              leagueName={matchtickerLeagueName}
              leagueId={matchtickerLeagueId}
              ctaText={modifiedCtaText}
              disableFreeTrial={disableFreeTrial}
            />
          </>
        )}

        <HeroSectionNew
          logo={logo}
          logoHeight={logoHeight}
          lang={lang}
          market={market}
          heroBgVideo={video}
          heroBgVideoMobile={videoMobile}
          title={title}
          subtitle={subtitle}
          ctaText={modifiedCtaText}
          staticAnnotation={staticAnnotation}
          posterImg={poster}
          posterImgMobile={posterMobile}
          noteText={heroCtaNote}
          hasOfferInput={hasOfferInput}
          noVideo={noVideo}
          ctaHrefOverride={hasAnchor}
          offerCode={offerCode}
          addonCode={addonCode}
          footnote={heroFootnote}
          disableFreeTrial={disableFreeTrial}
        />

        {matchtickerLeagueId && isDesktop && (
          <>
            <MatchTicker
              lang={lang}
              market={market}
              sportId={matchtickerSportId}
              leagueName={matchtickerLeagueName}
              leagueId={matchtickerLeagueId}
              ctaText={modifiedCtaText}
              disableFreeTrial={disableFreeTrial}
            />
          </>
        )}
        {showRSN && (
          <RSNGeolocationBannerNew
            hasOfferInput={hasOfferInput}
            ctaText={modifiedCtaText}
            ctaHrefOverride={hasAnchor}
            disableFreeTrial={disableFreeTrial}
            lang={lang}
            market={market}
          />
        )}
        {showRedzone && (
          <RedzoneBanner
            redzoneOfferCode={redzoneOfferCode}
            ctaText={modifiedCtaText}
            disableFreeTrial={disableFreeTrial}
          />
        )}

        {highlightImage && (
          <HighlightSection
            image={highlightImage}
            title={highlightHeadline}
            subtitle={highlightSubtitle}
            miceText={highlightNote}
            background={highlightBackground}
            showHeadlineTop={highlightShowHeadlineTop}
            disableFreeTrial={disableFreeTrial}
            market={market}
            lang={lang}
            urlParams={highlightUrlParams}
            ctaText={highlightCtaText}
          />
        )}
        {hasFanduelPlanCards && (
          <FanduelPlanCards
            fanduelRSNData={fanduelRSNData}
            ctaText={modifiedCtaText}
            showFanduelWithPro={showFanduelWithPro}
            leagueId={leagueId}
            redsOffer={redsOffer}
          />
        )}
        {hasEssentialPlanCards && <EssentialPlanCards ctaText={modifiedCtaText} />}
        {hasProPlanCards && <ProPlanCards ctaText={modifiedCtaText} />}
        {hasCharterPlanCards && (
          <CharterPlanCards lang={lang} market={market} ctaText="Start Your Free Trial" />
        )}
        {hasOptimumPlanCards && (
          <OptimumPlanCards lang={lang} market={market} ctaText="Start Your Free Trial" />
        )}
        {/* {hasPlanCards && <USPlanCards lang={lang} market={market} />} */}
        {hasZeeFamilyPlanCard && <ZeeFamilyPlanCard ctaText={modifiedCtaText} />}

        {!hasCharterPlanCards && (
          <NetworkSectionNew
            lang={lang}
            market={market}
            headline={networkHeadline}
            subtitle={modifiedNetworkSubtitle}
            networkSet={networkSet}
            customNetworks={customNetworks}
            ctaText={modifiedCtaText}
            hasOfferInput={hasOfferInput}
            ctaHrefOverride={hasAnchor}
            disableFreeTrial={disableFreeTrial}
          />
        )}
        {!hideChannelSection && (
          <ChannelSection
            lang={lang}
            market={market}
            title={
              removeCable &&
              (lang === 'es'
                ? 'Disfruta de los deportes, programas, noticias y películas que te encantan.'
                : 'Enjoy the sports, shows, news and movies you love')
            }
            initialChannelCount={hasCharterPlanCards && 116}
            zeeFamily={hasZeeFamilyPlanCard}
          />
        )}
        <DealSectionNew
          lang={lang}
          market={market}
          title={
            removeCable && (lang === 'es' ? '¿Por qué elegir Fubo?' : 'Why you should choose Fubo')
          }
          savingsText={dealSavingsText}
        />
        <PlatformSectionNew
          lang={lang}
          market={market}
          ctaText={modifiedCtaText}
          hasOfferInput={hasOfferInput}
          ctaHrefOverride={hasAnchor}
          platformImage={platformImage}
          platformHeadline={platformHeadline}
          disableFreeTrial={disableFreeTrial}
        />
        {!disableFreeTrial && (
          <ExtraCTABanner
            hasOfferInput={hasOfferInput}
            ctaText={modifiedCtaText}
            ctaHrefOverride={hasAnchor}
            market={market}
            lang={lang}
            disableFreeTrial={disableFreeTrial}
          />
        )}
        <FAQSectionNew
          lang={lang}
          market={market}
          team={team}
          removeCable={removeCable}
          additionalFAQs={additionalFAQs}
          faqListOverride={faqListOverride}
        />
      </LogoSyncContextProvider>
    </Layout>
  )
}

export default USTemplateDefault
