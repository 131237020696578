import { getLocation } from '@fubotv/api-client/lib/domains/location'
import { getUniqueIdCookie } from '@fubotv/fubo-cookie/lib/uniqueId'
import { defaultImport } from 'default-import'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import initAnalytics from 'src/modules/analytics/vendor'
import { deviceReady, updateParameters } from 'src/modules/reducers/analytics'
import { call } from 'src/modules/reducers/api-client'
import { initStacFlags } from 'src/modules/reducers/cohorts/actions'
import { setAppReferrer, tryPageLoad } from 'src/modules/reducers/impact/actions'
import { updateMarket } from 'src/modules/reducers/market'
import { updateParams } from 'src/modules/reducers/params'
import { tryFetchProducts } from 'src/modules/reducers/products/actions'
import { parseQueryParams } from 'src/modules/utils/parseQueryParams'
import universalCookie from 'universal-cookie'

const THIRTY_DAYS_AS_MILLISECONDS = 30 * 24 * 60 * 60 * 1000

const AnalyticsV2 = ({ lang, market, sportId, teamId, leagueId }) => {
  const dispatch = useDispatch()
  const Cookies = defaultImport(universalCookie)

  const cookies = new Cookies()
  const deviceId = cookies.get('ftvOption%3AuniqueId')
  const location = typeof window !== 'undefined' && window.location

  // Get geolocation data
  const fetchGeolocation = async () => {
    try {
      const res = await call(getLocation)
      if (res) {
        const data = {
          countryCode: res.countryCode,
          countryCode2: res.countryCode2,
          countryName: res.countryName,
          dma: res.dma,
          postalCode: res.postalCode,
          regionCode: res.regionCode,
        }
        return data
      }
    } catch (error) {
      if (error.response) {
        console.log('location error', error.response)
      } else if (error.request) {
        console.log('location error', error.request)
      } else {
        console.log('location error', error.message)
      }
    }
  }

  const getMarket = async (query, deviceId) => {
    let geolocation = null
    var language = window.navigator.userLanguage || window.navigator.language
    geolocation = await fetchGeolocation()
    dispatch(updateMarket({ ...geolocation, market, lang, language, sportId, leagueId, teamId }))
  }
  const initAnalyticsPackage = () => {
    initAnalytics()

    const query = parseQueryParams(window.location.href)

    const documentReferrer =
      document?.referrer && !document?.referrer?.includes('fubo.tv') ? document.referrer : null

    const refUrl = documentReferrer || query?.refUrl || cookies.get('ftvOption%3ArefUrl')

    window.analytics &&
      window.analytics.ready &&
      window.analytics.ready(() => {
        // dispatch(updateAnalyticsReady()) TODO: remove this
        const amplitudeInstance = window.amplitude && window.amplitude.getInstance()
        const amplitudeDeviceId =
          amplitudeInstance && amplitudeInstance.options && amplitudeInstance.options.deviceId
        const uniqueId = amplitudeDeviceId || getUniqueIdCookie()

        const ftvCampaign =
          query && typeof query.ftv_campaign === 'string' ? query.ftv_campaign : null
        if (uniqueId) {
          window.analytics.user().anonymousId(uniqueId)
          dispatch(initStacFlags(query, uniqueId))
          dispatch(deviceReady(uniqueId, ftvCampaign, query))
        }

        if (
          query?.irad &&
          query?.irmp &&
          document?.referrer &&
          !document?.referrer.includes('fubo.tv')
        ) {
          dispatch(setAppReferrer(document.referrer))
        }

        if (query?.irmp && query?.irad) {
          cookies.set('ftvOption%3Airmp', query.irmp, {
            path: '/',
            maxAge: THIRTY_DAYS_AS_MILLISECONDS,
          })
          cookies.set('ftvOption%3Airad', query.irad, {
            path: '/',
            maxAge: THIRTY_DAYS_AS_MILLISECONDS,
          })
          if (refUrl) {
            cookies.set('ftvOption%3ArefUrl', refUrl, {
              path: '/',
              maxAge: THIRTY_DAYS_AS_MILLISECONDS,
            })
          }
          if (deviceId) {
            dispatch(tryPageLoad({ deviceId, referringUrl: refUrl }))
          } else {
            console.error('Could not find deviceId', cookies.get('ftvOption%3AuniqueId'))
          }
        }
      })
    if (query?.irmp && query?.irad) {
      cookies.set('ftvOption%3Airmp', query.irmp, {
        path: '/',
        maxAge: THIRTY_DAYS_AS_MILLISECONDS,
      })
      cookies.set('ftvOption%3Airad', query.irad, {
        path: '/',
        maxAge: THIRTY_DAYS_AS_MILLISECONDS,
      })
    }
    if (refUrl) {
      cookies.set('ftvOption%3ArefUrl', refUrl, {
        path: '/',
        maxAge: THIRTY_DAYS_AS_MILLISECONDS,
      })
    }
  }

  useEffect(() => {
    getMarket()
    initAnalyticsPackage()
    dispatch(tryFetchProducts())
  }, [])

  useEffect(() => {
    let queryParams = parseQueryParams(location.href)
    const referrer = window?.document?.referrer
    if (Object.keys(queryParams).length > 0) {
      if (referrer.includes('.google.') || referrer.includes('.bing.')) {
        if (queryParams.irad) {
          delete queryParams.irad
          console.log('removing irad', queryParams.irad)
        }
        if (queryParams.irmp) {
          delete queryParams.irmp
          console.log('removing irmp', queryParams.irmp)
        }
      }
      dispatch(updateParameters(queryParams))
      dispatch(updateParams(queryParams))
    }
  }, [location])
  return null
}

export default AnalyticsV2
