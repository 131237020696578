import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    video={videos.bally_guardians.videoUrl}
    videoMobile={videos.bally_guardians.videoMobileUrl}
    title="Watch Cleveland Guardians baseball"
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    tagline="Stream FanDuel Sports Great Lakes from any device. Regional restrictions apply."
    networkSet="baseball"
    customNetworks={['fanduelsports']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-cleveland-guardians-v2.png?w=450&ch=width&auto=format,compress"
    logoHeight="100px"
    sportId={sportIds.Baseball}
    leagueId={leagueIds.MLB}
    teamId={teamIds['Cleveland Guardians']}
  />
)
