import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca'
import { basePlanCodes, networkLogos, sportIds } from 'src/constants'
import styled from 'styled-components'
export default (props) => (
  <StyledContainer>
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={'https://assets-imgx.fubo.tv/marketing/lp/videos/compressed/IPL_LP_1280x720_10s.mp4'}
      videoMobile={
        'https://assets-imgx.fubo.tv/marketing/lp/videos/mobile/IPL_LP_1080x1080_10s.mp4'
      }
      staticAnnotation={networkLogos.willow_by_cricbuzz}
      title="Watch Indian Premier League live"
      subtitle="Stream every IPL cricket match on Willow TV"
      plan="soccer"
      plansSubtitle=""
      planCardOverride="cricket"
      networkHeadline="Stream the cricket and other sports you love"
      networkSubtitle="IPL, PSL, ICC and more."
      soccerPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/canada/ca_card_cricket.jpg?w=750&ch=width&auto=format,compress"
      networkSet="canada_cricket"
      sportId={sportIds.Cricket}
      // matchtickerSportId={sportIds.Cricket}
      // matchtickerLeagueName="Cricket"
      // matchtickerLeagueId={leagueGroupIds.cricket}
      forcePlanProduct="Sports"
      selectedPlanLengths={['Monthly', 'Quarterly', 'Annual']}
      usRedirectUrl={'/ipl/'}
      faqListOverride={faqListOverride}
      platformImage={
        'https://assets-imgx.fubo.tv/marketing/lp/images/devices/cricket-device-poster.png'
      }
      platformHeadline={'Watch live cricket from around the world'}
      channelPlanCode={basePlanCodes.canada_cricket_mo}
      savingsHeader={'Cricket and more'}
      savingsText={
        'Fubo has more than just cricket including NBA, racing, golf and more top sports coverage.'
      }
    />
  </StyledContainer>
)

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 680px;
  }
`

const faqListOverride = [
  {
    question: 'Can I stream the Mumbai Indians vs. Chennai Super Kings cricket match with Fubo?',
    answer:
      'Yes, the match between Mumbai Indians and Chennai Super Kings, and other top cricket rivalries, is available with Fubo via Willow TV, allowing you to catch all the live action.',
  },
  {
    question:
      'How can I find the Kolkata Knight Riders vs. Royal Challengers Bangalore match on Fubo?',
    answer:
      'You can find the Kolkata Knight Riders vs. Royal Challengers Bangalore match on Fubo by navigating to Willow TV in your channel lineup, where the match will be streamed live.',
  },

  {
    question: 'Can I watch Virat Kohli and Royal Challengers Bangalore live with Fubo?',
    answer:
      'Yes, you can watch Virat Kohli play in the IPL with Royal Challengers Bangalore on Willow TV with Fubo.',
  },
  {
    question: 'Will I be able to watch MS Dhoni and Chennai Super Kings live with Fubo?',
    answer:
      'Yes, you can watch MS Dhoni play in the IPL with Chennai Super Kings  on Willow TV with Fubo.',
  },
  {
    question: 'How do I access live IPL matches on Fubo?',
    answer:
      "To access live IPL events on Fubo, simply tune into Willow TV, where all the matches are streamed live, ensuring you don't miss any action.",
  },
  {
    question: 'Are there any shows related to IPL on Fubo?',
    answer:
      'Yes, Fubo features various shows related to cricket and IPL, including pre-match and post-match analysis, available through Willow TV to keep you updated on all the action.',
  },
  {
    question: 'Is the Indian Premier League available for streaming with Fubo?',
    answer:
      'Yes, the IPL is available for streaming with Fubo on Willow TV. You can watch it live or save every match to your the included Unlimited Cloud DVR with one click.',
  },

  {
    question: 'Can I watch cricket highlights with Fubo?',
    answer:
      'Yes, you can watch cricket highlights and match replays with Fubo. Just be sure to subscribe to a plan or add-on that includes Willow TV in its channel lineup.',
  },

  {
    question: 'Can I record cricket matches with Fubo?',
    answer:
      'Yes, all Fubo plans include Unlimited DVR so you can record cricket matches, TV shows, movies and more, and watch them when you want, on your TV or other devices.',
  },
  {
    question: 'Does Fubo have DVR?',
    answer:
      'Yes, every Fubo plan includes unlimited Cloud DVR space at no extra charge. That means you can record all your favorite teams, shows, movies and more. Since our DVR is cloud-based, that means you can access all of your DVR recordings from all of your devices — with no extra equipment or installation required. So if you hit record on your smart TV, you can watch the recording on your phone.',
  },

  {
    question: 'Which devices can I stream from with Fubo?',
    answer: (
      <>
        {' '}
        With Fubo, you can stream on all popular connected TVs, smart phones and computers.
        Including Apple TV, Roku, Samsung Smart TV and Amazon Fire TV; Mac and PC computers; Apple
        and Android phones and tablets; and more. View the list of all supported devices & browsers{' '}
        <a
          href="https://support.fubo.tv/hc/en-us/articles/7120445443085-What-supported-devices-can-I-watch-Fubo-on"
          target="_blank"
        >
          here
        </a>
        .
      </>
    ),
  },
]
