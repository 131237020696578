import React, { useEffect, useRef, useState } from 'react'
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from '!video.js'
import 'video.js/dist/video-js.css'
import styled from 'styled-components'
import muted from './images/muted.png'
import unmuted from './images/unmuted.png'
import play from './images/play.png'
import pause from './images/pause.png'

const FuboLivePreviewWidget = ({
  callsign,
  ctaHeadline,
  ctaSubhead,
  ctaButton,
  ctaText,
  previewTimerTemplate,
  ctaTrackingParams,
  previewLen = 28,
  signupPath = 'signup',
  autoplay = true,
  loadingText,
  overrideTimer,
}) => {
  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const timerId = useRef(null)
  const [timer, setTimer] = useState(previewLen)
  const [previewStarted, setPreviewStarted] = useState(false)
  const [previewEnded, setPreviewEnded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(true)

  const videoJsOptions = {
    autoplay,
    muted: true,
    controls: true,
    disablePictureInPicture: false,
    preferFullWindow: true,
    playsInline: true,
    sources: [
      {
        src: `https://preview.fubo.tv/${callsign}/1.m3u8`,
        type: 'application/x-mpegURL',
      },
    ],
  }

  const handlePlayPause = () => {
    if (playerRef.current) {
      if (playerRef.current.paused()) {
        playerRef.current.play()
      } else {
        playerRef.current.pause()
      }
    }
  }

  const handleMuteToggle = (event) => {
    event.stopPropagation() // Prevents the event from bubbling up to parent elements
    if (playerRef.current) {
      playerRef.current.muted(!playerRef.current.muted())
    }
  }

  const autoplayRetryLimit = 5 // Set the maximum number of autoplay attempts
  const autoplayRetries = useRef(0)

  const tryToAutoplay = (player) => {
    if (autoplayRetries.current >= autoplayRetryLimit) {
      console.error('Autoplay retry limit reached')
      return
    }

    const playPromise = player.play()

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          // Autoplay started successfully
          setIsPlaying(true)
        })
        .catch((error) => {
          // Autoplay was prevented. Retry autoplay
          console.error('Autoplay failed:', error)
          autoplayRetries.current += 1
          setTimeout(() => tryToAutoplay(player), 1000)
        })
    }
  }

  const setupPlayer = () => {
    const videoElement = document.createElement('video-js')
    videoElement.classList.add('vjs-big-play-centered')
    videoElement.setAttribute('playsinline', '')
    videoRef.current.appendChild(videoElement)

    const player = (playerRef.current = videojs(videoElement, videoJsOptions, () => {
      videojs.log('player is ready')

      // Event listeners
      player.on('loadedmetadata', () => {
        // Fired when the player has initial duration and dimension information
        setPreviewStarted(true)
      })

      // Event listeners
      player.on('play', () => {
        setIsPlaying(true)
        startTimer()
      })
      player.on('pause', () => {
        setIsPlaying(false)
        clearInterval(timerId.current)
      })
      player.on('volumechange', () => {
        setIsMuted(player.muted())
      })

      if (autoplay) {
        tryToAutoplay(player)
      }
    }))
  }

  const getSavedTimer = () => {
    const savedTimer = localStorage.getItem('fubo_preview_timer')
    return overrideTimer ? overrideTimer : savedTimer ? parseInt(savedTimer, 10) : previewLen
  }

  useEffect(() => {
    setTimer(getSavedTimer()) // Initialize timer with saved value or default
  }, [])

  const saveTimer = (time) => {
    localStorage.setItem('fubo_preview_timer', time.toString())
  }

  const startTimer = () => {
    clearInterval(timerId.current)
    timerId.current = setInterval(() => {
      setTimer((prevTimer) => {
        const newTimer = prevTimer <= 1 ? 0 : prevTimer - 1
        saveTimer(newTimer) // Save remaining time
        if (newTimer === 0) {
          clearInterval(timerId.current)
          setPreviewEnded(true)
          playerRef.current.pause()
        }
        return newTimer
      })
    }, 1000)
  }

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.on('pause', () => {
        setIsPlaying(false)
        clearInterval(timerId.current)
        saveTimer(timer) // Save remaining time on pause
      })
    }
  }, [timer])

  useEffect(() => {
    // Only setup the player if callsign is truthy
    if (callsign) {
      if (!playerRef.current) {
        setupPlayer()
      } else {
        const player = playerRef.current
        player.autoplay(videoJsOptions.autoplay)
        player.src(videoJsOptions.sources)
      }
    }
  }, [callsign])

  useEffect(() => {
    return () => {
      if (playerRef.current && !playerRef.current.isDisposed()) {
        playerRef.current.dispose()
        playerRef.current = null
      }
      clearInterval(timerId.current)
    }
  }, [])

  return (
    <StyledLivePreviewPlayer className="live-preview-player">
      <div ref={videoRef} className="fubo-livepreview-widget" data-vjs-player />
      {previewEnded && (
        <div className="preview-cta">
          <h3>{ctaHeadline}</h3>
          <h3>{ctaSubhead}</h3>
          <div className="button-container">
            <a
              className="cta-button"
              href={`https://www.fubo.tv/${signupPath}?${ctaTrackingParams}`}
              target="_top"
            >
              {ctaButton}
            </a>
          </div>
        </div>
      )}
      {!previewStarted && !previewEnded && (
        <div className="live-player-placeholder">{loadingText || 'Loading...'}</div>
      )}
      {previewStarted && !previewEnded && (
        <div className="fubo-widget-wrapper" onClick={handlePlayPause}>
          <div className="fubo-widget">
            <div className="top-nag">
              <h4 className="preview-seconds">{previewTimerTemplate.replace('{time}', timer)}</h4>
              <h4 className="buy-link">
                <span>
                  <a
                    action="sign-up"
                    className="sign-up-cta-header"
                    target="_top"
                    href="https://www.fubo.tv/signup?comp=end_previews"
                  >
                    {ctaText || 'Start Your Free Trial'}
                  </a>
                </span>
              </h4>
            </div>
            <div className="controls">
              <div className={`play-pause-button ${isPlaying ? 'playing' : 'paused'}`}></div>
              <div
                className={`mute-button ${isMuted ? 'muted' : ''}`}
                onClick={handleMuteToggle}
              ></div>
            </div>
          </div>
        </div>
      )}
    </StyledLivePreviewPlayer>
  )
}

export default FuboLivePreviewWidget

const StyledLivePreviewPlayer = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  ${'' /* aspect-ratio: 16/9; */}
  position: relative;
  background: #000;

  .live-player-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    z-index: 99;
  }

  .fubo-livepreview-widget {
    color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;

    .video-js {
      width: 100%;
      height: 100%;
    }
  }

  .preview-cta {
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    z-index: 99;
    text-align: center;

    h3 {
      font-size: 28px;
      font-weight: 500;
      margin: 0;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    .cta-button {
      border: 0;
      font-size: 20px;
      font-weight: 500;
      margin-top: 20px;
      text-transform: uppercase;
      background-color: #fa4616;
      padding: 10px 20px;
      display: inline-block;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  .top-nag {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 15px 20px;
  }

  .vjs-hidden,
  .vjs-big-play-button,
  .vjs-control-bar {
    display: none !important;
  }

  .fubo-widget-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    z-index: 99;

    .fubo-widget {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 15px;
      letter-spacing: 0.5px;

      .preview-seconds {
        font-weight: 500;
        margin: 0;
        text-align: left;
      }

      .top img {
        height: auto;
        width: auto;
        display: initial;
      }

      .buy-link {
        font-weight: 500;
        z-index: 1;

        a {
          cursor: pointer;
          text-decoration: underline;

          &:hover {
            color: #f6891f;
          }
        }
      }

      a {
        color: #fff;
      }
    }

    .controls {
      position: absolute !important;
      width: 100%;
      height: 100%;
      opacity: 1;
      top: 0;
      left: 0;

      &:hover .play-pause-button {
        opacity: 1;
        transition: opacity 0.25s ease-in-out 0s;
      }

      .play-pause-button {
        cursor: pointer;
        width: 80px;
        height: 80px;
        left: 0;
        opacity: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-left: auto;
        margin-right: auto;
        right: 0;
        bottom: 0;
        position: absolute !important;
        background: url(${play}) no-repeat center center;
        background-size: contain;

        &:hover {
          opacity: 0.8;
        }

        &.paused {
          opacity: 1 !important;
          background: url(${pause}) no-repeat center center;
          background-size: contain;

          ${
            '' /* @media (pointer: coarse) {
            display: block;
          } */
          }
        }

        ${
          '' /* @media (pointer: coarse) {
          &.paused {
            display: none;
          }
        } */
        }
      }

      .mute-button {
        cursor: pointer;
        width: 3%;
        height: 3%;
        max-width: 50px;
        min-width: 25px;
        max-height: 50px;
        min-height: 25px;
        position: absolute !important;
        right: 20px;
        bottom: 20px;
        background: url(${unmuted}) no-repeat center center;
        background-size: contain;

        &.muted {
          background: url(${muted}) no-repeat center center;
          background-size: contain;
        }
      }
    }
  }

  ${
    '' /* @media (pointer: coarse) {
    .fubo-widget-wrapper .controls .play-pause-button,
    .fubo-widget-wrapper .controls .play-pause-button.paused {
      display: none;
    }
  } */
  }
`
