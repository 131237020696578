import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
export default (props) => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="NHLNET"
    title="Watch NHL Network™ Live with Fubo"
    networkName="NHL Network™"
    // matchtickerSportId="122"
    // matchtickerLeagueName="NHL"
    // matchtickerLeagueId="191278"
  />
)
