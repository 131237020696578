import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { leagueIds, sportIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/SEM_Knicks_1280x720_LP_15s.mp4"
      staticAnnotation={networkLogos.msg}
      title="Watch New York basketball live"
      subtitle="STREAM THE KNICKS ON MSG NETWORK"
      tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
      networkSet="basketball"
      customNetworks={['msg', 'nbatv']}
      sportId={sportIds.Basketball}
      leagueId={leagueIds.NBA}
      teamId={teamIds['New York Knicks']}
    />
  )
}
