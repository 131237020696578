import React from 'react'
import { Provider } from 'react-redux'
import 'src/styles/ft-regola-neue.css'
import 'src/styles/global.scss'
import configureAppStore from './modules/store/configureAppStore'
import { makeEnhancers } from './modules/store/configureMiddleware'

/* 
GatsbyJS convention for wrapping the root element with a provider
See https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
*/

const store = configureAppStore(makeEnhancers)

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}
