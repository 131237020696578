import React from 'react'
import TermsConditionsTemplate from 'src/components/template/terms-conditions'
import { caPlanPricing } from 'src/constants/caPlanData'

export default (props) => (
  <TermsConditionsTemplate>
    <h1>Fubo TV Terms &amp; Conditions</h1>
    <p>
      Eligible Mastercard* cardholders who are new subscribers** of Fubo will receive a C$30
      discount on Fubo Canada Sports quarterly plan when they pay with their Eligible Mastercard.
      The offer is valid one time only. Each eligible World and World Elite cardholder will receive
      the C$30 discount on his or her first payment only (total first quarterly payment with
      discount applied will be equal to C$49.97*** + applicable taxes) and thereafter, the quarterly
      subscription will automatically renew at a cost of C$
      {caPlanPricing.soccer.totalPrice.quarterly}*** per quarter + applicable taxes until
      cancellation. To access the offer and subscribe to a Fubo Sports Quarterly plan, you must
      register at
      <a href="https://www.fubo.tv/stream/ca/mastercard/">https://fubo.tv/stream/ca/mastercard</a>.
      The $30 discount on the Sports Quarterly plan will be deducted from the cost of the plan at
      the time of checkout. Additional taxes may be applied.
    </p>
    <p>
      Additional terms and conditions apply. Your subscription is subject to Fubo’s Terms of Service
      and Privacy Policy available at: Fubo Terms of Service. Terms, conditions, subscription
      pricing, features, programming, and service and support options subject to change without
      notice. You can cancel your subscription through your Account Page at any time before renewal,
      visit Fubo TV website (<a href="https://www.fubo.tv/account">My Account</a>). In the event of
      cancellation, no credits or refunds will be provided for subscription fees already paid.
      Mastercard and Fubo reserve the rights to modify or end this offer at any time and for any
      reason, without notice.
    </p>
    <p>
      Neither Mastercard or any participating financial institution card issuer has any involvement
      in or responsibility for the provision of any Fubo subscriptions, products, or services, and
      are not responsible for any arising claims or damages.
    </p>
    <p>Offer not available in Quebec.</p>
    <p>
      *The following cards issued in Canada: World Mastercard, World Elite Mastercard, and Muse by
      Mastercard that are eligible. Check here for eligibility:{' '}
      <a href="https://www.fubo.tv/stream/ca/mastercard/">https://fubo.tv/stream/ca/mastercard</a>.
    </p>
    <p>
      **A “new subscriber” eligible for the one-time C$30 discount is someone who has not registered
      for Fubo services previously as determined solely by Fubo. Subscribers who have a subscription
      to Fubo services would not be eligible to redeem this offer.
    </p>
    <p>
      *** Price subject to change. Visit checkout page or{' '}
      <a href="https://www.fubo.tv/account">My Account</a> page for current subscription price.
    </p>
  </TermsConditionsTemplate>
)
