import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca'
import { networkLogos } from 'src/constants/networkLogos'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
export default (props) => (
  <StyledContainer>
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={videos.champions_tour.videoUrl}
      title="Watch the Soccer Champions Tour"
      subtitle="SEE MAN CITY, REAL MADRID, AC MILAN & MORE"
      tagline={
        <>
          Stream these friendlies <span className="desktop-line-break">live from any device </span>
        </>
      }
      networkSet="canada"
      staticAnnotation={networkLogos.fsn}
      plan="soccer"
    />
  </StyledContainer>
)

const StyledContainer = styled.div`
  .desktop-line-break {
    display: block;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`
