export const promoCodes = {
  // Has strange checkout screen
  canada_entertainment_plus: 'canada-month-entertainment-plus',
  // Has strange checkout screen
  canada_entertainment_plus_quarterly: 'canada-quarter-entertainment-plus',
  canada_ftf_canada: 'canada-quarter-essentials-ftf-30-off',
  // Has strange checkout screen
  canada_lenovo: 'canada-month-essentials-lenovo-promo',
  canada_mastercard: 'ca-recu-mk-sports_mstrcrd-qt-nt-30amtoff1-v1',
  // Disabled
  canada_mlbtv_mo: 'canada-month-essentialsmlbtv-v3-promo',
  // Disabled
  canada_mlbtv_qt: 'canada-quarter-essentials-v5-mlbtv-promo',
  // Disabled
  canada_mlbtv_yr: 'canada-year-essentials-v5-promo-mlbtv',
  canada_sabres_mo: 'ca-recu-mk-sports_sabre-mo-14d-17amtoff12-v1',
  canada_sabres_qt: 'ca-recu-mk-sports_sabre-qt-14d-45amtoff4-v1',
  canada_sabres_yr: 'ca-recu-mk-sports_sabre-yr-14d-150amtoff1-v1',
  // We can remove
  // canada_soccer: 'canada-month-soccer-affiliate',
  canada_soccer_mo: 'ca-recu-mk-sports-mo-nt-5amtoff1-v1',
  canada_soccer_qt: 'ca-recu-mk-sports-qt-nt-15amtoff1-v1',
  canada_soccer_yr: 'ca-recu-mk-sports-yr-nt-20amtoff1-v1',
  // We can remove
  // canada_SPC: 'canada-month-essentials-spc',
  capital_one: 'us-recu-mk-pro_capone-mo-5d-20amtoff2-v1 ',
  charter_elite_plan: 'us-recu-mk-elite_comcast-mo-5d-28amtoff2-v1',
  charter_pro_plan: 'us-recu-mk-pro_comcast-mo-5d-25amtoff2-v1',
  colin_cowherd: 'us-recu-bd-pro_ccowherd-mo-5d-25amtoff2-v1',
  crunch: 'us-recu-mk-pro_planetf-mo-5d-25pctoff1-v1',
  detroit_pistons: 'us-recu-ga-fanduel_det-mo-14d-sponsorship',
  epic_golf: 'us-recu-mk-pro_epic-mo-30d-v2',
  epic_golf_promo: 'us-recu-bd-pro-mo-5d-15pctoff1-v1',
  // Five Iron Golf codes expires February 14, 2026
  five_iron_golf: 'us-recu-mk-pro_figolf-mo-5d-20amtoff1-v1',
  five_iron_golf_members: 'us-recu-mk-pro_figolf-mo-5d-30amtoff2-v1',
  fubotv_amex: 'us-recu-bd-elite-mo-5d-25amtoff3-v1',
  golf_pass_non_members: 'us-recu-bd-pro_golfnow-mo-5d-20amtoff2-v1',
  golf_pass_members: 'us-recu-bd-pro_golfnow-mo-5d-30amtoff3-v1',
  govX: 'us-recu-bd-pro_govx-mo-5d-10amtoff2-v1',
  lenovo: 'us-recu-bd-elite_lenovo-mo-5d-30pctoff3-v1',
  links_golf: 'us-recu-bd-pro-mo-5d-15pctoff1-v1',
  links_golf_club: 'us-recu-mk-pro_links-mo-30d-v2',
  mymove: 'us-recu-mk-pro-mo-5d-25amtoff2-v1',
  optimum_pro_plan: 'us-recu-mk-pro_optimum-mo-5d-25amtoff2-v1',
  planet_fitness: 'us-recu-mk-pro_planetf-mo-5d-25pctoff1-v1',
  troon_14: 'us-recu-mk-pro_troon-mo-14d-v2',
  troon_30: 'us-recu-mk-pro_troon-nt-102amtoff1-v1',
  houston_astros: 'us-recu-mk-pro_teams-mo-7d-20amtoff1-v1',
  cincinnati_reds: 'us-recu-mk-pro_teams-mo-7d-20amtoff1-v1',
  cleveland_guardians: 'us-recu-mk-pro_teams-mo-7d-20amtoff1-v1',
  texas_rangers: 'us-recu-mk-pro_teams-mo-7d-20amtoff1-v1',
}
