import * as React from 'react'
import Checkbox from 'src/components/common/TabSelector/Checkbox'
import EmptyCircle from 'src/components/common/TabSelector/EmptyCircle'
import styled from 'styled-components'

const TabSelector = ({ tabs, activeTab, handleTabClick, tabsSavings }) => {
  return (
    <StyledTabSelector>
      {tabs?.length > 0 && (
        <div role="tablist" aria-orientation="horizontal" className="plan-tabs-new">
          {tabs.map((tab) => (
            <div className="tab-container" key={tab}>
              {/* <div className="tab-note">{tabsSavings?.[tab.toLowerCase()] || <>&nbsp;</>}</div> */}
              <button
                aria-controls={`tab-${tab}`}
                aria-selected="true"
                tabIndex="0"
                role="tab"
                onClick={() => handleTabClick(tab)}
                className={activeTab.toLowerCase() === tab.toLowerCase() ? 'active' : ''}
              >
                <div className="tab-content">
                  <div>
                    <div className="tab-space"></div>
                  </div>
                  <div className="tab-length">
                    {tab.toUpperCase()}
                    {tabsSavings?.[tab.toLowerCase()] && (
                      <div className="tab-note-mobile">{tabsSavings?.[tab.toLowerCase()]}</div>
                    )}
                  </div>
                  <div className="tab-icon">
                    {activeTab.toLowerCase() === tab.toLowerCase() ? <Checkbox /> : <EmptyCircle />}
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
      )}
    </StyledTabSelector>
  )
}

export default TabSelector

const StyledTabSelector = styled.div`
  --fubo-color-orange100: 250, 70, 22;

  display: grid;
  grid-template-columns: var(--spreadsheet-gutter-left) auto var(--spreadsheet-gutter-right);
  grid-template-rows: min-content 1px;
  ${'' /* column-gap: 24px; */}
  height: fit-content;

  ${
    '' /* @media (min-width: 1440px) {
    grid-template-columns: var(--spreadsheet-gutter-left) auto var(--spreadsheet-gutter-right);
  } */
  }

  @media (max-width: 1023px) {
    grid-template-rows: min-content min-content 1px;
    grid-template-columns: 100%;
  }

  .plan-tabs-new {
    margin: 0 auto 30px;
    display: flex;
    width: 100%;
    gap: 20px;

    max-width: 800px;

    @media (max-width: 599px) {
      gap: 6px;
    }

    .tab-container {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .tab-note {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
      }

      .tab-note-mobile {
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        display: none;
        @media (min-width: 481px) {
          display: none;
        }
      }

      button {
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;
        border-radius: 50px;
        width: 100%;

        @media (max-width: 599px) {
          border-radius: 10px;
        }

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          ${
            '' /* background: #000;
        color: #fff; */
          }
            ${
            '' /* outline-color: rgb(var(--fubo-color-orange100));
            outline-style: solid;
            outline-width: 3px;
            outline-offset: -3px; */
          }
            box-shadow: 0 0 0 3px rgb(var(--fubo-color-orange100));
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-weight: 700;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          display: flex;
          justify-content: space-around;
          align-items: center;
          @media (max-width: 599px) {
            flex-direction: column;
            font-size: 16px;
            ${
              '' /* .selected-icon {
                display: none;
              } */
            }
          }
          .tab-space {
            width: 24px;
            content: '';
            @media (max-width: 599px) {
              display: none;
            }
          }
          .tab-icon {
            height: 24px;
          }
        }
      }
    }
  }
`
