import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.mlbtv.videoUrl}
    title="Watch baseball live without cable"
    subtitle="CATCH LIVE GAMES ALL SEASON LONG"
    tagline="Stream them from your TV, phone and other devices"
    networkSet="baseball"
    league="mlb"
    showRSN={true}
  />
)
