import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
import { laChannelCount } from 'src/constants/laPlanData'
import styled from 'styled-components'
export default (props) => {
  return (
    <StyledContainer>
      <LatinoTemplateDefault
        location={props.location}
        lang="es"
        market="latino"
        video={videos.generic_baseball.videoUrl}
        title="Mira la Serie del Caribe en vivo en español"
        subtitle={`Más de ${laChannelCount} canales con deportes, noticias, novelas y entretenimiento en español.`}
        networkSet="entertainmentLatino"
        customNetworks={['espndeportes']}
        noteLink="/la/carribean/"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 860px;
  }
`
