import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default (props) => {
  return (
    <BallySportsNBA
      location={props.location}
      video={videos.bally_clippers.videoUrl}
      staticAnnotation={videos.bally_clippers.staticAnnotation}
      title="Watch Los Angeles basketball live"
      subtitle="STREAM THE CLIPPERS ON FANDUEL SPORTS SOCAL"
      teamId={teamIds['Los Angeles Clippers']}
    />
  )
}
