import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ArrowDown from 'src/assets/svg/arrow-down.svg'
import ChannelList from './components/channelList'
import { selectPostalCode } from 'src/modules/reducers/market'
import { useSelector, useDispatch } from 'react-redux'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import {
  selectFuboFreeChannels,
  selectLatinoChannels,
  selectMostChannels,
  selectZeeFamilyChannels,
  selectCricketInternationalChannels,
  selectChannelsByExactPlanCode,
} from 'src/modules/reducers/products/selectors'
import { laChannelCount } from 'src/constants/laPlanData'
import {
  tryFetchProductByRatePlan,
  tryFetchAddonByRatePlan,
} from 'src/modules/reducers/products/actions/fetchProductByRatePlan'
import { ratePlanCodes } from 'src/constants'

const ChannelSection = ({
  lang = 'en',
  market = 'us',
  showAllChannels = false,
  title,
  seeMoreText,
  initialChannelCount = 33,
  fuboFree = false,
  zeeFamily = false,
  ratePlanCode = false,
}) => {
  const postal = useSelector(selectPostalCode)
  const dispatch = useDispatch()

  const getChannelType = () => {
    if (ratePlanCode) return 'ratePlanCode'
    if (market === 'latino') return 'latino'
    if (zeeFamily) return 'zeefamily'
    if (fuboFree) return 'fubofree'
    return 'default'
  }

  const channelPlanMap = {
    latino: selectLatinoChannels,
    zeefamily: (state) => [
      ...selectCricketInternationalChannels(state),
      ...selectZeeFamilyChannels(state),
    ],
    ratePlanCode: (state) => [...selectChannelsByExactPlanCode(ratePlanCode)(state)],
    fubofree: selectFuboFreeChannels,
    default: selectMostChannels,
  }

  const channelPlan = channelPlanMap[getChannelType()]

  const availableChannels = useSelector(channelPlan)
  const [displayAllChannels, setDisplayAllChannels] = useState(showAllChannels)
  const stateForProtobuf = useSelector(selectStateForProtobuf)

  const updatedChannels = availableChannels?.length
    ? displayAllChannels
      ? availableChannels
      : availableChannels.slice(0, initialChannelCount)
    : []

  const latinoCopy =
    lang === 'es'
      ? `Accede a más de ${laChannelCount} canales premium`
      : `Get access to over ${laChannelCount}+ premium channels`

  let sectionTitleText =
    market === 'latino'
      ? latinoCopy
      : lang === 'en'
        ? `See all channels available in ${postal}`
        : 'Corta el cable, quédate con los canales que te encantan'

  const moreChannelsText = seeMoreText || (lang === 'en' ? 'See more channels' : 'Ver más canales')

  const handleButtonClick = (event) => {
    event.preventDefault()
    setDisplayAllChannels(true)
    fireUIInteractionEvent(
      {
        element: moreChannelsText,
        component: 'channels_section_cta_clicked',
      },
      stateForProtobuf
    )
  }

  useEffect(() => {
    if (zeeFamily) {
      dispatch(tryFetchProductByRatePlan(ratePlanCodes.zee_family_mo))
      dispatch(tryFetchAddonByRatePlan('us-recu-ga-intlsprt_crkt-mo-v1'))
    }
  }, [zeeFamily])

  useEffect(() => {
    if (ratePlanCode) {
      dispatch(tryFetchProductByRatePlan(ratePlanCode))
    }
  }, [ratePlanCode])

  if (updatedChannels.length === 0) return null

  return (
    <StyledChannelSection
      id="channels-section"
      className={`${displayAllChannels ? 'expanded-channels' : 'closed-channels'} `}
    >
      <SectionInner>
        <SectionTitle>{title || sectionTitleText}</SectionTitle>
        <ChannelListWrapper>
          <ChannelList channels={updatedChannels} activeCategory="all" />
        </ChannelListWrapper>
        {!displayAllChannels && (
          <SectionCTA onClick={handleButtonClick}>
            <ButtonWrapper>
              <span>{moreChannelsText}</span>
              <ArrowDown />
            </ButtonWrapper>
          </SectionCTA>
        )}
      </SectionInner>
    </StyledChannelSection>
  )
}

export default ChannelSection

const breakpoint = 768
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

// 3:2 Figma to Mobile (calculate 2/3 of figma sizing for mobile)

const StyledChannelSection = styled.section`
  background: #fafafa;
  padding: 30px 0;
  position: relative;
  z-index: 1;

  &.closed-channels {
    ::after {
      display: block;
      position: absolute;
      bottom: 95px;
      background-image: linear-gradient(
        to bottom,
        rgba(250, 250, 250, 0) 0,
        rgba(250, 250, 250, 0.95) 95%,
        rgba(250, 250, 250, 1) 100%
      );
      content: '';
      height: 140px;
      width: 100%;
      z-index: 20;
    }
  }
`
const SectionInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const SectionTitle = styled.h2`
  margin: 10px auto;
  color: #040911;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
`
const SectionCTA = styled.div`
  width: 250px;
  box-sizing: border-box;
  border: 1px solid #210b61;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 12px;
  flex-flow: row nowrap;
  display: block;
  margin: 0 auto;
  text-align: center;
`
const ButtonWrapper = styled.div`
  font-size: 18px;
  color: #170e30;
  line-height: 1.1;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;

  svg {
    color: #170e30;
    vertical-align: middle;
    font-size: 18px;
    margin-left: 7px;
    height: 20px;
  }
`

const ChannelListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 25px 50px;
  max-width: 1400px;
  display: block;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 25px 0;
  }
`
