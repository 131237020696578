// Define action types
export const TRY_PAGE_LOAD = 'IMPACT/TRY_PAGE_LOAD'
export const PAGE_LOAD_SUCCESS = 'IMPACT/PAGE_LOAD_SUCCESS'
export const SET_APP_REFERRER = 'IMPACT/SET_APP_REFERRER'
export const PAGE_LOAD_FAIL = 'IMPACT/PAGE_LOAD_FAIL'

export type SetAppReferrerAction = {
  type: typeof SET_APP_REFERRER
  referrer: string
}

export type TryPageLoadAction = {
  type: typeof TRY_PAGE_LOAD
  payload: {
    deviceId: string
    referringUrl?: string
  }
}

export type PageLoadSuccessAction = {
  type: typeof PAGE_LOAD_SUCCESS
  payload: any
}

export type PageLoadFailAction = {
  type: typeof PAGE_LOAD_FAIL
  error: any
}
