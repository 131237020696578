let persistor: any = null

// promise to make sure the store finishes all pending actions before moving on
export const flushStore = (): Promise<void> => {
  if (persistor && typeof persistor.flush === 'function') return persistor.flush()
  return Promise.resolve()
}
export function setPersistor(newValue: any) {
  persistor = newValue
}
