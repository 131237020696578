import { createSelector } from 'reselect'

import {
  WEB_MARKETING_PROMO_BANNER_US,
  WEB_MARKETING_FAQ_MESSAGING,
  WEB_MARKETING_VALUE_PROPS_HERO,
  WEB_MARKETING_ADCASH_CTA_URL,
  WEB_MARKETING_FANDUEL_W_PRO,
  WEB_MARKETING_NO_PROMO_BANNER,
  WEB_MARKETING_MLB_TV_W_TRIAL,
} from './constants'
import { StacFlags } from './flags'

import type { BooleanSelector, StacFlagName, StringSelector } from './types'
import { StacFlagsState } from './reducer'

export const selectStacFlagsState = (state): StacFlagsState => state.cohorts

export const selectStacFlagsReady: (state) => boolean = createSelector(
  selectStacFlagsState,
  ({ isInitialized, isFetching }) => isInitialized && !isFetching
)

export const selectAllStacFlags: (arg1) => StacFlags = createSelector(
  selectStacFlagsState,
  (local) => local?.flags
)

// allows passing of flagName and sets it as a cacheKey
const selectStacFlagName = <T>(_: T, flagName: StacFlagName): StacFlagName => flagName

// @ts-expect-error
export const selectStacFlagValue: <T>(arg1, arg2: StacFlagName) => T | null = createSelector(
  selectAllStacFlags,
  selectStacFlagName,
  (flags: StacFlags, flagName: StacFlagName) => {
    const flag = flags?.[flagName]
    return flag ? flag.value : null
  }
)

export const selectFAQMessagingFlag: StringSelector = (state) =>
  selectStacFlagValue(state, WEB_MARKETING_FAQ_MESSAGING)

export const selectPromoBannerUSFlag: BooleanSelector = (state) =>
  selectStacFlagValue(state, WEB_MARKETING_PROMO_BANNER_US)

export const selectValuePropsHeroFlag: BooleanSelector = createSelector(
  (state) => selectStacFlagValue(state, WEB_MARKETING_VALUE_PROPS_HERO),
  (value) => value === 'test'
)

export const selectAdcashCtaUrlFlag: BooleanSelector = createSelector(
  (state) => selectStacFlagValue(state, WEB_MARKETING_ADCASH_CTA_URL),
  (value) => value === 'test'
)

export const selectFanduelWithProFlag: BooleanSelector = createSelector(
  (state) => selectStacFlagValue(state, WEB_MARKETING_FANDUEL_W_PRO),
  (value) => value === 'test'
)

export const selectNoPromoBannerFlag: BooleanSelector = createSelector(
  (state) => selectStacFlagValue(state, WEB_MARKETING_NO_PROMO_BANNER),
  (value) => value === 'test'
)

export const selectMLBTVTrialFlag: BooleanSelector = createSelector(
  (state) => selectStacFlagValue(state, WEB_MARKETING_MLB_TV_W_TRIAL),
  (value) => value === 'test'
)
