import React from 'react'
import Image from 'src/components/common/Image'
import CompareCard from 'src/components/template/compare/components/modules/CompareCard'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import { kebabCase } from 'src/modules/utils/kebabCase'
import styled from 'styled-components'

const CompareCardNetworks = ({ competitor, networks, logoText, ...props }) => {
  return (
    <StyledCompareCardNetworks
      hasLogo={true}
      hasButton={true}
      competitor={competitor}
      logoText={logoText}
      {...props}
    >
      <div className="networks">
        <ul>
          {networks.map((item, index) => {
            const network = item.network
            let available
            if (competitor) {
              available = item.competitor
            } else {
              available = true
            }
            const icon = available ? <>✅</> : <>❌</>
            if (network && network.logoWhite) {
              const tvChannelURL = network?.name
                ? `https://www.fubo.tv/welcome/networks/${kebabCase(network?.name).toLowerCase()}`
                : null

              return (
                <li className={!available ? 'faded' : ''} key={index}>
                  <a href={tvChannelURL} target="_blank">
                    <Image src={network.logoWhite} w={70} alt={network.alt} />
                    <span>
                      {network.alt} {icon}
                    </span>
                  </a>
                </li>
              )
            }
          })}
        </ul>
      </div>
    </StyledCompareCardNetworks>
  )
}

export default CompareCardNetworks

const StyledCompareCardNetworks = styled(CompareCard)`
  flex: 1;
  ${breakpointQuery.mobile} {
    .networks + .cta.hidden {
      display: none;
    }
  }
  .networks {
    width: 100%;
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      li {
        width: 50px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin: 0 auto;
        img {
          width: 50px;
          height: 35px;
          object-fit: contain;

          ${breakpointQuery.desktop} {
            width: 70px;
            height: 50px;
          }
        }
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 6.36364px;
          line-height: 10px;
          white-space: nowrap;
          /* identical to box height, or 160% */

          color: #ffffff;

          ${breakpointQuery.desktop} {
            font-size: 10px;
            line-height: 16px;
            /* identical to box height, or 160% */
          }
        }

        &.faded {
          img {
            opacity: 0.2;
          }
        }
      }
    }
  }
`
