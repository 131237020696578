import React from 'react'
import { caPlanCardOffers } from 'src/constants/caPlanCardOffers'
import { ratePlanCodes } from 'src/constants'
import { CanadaDefaultCtaText, defaultCTA } from 'src/modules/utils/constants'

export const caPlanPricing = {
  entertainment: {
    totalPrice: {
      monthly: 17.99,
      quarterly: 50.97,
    },
    discountedTotalPrice: {
      monthly: null,
      quarterly: null,
    },
  },
  soccer: {
    totalPrice: {
      monthly: 31.49,
      quarterly: 83.97,
      annual: 293.99,
    },
    discountedTotalPrice: {
      monthly: 26.99,
      quarterly: 44.97,
      annual: 230.99,
    },
  },
  premium: {
    totalPrice: {
      monthly: 45.14,
      quarterly: 110.23,
      annual: 415.67,
    },
    discountedTotalPrice: {
      monthly: null,
      quarterly: null,
      annual: null,
    },
  },
}

export const caPlanData = (channels, offerKey) => {
  const entertainmentChannels = channels?.['entertainment'] || 64
  const soccerChannels = channels?.['soccer'] || 79
  const premiumChannels = channels?.['premium'] || 101

  let data = {
    entertainment: {
      promoImg: 'https://assets-imgx.fubo.tv/marketing/lp/images/card/canada/ca_card_ent.jpg?w=451',
      plan: 'entertainment',
      title: 'Entertainment',
      subtitle: 'Best for Families',
      listItems: [
        { available: true, text: `${entertainmentChannels} channels - 1000's of popular titles` },
        { available: false, text: 'Premier League matches' },
        {
          available: true,
          text: 'Lifestyle channels - Global TV, Slice & more',
        },
        { available: true, text: 'Kids channels - Disney Channel & more' },
        { available: true, text: 'Unlimited Cloud DVR included' },
      ],
      footnote: '',

      offerCode: {
        monthly: ratePlanCodes.canada_ent_mo,
        quarterly: ratePlanCodes.canada_ent_qt,
      },
      totalPrice: caPlanPricing.entertainment.totalPrice,

      ctaText: defaultCTA.canada.freeTrial.text,
    },
    soccer: {
      promoImg: 'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_card_soccer-2.jpg?w=451',
      plan: 'soccer',
      title: 'Sports',
      subtitle: 'Best for Soccer and Sports Fans',
      listItems: [
        { available: true, text: `${soccerChannels} channels - Soccer and sports` },
        { available: true, text: 'All Premier League matches' },
        { id: '4k', available: true, text: 'Includes Premier League in 4K' },
        { available: true, text: 'Serie A, Coppa Italia, Ligue 1 & more' },
        { available: true, text: 'Unlimited Cloud DVR included' },
      ],
      footnote: (
        <>
          {/* *No free trial available. <br /> */}
          *Annual Plan. Monthly and quarterly plans also available.
        </>
      ),

      offerCode: {
        monthly: ratePlanCodes.canada_sports_mo,
        quarterly: ratePlanCodes.canada_sports_qt,
        annual: ratePlanCodes.canada_sports_yr,
      },
      savingsNote: {
        monthly: '',
        quarterly: '45% off',
        annual: '',
      },
      totalPrice: caPlanPricing.soccer.totalPrice,
      discountedTotalPrice: caPlanPricing.soccer.discountedTotalPrice,
      ctaText: CanadaDefaultCtaText,
    },
    premium: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca-premium-card-img-24.jpg?w=451',
      plan: 'premium',
      title: 'Premium',
      subtitle: 'Best for Everyone',
      listItems: [
        { available: true, text: `${premiumChannels} channels - Entertainment and sports` },
        { available: true, text: 'All Premier League & Serie A matches' },
        { available: true, text: 'Includes Premier League in 4K' },
        { available: true, text: 'Includes NBA TV' },
        {
          available: true,
          text: 'Lifestyle channels - Global TV, Slice & more',
        },
        { available: true, text: 'Kids channels - Disney Channel & more' },
        { available: true, text: 'Unlimited Cloud DVR included' },
      ],
      footnote: (
        <>
          {/* *No free trial available. <br /> */}
          *Annual Plan. Monthly and quarterly plans also available.
        </>
      ),

      offerCode: {
        monthly: ratePlanCodes.canada_premium_mo,
        quarterly: ratePlanCodes.canada_premium_qt,
        annual: ratePlanCodes.canada_premium_yr,
      },
      savingsNote: {
        monthly: '',
        quarterly: '',
        annual: '',
      },
      totalPrice: caPlanPricing.premium.totalPrice,
      discountedTotalPrice: caPlanPricing.premium.discountedTotalPrice,
      ctaText: CanadaDefaultCtaText,
    },
  }

  // adjust card data for promo offers
  if (offerKey) data = caPlanCardOffers(data, offerKey)

  // calculate per month numbers
  data = calculatePrices(data)
  data = addFootnotes(data)

  return data
}

const calculatePrices = (data) => {
  let newData = {
    entertainment: {
      ...data.entertainment,
      price: '',
      pricePerMonth: {
        monthly: data.entertainment.totalPrice.monthly,
        quarterly: data.entertainment.totalPrice.quarterly / 3,
      },
      // Preserve any manual savingsNote values, only calculate when not manually specified
      savingsNote: {
        ...calculateSavingsNotes(
          data.entertainment.totalPrice,
          data.entertainment.discountedTotalPrice
        ),
        ...Object.fromEntries(
          Object.entries(data.entertainment.savingsNote || {}).filter(([_, value]) => value !== '')
        ),
      },
    },
    soccer: {
      ...data.soccer,
      price: '',
      pricePerMonth: {
        monthly: data.soccer.totalPrice.monthly,
        quarterly: data.soccer.totalPrice.quarterly / 3,
        annual: data.soccer.totalPrice.annual / 12,
      },
      discountedPricePerMonth: {
        monthly: data.soccer.discountedTotalPrice.monthly,
        quarterly: data.soccer.discountedTotalPrice.quarterly / 3,
        annual: data.soccer.discountedTotalPrice.annual / 12,
      },
      // Preserve any manual savingsNote values, only calculate when not manually specified
      savingsNote: {
        ...calculateSavingsNotes(data.soccer.totalPrice, data.soccer.discountedTotalPrice),
        ...Object.fromEntries(
          Object.entries(data.soccer.savingsNote || {}).filter(([_, value]) => value !== '')
        ),
      },
    },
    premium: {
      ...data.premium,
      price: '',
      pricePerMonth: {
        monthly: data.premium.totalPrice.monthly,
        quarterly: data.premium.totalPrice.quarterly / 3,
        annual: data.premium.totalPrice.annual / 12,
      },
      discountedPricePerMonth: {
        monthly: data.premium.discountedTotalPrice.monthly,
        quarterly: data.premium.discountedTotalPrice.quarterly / 3,
        annual: data.premium.discountedTotalPrice.annual / 12,
      },
      // Preserve any manual savingsNote values, only calculate when not manually specified
      savingsNote: {
        ...calculateSavingsNotes(data.premium.totalPrice, data.premium.discountedTotalPrice),
        ...Object.fromEntries(
          Object.entries(data.premium.savingsNote || {}).filter(([_, value]) => value !== '')
        ),
      },
    },
  }

  newData.entertainment.price = lowestPrice(newData.entertainment)
  newData.soccer.price = lowestPrice(newData.soccer)
  newData.premium.price = lowestPrice(newData.premium)

  return newData
}

const calculateSavingsNotes = (totalPrice, discountedTotalPrice) => {
  const savingsNote = {
    monthly: '',
    quarterly: '',
    annual: '',
  }

  // Handle case where discountedTotalPrice is null or undefined
  if (!discountedTotalPrice) return savingsNote

  // Calculate for each billing period if there's a discount
  Object.keys(savingsNote).forEach((period) => {
    if (totalPrice?.[period] && discountedTotalPrice?.[period]) {
      const savings = totalPrice[period] - discountedTotalPrice[period]
      const savingsPercent = Math.round((savings / totalPrice[period]) * 100)

      if (savings > 0) {
        savingsNote[period] = `C$${savings.toFixed(2)} off`
      }
    }
  })

  return savingsNote
}

const addFootnotes = (data) => {
  let newData = {
    entertainment: {
      ...data.entertainment,
      cardFootnote: {
        monthly: createFootnote(data.entertainment, 'Monthly', false),
        quarterly: createFootnote(data.entertainment, 'Quarterly', false),
      },
    },
    soccer: {
      ...data.soccer,
      cardFootnote: {
        monthly: createFootnote(data.soccer, 'Monthly'),
        quarterly: createFootnote(data.soccer, 'Quarterly'),
        annual: createFootnote(data.soccer, 'Annual'),
      },
    },
    premium: {
      ...data.premium,
      cardFootnote: {
        monthly: createFootnote(data.premium, 'Monthly'),
        quarterly: createFootnote(data.premium, 'Quarterly'),
        annual: createFootnote(data.premium, 'Annual'),
      },
    },
  }

  return newData
}

export const createFootnote = (data, duration) => {
  const totalPrice = data.totalPrice[duration.toLowerCase()]
  const discountedTotalPrice = data.discountedTotalPrice?.[duration.toLowerCase()]

  let numOfMonths
  switch (duration) {
    case 'Annual':
      numOfMonths = 12
      break
    case 'Quarterly':
      numOfMonths = 3
      break
    case 'Monthly':
      numOfMonths = 1
      break
    default:
      break
  }
  if (discountedTotalPrice && numOfMonths) {
    return `*Average cost per month. Charged for the first ${numOfMonths} months at C$${discountedTotalPrice}, then C$${totalPrice} every ${numOfMonths} months. Prices subject to change.`
  } else if (numOfMonths) {
    if (numOfMonths === 1) {
      return `*Charged every month at C$${totalPrice}. Prices subject to change.`
    } else {
      return `*Average cost per month. Charged every ${numOfMonths} months at C$${totalPrice}. Prices subject to change.`
    }
  } else if (discountedTotalPrice) {
    return `*Renews at full price of C$${totalPrice}. Prices subject to change.`
  }
}

const lowestPrice = (data) => {
  const lowest = Math.min(
    data.pricePerMonth.monthly,
    data.pricePerMonth.quarterly,
    data.pricePerMonth.annual,
    data.discountedPricePerMonth?.monthly,
    data.discountedPricePerMonth?.quarterly,
    data.discountedPricePerMonth?.annual
  )

  const highest = Math.max(
    data.pricePerMonth.monthly,
    data.pricePerMonth.quarterly,
    data.pricePerMonth.annual,
    data.discountedPricePerMonth?.monthly,
    data.discountedPricePerMonth?.quarterly,
    data.discountedPricePerMonth?.annual
  )

  return `${lowest !== highest ? 'Starting at ' : ''}C$${lowest}/mo`
}

const calculateLowestPrice = (data) => {
  const allPrices = []

  // Collect all valid prices from all plans
  Object.values(data).forEach((plan) => {
    // Add regular prices
    Object.values(plan.totalPrice || {}).forEach((price) => {
      if (typeof price === 'number' && !isNaN(price)) {
        // Calculate monthly price
        if (plan.totalPrice.quarterly && price === plan.totalPrice.quarterly) {
          allPrices.push(price / 3)
        } else if (plan.totalPrice.annual && price === plan.totalPrice.annual) {
          allPrices.push(price / 12)
        } else {
          allPrices.push(price)
        }
      }
    })

    // Add discounted prices if available
    Object.values(plan.discountedTotalPrice || {}).forEach((price) => {
      if (typeof price === 'number' && !isNaN(price)) {
        // Calculate monthly price
        if (plan.discountedTotalPrice.quarterly && price === plan.discountedTotalPrice.quarterly) {
          allPrices.push(price / 3)
        } else if (plan.discountedTotalPrice.annual && price === plan.discountedTotalPrice.annual) {
          allPrices.push(price / 12)
        } else {
          allPrices.push(price)
        }
      }
    })
  })

  // If no valid prices found, return null to handle the error case gracefully
  if (allPrices.length === 0) {
    console.error('No valid prices found in calculateLowestPrice')
    return null
  }

  // Return the lowest price
  return Math.min(...allPrices).toFixed(2)
}

// Calculate lowest price from all plans
export const CALowestPrice = calculateLowestPrice(caPlanPricing)

// Calculate lowest price from only sports plans (soccer and premium)
export const CALowestSportsPrice = calculateLowestPrice({
  soccer: caPlanPricing.soccer,
  premium: caPlanPricing.premium,
})

export const newTabData = {
  monthly: {
    tabNote: '',
  },
  quarterly: {
    tabNote: '',
  },
  annual: {
    tabNote: '',
  },
}
