import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CTAButton from 'src/components/common/PlanCardCTA'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { selectCTAParams } from 'src/modules/reducers/params'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import styled from 'styled-components'
import Image from '../Image'

const CheckSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.13055 15C7.55173 15 7.95468 14.8282 8.24629 14.5243L19.6726 2.61701C20.2639 2.00079 20.2437 1.02187 19.6275 0.430538C19.0113 -0.160795 18.0324 -0.140615 17.441 0.47561L7.13046 11.2202L2.66212 6.56426C2.07076 5.94806 1.09184 5.92793 0.475644 6.5193C-0.140551 7.11066 -0.160681 8.08958 0.430684 8.70578L6.0148 14.5244C6.30642 14.8282 6.70938 15 7.13055 15Z"
        fill="#49F296"
      />
    </svg>
  )
}

const PlusCircleSVG = () => {
  return (
    <svg
      className="plus-circle-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
    >
      <g filter="url(#filter0_d_290_448)">
        <rect x="13" y="11" width="37.0044" height="37.0044" rx="18.5022" fill="#222630" />
        <rect x="13.5" y="11.5" width="36.0044" height="36.0044" rx="18.0022" stroke="#A0A0B6" />
      </g>
      <path
        d="M29.9349 30.928H22.542V28.1276H29.9349V20.2119H32.7726V28.1276H40.1656V30.928H32.7726V38.881H29.9349V30.928Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_290_448"
          x="0.528544"
          y="0.60712"
          width="61.9473"
          height="61.9473"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.07858" />
          <feGaussianBlur stdDeviation="6.23573" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_290_448" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_290_448"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

const AddOnCard = ({ title, subtitle, logos }) => {
  return (
    <StyledAddOnCard className="planCard__addOnCard">
      <div>
        <PlusCircleSVG />
        <h4>{title}</h4>
        {logos && (
          <div className="planCard__addOnCard__logos">
            {logos.map((logo, index) => (
              <Image src={logo} alt={`logo-${index}`} />
            ))}
          </div>
        )}
        {subtitle && <h3>{subtitle}</h3>}
      </div>
    </StyledAddOnCard>
  )
}
const PlanCardWithAddon = ({
  promoImg,
  plan,
  length,
  title,
  subtitle,
  currency,
  price,
  discountedPrice,
  savingsNote,
  // listItems will need to be an array of objects of {string, boolean}
  listItems,
  offerCode,
  ctaUrl,
  ctaText,
  asterisk,
  footnote,
  onClick,
  btnColor,
  banner,
  networkLogos,
  showNetworks,
  isFootnoteInCard,
  addOnInfo,
}) => {
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)
  ctaURL = replaceUrlParam(ctaURL, 'offer', offerCode)
  ctaURL = replaceUrlParam(ctaURL, 'comp', 'plan-card')

  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const handleOnClick = (item) => {
    fireUIInteractionEvent(
      {
        element: offerCode,
        component: 'plan_card_cta_clicked',
      },
      stateForProtobuf
    )
  }

  const footnoteInCardRef = useRef(null)

  useEffect(() => {
    const matchHeights = () => {
      // Only match heights if screen is larger than mobile breakpoint
      if (window.innerWidth <= 576) {
        // Reset heights on mobile
        if (footnoteInCardRef.current) footnoteInCardRef.current.style.minHeight = 'auto'
        return
      }

      // Desktop behavior
      if (footnoteInCardRef.current) footnoteInCardRef.current.style.minHeight = 'auto'

      const footnotes = document.querySelectorAll('.planCard__footnote_in_card')
      let maxHeight = 0
      footnotes.forEach((footnote) => {
        const height = footnote.getBoundingClientRect().height
        maxHeight = Math.max(maxHeight, height)
      })

      footnotes.forEach((footnote) => {
        footnote.style.minHeight = `${maxHeight}px`
      })
    }

    // Initial match
    matchHeights()

    // Add resize listener
    window.addEventListener('resize', matchHeights)

    // Cleanup
    return () => window.removeEventListener('resize', matchHeights)
  }, [footnote])

  return (
    <StyledPlanCardWithImage className="planCard-container">
      <a className="planCard" href={ctaURL} onClick={handleOnClick}>
        {banner && <div className="planCard__banner">{banner}</div>}
        <div className={`planCard__image ${banner ? 'planCard__image-with-banner' : ''}`}>
          <Image src={promoImg} alt={title} />
        </div>
        <div className="planCard__content">
          <div className="planCard__header">
            <div className="planCard__title">
              {title} {length}
            </div>
            <div className="planCard__subtitle">{subtitle}</div>
            {showNetworks && networkLogos && networkLogos.length > 0 && (
              <div className="planCard__networks">
                {networkLogos.map(({ logo, alt, width, height }) => (
                  <div key={alt} className="planCard__network-logo">
                    {logo ? (
                      <StyledImg src={logo} alt={alt} customWidth={width} customHeight={height} />
                    ) : (
                      <div className="planCard__network-logo-text">{alt}</div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className="planCard__price">
              {discountedPrice ? (
                <>
                  {currency}${discountedPrice.toFixed(2)}/mo
                  {asterisk && <span className="asterisk">*</span>}
                  <div className="faded-price">
                    {' '}
                    {currency}${price.toFixed(2)}/mo
                  </div>
                </>
              ) : (
                <>
                  {currency}${price.toFixed(2)}/mo
                  {asterisk && <span className="asterisk">*</span>}
                </>
              )}
            </div>

            {savingsNote && (
              <div className="discount__note">
                {typeof savingsNote === 'string' && <span>{savingsNote}</span>}
              </div>
            )}
          </div>
          <div className="planCard__divider"></div>

          <div className="planCard__list">
            {listItems?.map((item, index) => {
              if (item === null) return null
              const check = item?.available ? (
                <span className="check">
                  <CheckSVG />
                  &#xfe0e;
                </span>
              ) : (
                <span className="cross">✘&#xfe0e;</span>
              )

              return (
                <div key={index} className="list-item">
                  {check} {item.text}
                </div>
              )
            })}
          </div>
          {addOnInfo && <AddOnCard title={addOnInfo.title} logos={addOnInfo.logos} />}
        </div>

        <div className="planCard_cta">
          <CTAButton link={ctaUrl} color={btnColor && '#ffffff'}>
            {ctaText}
          </CTAButton>
        </div>
        {isFootnoteInCard && (
          <div className="planCard__footnote_in_card" ref={footnoteInCardRef}>
            {footnote}
          </div>
        )}
      </a>
      {!isFootnoteInCard && <div className="planCard__footnote">{footnote}</div>}
    </StyledPlanCardWithImage>
  )
}

export default PlanCardWithAddon

const StyledImg = styled.img`
  max-height: ${(props) => `${props.customHeight}px`};
  max-width: ${(props) => `${props.customWidth}px`};
  @media (max-width: 576px) {
    max-height: ${(props) => `${props.customHeight * 0.9}px`};
    max-width: ${(props) => `${props.customWidth * 0.9}px`};
  }
`

const StyledPlanCardWithImage = styled.div`
  width: 100%;
  max-width: 520px;

  .planCard {
    ${'' /* width: 460px; */}
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 43%;
    padding-bottom: 10%;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
    background: linear-gradient(181deg, rgba(4, 9, 17, 0) 7.46%, #040911 26.03%);
    cursor: pointer;
    transition: transform 0.4s;
    &:hover {
      transform: scale(1.01);
    }
    .planCard__divider {
      width: 85%;
      height: 1px;
      background: #5d6479;
      margin-bottom: 35px;
    }
    @media (max-width: 576px) {
      padding-bottom: 8%;
    }
    .planCard__banner {
      z-index: 3;
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      background: #0367ff;
      color: #fff;
      padding: 14px 10px;
      font-weight: 700;
      font-size: 22px;
      @media (max-width: 576px) {
        font-size: 18px;
      }
    }
    .planCard__image {
      width: 100%;
      position: absolute;
      top: 55px;
      z-index: 1;
      overflow: hidden;
      border-radius: 12px;
      &.planCard__image-with-banner {
        border-radius: 0;
      }

      img {
        width: 100%;
      }
      &::after {
        background: linear-gradient(181deg, rgba(4, 9, 17, 0) 10.46%, #040911 92.03%);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .planCard__content {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding-left: 10%;
      padding-right: 10%;
      .planCard__header {
        .planCard__title {
          text-transform: capitalize;
          font-size: 34px;
          font-weight: 700;
          margin-bottom: 4px;
          @media (max-width: 576px) {
            font-size: 30px;
          }
        }
        .planCard__subtitle {
          font-size: 22px;
          line-height: 1.4;
          font-weight: 500;
          @media (max-width: 576px) {
            font-size: 20px;
          }
        }

        .planCard__price {
          font-size: 30px;
          font-weight: 700;
          margin: 35px 0;
          display: flex;
          align-items: flex-end;
          @media (max-width: 576px) {
            font-size: 20px;
          }
        }
        .faded-price {
          font-size: 22px;
          color: #6c7078;
          text-decoration: line-through;
          font-weight: 500;
          -webkit-text-decoration-color: #ea0000;
          text-decoration-color: #ea0000;
          margin-left: 14px;
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }
        .planCard__networks {
          display: flex;
          flex-direction: row;
          gap: 18px;
          height: 50px;
          margin-top: 30px;
          margin-bottom: 40px;
          @media (max-width: 576px) {
            gap: 15px;
          }
        }
        .planCard__network-logo {
          display: flex;
          align-items: center;
          gap: 6px;

          img {
            ${
              '' /* max-height: 30px;
            max-width: 100px; */
            }
          }
          .planCard__network-logo-text {
            font-size: 18px;
            font-weight: 700;
            white-space: nowrap;
          }
        }
        .discount__note {
          font-size: 0.75rem;
          font-weight: 600;
          letter-spacing: 0.01em;
          line-height: 1.25;
          text-decoration: none;
          text-transform: none;
          color: rgb(255, 255, 255);
          display: inline-block;
          background-color: rgb(0, 136, 23);
          border-radius: 4px;
          span {
            display: inline-block;
            padding: 4px 8px;
          }
        }
      }
      .planCard__list {
        display: flex;
        flex-direction: column;
        @media (max-width: 576px) {
          padding-bottom: 20px;
        }
        .list-item {
          font-size: 18px;
          line-height: 1.3;
          font-weight: 600;
          letter-spacing: 0px;
          margin-bottom: 14px;
          display: flex;
          @media (max-width: 576px) {
            font-size: 16px;
          }
          span {
            width: 20px;
            margin-right: 14px;
            line-height: 1;
            flex: 0 0 20px;
          }
          .check {
            color: #04a928;
            font-size: 18px;
            display: flex;
            align-items: center;
            margin-top: 2px;
          }
          .cross {
            color: #ff0000;
            font-size: 20px;
          }
        }
      }
    }
    .planCard_cta {
      position: relative;
      z-index: 2;
      padding-left: calc(11%);
      padding-right: calc(11%);
      margin-top: 40px;
    }
    .planCard__footnote_in_card {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin-top: 20px;
      padding-left: 8%;
      padding-right: 8%;
    }
  }
  .planCard__footnote {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
  }
`

const StyledAddOnCard = styled.div`
  border-radius: 8px;
  border: 1px solid #5d6479;
  background-color: #161822;
  margin: 40px auto 0;
  max-width: 350px;
  width: 100%;
  position: relative;
  > div {
    padding: 32px 20px;
  }
  .plus-circle-svg {
    position: absolute;
    top: -33px;
    left: 50%;
    transform: translateX(-50%);
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .planCard__addOnCard__logos {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    margin-top: 5px;
    align-items: center;
    img {
      height: 33px;
      width: 120px;
      max-width: 100%;
      object-fit: contain;
      justify-self: center;
      @media (max-width: 576px) {
        width: 100px;
        height: 25px;
      }
    }
  }
`
