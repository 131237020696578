import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { images } from 'src/constants/images'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      poster={images.corus.url}
      title="Watch your top channels live"
      subtitle="SLICE, HOME NETWORK, FLAVOUR NETWORK AND MORE"
      tagline="Stream them from your TV and other devices"
      plan="entertainment"
      networkSet="canada"
    />
  )
}
