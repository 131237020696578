import {
  INIT_STAC_FLAGS,
  TRY_FETCH_STAC_FLAGS,
  FETCH_STAC_FLAGS_SUCCESS,
  FETCH_STAC_FLAGS_FAILED,
} from './actionTypes'
import { StacFlags, initValues } from './flags.ts'

export type StacFlagsState = {
  isDefault: boolean
  isInitialized: boolean
  isFetching: boolean
  isRefetching: boolean
  flags: StacFlags
}
const initState: StacFlagsState = {
  isDefault: true,
  isInitialized: false,
  isFetching: false,
  isRefetching: false,
  flags: initValues,
}
export default function reducer(
  state: StacFlagsState | null | undefined = initState,
  action
): StacFlagsState {
  switch (action.type) {
    case INIT_STAC_FLAGS:
      return {
        ...state,
        isInitialized: true,
        isFetching: true,
      }
    case TRY_FETCH_STAC_FLAGS:
      return {
        ...state,
        isFetching: !action.keys,
        isRefetching: !!action.keys,
      }
    case FETCH_STAC_FLAGS_SUCCESS:
      return {
        ...state,
        isDefault: false,
        isFetching: false,
        isRefetching: false,
        flags: {
          ...state.flags,
          ...action.flags,
        },
      }
    case FETCH_STAC_FLAGS_FAILED:
      return {
        ...state,
        isFetching: false,
        isRefetching: false,
      }
    default:
      return state
  }
}
