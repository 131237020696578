import { combineReducers } from 'redux'
import cohortReducer from '../reducers/cohorts/reducer.ts'
import analyticsReducer from '../reducers/analytics/reducer.ts'
import { marketReducer } from '../reducers/market/reducers.ts'
import { transcendReducer } from '../reducers/transcend/reducers.ts'
import { paramsReducer } from '../reducers/params/reducers.ts'
import productsReducer from '../reducers/products/index.ts'
import impactReducer from '../reducers/impact/reducer.ts'
import zvaReducer from '../reducers/zva/reducer.ts'

const reducers = {
  cohorts: cohortReducer,
  analytics: analyticsReducer,
  market: marketReducer,
  transcend: transcendReducer,
  params: paramsReducer,
  impact: impactReducer,
  zva: zvaReducer,
  products: productsReducer,
}

export const rootReducer = combineReducers(reducers)
