import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { fireUIInteractionEvent, fireUIRenderEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { selectStacFlagsReady } from 'src/modules/reducers/cohorts'
import { getTextContent } from 'src/modules/utils/text'

const defaultTitle = {
  en: '✔ Special Offer Applied',
  es: '✔ Oferta Especial Aplicada',
}

const Banner = ({ title, ctaHrefOverride, lang = 'en' }) => {
  const parsedParams = useSelector(selectCTAParams)
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const [isPromoBannerRendered, setIsPromoBannerRendered] = useState(false)
  const isCohortReady = useSelector(selectStacFlagsReady)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)
  ctaURL = replaceUrlParam(ctaURL, 'comp', 'offerbanner')

  title =
    typeof title === 'string' && title?.includes('<>')
      ? title.split('<>').map((line, index, array) => (
          <Fragment key={index}>
            {line}
            {index < array.length - 1 && <br />}
          </Fragment>
        ))
      : title

  const bannerTitle = title || defaultTitle[lang]
  const plainTextTitle = getTextContent(bannerTitle)

  const handleOnClick = () => {
    fireUIInteractionEvent(
      {
        element: plainTextTitle,
        component: 'offer_banner_clicked',
      },
      stateForProtobuf
    )
  }

  useEffect(() => {
    if (!isCohortReady) return
    if (bannerTitle && !isPromoBannerRendered) {
      fireUIRenderEvent(
        {
          element: plainTextTitle,
          component: 'promo_banner',
        },
        stateForProtobuf
      )
      setIsPromoBannerRendered(true)
    }
  }, [isCohortReady, isPromoBannerRendered, bannerTitle])

  return (
    <BannerWrapper
      onClick={handleOnClick}
      href={ctaHrefOverride || ctaURL}
      className="offer-banner"
    >
      <BannerInner>
        <BannerContent>
          <div className="banner-title">{bannerTitle}</div>
        </BannerContent>
      </BannerInner>
    </BannerWrapper>
  )
}

export default Banner

const BannerWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 160, 215, 0.95);
  position: sticky;
  color: #fff;
  top: 0;
  padding: 20px 5px 15px;
  z-index: 10000;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }

  .banner-title {
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
    max-width: 400px;
    line-height: 1.6;
    width: 100%;
    font-weight: 700;

    img {
      height: 23px;
      margin-left: 6px;
      margin-right: 6px;
    }

    @media (min-width: 600px) {
      max-width: 100%;
      padding: 0;
    }
  }

  .banner-space {
    visibility: hidden;
    pointer-events: none;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .banner-note {
    font-weight: 700;
    padding-right: 20px;
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const BannerInner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BannerSubtitle = styled.div`
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  max-width: 400px;
  line-height: 1.6;
  width: 100%;
  font-weight: 500;

  @media (min-width: 600px) {
    max-width: 100%;
    padding: 0;
  }

  a {
    font-weight: 700;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`
