import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { images } from 'src/constants/images'
import { laChannelCount } from 'src/constants/laPlanData'
export default (props) => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      poster={images.formula1.url}
      title="Las Carreras del Grand Prix en vivo"
      subtitle="ACCEDE DESDE CUALQUIER DISPOSITIVO"
      tagline={`Más de ${laChannelCount} canales con deportes, noticias, novelas y entretenimiento en español.`}
      networkSet="entertainmentLatino"
      noteLink="/la/formula1/"
    />
  )
}
