import { teamData } from 'src/constants/teamData'

export const getUniqueLeagues = (teams) => {
  if (!teams) return []
  return [...new Set(teams.map((team) => teamData[team].league).filter(Boolean))]
}

export const getLeague = (team) => {
  return teamData[team].league
}
