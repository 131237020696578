import React from 'react'
import styled from 'styled-components'

function getContrastingColor(hex) {
  // Remove the leading '#' symbol if present
  if (hex.startsWith('#')) {
    hex = hex.slice(1)
  }

  // Parse the hex code into its RGB components
  const red = parseInt(hex.slice(0, 2), 16)
  const green = parseInt(hex.slice(2, 4), 16)
  const blue = parseInt(hex.slice(4, 6), 16)

  // Calculate the relative luminance of the color
  const luminance = (red * 0.299 + green * 0.587 + blue * 0.114) / 255

  // Determine if the color is dark or light based on luminance
  if (luminance > 0.5) {
    return '#000000' // Light color
  } else {
    return '#ffffff' // Dark color
  }
}

const CTAButton = ({ link, round, color, children, ...props }) => {
  return (
    <StyledCTAButton href={link} color={color} {...props} round={round} className="CTAButton">
      {children}
    </StyledCTAButton>
  )
}

export default CTAButton

const StyledCTAButton = styled.div`
  position: relative;
  display: inline-block;
  font-size: 24px;
  background: ${(props) => (props.color ? '#transparent' : '#e03100')};
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => (props.color ? props.color : '#e03100')};
  padding: 14px 20px;
  border-radius: 4px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  align-self: center;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;

  :hover {
    background: ${(props) => (props.color ? props.color : '#b33922')};
    border-color: ${(props) => (props.color ? props.color : '#b33922')};
    color: ${(props) => (props.color ? getContrastingColor(props.color) : '#ffffff')};
    cursor: pointer;
  }

  @media screen and (max-width: 738px) {
    ${'' /* max-width: 300px; */}
    font-size: 20px;
    padding: 10px 20px;
  }
`
