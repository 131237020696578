import React from 'react'
import TermsConditionsTemplate from 'src/components/template/terms-conditions'

export default (props) => (
  <TermsConditionsTemplate>
    <h1>Fubo Streaming Device Signup Promotion Terms and Conditions</h1>
    <h2>OFFICIAL RULES</h2>

    <ol>
      <li>
        These terms and conditions apply to the fuboTV Inc. ("Fubo," "we," "our," or "us") Streaming
        Device Signup Promotion (the "Promotion").
      </li>
      <li>
        To be eligible for the Fubo Streaming Device Signup Promotion, you must:
        <ul>
          <li>
            Be a new Fubo subscriber who has not previously had a free or paid subscription to Fubo;
          </li>
          <li>
            Be presented with an offer by Fubo to sign up for the Fubo Streaming Device Signup
            Promotion during a signup journey and accept the offer at that time;
          </li>
          <li>
            Accept the{' '}
            <a href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
              Fubo Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://legal.fubo.tv/policies/en-US/?name=privacy-policy">Privacy Policy</a>;
          </li>
          <li>
            Be a U.S. resident, who at the time of subscription to Fubo is at least eighteen (18)
            years of age or older;
          </li>
          <li>May not reside in Alaska, Hawaii, or Puerto Rico;</li>
          <li>
            The officers, directors and employees of Fubo, its subsidiaries and affiliated
            companies, persons engaged in the development, production or distribution of materials
            for the Fubo Streaming Device Signup Promotion, and the immediate family members
            (grandparent, parent, child, sibling, and spouse of each) of and/or persons living in
            the same household as such individuals (whether related or not) are not eligible to
            participate in the Fubo Streaming Device Signup Promotion;
          </li>
          <li>
            Be eligible for Fubo service as such eligibility is defined in the{' '}
            <a href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
              Fubo Terms of Service
            </a>
            ; and,
          </li>
          <li>
            Sign up for Fubo and maintain a paid monthly "Elite" subscription with Fubo for three
            hundred and sixty-five (365) days following the end of any free trial ("Service Period")
            and maintain your Fubo account in good standing ("Required Service Level") for the
            entire Service Period. The addition or removal of{' '}
            <a href="https://www.fubo.tv/welcome/channels">add-ons</a> by you during signup or the
            Service Period will not impact the Promotion.
          </li>
        </ul>
      </li>
      <li>
        Participation in the Promotion requires acceptance of the{' '}
        <a href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
          Fubo Terms of Service
        </a>
        . The{' '}
        <a href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
          Fubo Terms of Service
        </a>{' '}
        CONTAIN A CLASS WAIVER AS WELL AS A MANDATORY ARBITRATION PROVISION THAT MAY IMPACT YOUR
        LEGAL RIGHTS IN THE EVENT OF A DISPUTE. SPECIFICALLY, PLEASE REFER TO SECTION 22 OF THE{' '}
        <a href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
          FUBO TERMS OF SERVICE
        </a>
        , WHICH REQUIRES THE USE OF BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES,
        RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS ACTIONS OF ANY KIND, TO THE
        FULLEST EXTENT PERMITTED BY THE LAW. ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU WILL
        NOT HAVE, AND THAT YOU WAIVE, THE RIGHT FOR A JUDGE OR JURY TO DECIDE YOUR CLAIMS, AND THAT
        YOU MAY NOT PROCEED IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE CAPACITY IN ANY FORUM, TO
        THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND AS DESCRIBED IN MORE DETAIL BELOW, YOU
        HAVE A RIGHT TO OPT OUT OF ARBITRATION AND/OR THE CLASS ACTION WAIVER, AS EXPLAINED BELOW.
      </li>
      <li>
        Your first subscription payment will not be due until after the end of any free trial. The
        Device will be shipped to you within seven (7) business days after your first subscription
        payment. Delivery of the device may take up to fourteen (14) business days after shipment.
      </li>
      <li>
        If you are eligible and sign up for Fubo service through the Fubo Streaming Device Signup
        Promotion, you will be provided with an Apple TV 4K Wi-Fi with 64GB storage and a compatible
        remote (collectively, the "Device"). The Device has a retail value of one hundred and
        twenty-nine dollars ($129) plus any applicable taxes ("Retail Value").
      </li>
      <li>
        Device is not required to watch content on Fubo. Fubo is available on multiple platforms,
        including streaming devices, computer browsers, select smart TVs, mobile devices, and more.
        Device compatibility subject to change. For a list of devices that can be used to watch
        content on Fubo visit{' '}
        <a href="https://support.fubo.tv/hc/en-us/articles/115002183228-What-devices-can-I-watch-Fubo-on">
          https://support.fubo.tv/hc/en-us/articles/115002183228-What-devices-can-I-watch-Fubo-on
        </a>
        .
      </li>
      <li>
        You are required to provide at your own expense any devices, cables, or equipment and
        internet and/or data service(s) necessary to access Fubo as well as any cables, or equipment
        and internet and/or data service(s) necessary to operate the Device.
      </li>
      <li>
        You are bound to these terms regardless of whether you activate and/or use your Device
        during the Required Service Term. Your Fubo subscription will not be paused, extended, or
        modified as a result of any limitations, restrictions, or terminations of access to the Fubo
        services on the Device regardless of the cause.
      </li>
      <li>
        You must contact Fubo within seven (7) days of the scheduled delivery date of the Device to
        report an undelivered or damaged Device.
      </li>
      <li>
        The Apple Limited Warranty covers your Apple TV and the Apple-branded accessories that come
        in the box with your product against manufacturing issues for one year from the date you
        bought them. If you are experiencing an issue with your Apple TV, please contact Apple using
        the following links: To check your device's coverage, visit{' '}
        <a href="https://checkcoverage.apple.com/">this page</a>. To learn more about Apple repair
        services, visit <a href="https://support.apple.com/apple-tv/repair">this page</a>. To read
        the Apple Limited Warranty, visit{' '}
        <a href="https://www.apple.com/legal/warranty/">this page</a>.
      </li>
      <li>
        You may contact Fubo within one year from the purchase date should you need assistance with
        your remote.
      </li>
      <li>Device is not returnable except where required by applicable law.</li>
      <li>
        You may modify or cancel your subscription to Fubo at any time in accordance with the{' '}
        <a href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
          Fubo Terms of Service
        </a>
        , provided however that except as required by applicable law, if you modify, suspend,
        cancel, or terminate the Required Service Level during the Service Period, you will
        immediately be charged and have to pay to Fubo the full Retail Value of the Device ("Device
        Payment"). The Retail Value associated with such payment will not be prorated or otherwise
        adjusted during the Service Period.
      </li>
      <li>
        Nothing in these Official Rules shall prevent Fubo from Terminating Service your service in
        accordance with the{' '}
        <a href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
          Fubo Terms of Service
        </a>
        . In the event Fubo terminates your service for a breach by you of the{' '}
        <a href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
          Fubo Terms of Service
        </a>
        , including a failure to pay any required Fees (as defined by the{' '}
        <a href="https://legal.fubo.tv/policies/en-US/?name=terms-of-service">
          Fubo Terms of Service
        </a>
        ), except as required by applicable law, you will immediately be charged and have to pay to
        Fubo the full Device Payment. The Retail Value will not be prorated or otherwise adjusted
        during the Service Period.
      </li>
      <li>
        You bear the entire risk of loss, theft or damage to the Device from any cause during the
        term of the Service Period. Even if the Device is lost, stolen or damaged, you remain
        obligated to maintain the Required Service Level or pay the Device Payment.
      </li>
      <li>
        We may, without your consent, and without giving you notice, assign or transfer the amounts
        due by you in accordance with these Official Rules or any payment or any other sums due or
        to become due hereunder. In such event the assignee will have, to the extent transferred or
        assigned to it, all Our rights, powers, privileges and remedies under these Official Rules.
        You agree you will not assign your obligations under these Official Rules or any interest in
        it and will not sell or transfer, or offer to sell or transfer, or enter, or offer to enter,
        into any lease with respect to the Device covered by these Official Rules without Our prior
        written consent.
      </li>
      <li>Plan pricing is subject to change during the Required Service Period.</li>
      <li>This is not a lease.</li>
      <li>
        Fubo may share necessary information, including name, address, and phone number, with third
        parties to facilitate shipping of the Device.
      </li>
      <li>
        Apple Inc. is not a sponsor, endorser, or affiliated in any way with the Promotion. All
        questions regarding the Promotion must be addressed to Fubo at the address provided below.
      </li>
      <li>
        Limitation of Liability. TO THE FULL EXTENT PERMISSIBLE BY LAW, WE MAKE NO WARRANTIES,
        EXPRESS OR IMPLIED, WITH RESPECT TO THE DEVICE, INCLUDING WITHOUT LIMITATION, ANY EXPRESS OR
        IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. TO THE FULL EXTENT
        PERMISSIBLE BY LAW, IN THE EVENT THE DEVICE IS NON-FUNCTIONAL, YOUR SOLE REMEDY, AND FUBO’S
        SOLE LIABILITY, WILL BE THE REPLACEMENT OF THE DEVICE.
      </li>
      <li>
        If Fubo determines that you participated in the Promotion but were not eligible to
        participate in the Promotion, Fubo will seek a return of the Device, or reimbursement for
        the value of the Device, at its sole discretion.
      </li>
      <li>Fubo may modify or terminate this promotion at any time and without notice.</li>
      <li>The Promotion is void where prohibited or restricted by law.</li>
      <li>
        If You wish to contact Fubo or have any questions or concerns related to this promotion,
        please (1) visit our Help Center, (2) message us via the in-service chat, (3) call us at
        1-844-238-2688, (4) email us at <a href="mailto:support@fubo.tv">support@fubo.tv</a>; or (5)
        write us at the below address:
        <p>
          fuboTV Inc.
          <br />
          1290 Avenue of the Americas
          <br />
          New York, NY 10104
        </p>
      </li>
    </ol>
  </TermsConditionsTemplate>
)
