export const basePlanCodes = {
  canada_cricket_mo: 'ca-crick_sportsp-mo-v1',
  canada_cricket_qt: 'ca-crick_sportsp-qt-v1',
  canada_cricket_yr: 'ca-crick_sportsp-yr-v1',
  canada_entertainment: 'canada-entertainment',
  canada_essentials: 'canada-essentials',
  canada_premium: 'canada-premium',
  fubo_free: 'fubo_free',
  spain_basic: 'spain-basic',
  us_deluxe: 'us-deluxe',
  us_elite: 'us-elite-v2',
  us_essential: 'us-essential-mo-v1',
  us_latino: 'us-latino',
  us_pro: 'us-pro',
  us_zee_family_mo: 'us-zee_family-mo-v1',
  us_zee_family_qt: 'us-zee_family-qt-v1',
  us_zee_family_yr: 'us-zee_family-yr-v1',
}
