import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'
export default (props) => (
  <CanadaTemplateDefault
    location={props.location}
    lang="en"
    market="canada"
    video={videos.mlbtv.videoUrl}
    staticAnnotation={videos.mlbtv.staticAnnotation}
    title="Get MLB.TV with Fubo"
    subtitle="WATCH +2000 LIVE GAMES AND MUCH MORE"
    tagline="See every out-of-marketing team in action"
    heroCtaNote={
      <>
        Blackout and other restrictions apply. Major League Baseball trademarks and copyrights are
        used with permission of Major League Baseball. Visit{' '}
        <a href="MLB.com" target="_blank" style={{ textDecoration: 'underline' }}>
          MLB.com
        </a>{' '}
        for details.
      </>
    }
    networkSubtitle="Watch Premier League, Serie A, MLB & so much more?"
    networkSet="canadaEssentials"
    plan="sportsOnly"
    plansSubtitle="Canada’s home to streaming live sports and top entertainment"
    customNetworks={['mlbtv']}
    league="mlb"
    planCardOverride={'mlbtvOffer'}
    soccerPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/canada/ca_card_mlb-tv.jpg?w=750&ch=width&auto=format,compress"
    forceAnchorLink={true}
  />
)
