import { ApiError } from 'src/modules/utils/types/domain'
import {
  FETCH_FREE_TRIAL_AVAILABLE_SUCCESS,
  FETCH_FREE_TRIAL_AVAILABLE_FAILURE,
  FetchFreeTrialAvailableSuccessAction,
  FetchFreeTrialAvailableFailureAction,
  TRY_FETCH_FREE_TRIAL_AVAILABLE,
  TryFetchFreeTrialAvailableAction,
} from '../actionTypes/'
import { FreeTrialInfoResponse } from '../types'

export const tryFetchFreeTrialAvailable = (
  countryCode: string,
  postalCode: string,
  seriesId?: number,
  networkId?: number,
  deviceId?: string
): TryFetchFreeTrialAvailableAction => ({
  type: TRY_FETCH_FREE_TRIAL_AVAILABLE,
  countryCode,
  postalCode,
  seriesId,
  networkId,
  deviceId,
})

export const fetchFreeTrialAvailableSuccess = (
  response: FreeTrialInfoResponse
): FetchFreeTrialAvailableSuccessAction => ({
  type: FETCH_FREE_TRIAL_AVAILABLE_SUCCESS,
  payload: response,
})

export const fetchFreeTrialAvailableFailure = (
  error: ApiError
): FetchFreeTrialAvailableFailureAction => ({
  type: FETCH_FREE_TRIAL_AVAILABLE_FAILURE,
  error,
})
