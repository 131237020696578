import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { useEPLImage } from 'src/modules/hooks/useEPLImage'
import styled from 'styled-components'

export default (props) => {
  const posterImg = useEPLImage()

  return (
    <StyledContainer>
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        offerBanner="✔ Enter the code from BetMGM to claim your FREE 1-month subscription"
        hasOfferInput={true}
        ctaText="Redeem Offer"
        // video={videos.canada_epl.videoUrl}
        poster={posterImg}
        noVideo={true}
        pageTitle="Watch the Premier League all season"
        title={
          <>
            Watch the Premier League <span className="desktop-line-break">all season</span>
          </>
        }
        subtitle="Unlock your FREE 1-month subscription now!"
        tagline="Redeem now and enjoy your free monthly subscription! Cancel anytime."
        hasPlanCards={false}
        plan="soccer"
        networkSet="canada"
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-betmgm.png?ch=width&auto=format,compress"
        logoHeight="80px"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .desktop-line-break {
    display: inline-block;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
