export function parseQueryParams(url) {
  // Takes the search params from the url, turns it into a URLSearchParams constructor, and creates object from it
  return Object.fromEntries(
    [...new URLSearchParams(new URL(url).search).entries()].map(([key, value]) => [
      key.toLowerCase(),
      value,
    ])
  )
}

export function parseFlagsQueryParams(params) {
  return Object.entries(params || {}).reduce((acc, [key, value]) => {
    const match = key.match(/sdflags\[(.*?)\]/)
    if (match) {
      acc[match[1]] = value
    }
    return acc
  }, {})
}
