import React from 'react'
import USTemplateDefault from 'src/components/template/us'

export default (props) => {
  return (
    <USTemplateDefault
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/redsox-nesn-15s.mp4'}
      title="Watch the Boston Red Sox"
      subtitle="LIVE ON NESN ALL SEASON LONG"
      tagline="Stream games from your TV, phone and other devices. Regional restrictions apply."
      networkSet="baseball"
      customNetworks={['nesn']}
    />
  )
}
