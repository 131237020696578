import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { promoCodes } from 'src/constants'
import { videos } from 'src/constants/videos'

export default (props) => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      offerBanner={
        <>
          ✔ Special Offer for Planet Fitness Members:
          <br />
          Enjoy 25% off your first month
        </>
      }
      offerCode={promoCodes.planet_fitness}
      title="Stream Live TV & Sports"
      subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
      logo={'https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-planet-fitness.png'}
      logoHeight={'80px'}
    />
  )
}
