import React from 'react'
import NFL from 'src/pages/nfl'
import { teamIds } from 'src/constants/personalizationIds'
export default (props) => (
  <NFL
    {...props}
    team="Tampa Bay Buccaneers"
    teamShort="Buccaneers"
    teamId={teamIds['Tampa Bay Buccaneers']}
  />
)
