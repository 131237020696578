import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.masn.videoUrl}
    staticAnnotation={videos.masn.staticAnnotation}
    title="Watch MASN with Fubo"
    subtitle="STREAM LIVE GAMES AND LOCAL TEAM COVERAGE"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    networkSet="baseball"
    league="mlb"
    customNetworks={['masn']}
  />
)
