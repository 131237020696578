import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={videos.canada_seriea.videoUrl}
      title="Watch Italian soccer with Fubo"
      subtitle="LIVE SERIE A AND COPPA ITALIA"
      tagline="The only way to watch all matches all season"
      plan="soccer"
      networkSet="canada"
    />
  )
}
