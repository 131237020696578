import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { leagueIds, sportIds } from 'src/constants/personalizationIds' // Add this import

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/NCAA_BTN_LP_15s.mp4'}
    title="Watch Big Ten Network with Fubo"
    subtitle="ENJOY COLLEGE HOOPS ALL SEASON"
    tagline="Stream live games from your TV and other devices"
    networkSet="basketball"
    customNetworks={['bigten']}
    league="nba"
    showRSN={true}
    sportId={sportIds.Basketball}
    leagueId={leagueIds["Men's NCAA Basketball"]}
  />
)
