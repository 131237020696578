import React from 'react'
import OneboxTemplateDefault from 'src/components/template/onebox'
import { videos } from 'src/constants/videos'
export default (props) => (
  <OneboxTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/logos/logo-fubotv-uefa-euro-qualifiers.png?w=300&ch=width&auto=format,compress"
    logoHeight="90px"
    video={videos.uefa_euro_qualifiers.videoUrl}
    videoUrlBase="https://marketing-partners.fubo.tv/wsc/uefa-euro-qualifiers-2023/"
    background="https://assets-imgx.fubo.tv/marketing/lp/images/posters/onebox-uefa-euro-qualifiers.jpg?w=1920&ch=width&auto=format,compress"
    title="Watch live UEFA European Qualifiers soccer"
    subtitle="All matches + exclusive content. Cancel anytime."
    networkSet="soccer"
    customNetworks={['fsn']}
  />
)
