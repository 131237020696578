import { TRANSCEND_INIT_FAILED, TRANSCEND_INIT_SUCCESS } from './actionTypes'

import type { TranscendInitFailedAction, TranscendInitSuccessAction } from './actionTypes'

export const initTranscend = () => {
  return async (dispatch) => {
    try {
      // Check if already initialized
      if (window?.transcend?.ready) {
        dispatch(initTranscendSuccess())
        return Promise.resolve()
      }

      // Create and configure script element
      const transcendEl = document.createElement('script')
      transcendEl.src = 'https://cdn.transcend.io/cm/8bb139e4-7c3e-463f-bcab-dcffc8cf43e4/airgap.js'
      transcendEl.setAttribute('data-cfasync', 'false')
      transcendEl.setAttribute(
        'data-tracker-overrides',
        'FacebookLDUStrict GoogleConsentMode VimeoDNT YouTubePrivacyEnhancedMode'
      )
      transcendEl.setAttribute('data-report-only', 'off')
      transcendEl.async = true

      // Return promise that resolves when script loads or fails
      return new Promise((resolve, reject) => {
        transcendEl.onload = () => {
          dispatch(initTranscendSuccess())
          Promise.resolve()
        }

        transcendEl.onerror = (err) => {
          console.error('Transcend: error loading script', err)
          dispatch(initTranscendFailed())
          reject(err)
        }

        // Safely append to document body
        document.body?.appendChild(transcendEl)
      })
    } catch (err) {
      console.error('Transcend: error loading script', err)
      dispatch(initTranscendFailed())
      return Promise.reject(err)
    }
  }
}

export const initTranscendSuccess = (): TranscendInitSuccessAction => ({
  type: TRANSCEND_INIT_SUCCESS,
})

export const initTranscendFailed = (): TranscendInitFailedAction => ({
  type: TRANSCEND_INIT_FAILED,
})
