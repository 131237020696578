import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
export default (props) => {
  return (
    <USTemplateDefault
      location={props.location}
      video={videos.bally_cardinals.videoUrl}
      staticAnnotation={videos.bally_cardinals.staticAnnotation}
      offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
      hasOfferInput={true}
      title="Watch St. Louis Cardinals baseball"
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream FanDuel Sports Midwest from any device. Regional restrictions apply."
      networkSet="baseball"
      customNetworks={['fanduelsports']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-st-louis-cardinals.png?w=450&ch=width&auto=format,compress"
      logoHeight="100px"
      sportId={sportIds.Baseball}
      leagueId={leagueIds.MLB}
      teamId={teamIds['St. Louis Cardinals']}
    />
  )
}
