import React from 'react'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'
import BallySportsMLB from 'src/components/page/bally-sports-mlb'

export default (props) => {
  return (
    <BallySportsMLB
      location={props.location}
      video={videos.bally_cardinals.videoUrl}
      staticAnnotation={videos.bally_cardinals.staticAnnotation}
      title="Watch St. Louis Cardinals baseball"
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream FanDuel Sports Midwest from any device. Regional restrictions apply."
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-st-louis-cardinals.png?w=450&ch=width&auto=format,compress"
      logoHeight="100px"
      teamId={teamIds['St. Louis Cardinals']}
    />
  )
}
