import React from 'react'
import BallySportsNHL from 'src/components/page/bally-sports-nhl'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
export default (props) => (
  <BallySportsNHL
    location={props.location}
    video={videos.bally_bluejackets.videoUrl}
    staticAnnotation={videos.bally_bluejackets.staticAnnotation}
    title="Watch Columbus hockey live"
    subtitle="STREAM THE BLUE JACKETS ON FANDUEL SPORTS OHIO"
    sportId={sportIds.Hockey}
    leagueId={leagueIds.NHL}
    teamId={teamIds['Columbus Blue Jackets']}
  />
)
