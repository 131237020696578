const isValidVideoSrc = (videoSrc) => {
  const validExtensions = ['mp4']
  const videoExtension = typeof videoSrc === 'string' && videoSrc.split('.')[1]

  if (validExtensions.includes(videoExtension)) {
    return true
  } else {
    return false
  }
}

export { isValidVideoSrc }
