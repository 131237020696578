import React from 'react'
import styled from 'styled-components'

import Channel from './channel'
import ChannelError from 'src/components/common/ChannelError'

import { getFilteredChannels } from 'src/modules/utils/channels'

const ChannelList = ({ channels, activeCategory }) => {
  const hasChannels = channels.length ? true : false
  const showAllChannels = activeCategory === 'all'
  const channelList = showAllChannels ? channels : getFilteredChannels(channels, activeCategory)

  if (!hasChannels) return <ChannelError />

  return (
    <SectionList>
      {channelList.map((channel, index) => (
        <Channel key={channel.id + '_' + index} channel={channel} />
      ))}
    </SectionList>
  )
}

export default ChannelList

const SectionList = styled.ul`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0 0 6px 6px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin: 0;
  max-width: 1400px;
  margin: 0 auto;

  padding: 0 50px;

  @media (max-width: 1200px) {
    // border-radius: 0 6px 6px 6px;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`
