import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'

export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/NFLNetwork2021.mp4'}
      staticAnnotation={networkLogos.nflnetwork}
      title="Watch NFL Network live"
      subtitle="EXCLUSIVE NFL GAMES, ANALYSIS AND MORE"
      tagline="Stream it from your TV, laptop and other devices"
      networkSet="nfl"
      // Remove cable references
      removeCable={true}
      showRedzone={false}
    />
  )
}
