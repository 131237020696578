export const TRANSCEND_INIT_SUCCESS = 'TRANSCEND/INIT_SUCCESS'
export type TranscendInitSuccessAction = {
  type: typeof TRANSCEND_INIT_SUCCESS
}

export const TRANSCEND_INIT_FAILED = 'TRANSCEND/INIT_FAILED'
export type TranscendInitFailedAction = {
  type: typeof TRANSCEND_INIT_FAILED
}

export type TranscendAction = TranscendInitSuccessAction | TranscendInitFailedAction
