import React from 'react'
import styled, { keyframes } from 'styled-components'
import ImgIX from 'src/components/common/ImgIX'
import { networkLogos } from 'src/constants/networkLogos'

const slideUp = keyframes`
    0% {
        opacity: 0;
        transform: translateY(90px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`

const Channel = ({ channel }) => {
  let { stationId, logoOnWhite } = channel
  let { id, logoOnWhiteUrl, name, callSign } = channel
  // const channelRouter = new ContentRouter(channel)
  // const channelDetailURL = channelRouter.getContentRoute()
  if (callSign === 'SECH') {
    return (
      <SectionListItem key={id}>
        {/* <a href={channelDetailURL} target="_blank"> */}
        <ImgIX
          imgSrc={networkLogos.sec.logoUrl}
          isCompress={true}
          w={70}
          h={35}
          alt={name}
          isDefaultSrc
        />
        {/* </a> */}
      </SectionListItem>
    )
  }
  return (
    <SectionListItem key={stationId || id}>
      {/* <a href={channelDetailURL} target="_blank"> */}
      <ImgIX imgSrc={logoOnWhite || logoOnWhiteUrl} isCompress={true} w={70} h={35} alt={name} />
      {/* </a> */}
    </SectionListItem>
  )
}

export default Channel

const SectionListItem = styled.li`
  width: 70px;
  margin: 8px 10px;
  animation: 0.5s ${slideUp} ease;

  img {
    display: block;
    margin: 7px auto;
    max-width: 70px;
    max-height: 35px;

    @media (max-width: 600px) {
      max-width: 55px;
      margin: 0 auto;
    }
  }
`
