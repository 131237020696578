import React from 'react'
import styled from 'styled-components'
import FacebookIcon from 'src/assets/svg/facebook-icon.svg'
import TwitterIcon from 'src/assets/svg/twitter-icon.svg'
import InstagramIcon from 'src/assets/svg/instagram-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsTranscendInitialized } from 'src/modules/reducers/transcend'
import { startZVA } from 'src/modules/reducers/zva/actions'
import { selectIsZvaLoading } from 'src/modules/reducers/zva'

const description = {
  en: 'Fubo is the world’s only sports-focused live TV streaming service with top leagues and teams, plus popular shows, movies and news for the entire household. Watch 200+ live TV channels, thousands of on-demand titles and more on your TV, phone, tablet, computer and other devices.',
  es: 'Fubo es un servicio que ofrece las mejores series internacionales al mejor precio del mercado y que además te permite ver y grabar los programas de la TV en abierto en una misma aplicación. Puedes verlo en tu ordenador, móvil, tablet y cualquier otro dispositivo disponible, donde y cuando quieras.',
  canada:
    'Fubo is the world’s only sports-focused live TV streaming service with top leagues and teams, plus popular shows, movies and news for the entire household. Watch live TV channels, thousands of on-demand titles and more on your TV, phone, tablet, computer and other devices.',
}

const copy = {
  en: {
    callus: 'Call Us',
    emailus: 'Email Us',
    terms: 'Terms',
    privacy: 'Privacy',
    cookies: 'Cookie Policy',
    transcend: 'Your Privacy Choices',
    unsolicited: 'Unsolicited Submissions',
  },
  es: {
    callus: 'Llámanos al',
    emailus: 'Escríbenos Un E-mail',
    terms: 'Términos del servicio',
    privacy: 'Privacidad',
    cookies: 'Política de Cookies',
    transcend: 'Tus Opciones de Privacidad',
    unsolicited: 'Unsolicited Submissions',
  },
}

const getCurrentYear = () => {
  return new Date().getFullYear()
}

const StyledContactButton = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 215px;
  margin-bottom: 0;
  ${'' /* padding: 0 0 0 20px; */}
  a {
    cursor: pointer;
    color: rgb(156, 160, 160);
    font-weight: 700;
    display: flex;
    flex-grow: 0;
    flex-basis: auto;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding: 10px 20px;
    border: 1px solid rgb(198, 200, 200);
  }
`

const ContactButton = ({ children, ...props }) => {
  return (
    <StyledContactButton>
      <a {...props}>{children}</a>
    </StyledContactButton>
  )
}

const Footer = ({ miceText, lang = 'en', plan = 'us' }) => {
  // Transcend
  const isTranscendInitialized = useSelector(selectIsTranscendInitialized)
  const [showTranscendLink, setShowTranscendLink] = React.useState(false)

  const showTranscendHandler = (e) => {
    e.preventDefault()
    window.transcend.showConsentManager({
      viewState: 'DoNotSellExplainer',
    })
  }

  const hideTranscendHandler = React.useCallback(
    (e) => {
      if (showTranscendLink && e.key === 'Escape') {
        window.transcend.hideConsentManager()
      }
    },
    [showTranscendLink]
  )

  React.useEffect(() => {
    document.addEventListener('keydown', hideTranscendHandler)
    return () => document.removeEventListener('keydown', hideTranscendHandler)
  }, [hideTranscendHandler])

  React.useEffect(() => {
    if (isTranscendInitialized) {
      setShowTranscendLink(
        window.transcend?.ready &&
          window.airgap?.getRegimes &&
          window.airgap.getRegimes().has('DoNotSell')
      )
    }
  }, [isTranscendInitialized])

  // Solvvy
  const dispatch = useDispatch()
  const isSolvvyLoading = useSelector(selectIsZvaLoading)

  const solvyLoadingText = lang === 'es' ? 'Cargando...' : 'Loading...'
  const solvyButtonText = lang === 'es' ? 'Contáctanos' : 'Contact Us'
  const handleStartSolvvy = (e) => {
    e.preventDefault()
    dispatch(startZVA())
  }

  const currentYear = getCurrentYear()

  return (
    <StyledFooter>
      <div className="footer__row">
        <div>
          <div className="footer-links--sitemap">
            <a className="footer-link" href="/welcome/directory/networks">
              Networks
            </a>
            <a className="footer-link" href="/welcome/directory/teams">
              Teams
            </a>
            <a className="footer-link" href="/welcome/directory/leagues">
              Leagues
            </a>
            <a className="footer-link" href="/welcome/leagues/191277">
              NFL games
            </a>
            <a className="footer-link" href="/welcome/leagues/191261">
              College football games
            </a>
          </div>
          <div className="description">
            {miceText && <div>{miceText}</div>}
            <div>{plan === 'canada' ? description[plan] : description[lang]}</div>
          </div>
        </div>
        <div className="contact">
          <ul className="social-list">
            <li>
              <span>{lang === 'es' ? 'Encuéntranos en:' : 'Find us on:'}</span>
            </li>
            <li className="facebook social">
              <a href="https://www.facebook.com/fuboTV?fref=ts" target="_blank" rel="noreferrer">
                <FacebookIcon />
              </a>
            </li>
            <li className="twitter social">
              <a href="https://twitter.com/fuboTV" target="_blank" rel="noreferrer">
                <TwitterIcon />
              </a>
            </li>
            <li className="instagram social">
              <a
                href="https://www.youtube.com/user/OfficialFuboTV"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
            </li>
          </ul>
          {/* <div className="contact-info">
            <a href="tel:+18442382688">
              {copy[lang].callus}: <span>+1 844 238 2688</span>
            </a>
          </div>
          <div className="contact-info">
            <a
              href={`${helpCenterUrl[plan]}/requests/new`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {copy[lang].emailus}
            </a>
          </div> */}

          {/* Contact */}
          <div>
            <ContactButton
              href=""
              onClick={handleStartSolvvy}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isSolvvyLoading ? solvyLoadingText : solvyButtonText}
            </ContactButton>
          </div>
        </div>
      </div>
      <div className="footer__row">
        <div className="copyright">
          <span>© {currentYear} Fubo</span>
        </div>
        <div className="footer-links">
          {showTranscendLink && (
            <a className="footer-link" href="" onClick={showTranscendHandler}>
              {copy[lang].transcend}
            </a>
          )}

          <a
            className="footer-link"
            href="https://legal.fubo.tv/policies/en-US/?name=unsolicited-submissions-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {copy[lang].unsolicited}
          </a>
          <a
            className="footer-link"
            href="/documents/USA/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            {copy[lang].terms}
          </a>
          <a
            className="footer-link"
            href="/documents/USA/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {copy[lang].privacy}
          </a>
          <a
            className="footer-link"
            href="/documents/USA/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {copy[lang].cookies}
          </a>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  width: 100%;
  color: #b8b8b8;
  background: #2f3233;
  position: relative;
  padding: 20px 5%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  z-index: 50;

  .footer__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  a {
    color: #b8b8b8;
    :hover {
      svg {
        color: #fff;
      }
    }
  }

  .description {
    max-width: 36.25rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .contact {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;

    @media (max-width: 767px) {
      align-items: center;
    }

    ul.social-list {
      display: flex;
      padding-left: 5px;

      li {
        height: 36px;
        cursor: pointer;
        margin-right: 10px;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        :hover {
          svg {
            color: #fff;
          }
        }
        :first-child {
          margin-right: 10px;
        }

        :not(:first-child) {
          align-items: center;
          width: 30px;
          height: 30px;
          border-radius: 2px;
          border: 1px solid #656565;
          text-align: center;
        }

        a {
          display: flex;
          align-items: center;
        }

        span {
          color: #b8b8b8;
        }

        svg {
          height: 18px;
          width: 18px;
        }
      }

      .facebook,
      .instagram,
      .twitter {
        margin-right: 4px;
      }

      .facebook:hover {
        background: #3170aa;
        border: 1px solid #3170aa;
      }

      .twitter:hover {
        background: #22c2f2;
        border: 1px solid #22c2f2;
      }

      .instagram:hover {
        background: #cf3327;
        border: 1px solid #cf3327;
      }
    }
    .contact-info {
      a {
        text-decoration: underline;
      }
    }
  }

  .copyright {
  }

  .footer-links--sitemap {
    display: flex;
    gap: 0.625rem;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    a {
      text-decoration: underline;
    }
  }

  .footer-links {
    display: flex;
    gap: 0.625rem;
    flex-direction: row;
    a {
      text-decoration: underline;
    }
  }
`
