import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'
import { parseQueryParams } from 'src/modules/utils/parseQueryParams'

const redirectDict = {
  abc: 'abc',
  acc: 'acc-network',
  bachelorette: 'the-bachelorette',
  bachelor: 'the-bachelor',
  'american-idol': 'american-idol',
  cbbonespn: 'college-basketball',
  cbbonacc: 'cbb-on-acc',
  disneychannel: 'disneychannel',
  dwts: 'dancing-with-the-stars',
  espn: 'espn',
  freeform_holidays: 'freeform-holidays',
  fx: 'fx-networks',
  greys: 'greys-anatomy',
  mnfonespn: 'nfl',
  nbaonespn: 'nba-on-espn',
  ncaacollegegameday: 'college-football',
  sec: 'sec-network',
  sportscenter: 'sportscenter',
}

export default (props) => {
  // Get preview network slug
  let slug
  if (props && props.location && props.location.href) {
    slug = parseQueryParams(props.location.href).v
  }

  const locationObj = props.location

  // Remove v from URL
  const searchParams = new URLSearchParams(locationObj.search)
  searchParams.delete('v')

  const updatedSearch = searchParams.toString()
  const updatedHref =
    updatedSearch.length > 0
      ? locationObj.href.replace(locationObj.search, `?${updatedSearch}`)
      : `${locationObj.origin}${locationObj.pathname}`

  const updatedLocation = {
    ...locationObj,
    search: updatedSearch ? `?${updatedSearch}` : '',
    href: updatedHref,
  }

  // if slug matches dict above, redirect to correct page
  if (slug && redirectDict.hasOwnProperty(slug.toLowerCase())) {
    return (
      <RedirectTemplate
        location={updatedLocation}
        destination={`/${redirectDict[slug.toLowerCase()]}/`}
      />
    )
  } else {
    return <RedirectTemplate location={updatedLocation} destination={`/tv/`} />
  }
}
