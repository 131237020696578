/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import Favicon from 'src/assets/images/favicon.ico'
import { getTextContent } from 'src/modules/utils/text'

function SEO({ description, title, lang = 'en', freeTrial = true }) {
  const metaDescription =
    description ||
    `Fubo is the world’s only sports-focused live TV streaming service with top leagues and teams, plus popular shows, movies and news for the entire household.`

  const metadataImg =
    'https://assets-imgx.fubo.tv/marketing/lp/images/meta/metadata_Fubo.jpg?auto=format,compress'

  const titleStr = getTextContent(title)
  return (
    <Helmet
      htmlAttributes={{
        lang: lang === 'en' ? 'en-US' : 'es-US',
      }}
      script={[
        {
          type: 'text/javascript',
          innerHTML: 'window.timeStampOnPageLoad = new Date().getTime();',
        },
      ]}
      title={titleStr}
      titleTemplate={`%s | Fubo`}
      link={[{ rel: 'icon', type: 'image/ico', sizes: '16x16', href: `${Favicon}` }]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `fb:app_id`,
          content: '162788457265492',
        },
        {
          property: `og:title`,
          content: titleStr,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: 'Fubo',
        },
        {
          property: `og:image`,
          content: metadataImg,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Fubo',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: metadataImg,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

export default SEO
