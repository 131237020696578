import type { Product } from '@fubotv/types/api/UP/products'

import {
  FETCH_ADDON_BY_RATE_PLAN_FAIL,
  FETCH_ADDON_BY_RATE_PLAN_SUCCESS,
  FETCH_PRODUCT_BY_RATE_PLAN_FAIL,
  FETCH_PRODUCT_BY_RATE_PLAN_SUCCESS,
  FetchAddonByRatePlanSuccessAction,
  FetchProductByRatePlanSuccessAction,
} from '../actionTypes/'
import { ApiError } from 'src/modules/utils/types/domain'
import { call } from '../../js-client'
import { doFetchProductByRatePlan } from 'src/modules/reducers/products/effects'

export const fetchProductByRatePlanSuccessful = (
  product: Product
): FetchProductByRatePlanSuccessAction => ({
  type: FETCH_PRODUCT_BY_RATE_PLAN_SUCCESS,
  plan: product,
})

export const fetchProductByRatePlanFail = (error: ApiError) => ({
  type: FETCH_PRODUCT_BY_RATE_PLAN_FAIL,
  error,
})

export interface TryFetchProductByRatePlanAction {
  params: {
    ratePlanId: string
  }
}

export const tryFetchProductByRatePlan = (ratePlanCode: string) => {
  return async (dispatch) => {
    try {
      const data = await call(() => doFetchProductByRatePlan({ params: { ratePlanCode } }))
      await dispatch(fetchProductByRatePlanSuccessful(data))
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'response' in error) {
        dispatch(fetchProductByRatePlanFail(error.response))
      } else if (error && typeof error === 'object' && 'request' in error) {
        dispatch(fetchProductByRatePlanFail(error.request))
      } else {
        dispatch(
          fetchProductByRatePlanFail(
            typeof error === 'string' ? error : 'An unknown error occurred'
          )
        )
      }
    }
  }
}

export const fetchAddonByRatePlanSuccessful = (
  product: Product
): FetchAddonByRatePlanSuccessAction => ({
  type: FETCH_ADDON_BY_RATE_PLAN_SUCCESS,
  addon: product,
})

export const fetchAddonByRatePlanFail = (error: ApiError) => ({
  type: FETCH_ADDON_BY_RATE_PLAN_FAIL,
  error,
})

export interface TryFetchProductByRatePlanAction {
  params: {
    ratePlanId: string
  }
}

export const tryFetchAddonByRatePlan = (ratePlanCode: string) => {
  return async (dispatch) => {
    try {
      const data = await call(() => doFetchProductByRatePlan({ params: { ratePlanCode } }))
      await dispatch(fetchAddonByRatePlanSuccessful(data))
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'response' in error) {
        dispatch(fetchAddonByRatePlanFail(error.response))
      } else if (error && typeof error === 'object' && 'request' in error) {
        dispatch(fetchAddonByRatePlanFail(error.request))
      } else {
        dispatch(
          fetchAddonByRatePlanFail(typeof error === 'string' ? error : 'An unknown error occurred')
        )
      }
    }
  }
}
