import React from 'react'
import CollegeFootball from 'src/pages/college-football'
import { teamIds } from 'src/constants/personalizationIds'
export default (props) => (
  <CollegeFootball
    {...props}
    team="Notre Dame Fighting Irish"
    hasGenericVideo={true}
    teamId={teamIds['Notre Dame Fighting Irish']}
  />
)
