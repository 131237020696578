import { ChannelsMap } from '@fubotv/js-client/dist/esm/domains/UP/channels/types'

import type { AddOn, Plan } from '@fubotv/types/api/UP/products'

import { FreeTrialInfoResponse } from './types'
import {
  FETCH_ADDON_BY_RATE_PLAN_FAIL,
  FETCH_ADDON_BY_RATE_PLAN_SUCCESS,
  FETCH_CHANNELS_BY_LOCATION_FAIL,
  FETCH_CHANNELS_BY_LOCATION_SUCCESS,
  FETCH_FREE_TRIAL_AVAILABLE_SUCCESS,
  FETCH_PRODUCT_BY_RATE_PLAN_FAIL,
  FETCH_PRODUCT_BY_RATE_PLAN_SUCCESS,
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCTS_SUCCESS,
  FetchChannelsPayload,
  TRY_FETCH_CHANNELS_BY_LOCATION,
  TRY_FETCH_PRODUCTS,
} from 'src/modules/reducers/products/actionTypes'

export type ProductsState = {
  allChannelsFetched: boolean
  channels: ChannelsMap
  addOns: AddOn[]
  plans: Plan[]
  freeTrialInfo: FreeTrialInfoResponse
  isLoadingProducts: boolean
  isLoadingChannels: boolean
}

const initialState = {
  allChannelsFetched: false,
  channels: {},
  addOns: [],
  plans: [],
  freeTrialInfo: {
    hasFreeTrial: null,
    lowestOfferPrice: null,
    exclusiveAddOn: null,
    currency: null,
  },
  isLoadingProducts: false,
  isLoadingChannels: false,
}

export default (state: ProductsState = initialState, action): ProductsState => {
  switch (action.type) {
    case TRY_FETCH_PRODUCTS: {
      return {
        ...state,
        isLoadingProducts: true,
      }
    }
    case FETCH_FREE_TRIAL_AVAILABLE_SUCCESS: {
      return {
        ...state,
        freeTrialInfo: {
          hasFreeTrial: action.payload.hasFreeTrial,
          lowestOfferPrice: action.payload.lowestOfferPrice,
          currency: action.payload.currency,
          exclusiveAddOn: action.payload.exclusiveAddOn,
          localChannelId: action.payload.localChannelId,
        },
      }
    }
    case FETCH_PRODUCTS_SUCCESS: {
      const { plans, addOns } = action
      return {
        ...state,
        plans: [...state.plans, ...plans],
        addOns: [...state.addOns, ...addOns],
        isLoadingProducts: false,
      }
    }
    case FETCH_PRODUCTS_FAIL: {
      return {
        ...state,
        isLoadingProducts: false,
      }
    }
    case TRY_FETCH_CHANNELS_BY_LOCATION: {
      return {
        ...state,
        isLoadingChannels: true,
      }
    }
    case FETCH_CHANNELS_BY_LOCATION_SUCCESS: {
      const { channels, allChannelsFetched }: FetchChannelsPayload = action.payload
      return {
        ...state,
        channels,
        allChannelsFetched,
        isLoadingChannels: false,
      }
    }
    case FETCH_CHANNELS_BY_LOCATION_FAIL: {
      return {
        ...state,
        isLoadingChannels: false,
      }
    }
    case FETCH_PRODUCT_BY_RATE_PLAN_SUCCESS: {
      return {
        ...state,
        plans: [...state.plans, action.plan],
      }
    }
    case FETCH_PRODUCT_BY_RATE_PLAN_FAIL: {
      return {
        ...state,
      }
    }
    case FETCH_ADDON_BY_RATE_PLAN_SUCCESS: {
      return {
        ...state,
        addOns: [...state.addOns, action.addon],
      }
    }
    case FETCH_ADDON_BY_RATE_PLAN_FAIL: {
      return {
        ...state,
      }
    }

    default:
      return state
  }
}
