import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CTAButton from 'src/components/common/CTAButtonNew'
import { selectParams } from 'src/modules/reducers/params'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import styled from 'styled-components'

const CTAButtonOfferCode = ({ link, children, onClick, ...props }) => {
  const params = useSelector(selectParams)
  const paramsOffer = params?.offer

  const [offerCode, setOfferCode] = useState('')

  useEffect(() => {
    if (paramsOffer) {
      setOfferCode(paramsOffer)
    }
  }, [paramsOffer])

  const handleInputChange = (event) => {
    setOfferCode(event.target.value)
  }

  let ctaURL = link

  const handleButtonClick = (e) => {
    e.preventDefault()
    if (onClick) {
      onClick()
    }
    ctaURL = replaceUrlParam(ctaURL, 'offer', offerCode)
    window.location.href = ctaURL
  }

  return (
    <StyledCTAButtonOfferCodeContainer className="CTAButtonOfferCode">
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter offer code"
          value={offerCode}
          onChange={handleInputChange}
        />
      </div>
      <CTAButton link={link} onClick={(e) => handleButtonClick(e)} {...props}>
        {children}
      </CTAButton>
    </StyledCTAButtonOfferCodeContainer>
  )
}

export default CTAButtonOfferCode

const StyledCTAButtonOfferCodeContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  .input-container {
    input {
      padding: 5px;
      height: 100%;
      box-sizing: border-box;
      font-size: 20px;
      width: 220px;
      min-height: 45px;
    }
  }
  @media screen and (min-width: 768px) {
    .CTAButton {
      min-width: 200px;
      padding: 21px 38px;
    }
  }
`
