import React from 'react'
import BallySportsNBA from 'src/components/page/bally-sports-nba'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_suns.videoUrl}
    title={
      <>
        Watch <br /> Phoenix Suns <br /> basketball live{' '}
      </>
    }
    pageTitle="Watch Phoenix Suns basketball live"
    subtitle="STREAM SUNS GAMES ON ARIZONA'S FAMILY"
    customNetworks={['arizonasfamily']}
    teamId={teamIds['Phoenix Suns']}
  />
)
