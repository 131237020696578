import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds } from 'src/constants/personalizationIds'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/logos/logo-fubotv-uefa-euro-qualifiers.png?w=300&ch=width&auto=format,compress"
    logoHeight="90px"
    video={videos.uefa_euro_qualifiers.videoUrl}
    // staticAnnotation={{
    //   logoUrl:
    //     "https://assets-imgx.fubo.tv/marketing/lp/images/annotations/world_cup_qatar_2022_fox_fs1.png?h=100&ch=width&auto=format,compress",
    //   height: 80,
    // }}
    title="Watch UEFA European Qualifiers live"
    subtitle="GET COMPLETE COVERAGE ONLY ON FUBO"
    tagline="Stream every match live from a TV & other devices"
    networkSet="soccer"
    customNetworks={['fsn']}
    sportId={sportIds.Soccer}
  />
)
