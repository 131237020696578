import React from 'react'
import GolfTemplate from 'src/components/page/golf'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'

export default (props) => (
  <StyledContainer>
    <GolfTemplate
      location={props.location}
      poster={
        'https://assets-imgx.fubo.tv/marketing/lp/images/posters/TGL-PLAYER-STATIC-HORZ-V1.jpg?w=2048&ch=width&auto=format,compress'
      }
      posterMobile={
        'https://assets-imgx.fubo.tv/marketing/lp/images/posters/TGL-PLAYER-STATIC-VERT-V1.jpg?ch=width&auto=format,compress'
      }
      title="Watch TGL golf without cable"
      subtitle={
        <>
          Fubo has more golf than any{' '}
          <span className="desktop-line-break">other live TV provider</span>
        </>
      }
      matchtickerSportId={sportIds.Golf}
      matchtickerLeagueName="TGL Golf"
      matchtickerLeagueId={leagueIds['TGL Golf']}
    />
  </StyledContainer>
)

const StyledContainer = styled.div`
  .desktop-line-break {
    display: inline-block;
    @media (max-width: 768px) {
      display: block;
    }
  }
`
