import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'

//REPLACED: univision_removed
export default (props) => <RedirectTemplate location={props.location} destination="/latino/" />

//REMOVED: univision_removed
// export default props => {
//   return (
//     <LatinoTemplateDefault
//       location={props.location}
//       lang="es"
//       market="latino"
//       video={videos.copa_america_latino.videoUrl}
//       staticAnnotation={videos.copa_america_latino.staticAnnotation}
//       title="Mira la Copa América 2024 en vivo"
//       subtitle="Todos los partidos en TUDN con fubo"
//       tagline="Disfruta desde cualquiera de tus dispositivos"
//       networkSet="soccerLatino"
//     />
//   )
// }
