import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
import { leagueIds, sportIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/SEM_76ers_1280x720_LP_15s.mp4'}
    staticAnnotation={networkLogos.nbcsphiladelphia}
    title="Watch Philly basketball live"
    subtitle="STREAM THE 76ERS ON NBC SPORTS PHILADELPHIA"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    ctaText={'Learn More'}
    networkSet={'basketball'}
    customNetworks={['nbcsphiladelphia']}
    sportId={sportIds.Basketball}
    leagueId={leagueIds.NBA}
    teamId={teamIds['Philadelphia 76ers']}
  />
)
