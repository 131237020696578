import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
      hasOfferInput={true}
      video={videos.houston_astros.videoUrl}
      staticAnnotation={videos.houston_astros.staticAnnotation}
      pageTitle="Watch Houston Astros baseball"
      title={
        <>
          Watch Houston Astros<sup>®</sup> baseball
        </>
      }
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream Space City Home Network from any device. Regional restrictions apply."
      networkSet="baseball"
      customNetworks={['spacecity']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-houston-astros.png?w=450&ch=width&auto=format,compress"
      logoHeight="100px"
      league="MLB"
      sportId={sportIds.Baseball}
      leagueId={leagueIds.MLB}
      teamId={teamIds['Houston Astros']}
    />
  )
}
