import {
  START_ZVA,
  ZVA_LOADING,
  OPEN_ZVA,
  ZVA_INITIALIZED,
  ZVA_UPDATED,
  ZVA_FAILED,
} from './actionTypes.ts'
export const startZVA = () => ({
  type: START_ZVA,
})
export const ZVALoading = () => ({
  type: ZVA_LOADING,
})
export const openZVA = () => ({
  type: OPEN_ZVA,
})
export const ZVAInitialized = () => ({
  type: ZVA_INITIALIZED,
})
export const ZVAUpdated = () => ({
  type: ZVA_UPDATED,
})
export const ZVAFailed = (error: Error) => ({
  type: ZVA_FAILED,
  error,
})
