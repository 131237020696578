import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerBanner="✔ Enter the code from your email and enjoy 30 days FREE."
    hasOfferInput={true}
    video={videos.bally_predators.videoUrl}
    staticAnnotation={videos.bally_predators.staticAnnotation}
    poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/Predators.jpg?ch=width&auto=format,compress"
    title="Watch Nashville hockey live"
    subtitle="STREAM THE PREDATORS ON FANDUEL SPORTS SOUTH"
    tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
    networkSet="hockey"
    customNetworks={['fanduelsports']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-nashville-predators-v2.png?ch=width&auto=format,compress"
    logoHeight="110px"
    sportId={sportIds.Hockey}
    leagueId={leagueIds.NHL}
    teamId={teamIds['Nashville Predators']}
  />
)
