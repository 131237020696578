import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={videos.canada_epl.videoUrl}
      title="Watch the Premier League all season"
      subtitle="LIVE GAMES AVAILABLE IN 4K ULTRA HD"
      tagline="Streaming exclusively on Fubo"
      plan="soccer"
      networkSet="canada"
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-epl-white.png?ch=width&auto=format,compress"
      logoHeight="90px"
      // hasScheduleBanner={true}
    />
  )
}
