import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/TheBachelorette_1280x720_2022_LP_15s.mp4"
    staticAnnotation={networkLogos.abc}
    title="Watch The Bachelorette on ABC"
    subtitle="SEE WHO WILL GET THE ROSE"
    tagline="Stream new episodes live from your TV and other devices"
  />
)
