import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
import { useCheckFanduelRSN } from 'src/modules/hooks/useCheckFanduelRSN'
import FuboFreeTemplate from 'src/components/template/fubofree'
import { fanduelSportsNetworkFAQs } from 'src/constants/faqs'

const BallySportsMLB = (props) => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()

  const commonProps = {
    location: props.location,
    lang: 'en',
    market: 'us',
    video: props.poster ? false : props.video || videos.generic_baseball.videoUrl,
    staticAnnotation: props.staticAnnotation,
    title: props.title,
    subtitle: props.subtitle,
    tagline: 'Anytime. Anywhere. From any device. Regional restrictions apply.',
    networkSet: 'baseball',
    customNetworks: props.customNetworks || ['fanduelsports'],
    sportId: sportIds.Baseball,
    leagueId: leagueIds.MLB,
  }
  return showFDPlanCards ? (
    <FuboFreeTemplate
      {...commonProps}
      hasFanduelPlanCards={showFDPlanCards}
      fanduelRSNData={rsnData}
      additionalFAQs={fanduelSportsNetworkFAQs}
      {...props}
    />
  ) : (
    <USTemplateDefault
      {...commonProps}
      hasFanduelPlanCards={props.hideFanduelPlanCards ? false : showFDPlanCards}
      fanduelRSNData={rsnData}
      {...props}
    />
  )
}

export default BallySportsMLB
