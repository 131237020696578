export const networkLogos = {
  '&tv': {
    id: '',
    alt: 'AT&T SportsNet',
    name: 'AT&T SportsNet',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/&TV_c-v2.png',
  },
  abc: {
    id: '',
    alt: 'ABC',
    name: 'ABC',
    height: 70,
    logoUrl: 'https://imgx.fubo.tv/station_logos/ABC_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/ABC_m.png',
  },
  abcnews: {
    id: '',
    alt: 'ABC News',
    name: 'ABC News Live',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/ABCNL_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/ABCNL_m.png',
  },
  acc: {
    id: '',
    alt: 'ACC Network',
    name: 'ACCN',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/accn_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/accn_m.png',
  },
  alpha_etc: {
    id: '',
    alt: 'Alpha ETC',
    name: 'Alpha ETC',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/Alpha-Etc-Punjabi_c-v2.png',
  },
  altitude: {
    id: '',
    alt: 'Altitude Sports',
    name: 'Altitude Sports',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/Altitude_Sports_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/Altitude_Sports_m.png',
  },
  arizonasfamily: {
    id: '',
    alt: "Arizona's Family",
    name: 'Altitude Sports',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/arizonas-family-c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/arizonas-family-c.png',
  },
  attsportsnet: {
    alt: 'AT&T SportsNet',
    height: null,
    // name: 'ATT SportsNet',
    logoUrl: 'https://imgx.fubo.tv/station_logos/att-sportsnet_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/att-sportsnet_m.png',
  },
  ballysports: {
    id: '',
    alt: 'Bally Sports',
    name: 'Bally Sports',
    height: 65,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports_m.png',
  },
  ballysportsarizona: {
    id: '',
    alt: 'Bally Sports Arizona',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-arizona_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-arizona_m.png',
  },
  ballysportsdetroit: {
    id: '',
    alt: 'Bally Sports Detroit',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-detroit_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-detroit_m.png',
  },
  ballysportsflorida: {
    id: '',
    alt: 'Bally Sports Florida',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-florida_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-florida_m.png',
  },
  ballysportsgreatlakes: {
    id: '',
    alt: 'Bally Sports Great Lakes',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-great-lakes_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-great-lakes_m.png',
  },
  ballysportsindiana: {
    id: '',
    alt: 'Bally Sports Indiana',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-indiana_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-indiana_m.png',
  },
  ballysportskansascity: {
    id: '',
    alt: 'Bally Sports Kansas City',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-kansas-city_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-kansas-city_m.png',
  },
  ballysportsmidwest: {
    id: '',
    alt: 'Bally Sports Midwest',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-midwest_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-midwest_m.png',
  },
  ballysportsneworleans: {
    id: '',
    alt: 'Bally Sports New Orleans',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-new-orleans_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-new-orleans_m.png',
  },
  ballysportsnorth: {
    id: '',
    alt: 'Bally Sports North',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-north_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-north_m.png',
  },
  ballysportsohio: {
    id: '',
    alt: 'Bally Sports Ohio',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-ohio_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-ohio_m.png',
  },
  ballysportsoklahoma: {
    id: '',
    alt: 'Bally Sports Oklahoma',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-oklahoma_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-oklahoma_m.png',
  },
  ballysportssandiego: {
    id: '',
    alt: 'Bally Sports San Diego',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-san-diego_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-san-diego_m.png',
  },
  ballysportssocal: {
    id: '',
    alt: 'Bally Sports SoCal',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-socal_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-socal_m.png',
  },
  ballysportssouth: {
    id: '',
    alt: 'Bally Sports South',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-south_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-south_m.png',
  },
  ballysportssoutheast: {
    id: '',
    alt: 'Bally Sports Southeast',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-southeast_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-southeast_m.png',
  },
  ballysportssouthwest: {
    id: '',
    alt: 'Bally Sports Southwest',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-southwest_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-southwest_m.png',
  },
  ballysportssun: {
    id: '',
    alt: 'Bally Sports Sun',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-sun_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-sun_m.png',
  },
  ballysportswest: {
    id: '',
    alt: 'Bally Sports West',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-west_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-west_m.png',
  },
  ballysportswisconsin: {
    id: '',
    alt: 'Bally Sports Wisconsin',
    name: 'Bally Sports',
    height: 75,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-wisconsin_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/bally-sports/bally-sports-wisconsin_m.png',
  },
  bein: {
    id: '',
    alt: 'beIN Sports',
    name: 'beIN Sports',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/bein_sports_english_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/bein_sports_english_m.png',
  },
  bigten: {
    id: '',
    alt: 'Big Ten Network',
    name: 'Big Ten Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/bigten_2020_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/bigten_2020_m.png',
  },
  bkfctv: {
    id: '',
    alt: 'BKFCTV (VOD2LIVE)',
    name: 'BKFCTV',
    height: null,
    logoUrl: 'https://images.fubo.tv/channel-config-ui/station-logos/on-white/bkfc--logo_black.png',
  },
  bravo: {
    id: '',
    alt: 'Bravo',
    name: 'Bravo',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/bravo_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/bravo_m.png',
  },
  cartoonnetwork: {
    id: '',
    alt: 'Cartoon Network',
    // name: 'Cartoon Network',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/cartoon-network_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/cartoon-network_m.png',
  },
  cbc: {
    id: '',
    alt: 'CBC',
    // name: 'CBC',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/CBC2022_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/CBC2022_m.png',
  },
  cbs: {
    id: '',
    alt: 'CBS',
    name: 'CBS',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/cbs_2020_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/cbs_2020_m.png',
  },
  cbsnews: {
    id: '',
    alt: 'CBS News',
    name: 'CBS News',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/cbsnews_stacked_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/cbsnews_stacked_m.png',
  },
  cbssports: {
    id: '',
    alt: 'CBS Sports Network',
    name: 'CBS Sports Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/cbs_sports_network_2021_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/cbs_sports_network_2021_m.png',
  },
  cleotv: {
    id: '',
    alt: 'CleoTV',
    name: 'CleoTV',
    height: null,
    logoUrl: 'https://images.fubo.tv/station_logos/CLEOTV_c.png',
    logoWhite: 'https://images.fubo.tv/station_logos/CLEOTV_m.png',
  },
  chsn: {
    id: '',
    alt: 'Chicago Sports Network',
    name: 'Chicago Sports Network',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/240913_chsn_logo_patch-only_primary_red-black.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/240827_chsn_logo_black.png',
  },
  cnbc: {
    id: '',
    alt: 'CNBC',
    name: 'CNBC',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/cnbc_2020_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/cnbc_2020_m.png',
  },
  comedycentral: {
    id: '',
    alt: 'Comedy Central',
    name: 'Comedy Central',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/comedy_central_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/comedy_central_m.png',
  },
  cw: {
    id: '',
    alt: 'CW',
    name: 'CW',
    height: null,

    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/cw_logo_broadcast_white.png',
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/cw_logo_broadcast_c.png',
  },
  discovery: {
    id: '',
    alt: 'Discovery Channel',
    name: 'Discovery',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/discovery_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/discovery_m.png',
  },
  disney: {
    id: '',
    alt: 'Disney Channel',
    name: 'Disney Channel',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/disney-logo_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/disney-logo_m.png',
  },
  disneyjunior: {
    id: '',
    alt: 'Disney Junior',
    name: 'Disney Junior',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/disney-junior_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/disney-junior_m.png',
  },
  disneyxd: {
    id: '1105',
    alt: 'Disney XD',
    name: 'Disney XD',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/disney_xd_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/disney_xd_m.png',
  },
  espn: {
    id: '1106',
    alt: 'ESPN',
    name: 'ESPN',
    height: 40,
    logoUrl: 'https://imgx.fubo.tv/station_logos/espn-logo-new_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/espn-logo-new_m.png',
  },
  espn2: {
    id: '1106',
    alt: 'ESPN2',
    name: 'ESPN',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/espn2_2020_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/espn2_2020_m.png',
  },
  espndeportes: {
    id: '',
    alt: 'ESPN Deportes',
    name: 'ESPN',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/espn-hd-deportes_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/espn-hd-deportes_m.png',
  },
  fanduelsports: {
    id: '',
    alt: 'FanDuel Sports',
    name: 'FanDuel Sports',
    height: null,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/fanduel-sports/fanduel-sports-c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/fanduel-sports/fanduel-sports-m.png',
  },
  fanduel_sports_network_detroit: {
    id: '',
    alt: 'FanDuel Sports Detroit',
    name: 'FanDuel Sports Detroit',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_detroit_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_detroit_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_florida: {
    id: '',
    alt: 'FanDuel Sports Florida',
    name: 'FanDuel Sports Florida',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_florida_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_florida_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_great_lakes: {
    id: '',
    alt: 'FanDuel Sports Great Lakes',
    name: 'FanDuel Sports Great Lakes',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_greatlakes_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_greatlakes_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_midwest: {
    id: '',
    alt: 'FanDuel Sports Midwest',
    name: 'FanDuel Sports Midwest',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_midwest_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_midwest_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_north: {
    id: '',
    alt: 'FanDuel Sports North',
    name: 'FanDuel Sports North',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_north_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_north_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_ohio: {
    id: '',
    alt: 'FanDuel Sports Ohio',
    name: 'FanDuel Sports Ohio',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_ohio_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_ohio_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_socal: {
    id: '',
    alt: 'FanDuel Sports SoCal',
    name: 'FanDuel Sports SoCal',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_socal_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_socal_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_southeast: {
    id: '',
    alt: 'FanDuel Sports Southeast',
    name: 'FanDuel Sports Southeast',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_southeast_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_southeast_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_southwest: {
    id: '',
    alt: 'FanDuel Sports Southwest',
    name: 'FanDuel Sports Southwest',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_southwest_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_southwest_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_south: {
    id: '',
    alt: 'FanDuel Sports South',
    name: 'FanDuel Sports South',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_south_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_south_lg_hr_rgb_wt.png',
  },

  fanduel_sports_network_sun: {
    id: '',
    alt: 'FanDuel Sports Sun',
    name: 'FanDuel Sports Sun',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_florida_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_sun_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_west: {
    id: '',
    alt: 'FanDuel Sports West',
    name: 'FanDuel Sports West',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_west_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_west_lg_hr_rgb_wt.png',
  },
  fanduel_sports_network_wisconsin: {
    id: '',
    alt: 'FanDuel Sports Wisconsin',
    name: 'FanDuel Sports Wisconsin',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/fd_rsn_wisconsin_lg_hr_rgb_blu.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/fd_rsn_wisconsin_lg_hr_rgb_wt.png',
  },
  fight_network: {
    id: '',
    alt: 'Fight Network',
    name: 'Fight Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/fight_network_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/fight_network_m.png',
  },
  flavour_network: {
    id: '',
    alt: 'Flavour Network',
    name: 'Flavour Network',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/flavour_network_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/flavour_network_m.png',
  },
  foodnetwork: {
    id: '21',
    alt: 'Food Network',
    name: 'Food Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/food_network_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/food_network_m.png',
  },
  foodnetworkca: {
    id: '',
    alt: 'Food Network Canada',
    // name: 'Food Network Canada',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/food_network_canada_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/food_network_manada_c.png',
  },
  fox: {
    id: '23',
    alt: 'FOX',
    name: 'FOX',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/fox_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/fox_m.png',
  },
  foxfs1: {
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/fox_fs1_logos_white.png?ch=width&auto=format,compress',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/fox_fs1_logos_white.png?ch=width&auto=format,compress',
    alt: 'Fox and FS1',
    height: 40,
    name: 'Fox and FS1',
    id: '',
  },
  foxbusiness: {
    id: '',
    alt: 'Fox Business',
    name: 'FOX News',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/foxbusiness_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/foxbusiness_m.png',
  },
  foxdeportes: {
    id: '',
    alt: 'FOX Deportes',
    name: 'FOX',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/foxDeportes2021_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/foxDeportes2021_m.png',
  },
  foxnews: {
    id: '25',
    alt: 'FOX News',
    name: 'FOX News',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/fox_news_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/fox_news_m.png',
  },
  foxlivenow: {
    id: '',
    alt: 'FOX LiveNOW',
    name: 'FOX LiveNOW',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/fox_LiveNOW_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/fox_LiveNOW_m.png',
  },
  fox_sports_racing: {
    id: '',
    alt: 'FOX Sports Racing',
    name: 'FOX Sports Racing',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/FOX_Sports_Racing_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/FOX_Sports_Racing_m.png',
  },
  foxweather: {
    id: '',
    alt: 'FOX LiveNOW',
    name: 'FOX LiveNOW',
    height: null,
    logoUrl: 'https://images.fubo.tv/station_logos/fox-weather_c.png',
    logoWhite: 'https://images.fubo.tv/station_logos/fox-weather_m.png',
  },
  freeform: {
    id: '',
    alt: 'Freeform',
    name: 'Freeform',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/freeform_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/freeform_m.png',
  },
  fs1: {
    id: '',
    alt: 'FS1',
    name: 'FOX',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/fs1_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/fs1_m.png',
  },
  fs2: {
    id: '',
    alt: 'FS2',
    name: 'FOX',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/fs2_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/fs2_m.png',
  },
  fsn: {
    id: '27',
    alt: 'fubo Sports Network',
    name: 'fubo Sports Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/fubo-sports23_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/fubo-sports23_m.png',
  },
  fuse: {
    alt: 'Fuse',
    name: 'Fuse',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/sc-fuse_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/sc-fuse_c.png',
  },
  fx: {
    id: '30',
    alt: 'FX',
    name: 'FX',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/fx_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/fx_m.png',
  },
  gameshowcentral: {
    id: '',
    alt: 'Game Show Central',
    name: 'Game Show Central',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/game_show_central_logo.png',
  },
  gettv: {
    id: '',
    alt: 'getTV',
    name: 'getTV',
    height: null,
    logoUrl: 'https://images.fubo.tv/station_logos/getTV_c.png',
    logoWhite: 'https://images.fubo.tv/station_logos/getTV_m.png',
  },
  global: {
    id: '',
    alt: 'Global',
    // name: 'Global',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/globalTV_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/globalTV_m.png',
  },
  golf: {
    id: '',
    alt: 'Golf',
    name: 'Golf',
    height: 40,
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/golf-logo_m.png',
  },
  golfpass: {
    id: '',
    alt: 'GolfPass',
    name: 'GolfPass',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/golfpass-fclogo-72-dpi-color.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/golfpass-fclogo-72-dpi-white.png',
  },
  goltv: {
    id: '33',
    alt: 'GolTV',
    name: 'GolTV',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/goltv_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/goltv_m.png',
  },
  gulfcoastsports: {
    id: '',
    alt: 'Gulf Coast Sports',
    name: 'Gulf Coast Sports',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/logo-pelscolor-01-w_bg_jh.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/logo-pelscolor-01-w_bg_bw_jh.png',
  },
  hallmark: {
    alt: 'Hallmark',
    height: null,
    name: 'Hallmark',
    logoUrl: 'https://imgx.fubo.tv/station_logos/hallmark_channel22_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/hallmark_channel22_m.png',
  },
  heartandhome: {
    alt: 'Home & Home',
    name: 'Home & Home',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/sc-hah_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/sc-hah_c.png',
  },
  hgtv: {
    id: '35',
    alt: 'HGTV',
    name: 'HGTV',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/hgtv_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/hgtv_m.png',
  },
  hgtvca: {
    id: '',
    alt: 'HGTV Canada',
    // name: 'HGTV Canada',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/hgtv-canada_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/hgtv-canada_m.png',
  },
  home_network: {
    id: '',
    alt: 'Home Network',
    name: 'Home Network',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/home_network_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/home_network_m.png',
  },
  icitele: {
    id: '',
    alt: 'ICI Télé',
    name: 'ICI Télé',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/ICITele_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/ICITele_m.png',
  },
  ion: {
    alt: 'ION',
    height: null,
    name: 'ION',
    logoUrl: 'https://imgx.fubo.tv/station_logos/ion_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/ion_m.png',
  },
  kitchennightmares: {
    id: '',
    alt: "Hell's kitchen Kitchen Nightmares",
    height: null,
    name: "Hell's Kitchen/Kitchen Nightmares",
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/hellskitchenkitchennightmares_logo_color.png',
  },
  lawcrime: {
    id: '',
    alt: 'Law & Crime',
    height: null,
    name: 'Law & Crime',
    logoUrl: 'https://images.fubo.tv/channel-config-ui/station-logos/on-white/lc-js-box-logo.png',
  },
  kasw: {
    id: '',
    alt: 'Arizona 61',
    name: 'Arizona 61',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/ARIZONA61_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/ARIZONA61_m.png',
  },
  kcop: {
    id: '',
    alt: 'KCOP',
    name: 'KCOP',
    height: null,
    logoUrl: 'https://images.fubo.tv/station_logos/kcop_c.png',
    logoWhite: 'https://images.fubo.tv/station_logos/kcop_m.png',
  },
  kfaa: {
    id: '',
    alt: 'KFAA',
    name: 'KFAA',
    height: 65,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/kfaa-logo-ball-black.jpg',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/kfaa-logo-ball-white.png',
  },
  kjzz: {
    alt: 'ION',
    height: null,
    name: 'ION',
    logoUrl: 'https://images.fubo.tv/station_logos/kjzz14_c.png',
    logoWhite: 'https://images.fubo.tv/station_logos/kjzz14_m.png',
  },
  kmcc: {
    id: '',
    alt: 'KMCC',
    name: 'KMCC',
    height: null,
    logoUrl: 'https://images.fubo.tv/station_logos/VEGAS34_c.png',
    logoWhite: 'https://images.fubo.tv/station_logos/VEGAS34_m.png',
  },
  kong: {
    id: '',
    alt: 'KONG',
    name: 'KONG',
    height: null,
    logoUrl: 'https://images.fubo.tv/channel-config-ui/station-logos/on-white/kong-blue.png',
    logoWhite: 'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/kong-white.png',
  },
  ktvd: {
    id: '',
    alt: 'KTVD',
    name: 'KTVD',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/ktvd-logo-blue-copy.jpg',
    logoWhite: 'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/ktvd-logo-white.png',
  },
  kupx: {
    id: '',
    alt: 'KUPX',
    name: 'KUPX',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/Utah16_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/Utah16_m.png',
  },
  magnolia: {
    id: '1148',
    alt: 'Magnolia Network',
    name: 'Magnolia Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/magnolia-network_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/magnolia-network_m.png',
  },
  marquee: {
    id: '1131',
    alt: 'Marquee Sports Network',
    name: 'Marquee Sports Network',
    height: 75,
    logoUrl: 'https://imgx.fubo.tv/station_logos/marquee_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/marquee_m.png',
  },
  masn: {
    id: '',
    alt: 'MASN',
    name: 'MASN',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/masn_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/masn_m.png',
  },
  mlbnetwork: {
    id: '607',
    alt: 'MLB Network',
    name: 'MLB Network',
    height: 70,
    logoUrl: 'https://imgx.fubo.tv/station_logos/MLB_Network_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/MLB_Network_m.png',
  },
  mlbtv: {
    id: null,
    alt: 'MLB.TV',
    name: 'MLB.TV',
    // height: 80,
    logoUrl: 'https://images.fubo.tv/station_logos/mlbtv_c.png',
    logoWhite: 'https://images.fubo.tv/station_logos/mlbtv_m.png',
  },
  msg: {
    id: '67',
    alt: 'MSG Network',
    name: 'MSG',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/msgnetworks-c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/msgnetworks-m.png',
  },
  msgsn: {
    id: '',
    alt: 'MSGSN',
    name: 'MSG',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/MSGSN_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/MSGSN_m.png',
  },
  msnbc: {
    id: '41',
    alt: 'MSNBC',
    name: 'MSNBC',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/msnbc-logo_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/msnbc-logo_m.png',
  },
  mtv: {
    alt: 'MTV',
    name: 'MTV',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/mtv_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/mtv_m.png',
  },
  natgeomundo: {
    id: '38',
    alt: 'Nat Geo Mundo',
    name: 'National Geographic',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/NatGeoMundo_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/NatGeoMundo_m.png',
  },
  nbaleaguepass: {
    alt: 'NBA League Pass',
    name: 'NBA League Pass',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nba-league-pass_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nba-league-pass_m.png',
  },
  nbatv: {
    id: '39',
    alt: 'NBA TV',
    name: 'NBA TV',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nba_tv_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nba_tv_m.png',
  },
  nbatvca: {
    alt: 'NBA TV',
    name: 'NBA TV',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/NBA_TV_Canada_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/NBA_TV_Canada_w.png',
  },
  nbc: {
    id: '40',
    alt: 'NBC',
    name: 'NBC',
    height: 40,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nbc_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nbc_m.png',
  },
  nbcsbayarea: {
    id: '',
    alt: 'NBC Sports Bay Area',
    name: 'NBC Sports Networks',
    height: 60,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-bay-area_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-bay-area_m.png',
  },
  nbcsboston: {
    id: '',
    alt: 'NBC Sports Boston',
    name: 'NBC Sports Networks',
    height: 60,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-boston_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-boston_m.png',
  },
  nbcscalifornia: {
    id: '',
    alt: 'NBC Sports California',
    name: 'NBC Sports Networks',
    height: 60,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-california_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-california_m.png',
  },
  nbcschicago: {
    id: '',
    alt: 'NBC Sports Chicago',
    name: 'NBC Sports Networks',
    height: 60,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-chicago_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-chicago_m.png',
  },
  nbcsphiladelphia: {
    id: '',
    alt: 'NBC Sports Philadelphia',
    name: 'NBC Sports Networks',
    height: 60,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-philadelphia_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-philadelphia_m.png',
  },
  nbcswashington: {
    id: '42',
    alt: 'NBC Sports Washington',
    name: 'NBC Sports Networks',
    height: 60,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-washington_c.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/nbc-sports-washington_m.png',
  },
  nbc_news_now: {
    id: '',
    alt: 'NBC News Now',
    name: 'NBC News Now',
    height: null,
    logoUrl:
      'https://images-qa.fubo.tv/channel-config-ui/station-logos/on-white/news-now-logo-style-sheet-v31-for-epgs-only_blk_color.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/news-now-logo-style-sheet-v31-for-epgs-only_wht.png?',
  },
  nesn: {
    id: '44',
    alt: 'NESN',
    name: 'NESN',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nesn_n_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nesn_n_m.png',
  },
  nflnetwork: {
    id: '46',
    alt: 'NFL Network',
    name: 'NFL Network',
    height: 80,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nfl_network_2020_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nfl_network_2020_m.png',
  },
  nflredzone: {
    id: '',
    alt: 'NFL RedZone',
    name: 'NFL Network',
    height: 100,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nfl_redzone_2020_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nfl_redzone_2020_m.png',
  },
  nhlnetwork: {
    alt: 'NHL Network',
    name: 'NHL Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/NHL_Network_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/NHL_Network_m.png',
  },
  nickelodeon: {
    alt: 'Nickelodeon',
    name: 'Nickelodeon',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nickelodeon_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nickelodeon_m.png',
  },
  nickjr: {
    alt: 'Nick Jr.',
    name: 'Nickelodeon',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nickjr_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nickjr_m.png',
  },
  nicktoons: {
    alt: 'Nicktoons',
    name: 'Nickelodeon',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nicktoons_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nicktoons_m.png',
  },
  own: {
    id: '125',
    alt: 'OWN',
    name: 'OWN',
    logoUrl: 'https://imgx.fubo.tv/station_logos/own_gold_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/own_m.png',
  },
  oxygen: {
    id: '48',
    alt: 'Oxygen True Crime',
    name: 'Oxygen True Crime',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/oxygen2021_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/oxygen2021_m.png',
  },
  onesoccer: {
    id: '',
    alt: 'One Soccer',
    name: 'One Soccer',
    height: 100,
    logoUrl: 'https://imgx.fubo.tv/station_logos/one_soccer_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/ONE_Soccer_Logo_w.png',
  },
  pac12: {
    id: '49',
    alt: 'PAC 12 Network',
    name: 'Pac-12 Networks',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/pac-12-shield-logo.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/pac-12-newtworks2022_m.png',
  },
  paramount: {
    id: '',
    alt: 'Paramont Network',
    // name: 'Paramont Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/paramount_network_canada_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/paramount_network_canada_c.png',
  },
  rangers_sports_network: {
    id: '',
    alt: 'Rangers Sports Network',
    name: 'Rangers Sports Network',
    height: 60,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/RSN_Progressive_Primary.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/RSN_Progressive_Primary_ON_RED.png',
  },
  ripcity: {
    id: '',
    alt: 'Rip City',
    name: 'Rip City',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/rctv-vertical-color.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/rctv-vertical-white.png',
  },
  rootsports: {
    id: '1137',
    alt: 'Root Sports',
    name: 'ROOT SPORTS Northwest',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/Root_Sports_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/Root_Sports_m.png',
  },
  scripps_sports: {
    id: '',
    alt: 'Scripps Sports',
    name: 'Scripps Sports',
    height: null,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/scripps/ScrippsSports_BLK-ELECTRIC.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/scripps/ScrippsSports_WHT-ELECTRIC.png',
  },
  sec: {
    id: '',
    alt: 'SEC Network',
    name: 'SECN',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/SEC.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/espn-sec-network_m.png',
  },
  showtime: {
    id: '54',
    alt: 'Showtime',
    name: 'Showtime',
    height: null,
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/paramount-plus_w_showtime_v2.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/paramount-plus_w_showtime_v2.png',
    // logoWhite: 'https://imgx.fubo.tv/station_logos/showtime_brand_m.png',
  },
  slice: {
    id: '',
    alt: 'Slice',
    // name: 'Slice',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/Slice_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/Slice_m.png',
  },
  sny: {
    alt: 'SNY',
    height: null,
    name: 'Sportsnet NY',
    logoUrl: 'https://imgx.fubo.tv/station_logos/sny_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/sny_m.png',
  },
  spacecity: {
    alt: 'Space City Home Network',
    height: null,
    name: 'Space City Home Network',
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/space-city_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/space-city_m.png',
  },
  sportsnetpittsburgh: {
    alt: 'Sportsnet Pittsburgh',
    height: 80,
    name: 'Sportsnet Pittsburgh',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/SportsNetPittsburgh_Stack_Black.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/SportsNetPittsburgh_Stack_White.png',
  },
  telemundo: {
    id: '57',
    alt: 'Telemundo',
    name: 'Telemundo',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/telemundo_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/telemundo_m.png',
  },
  telemundo_accion: {
    id: '',
    alt: 'Telemundo Accion',
    name: 'Telemundo Accion',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/telemundo-acci-n-fclogo-72-color.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/telemundo-acci-n-fclogo-72-white.png',
  },
  telemundo_noticias_ahora: {
    id: '',
    alt: 'Telemundo Noticias Ahora',
    name: 'Telemundo Noticias Ahora',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/noticias-telemundo-ahora-fclogo-color.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/noticias-telemundo-ahora-fclogo-white.png',
  },
  telemundo_romance: {
    id: '',
    alt: 'Telemundo Romance',
    name: 'Telemundo Romance',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/telemundo-romance-fclogo-72-color.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/telemundo-romance-fclogo-72-white.png',
  },
  tennischannel: {
    id: '',
    alt: 'Tennis Channel',
    name: 'Tennis Channel',
    height: null,
    logoUrl: 'https://images-qa.fubo.tv/station_logos/T2_live-tennis_c.png',
    logoWhite: 'https://images-qa.fubo.tv/station_logos/T2_live-tennis_m.png',
  },
  tlc: {
    alt: 'TLC',
    height: null,
    name: 'TLC',
    logoUrl: 'https://imgx.fubo.tv/station_logos/tlc_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/tlc_m.png',
  },
  treehouse: {
    id: '',
    alt: 'Treehouse',
    // name: 'Treehouse',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/treehouse_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/treehouse_m.png',
  },
  tudn: {
    id: '137',
    alt: 'TUDN',
    name: 'TUDN',
    height: 25,
    logoUrl: 'https://imgx.fubo.tv/station_logos/tudn_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/tudn_m.png',
  },
  tyc: {
    id: '',
    alt: 'TyC Sports',
    name: 'TyC Sports',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/tyc_sports_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/tyc_sports_m.png',
  },
  tyt: {
    id: '1173',
    alt: 'TYT Network',
    name: 'The Young Turks',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/TYTlogo_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/TYTlogo_m.png',
  },
  unimas: {
    id: '63',
    alt: 'UniMas',
    name: 'Univision',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/unimas2021_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/unimas2021_m.png',
  },
  universalkids: {
    id: '',
    alt: 'Universal Kids',
    name: 'Universal',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/universal_kids_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/universal_kids_m.png',
  },
  universo: {
    id: '43',
    alt: 'Universo',
    name: 'NBC Universo',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/nbc_universo_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/nbc_universo_m.png',
  },
  univision: {
    id: '63',
    alt: 'Univision',
    name: 'Univision',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/univision_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/univision_m.png',
  },
  usa: {
    id: '64',
    alt: 'USA Network',
    name: 'USA Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/usa-network2021_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/usa-network2021_m.png',
  },
  vault: {
    alt: 'Vault',
    name: 'Vault',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/sc-vault_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/sc-vault_c.png',
  },
  weatherchannel: {
    id: '60',
    alt: 'The Weather Channel',
    name: 'The Weather Channel',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/the_weather_channel22_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/the_weather_mhannel22_c.png',
  },
  willow: {
    id: '',
    alt: 'Willow',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/willow_2023_primary.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/willow_2023_primary-white.png',
  },
  willow_by_cricbuzz: {
    id: '',
    alt: 'Willow by Cricbuzz',
    name: 'Willow by Cricbuzz',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/willow-by-cricbuzz_c.png',
    logoWhite: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/willow-by-cricbuzz_m.png',
  },
  willow_extra: {
    id: '',
    alt: 'Willow Extra',
    name: 'Willow Extra',
    height: null,
    logoUrl:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-white/willow_2023_primary_xtra.png',
    logoWhite:
      'https://images.fubo.tv/channel-config-ui/station-logos/on-dark/willow_2023_primary-white_xtra.png',
  },
  wnetwork: {
    id: '',
    alt: 'W Network',
    // name: 'W Network',
    height: null,
    logoUrl: 'https://imgx.fubo.tv/station_logos/w-network_c.png',
    logoWhite: 'https://imgx.fubo.tv/station_logos/w-network_m.png',
  },
  yesnetwork: {
    alt: 'YES Network',
    name: 'YES Network',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/YES_One_Color_Blue-v2.png',
    logoWhite:
      'https://assets-imgx.fubo.tv/marketing/lp/images/networks/YES_One_Color_White-v2.png',
  },
  zee_bollywood: {
    id: '',
    alt: 'Zee Bollywood',
    name: 'Zee Bollywood',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/Zee-Bollywood_c-v2.png',
  },
  zee_cinema: {
    id: '',
    alt: 'Zee Cinema',
    name: 'Zee Cinema',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/Zee-Cinema_c-v2.png',
  },
  zee_classic: {
    id: '',
    alt: 'Zee Classic',
    name: 'Zee Classic',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/Zee-Classic_c-v2.png',
  },
  zee_tv: {
    id: '',
    alt: 'Zee TV',
    name: 'Zee TV',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/Zee-TV_c-v2.png',
  },
  zing: {
    id: '',
    alt: 'Zing',
    name: 'Zing',
    height: null,
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/Zing_c-v2.png',
  },
}
