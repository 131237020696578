import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { laDiscountPriceMonthly, laChannelCount } from 'src/constants/laPlanData'
import { videos } from 'src/constants/videos'
export default (props) => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.espn.videoUrl}
      annotations={videos.espn.annotations}
      title="MIRA DEPORTES AMERICANOS"
      subtitle={`Accede ahora por solo $${laDiscountPriceMonthly} el primer mes`}
      tagline={`Más de ${laChannelCount} canales con deportes, noticias, novelas y entretenimiento en español.`}
      networkSet="entertainmentLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/american/"
    />
  )
}
