import { getEnvironment } from 'src/config/env'
import { ImpactRadiusPageLoadRequest } from 'src/modules/utils/integrations/impactRadius'
import request from 'superagent'

export const MAX_API_TIMEOUT = 5000

const env = getEnvironment()
const THIRTY_DAYS_AS_MILLISECONDS = 1000 * 60 * 60 * 24 * 30

export const impactRadiusPageLoad = async ({
  PageUrl,
  ReferringUrl,
  CustomProfileId,
  CustomerId,
  CustomerEmail,
  UserAgent,
  IpAddress,
}: ImpactRadiusPageLoadRequest) => {
  const currentDate = new Date().toISOString()

  // impact api documentation: https://docs.google.com/document/d/1P2s4JBQB9cgzAcW8VnLMp4Q4sco9m5rcquc1yOzXVwU/edit#bookmark=id.s9htq583ywvi
  return await request
    .post(env.impact.url)
    .send({
      CampaignId: env.impact.campaignId,
      PageUrl,
      ReferringUrl,
      CustomProfileId,
      CustomerId,
      CustomerEmail,
      UserAgent,
      IpAddress,
      EventDate: currentDate,
    })
    .timeout(MAX_API_TIMEOUT)
    .accept('json')
    .then((response) => response.body)
    .then((data) => {
      if (!document?.cookie) {
        throw new Error('Cookies are not available')
      }

      document.cookie = `ftvOption%3AcustomProfileId=${CustomProfileId}; max-age=${THIRTY_DAYS_AS_MILLISECONDS}; path=/`

      if (data?.clickId) {
        document.cookie = `ftvOption%3Airclickid=${data.clickId}; max-age=${THIRTY_DAYS_AS_MILLISECONDS}; path=/`
      }
    })
}
