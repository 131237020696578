import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueGroupIds, leagueIds } from 'src/constants/personalizationIds'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.nascar.videoUrl}
    staticAnnotation={videos.nascar.staticAnnotation}
    title="Watch NASCAR live on FOX & FS1"
    subtitle="FOLLOW YOUR FAVORITE DRIVERS ALL SEASON"
    tagline="Stream races from your TV, phone & other devices "
    networkSet="sports"
    customNetworks={['fox', 'fs1']}
    sportId={sportIds['Auto racing']}
    leagueId={leagueIds.NASCAR}
    matchtickerSportId={sportIds['Auto racing']}
    matchtickerLeagueName="NASCAR"
    matchtickerLeagueId={leagueGroupIds.nascar}
  />
)
