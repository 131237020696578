import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds } from 'src/constants/personalizationIds'

export default (props) => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.soccer.videoUrl}
      annotations={videos.soccer.annotations}
      title="Watch live soccer matches"
      subtitle="STREAM ON YOUR PHONE, TV AND MORE"
      tagline="Follow top leagues from all over the world"
      networkSet="soccer"
      noteLink="/soccer-es/"
      sportId={sportIds.Soccer}
    />
  )
}
