import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { promoCodes } from 'src/constants/promoCodes'
import { useEPLImage } from 'src/modules/hooks/useEPLImage'
import styled from 'styled-components'

export default (props) => {
  const posterImg = useEPLImage()

  return (
    <StyledContainer>
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        poster={posterImg}
        noVideo={true}
        offerCode={promoCodes.canada_ftf_canada}
        offerBanner={
          <>
            ✔ Special offer for FTF Canada members activated!
            <br />
            Enjoy $30 off 3 months of live Premier League soccer & more.
          </>
        }
        title="Watch the Premier League all season"
        subtitle="UNLOCK YOUR $30 DISCOUNT NOW!"
        plan="sportsOnly"
        networkSet="canada"
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-ftf-canada_v1.png?ch=width&auto=format,compress"
        logoHeight="90px"
        forceAnchorLink={false}
        planCardOverride={'ftfOffer'}
        forcePlanLength="Quarterly"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  ${
    '' /* .desktop-line-break {
    display: inline-block;
    @media (min-width: 768px) {
      display: block;
    }
  } */
  }
`
