import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'

//REPLACED: univision_removed
export default (props) => <RedirectTemplate location={props.location} destination="/tv/" />

//REMOVED: univision_removed
// export default props => (
//   <USTemplateDefault
//     location={props.location}
//     lang="en"
//     market="us"
//     video={videos.liga_mx.videoUrl}
//     staticAnnotation={videos.liga_mx.staticAnnotation}
//     title="Watch Liga MX Live on TUDN"
//     subtitle="STREAM ON YOUR PHONE, TV AND MORE"
//     // tagline="Follow top leagues from all over the world"
//     networkSet="soccer"
//     customNetworks={['tudn']}
//     // noteLink="/liga-mx-es/"
//   />
// )
