import React from 'react'
import CollegeFootball from 'src/pages/college-football'
import { teamIds } from 'src/constants/personalizationIds'
export default (props) => (
  <CollegeFootball
    {...props}
    team="USC Trojans"
    subtitle="SEE THE USC TROJANS IN ACTION THIS SEASON"
    hasGenericVideo={true}
    teamId={teamIds['USC Trojans']}
  />
)
