import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { networkLogos } from 'src/constants/networkLogos'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
export default (props) => (
  <CanadaTemplateDefault
    location={props.location}
    lang="en"
    market="canada"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/CA_NBA2023_LP_6s.mp4"
    staticAnnotation={networkLogos.nbatvca}
    title="Stream NBA TV Canada"
    subtitle="CATCH LIVE GAMES, SHOWS AND HIGHLIGHTS"
    tagline="Any time, any place, on all your devices"
    networkSet="canada"
    plan="premium"
    customNetworks={['nbatvca']}
    league="nba"
    showRSN={true}
    sportId={sportIds.Basketball}
    matchtickerSportId={sportIds.Basketball}
    matchtickerLeagueName="NBA"
    matchtickerLeagueId={leagueIds.NBA}
    additionalFAQs={[
      {
        question: 'What is NBA TV Canada?',
        answer:
          'Canada’s Home of Basketball. Giving fans 24/7 access to all the hardcourt action. 100s of LIVE games from across the NBA, WNBA, and NBA G-League. Exclusive programming from NBA Gametime, Inside the NBA, NBA360 and NBAXL! Plus, in-depth coverage of the Toronto Raptors! 1 channel. Lots of reasons to subscribe',
      },
    ]}
  />
)
