export const UPDATE_PARAMS = 'PARAMS/UPDATE_PARAMS'
export type UpdateParamsAction = {
  type: typeof UPDATE_PARAMS
  payload: Record<string, any>
}

export const DELETE_PARAM = 'PARAMS/DELETE_PARAM'
export type DeleteParamAction = {
  type: typeof DELETE_PARAM
  payload: string
}

export type ParamsAction = UpdateParamsAction | DeleteParamAction
