import {
  WEB_MARKETING_PROMO_BANNER_US,
  WEB_MARKETING_FAQ_MESSAGING,
  WEB_MARKETING_VALUE_PROPS_HERO,
  WEB_MARKETING_ADCASH_CTA_URL,
  WEB_MARKETING_FANDUEL_W_PRO,
  WEB_MARKETING_NO_PROMO_BANNER,
  WEB_MARKETING_MLB_TV_W_TRIAL,
} from './constants'

import type { BooleanValueFlag, Flag, StringValueFlag } from './types'

type MarketingFAQMessaging = typeof WEB_MARKETING_FAQ_MESSAGING
type MarketingPromoBannerUS = typeof WEB_MARKETING_PROMO_BANNER_US
type MarketingValuePropsHero = typeof WEB_MARKETING_VALUE_PROPS_HERO
type MarketingAdcashCtaUrl = typeof WEB_MARKETING_ADCASH_CTA_URL
type MarketingFanDuelWithPro = typeof WEB_MARKETING_FANDUEL_W_PRO
type MarketingNoPromoBanner = typeof WEB_MARKETING_NO_PROMO_BANNER
type MarketingMLBTVWTrial = typeof WEB_MARKETING_MLB_TV_W_TRIAL
function flag<K extends string, V>(key: K, value: V, ttl = -1): Flag<K, V> {
  return {
    key,
    value,
    ttl,
  }
}
function booleanFlag<K extends string>(key: K, value = false, ttl = -1): BooleanValueFlag<K> {
  return flag<K, boolean>(key, value, ttl)
}
function stringFlag<K extends string>(key: K, value = 'default', ttl = -1): StringValueFlag<K> {
  return flag<K, string>(key, value, ttl)
}

export const initValues = {
  [WEB_MARKETING_FAQ_MESSAGING]: stringFlag<MarketingFAQMessaging>(WEB_MARKETING_FAQ_MESSAGING),
  [WEB_MARKETING_PROMO_BANNER_US]: stringFlag<MarketingPromoBannerUS>(
    WEB_MARKETING_PROMO_BANNER_US
  ),
  [WEB_MARKETING_VALUE_PROPS_HERO]: stringFlag<MarketingValuePropsHero>(
    WEB_MARKETING_VALUE_PROPS_HERO
  ),
  [WEB_MARKETING_ADCASH_CTA_URL]: stringFlag<MarketingAdcashCtaUrl>(WEB_MARKETING_ADCASH_CTA_URL),
  [WEB_MARKETING_FANDUEL_W_PRO]: stringFlag<MarketingFanDuelWithPro>(WEB_MARKETING_FANDUEL_W_PRO),
  [WEB_MARKETING_NO_PROMO_BANNER]: stringFlag<MarketingNoPromoBanner>(
    WEB_MARKETING_NO_PROMO_BANNER
  ),
  [WEB_MARKETING_MLB_TV_W_TRIAL]: stringFlag<MarketingMLBTVWTrial>(WEB_MARKETING_MLB_TV_W_TRIAL),
} as const

export type StacFlags = typeof initValues
export type StacFlagKey = keyof StacFlags
export type StacFlagKeys = StacFlagKey[]

export const supportedFlags = Object.keys(initValues) as StacFlagKeys
