export const championshipLogos = {
  pga: {
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/championships/pga_c.png',
    logoWhite: '',
    alt: 'PGA Quail Hollow',
    height: null,
  },
  masters_tournament: {
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/championships/masters-tournament_c.png',
    logoWhite: '',
    alt: 'Masters Tournament',
    height: null,
  },
  the_open: {
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/championships/the-open_c.png',
    logoWhite: '',
    alt: 'The Open',
    height: null,
  },
  us_open: {
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/championships/us-open_c.png',
    logoWhite: '',
    alt: 'US Open',
    height: null,
  },
  the_players: {
    logoUrl: 'https://assets-imgx.fubo.tv/marketing/lp/images/championships/the-players_c.png',
    logoWhite: '',
    alt: 'The Players Championship',
    height: null,
  },
}
