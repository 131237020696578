import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PlanCardPlain from 'src/components/common/PlanCardPlain'
import { selectProChannels, selectEliteChannels } from 'src/modules/reducers/products/selectors'
import { promoCodes } from 'src/constants'
const optimumPlanData = (channels, lang) => {
  const proChannels = channels?.['pro'].length || '150+'

  const dataEn = {
    pro: {
      offerCode: promoCodes.optimum_pro_plan,
      plan: 'pro',
      title: 'Pro',
      price: '$84.99/mo',
      discountedPrice: 'only $59.49/mo*',
      tagline: (
        <>
          Exclusive offer: <span>30% off for 2 months!</span>
        </>
      ),
      listItems: [
        { available: true, text: `${proChannels} channels` },
        {
          available: true,
          text: <span>Includes MLB and FanDuel Sports</span>,
        },
        { available: true, text: 'Unlimited Cloud DVR' },
        { available: true, text: 'Watch on up to 10 screens at once' },
        { available: true, text: 'Free Trial' },
      ],
    },
  }

  return dataEn
}

const USPlanCards = ({ lang, ctaText }) => {
  const proChannels = useSelector(selectProChannels)
  const eliteChannels = useSelector(selectEliteChannels)

  const channels = {
    pro: proChannels,
    elite: eliteChannels,
  }

  const planData = optimumPlanData(channels, lang)

  const title = lang === 'es' ? '¿Cuál es el mejor plan para ti?' : 'Which plan is right for you?'
  const subtitle = lang === 'es' ? 'Cancela en cualquier momento.' : 'Cancel anytime.'

  const footnote =
    lang === 'es'
      ? ''
      : '*Reg. prices in effect after 2-mo promotional period ends. Additional taxes and fees may apply. Prices subject to change. Regional restrictions apply.'

  return (
    <StyledBundleSection>
      <div id="select-plan"></div>
      <div className="section-header">
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      <div className="section-content">
        {planData &&
          Object.entries(planData).map(([plan, data], index) => {
            return (
              <PlanCardPlain
                key={index}
                offerCode={data.offerCode}
                plan={data.plan}
                title={data.title}
                price={data.price}
                discountedPrice={data.discountedPrice}
                tagline={data.tagline}
                listItems={data.listItems}
                ctaText={ctaText}
                channels={channels[plan]}
              />
            )
          })}
      </div>
      <div className="section-footnote">{footnote}</div>
    </StyledBundleSection>
  )
}

export default USPlanCards

const StyledBundleSection = styled.section`
  color: #fff;
  padding: 60px 20px 60px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #474f56 0%, #1d1f2a 77.78%);
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .inline-block {
    display: inline-block;
  }

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  #select-plan {
    position: absolute;
    top: -80px;
  }

  .section-header {
    padding: 0 0 30px 0;

    h2 {
      color: rgb(240, 241, 242);
      font-size: 40px;
      font-weight: 700;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      letter-spacing: 1px;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
      margin-top: 20px;
    }
  }

  .section-tabs {
    width: 100%;
    max-width: 500px;
    .plan-tabs {
      ${'' /* border: 3px solid #fa4616; */}
      ${'' /* border-radius: 10px; */}
      overflow: hidden;
      margin-bottom: 30px;
      display: flex;
      width: 100%;
      max-width: 500px;
      align-items: flex-end;
      > * {
        flex: 1 0 0;
      }
      .tab {
        position: relative;
      }
      .savings {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .tab:first-child {
        button {
          border-radius: 10px 0 0 10px;
        }
      }
      .tab:last-child {
        button {
          border-radius: 0 10px 10px 0;
        }
      }
      button {
        width: 100%;
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          background: #000;
          color: #fff;
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 1020px) {
      align-items: stretch;
      flex-direction: row;
      gap: 30px;
      margin-bottom: 50px;
    }
  }

  .section-footnote {
    color: #c2c2c2;
    text-align: center;

    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 22.44px */
    max-width: 530px;
  }
`
