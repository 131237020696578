import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import styled from 'styled-components'

export default (props) => {
  return (
    <StyledContainer>
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        noVideo
        title={
          <>
            {' '}
            Watch live <span className="desktop-line-break"> events at the </span> Summer Games{' '}
          </>
        }
        pageTitle={'Watch live events at the Summer Games'}
        subtitle="SEE SWIMMING, GYMNASTICS, SOCCER & MORE"
        tagline="Stream full coverage of events on CBC from any device"
        networkSet="canada"
        poster={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/olympics_bg.jpg'}
        customNetworks={['cbc', 'icitele']}
        logo={'https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-Fubo-CBC-Ici-Tele.png'}
        plan={'soccer'}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .videoWrapper {
    @media (max-width: 768px) {
      background-position: 70% center;
    }
  }

  .desktop-line-break {
    display: block;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`
