import React, { forwardRef } from 'react'
import { imgIX } from 'src/modules/utils/imgIX'
import LazyImage from '../LazyLoad/LazyImage'

const Image = forwardRef(({ src, isLazyLoad = false, isCompress = true, ...rest }, ref) => {
  const imgSrc = imgIX(src, { isCompress, ...rest })
  if (isLazyLoad) {
    return <LazyImage src={imgSrc} {...rest} ref={ref} />
  }
  return <img src={imgSrc} {...rest} ref={ref} />
})

export default Image
