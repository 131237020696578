import React from 'react'
import USTemplateDefault from 'src/components/template/us'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={'https://assets.fubo.tv/marketing/lp/videos/compressed/Telemundo_LP.mp4'}
    title="Watch Telemundo with Fubo"
    subtitle="ENJOY SPORTS, NOVELAS, NEWS AND MORE"
    tagline="Stream it live from your TV and other devices"
    networkSet="entertainmentLatino"
    customNetworks={['telemundo', 'universo']}
  />
)
