import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/CONCACAF_2021_LP_19s.mp4'}
      title="Watch CONCACAF World Cup Qualifiers"
      subtitle="SEE THE CANADA MEN'S NATIONAL TEAM IN ACTION"
      tagline="Stream live soccer matches from any device"
      plan="soccer"
      networkSet="canada"
    />
  )
}
