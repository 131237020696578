import * as React from 'react'

interface Props {
  children: React.ReactNode
  annotationsTheme: string
  annotations: Array<any>
}

// returns float number to precision specified
function round(value: number): number {
  const multiplier = Math.pow(10, 2)
  return Math.round(value * multiplier) / multiplier
}

// determines if currentTime is within provided range
function testInRange(startSecs: string, endSecs: string, currentTime: number): boolean {
  if (currentTime >= round(parseFloat(startSecs)) && currentTime <= parseFloat(endSecs)) return true

  return false
}

interface LogoSyncContextType {
  onTimeUpdate: (currentTime: number) => void
  logoUrl: string
  alt: string
  height: number
}

export const initState: LogoSyncContextType = {
  onTimeUpdate: () => {},
  logoUrl: '',
  alt: '',
  height: 0,
}

export const LogoSyncContext = React.createContext<LogoSyncContextType>(initState)

export const LogoSyncContextProvider: React.FC<Props> = (props) => {
  const annotations = props.annotations || []
  const initLogoUrl = annotations[0]?.logoUrl || ''
  const initAlt = annotations[0]?.alt || ''
  const initHeight = annotations[0]?.height || 50
  const [logoUrl, setLogoUrl] = React.useState(initLogoUrl)
  const [alt, setAlt] = React.useState(initAlt)
  const [height, setHeight] = React.useState(initHeight)
  /*
  / onTimeUpdate func
  / params: current video playback time - number
  */

  const onTimeUpdate = React.useCallback(
    (currentTime: number) => {
      const roundedTime = round(currentTime)
      // iterate over annotations with currentTime
      if (annotations.length > 0) {
        annotations.forEach((item) => {
          if (item && testInRange(item.startSecs, item.endSecs, roundedTime)) {
            item.logoUrl ? setLogoUrl(item.logoUrl) : setLogoUrl('')
            item.alt && setAlt(item.alt)
            item.height ? setHeight(item.height) : setHeight(0)
          }
        })
      } else {
        return null
      }
    },
    [annotations]
  )

  const initContextValue = React.useMemo(
    () => ({ onTimeUpdate, logoUrl, alt, height }),
    [onTimeUpdate, logoUrl, alt, height]
  )

  return (
    <LogoSyncContext.Provider value={initContextValue}>{props.children}</LogoSyncContext.Provider>
  )
}
