import React from 'react'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'
import BallySportsNHL from 'src/components/page/bally-sports-nhl'

export default (props) => {
  return (
    <BallySportsNHL
      location={props.location}
      video={videos.bally_predators.videoUrl}
      staticAnnotation={videos.bally_predators.staticAnnotation}
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/Predators.jpg?ch=width&auto=format,compress"
      title="Watch Nashville hockey live"
      subtitle="STREAM THE PREDATORS ON FANDUEL SPORTS SOUTH"
      tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
      teamId={teamIds['Nashville Predators']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-nashville-predators-v2.png?ch=width&auto=format,compress"
      logoHeight="110px"
    />
  )
}
