import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'

//REPLACED: univision_removed
export default (props) => <RedirectTemplate location={props.location} destination="/latino/" />

//REMOVED: univision_removed
// export default props => {
//   return (
//     <LatinoTemplateDefault
//       location={props.location}
//       lang="en"
//       market="latino"
//       video={videos.leagues_cup.videoUrl}
//       title="Watch the Leagues Cup on Univision"
//       subtitle="SEE LIGA MX & MLS TEAMS GO HEAD-TO-HEAD"
//       tagline="Stream live matches from any device with Fubo"
//       networkSet="soccerLatino"
//       customNetworks={['univision', 'unimas']}
//       noteLink="/la/leagues-cup-es/"
//     />
//   )
// }
