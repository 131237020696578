import React from 'react'
import GolfTemplate from 'src/components/page/golf'
import { leagueGroupIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'

export default (props) => (
  <StyledContainer>
    <GolfTemplate
      location={props.location}
      poster={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/golf_Web_v3.png'}
      posterMobile={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/golf_Mobile_v3.jpg'}
      title="Fubo has the most golf"
      subtitle={'Stream 120+ live events from the PGA Tour & more'}
      matchtickerLeagueId={leagueGroupIds.golf}
      additionalFAQs={additionalFAQs}
    />
  </StyledContainer>
)

const StyledContainer = styled.div``

const additionalFAQs = [
  {
    question: 'Can I watch live golf tournaments on Fubo?',
    answer:
      'Yes, you can watch live golf tournaments on Fubo, as it provides access to the Golf Channel and ESPN, which broadcast major events.',
  },
  {
    question: 'How can I find golf coverage on Fubo?',
    answer:
      "To find golf coverage on Fubo, use the search feature in the app or website and type in 'golf' to see all available live events, shows, and related content.",
  },
  {
    question: 'Does Fubo offer golf commentary and analysis shows?',
    answer:
      'Yes, Fubo offers access to golf commentary and analysis shows available on the Golf Channel and ESPN, enhancing your viewing experience with expert insights.',
  },
  {
    question: 'Can I watch the Golf Channel on Fubo?',
    answer:
      'Yes, the Golf Channel is available on Fubo, allowing you to watch a variety of golf-related programming, including live events and instructional shows.',
  },
  {
    question: 'How do I watch replay coverage of golf events on Fubo?',
    answer:
      'You can watch replay coverage of golf events on Fubo by navigating to the On Demand section, where past events aired on the Golf Channel and ESPN are available for viewing.',
  },
  {
    question: 'Are there any golf shows I can watch on Fubo?',
    answer:
      'Yes, Fubo provides access to popular golf shows on the Golf Channel, featuring tips, highlights, and player interviews that you can watch at your convenience.',
  },
  {
    question: 'Can I follow specific golf tournaments on Fubo?',
    answer:
      "Yes, you can follow specific golf tournaments on Fubo by checking the schedule on the Golf Channel and ESPN, ensuring you don't miss any live action or updates.",
  },
  {
    question: 'Does Fubo provide coverage of both PGA and LPGA tours?',
    answer:
      'Yes, Fubo provides coverage of both the PGA and LPGA tours through the Golf Channel and ESPN, allowing you to enjoy a full range of professional golf events.',
  },
]
