import { SET_MARKET_SUCCESS } from './actionTypes'

export type MarketState = {
  countryCode?: string | null
  countryCode2?: string | null
  countryName?: string | null
  dma?: string | null
  postalCode?: string | null
  region?: string | null
}

const initialState = {
  countryCode: null,
  countryCode2: null,
  countryName: null,
  dma: null,
  postalCode: null,
}

export const marketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MARKET_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
