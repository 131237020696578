import React from 'react'
import NFL from 'src/pages/nfl'
import { teamIds } from 'src/constants/personalizationIds'
export default (props) => (
  <NFL
    {...props}
    team="New England Patriots"
    teamShort="Patriots"
    teamId={teamIds['New England Patriots']}
  />
)
