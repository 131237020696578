import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.generic_football.videoUrl}
    title="Watch Sunday Night Football"
    subtitle="LIVE ON NBC SUNDAYS AT 8PM ALL SEASON LONG"
    tagline="Stream it from your TV, laptop and other devices"
    networkSet="football"
    customNetworks={['nbc']}
    showRedzone={false}
  />
)
