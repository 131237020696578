import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/American_Idol_2022_LP_20s.mp4"
    staticAnnotations={networkLogos.abc}
    title="Watch American Idol on ABC"
    subtitle="CATCH NEW EPISODES LIVE AND ON DEMAND"
    tagline="Stream it on your TV, phone and other devices"
    ctaText="Start Watching"
  />
)
