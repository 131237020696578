import React from 'react'
import CompareRows from 'src/components/template/compare/components/modules/CompareRows'
import CompareContainer from 'src/components/template/compare/components/modules/Container'
import Footnote from 'src/components/template/compare/components/modules/Footnote'
import SectionText from 'src/components/template/compare/components/modules/SectionText'
import { breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import styled from 'styled-components'

const CompareProsConsSection = ({ competitor, data }) => {
  const {
    heading,
    subheading,
    listItems,
    fuboList,
    competitorList,
    note,
    fuboLogoText,
    competitorLogoText,
  } = data

  return (
    <StyledCompareProsConsSection>
      <CompareContainer className="content">
        <SectionText heading={heading} subheading={subheading} className="section-text" />
        {/* <CompareCardContainer className="cards-section">
          <CompareTableCard list={listItems} competitor=" " labels={true} />
          <CompareTableCard list={fuboList} />
          <CompareTableCard competitor={competitor} list={competitorList} />
        </CompareCardContainer> */}

        <CompareRows
          className="cards-section"
          competitor={competitor}
          listItems={listItems}
          fuboList={fuboList}
          competitorList={competitorList}
          fuboLogoText={fuboLogoText}
          competitorLogoText={competitorLogoText}
        />

        <Footnote className="footnote">
          {note || '*Prices may vary with taxes and fees. Prices subject to change.'}
        </Footnote>
      </CompareContainer>
    </StyledCompareProsConsSection>
  )
}

export default CompareProsConsSection

const StyledCompareProsConsSection = styled.section`
  ${breakpointQuery.mobile} {
    --logo-height: 20px;
  }
  .section-text {
    margin-bottom: 20px;
  }
  .cards-section {
    ${
      '' /* ${breakpointQuery.desktop} {
      max-width: 800px;
    } */
    }
    gap: 0 32px;
    margin-bottom: 40px;
    flex-direction: row;
    ${breakpointQuery.mobile} {
      gap: 0 8px;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 30px;
    }
  }
  .footnote {
    margin: unset;
    margin-bottom: 40px;
    max-width: 800px;
    text-align: left;
    ${
      '' /* ${breakpointQuery.mobile} {
      margin-left: -10px;
      margin-right: -10px;
    } */
    }
  }
`
