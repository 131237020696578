import React from 'react'
import { networkLogos } from 'src/constants/networkLogos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
import USTemplateDefault from 'src/components/template/us'

export default (props) => {
  return (
    <USTemplateDefault
      location={props.location}
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/WhiteSox_Web_LP_10s.mp4"
      videoMobile="https://assets.fubo.tv/marketing/lp/videos/mobile/WhiteSox_Mobile_LP_10s.mp4"
      staticAnnotation={networkLogos.chsn}
      title="Watch Chicago baseball live"
      subtitle="STREAM THE WHITE SOX ON CHICAGO SPORTS NETWORK"
      tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
      networkSet="baseball"
      networkHeadline="The Best of Sports from the Channels You Love"
      customNetworks={['chsn']}
      sportId={sportIds.Baseball}
      leagueId={leagueIds.MLB}
      teamId={teamIds['Chicago White Sox']}
    />
  )
}
