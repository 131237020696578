import { DELETE_PARAM, DeleteParamAction, UPDATE_PARAMS, UpdateParamsAction } from './actionTypes'

export const updateParams = (params: Record<string, any>): UpdateParamsAction => ({
  type: UPDATE_PARAMS,
  payload: params,
})

export const deleteParam = (paramKey: string): DeleteParamAction => ({
  type: DELETE_PARAM,
  payload: paramKey,
})
