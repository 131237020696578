import React, { useEffect, useState } from 'react'
import { breakpoint, breakpointQuery } from 'src/components/template/compare/constants/breakpoint'
import { typography } from 'src/components/template/compare/constants/typography'
import styled from 'styled-components'

const SectionText = ({ heading, subheading, children, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      setIsExpanded(windowWidth > breakpoint.desktop)
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Call handleResize immediately to set initial state
    handleResize()

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleOnClick = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <StyledSectionText isExpanded={isExpanded} {...props}>
      <h2>{heading}</h2>
      {subheading && <h3>{subheading}</h3>}
      {children && (
        <div className="body">
          <div className="text">{children}</div>
          <div className="toggle" onClick={handleOnClick}>
            Read more +
          </div>
        </div>
      )}
    </StyledSectionText>
  )
}

export default SectionText

const StyledSectionText = styled.div`
  h2 {
    ${typography.heading.sm}

    letter-spacing: 0.01em;

    /* Grey / Grey-440 */

    color: #fafafa;

    margin-bottom: 12px;

    ${breakpointQuery.desktop} {
      ${typography.heading.lg}
    }
  }
  h3 {
    ${typography.heading.xs}

    letter-spacing: 0.01em;

    /* Grey / Grey-400 */

    color: #e5e6e6;

    margin-bottom: 12px;

    ${breakpointQuery.desktop} {
      ${typography.heading.md}
    }
  }
  .body {
    margin-top: 12px;
    max-width: 800px;
    .text {
      height: ${(props) => (props.isExpanded ? 'auto' : '189px')};
      overflow: hidden;
      margin-bottom: 16px;
      p {
        ${typography.body.sm}

        /* Grey / Grey-360 */

        color: #d0d2d2;
        margin: 0;

        ${breakpointQuery.desktop} {
          ${typography.body.md}
        }
      }
      p + p {
        margin-top: 16px;
      }
    }
    .toggle {
      /* Interactive/Interactive-S */

      display: ${(props) => (props.isExpanded ? 'none' : 'block')};

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      /* or 129% */

      letter-spacing: 0.01em;

      /* Grey / Grey-400 */

      color: #e5e6e6;
    }
  }
`
