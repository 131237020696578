// Action Types (Constants)
import {
  ANALYTICS_INIT_SUCCESS,
  ANALYTICS_PROPERTIES_READY,
  ANALYTICS_READY,
  COHORT_EVENT_INITIALIZED,
  DEVICE_READY,
  FIRE_COHORT_EVENT,
  PAGE_ANALYTICS_FAILED,
  PAGE_ANALYTICS_SENT,
  PAGE_VIEW_SENT,
  UPDATE_ANALYTICS_PROPERTIES,
  UPDATE_PARAMETERS,
} from './actionsTypes'

// Action Interfaces
import type {
  AnalyticsPropertiesReadyAction,
  AnalyticsReadyAction,
  CohortEventInitializedAction,
  DeviceReadyAction,
  FireCohortEventAction,
  InitAnalyticsAction,
  PageAnalyticsFailedAction,
  PageAnalyticsSuccessAction,
  PageViewSentAction,
  UpdateAnalyticsPropertiesAction,
} from './actionsTypes'

//old
export const updateAnalyticsReady = (): InitAnalyticsAction => {
  return {
    type: ANALYTICS_INIT_SUCCESS,
  }
}

export const analyticsReady = (): AnalyticsReadyAction => ({
  type: ANALYTICS_READY,
})

export const updateAnalyticsProperties = (properties): UpdateAnalyticsPropertiesAction => ({
  type: UPDATE_ANALYTICS_PROPERTIES,
  payload: properties,
})

export const updateAnalyticsPropertiesReady = (): AnalyticsPropertiesReadyAction => {
  return {
    type: ANALYTICS_PROPERTIES_READY,
  }
}

export const updatePageViewSent = (): PageViewSentAction => {
  return {
    type: PAGE_VIEW_SENT,
  }
}

export const updateParameters = (parameters) => {
  return {
    type: UPDATE_PARAMETERS,
    payload: parameters,
  }
}

export const initializeCohortEvent = (): CohortEventInitializedAction => ({
  type: COHORT_EVENT_INITIALIZED,
})

export const fireCohortEventAction = (): FireCohortEventAction => ({
  type: FIRE_COHORT_EVENT,
})

export function deviceReady(
  deviceId: string,
  ftvCampaign?: string | null,
  query?: any | null
): DeviceReadyAction {
  return {
    type: DEVICE_READY,
    deviceId,
    ftvCampaign,
    query,
  }
}

export function pageAnalyticsSuccess(): PageAnalyticsSuccessAction {
  return {
    type: PAGE_ANALYTICS_SENT,
  }
}

export function pageAnalyticsFailed(): PageAnalyticsFailedAction {
  return {
    type: PAGE_ANALYTICS_FAILED,
  }
}
