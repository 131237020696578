import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
// import { useDispatch } from 'react-redux'
// import { updateParams } from 'src/modules/reducers/params'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { promoCodes } from 'src/constants/promoCodes'

export default (props) => {
  // const dispatch = useDispatch()

  // const params = {
  //   irad: '366904',
  //   irmp: '365718',
  // }

  // useEffect(() => {
  //   dispatch(updateParams(params))
  // }, [])

  return (
    <StyledContainer>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        offerCode={promoCodes.charter_elite_plan}
        offerBanner={
          <>
            ✔&#xfe0e; Xfinity discount applied!
            <br />
            Xfinity customers get 30% off your first two months
          </>
        }
        video={videos.mlbtv.videoUrl}
        title="Watch FanDuel Sports with Fubo"
        subtitle="STREAM LIVE GAMES AND LOCAL TEAM COVERAGE"
        tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-xfinity.png?ch=width&auto=format,compress"
        networkSubtitle={'Cancel online anytime.'}
        hasCharterPlanCards={true}
        removeCable={true}
        networkSet="sports"
        customNetworks={['fanduelsports']}
        showRSN={true}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .offer-banner {
    /* background-color: #003057; */
    background: linear-gradient(94.26deg, #02579f 35.69%, #0173d0 98.83%);
    padding-left: 8px;
    .banner-title {
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
    .spectrum-offer-banner {
      display: flex;
      align-items: center;
      justify-content: center;
      .offer-banner-image {
        padding-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 160px;
          @media (max-width: 767px) {
            width: 90px;
          }
        }
      }
      .offer-banner-text {
        padding-left: 16px;
        text-align: left;
        border-left: 1px solid #fff;
      }
    }
  }
`

// import React from 'react'
// import RedirectTemplate from 'src/components/template/redirect'
// export default props => <RedirectTemplate location={props.location} destination="/tv/" />
