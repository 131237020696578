import React from 'react'
import Layout from 'src/components/common/Layout'
import styled from 'styled-components'

import SEO from 'src/components/common/SEO'
import Image from 'src/components/common/Image'
import CTAButton from 'src/components/common/CTAButtonNew'
import { selectCTAParams } from 'src/modules/reducers/params'
import { useSelector } from 'react-redux'
import { createUrl } from 'src/modules/utils/createUrl'

const AdcashTemplate = ({
  lang = 'en',
  market = 'all',
  ctaURL,
  title,
  description,
  image,
  imageMobile,
}) => {
  const parsedParams = useSelector(selectCTAParams)
  const url = createUrl(ctaURL, parsedParams)
  const pageDescription =
    description ||
    'See every match all season long. Stream live TV without cable. DVR included. No contract, cancel anytime.'

  return (
    <Layout lang={lang} market={market} noFooter={true}>
      <SEO title={title} description={pageDescription} />
      <StyledContainer>
        <CenterContainer>
          <h1>{title}</h1>
          <CTAButton link={url}>Watch now</CTAButton>
          <Image className="image-mobile" src={imageMobile} alt="Fubo vs Other services" />
          <Image className="image-desktop" src={image} alt="Fubo vs Other services" />
        </CenterContainer>
      </StyledContainer>
    </Layout>
  )
}

export default AdcashTemplate

const StyledContainer = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
  background: radial-gradient(
    202.38% 94.78% at 50% 5.22%,
    #414a5b 0%,
    #2c323e 29.79%,
    #14171f 89.31%
  );

  img {
    width: 90%;
    height: auto;
  }

  .image-mobile {
    display: block;
  }

  .image-desktop {
    display: none;
  }

  .desktop-line-break {
    display: block;
  }

  @media (min-width: 768px) {
    background: radial-gradient(332.77% 131.38% at 98.33% 16.04%, #434b5d 0%, #14171f 100%);
    .desktop-line-break {
      display: inline-block;
    }
    .image-mobile {
      display: none;
    }
    .image-desktop {
      display: block;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    @media (min-width: 530px) {
      font-size: 54px;
    }
  }

  .CTAButton {
    margin: 30px auto 40px;
    width: 220px;
    @media (min-width: 530px) {
      margin: 56px auto 58px;
      border-radius: 7px;
      min-width: 320px;
      font-size: 26px;
      padding: 22px 0;
      width: 60%;
    }
    @media (min-width: 768px) {
      width: 35%;
    }
  }
`

const CenterContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
  @media (min-width: 530px) {
    padding: 70px 30px;
  }
  @media (min-width: 768px) {
    max-width: 1000px;
    padding: 100px 50px;
  }
`
