import { ChannelExtended, ChannelsMap } from '@fubotv/js-client/dist/esm/domains/UP/channels/types'
import { promoUPChannelComparator } from '@fubotv/js-client/dist/esm/domains/UP/channels/utils'
import { AddOn, Tag, Plan as UPPlan } from '@fubotv/types/api/UP/products'
import { createSelector } from 'reselect'

import { selectUPState, AppState } from '../'
// import {
//   selectForZeroTodayFlag,
//   selectGrowthCanadaProductSelectFlag,
// } from '~/reducers/stacFlags/'
// import {
//   getFormattedUnit,
//   getFormattedUnitAdjective,
// } from '~/utils/billing/'

import { ProductsState } from '.'
import { mapProducts } from './utils'

import type {
  CategorizedAddOns,
  FreeTrialInfoResponse,
  MappedAddOn,
  MappedPlan,
  MappedProductsKey,
} from './types'
import { addOnCodes, basePlanCodes } from 'src/constants'

type SelectChannelsExtended = (state: AppState) => Array<ChannelExtended>

const selectProductsState: (state: AppState) => ProductsState = createSelector(
  selectUPState,
  (products) => products
)

// This is equivalent to the legacy selectAllPlans
export const selectAvailablePlans: (state: AppState) => UPPlan[] = createSelector(
  selectProductsState,
  ({ plans }) => plans
)

export const selectMappedPlans: (
  state: AppState
) => Record<MappedProductsKey, Map<string, MappedPlan>> = createSelector(
  selectProductsState,
  ({ plans }) => mapProducts(plans)
)

export const selectAvailableAddOns: (state: AppState) => AddOn[] = createSelector(
  selectProductsState,
  ({ addOns }) => addOns
)

export const selectMappedAddOns: (
  state: AppState
) => Record<MappedProductsKey, Map<string, MappedAddOn>> = createSelector(
  selectProductsState,
  ({ addOns }) => mapProducts(addOns)
)

export const selectCategorizedAddOns: (state: AppState) => CategorizedAddOns = createSelector(
  selectAvailableAddOns,
  (addOns: AddOn[]) => {
    return addOns.reduce(
      (acc: any, addOn: AddOn) => {
        // Conditionals are in display order
        if (addOn.tags) {
          addOn.tags.forEach((tag: Tag) => {
            if (tag.slug === 'most_popular') acc.most_popular.push(addOn)
            if (tag.slug === 'sports') acc.sports.push(addOn)
            if (tag.slug === 'premium') acc.premium.push(addOn)
            if (tag.slug === 'entertainment') acc.entertainment.push(addOn)
            if (tag.slug === 'channels') acc.channels.push(addOn)
            if (tag.slug === 'features') acc.features.push(addOn)
          })
        } else {
          // No tags property defaults to "Channels" category
          acc.channels.push(addOn)
        }

        return acc
      },
      {
        most_popular: [],
        sports: [],
        premium: [],
        entertainment: [],
        channels: [],
        features: [],
      }
    )
  }
)
export const selectIsProductsLoading: (state: AppState) => boolean = createSelector(
  selectProductsState,
  ({ isLoadingProducts }) => isLoadingProducts
)

// export const selectPreselectedPlanKey: (state: AppState) => string =
//   createSelector(
//     selectGrowthCanadaProductSelectFlag,
//     selectMappedPlans,
//     (canadaPreselectedPlanCode, mappedPlans) => {
//       let processedKey = 'quarterly_plans'
//       if (canadaPreselectedPlanCode) {
//         const preselectedPlan = mappedPlans.allByProductCode.get(
//           canadaPreselectedPlanCode
//         )

//         if (preselectedPlan) {
//           const period = getFormattedUnit(
//             preselectedPlan.ratePlan?.billingCycleUnit,
//             preselectedPlan.ratePlan?.billingCycleLength
//           )
//           processedKey = `${getFormattedUnitAdjective(period)}_plans`
//         }
//       }
//       return processedKey
//     }
//   )

export const selectFreeTrialInfo: (state: AppState) => FreeTrialInfoResponse = createSelector(
  selectUPState,
  (upData) => {
    return upData.products.freeTrialInfo
  }
)

// export const selectShowUnderlinedZeroTodayOnBanner: (
//   state: AppState
// ) => boolean = createSelector(
//   selectFreeTrialInfo,
//   selectForZeroTodayFlag,
//   ({ hasFreeTrial }, forZeroTodayFlag) =>
//     Boolean(hasFreeTrial && forZeroTodayFlag?.includes('test'))
// )

export const selectAllAvailableAddOns = createSelector(selectAvailablePlans, (availablePlans) => {
  const allAvailableAddOns = new Set()
  availablePlans.forEach((plan) => {
    plan.addonsIncluded?.forEach((addOn: string) => {
      allAvailableAddOns.add(addOn)
    })
  })

  return allAvailableAddOns
})

export const selectHasShowtimeAddOn = createSelector(
  selectAllAvailableAddOns,
  (allAvailableAddOns) =>
    [...allAvailableAddOns.values()].some((addOn: string) => addOn.includes('showtime'))
)

export const selectChannelsMap: (state: AppState) => ChannelsMap = createSelector(
  selectUPState,
  ({ products }) => products.channels
)

export const selectIsChannelsLoading: (state: AppState) => boolean = createSelector(
  selectProductsState,
  ({ isLoadingChannels }) => isLoadingChannels
)

export const selectAllChannelsFetched: (state: AppState) => boolean = createSelector(
  selectProductsState,
  ({ allChannelsFetched }) => allChannelsFetched
)

export const selectAllUPChannels: SelectChannelsExtended = createSelector(
  selectChannelsMap,
  (allChannelsMap) => Object.values(allChannelsMap)
)

// To recreate current logic for returning the promoted channels
// We first filter all channels that have the tag 'promo'
// Then we sort by top channels and alphabetical order
export const selectAllUPPromoChannels: SelectChannelsExtended = createSelector(
  selectAllUPChannels,
  (allChannels) =>
    allChannels.filter((channel) => channel.tags.includes('promo')).sort(promoUPChannelComparator)
)

// Generic channel selector by plan code substring
export const selectChannelsByPlanCode = (planCode: string) =>
  createSelector(
    selectAvailablePlans,
    (availablePlans) => availablePlans.find((plan) => plan.code.includes(planCode))?.channels || []
  )

// Generic channel selector by exact plan code
export const selectChannelsByExactPlanCode = (planCode: string) =>
  createSelector(
    selectAvailablePlans,
    (availablePlans) => availablePlans.find((plan) => plan.code === planCode)?.channels || []
  )

export const selectChannelsByAddOnCode = (addOnCode: string) =>
  createSelector(
    selectAvailableAddOns,
    (availableAddOns) => availableAddOns.find((addOn) => addOn.code === addOnCode)?.channels || []
  )

// Filter channels by tag
export const selectChannelsByTag = (channels, tag: string) =>
  createSelector(channels, (allChannels) =>
    allChannels.filter((channel) => channel?.tags?.includes(tag))
  )

// Most Channels
export const selectMostChannels = createSelector(
  selectAvailablePlans,
  (availablePlans) =>
    availablePlans
      .filter((plan) => plan?.channels)
      .sort((a, b) => (b.channels?.length || 0) - (a.channels?.length || 0))[0]?.channels || []
)

// Specific plan selectors using the generic functions
// Select Pro channels
export const selectProChannels = selectChannelsByExactPlanCode(basePlanCodes.us_pro)
// Select Elite channels
export const selectEliteChannels =
  selectChannelsByExactPlanCode(basePlanCodes.us_elite) || selectChannelsByPlanCode('us-elite-v')
export const selectDeluxeChannels = selectChannelsByPlanCode(basePlanCodes.us_deluxe)
// Select Latino channels
export const selectLatinoChannels =
  selectChannelsByExactPlanCode(basePlanCodes.us_latino) ||
  selectChannelsByPlanCode(basePlanCodes.us_latino)
// Select Canada channels
export const selectCanadaEntertainmentChannels =
  selectChannelsByExactPlanCode(basePlanCodes.canada_entertainment) ||
  selectChannelsByPlanCode(basePlanCodes.canada_entertainment)
// Select Canada Essentials Channels
export const selectCanadaSportsChannels =
  selectChannelsByExactPlanCode(basePlanCodes.canada_essentials) ||
  selectChannelsByPlanCode(basePlanCodes.canada_essentials)
// Select Canada Premium Channels
export const selectCanadaPremiumChannels =
  selectChannelsByExactPlanCode(basePlanCodes.canada_premium) ||
  selectChannelsByPlanCode(basePlanCodes.canada_premium)
// Select Spain channels
export const selectSpainChannels =
  selectChannelsByExactPlanCode(basePlanCodes.spain_basic) ||
  selectChannelsByPlanCode(basePlanCodes.spain_basic)
// Select Fubo Free Channels
export const selectFuboFreeChannels = selectChannelsByTag(
  selectMostChannels,
  basePlanCodes.fubo_free
)
// Select Zee Family Channels
export const selectZeeFamilyChannels = selectChannelsByPlanCode(basePlanCodes.us_zee_family_mo)
// Select Cricket International Channels
export const selectCricketInternationalChannels = selectChannelsByAddOnCode(addOnCodes.cricket)
