export const ANALYTICS_INIT_SUCCESS = 'ANALYTICS/ANALYTICS_INIT_SUCCESS'
export type InitAnalyticsAction = {
  type: 'ANALYTICS/ANALYTICS_INIT_SUCCESS'
}
export const ANALYTICS_READY = 'ANALYTICS/READY'
export type AnalyticsReadyAction = {
  type: 'ANALYTICS/READY'
}

export const UPDATE_ANALYTICS_PROPERTIES = 'ANALYTICS/UPDATE_ANALYTICS_PROPERTIES'
export type UpdateAnalyticsPropertiesAction = {
  type: 'ANALYTICS/UPDATE_ANALYTICS_PROPERTIES'
  payload: any
}

export const ANALYTICS_PROPERTIES_READY = 'ANALYTICS/PROPERTIES_READY'
export type AnalyticsPropertiesReadyAction = {
  type: 'ANALYTICS/PROPERTIES_READY'
}

export const PAGE_VIEW_SENT = 'ANALYTICS/PAGE_VIEW_SENT'
export type PageViewSentAction = {
  type: 'ANALYTICS/PAGE_VIEW_SENT'
}

export const UPDATE_PARAMETERS = 'ANALYTICS/UPDATE_PARAMETERS'
export type UpdateParametersAction = {
  type: 'ANALYTICS/UPDATE_PARAMETERS'
  payload: any
}

export const FIRE_COHORT_EVENT = 'ANALYTICS/FIRE_COHORT_EVENT'
export type FireCohortEventAction = {
  type: typeof FIRE_COHORT_EVENT
}

export const COHORT_EVENT_INITIALIZED = 'ANALYTICS/COHORT_EVENT_INITIALIZED'
export type CohortEventInitializedAction = {
  type: 'ANALYTICS/COHORT_EVENT_INITIALIZED'
}

export const DEVICE_READY = 'ANALYTICS/DEVICE_READY'
export type DeviceReadyAction = {
  type: 'ANALYTICS/DEVICE_READY'
  deviceId: string
  ftvCampaign: string | null | undefined
  query: any | null | undefined
}

export const PAGE_ANALYTICS_SENT = 'ANALYTICS/PAGE_ANALYTICS_SENT'
export type PageAnalyticsSuccessAction = {
  type: 'ANALYTICS/PAGE_ANALYTICS_SENT'
}

export const PAGE_ANALYTICS_FAILED = 'ANALYTICS/PAGE_ANALYTICS_FAILED'
export type PageAnalyticsFailedAction = {
  type: 'ANALYTICS/PAGE_ANALYTICS_FAILED'
}

export type AnalyticsAction =
  | InitAnalyticsAction
  | UpdateAnalyticsPropertiesAction
  | AnalyticsPropertiesReadyAction
  | PageViewSentAction
  | FireCohortEventAction
  | AnalyticsReadyAction
  | DeviceReadyAction
  | PageAnalyticsSuccessAction
  | PageAnalyticsFailedAction
