import { Action } from '../actionTypes.ts'
import { ZVA_LOADING, ZVA_INITIALIZED, ZVA_FAILED } from './actionTypes.ts'
export type ZvaState = {
  isLoading: boolean
  isInitialized: boolean
  isOpen: boolean
  isFailed: boolean
  error?: any
}
const defaultState: ZvaState = {
  isLoading: false,
  isInitialized: false,
  isOpen: false,
  isFailed: false,
}
export default function reducer(
  state: ZvaState | null | undefined = defaultState,
  action: Action
): ZvaState {
  switch (action.type) {
    case ZVA_LOADING: {
      return {
        ...state,
        isLoading: true,
        isFailed: false,
      }
    }
    case ZVA_INITIALIZED: {
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        isFailed: false,
      }
    }
    case ZVA_FAILED: {
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        error: action.error,
      }
    }
    default: {
      return state
    }
  }
}
