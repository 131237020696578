import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { images, promoCodes } from 'src/constants'

export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      offerCode={promoCodes.canada_entertainment_plus}
      ctaText="Get Access"
      poster={images.corus.url}
      // poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/CA_Ent_Superchannel_bundle_hero.jpg?w=2048&ch=width&auto=format,compress"
      title="Big savings on top TV channels"
      subtitle="SUPER CHANNEL AND YOUR FAVORITE STACKTV NETWORKS"
      plan="entertainment"
      networkSet="canada"
      networkHeadline="Watch the shows and movies you love"
      networkSubtitle="Live, on-demand and streaming on all your devices"
      customNetworks={[
        'global',
        'home_network',
        'flavour_network',
        'slice',
        'heartandhome',
        'fuse',
        'vault',
      ]}
      planCardOverride={'superChannelOffer'}
    />
  )
}
