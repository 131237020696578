import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { ratePlanCodes } from 'src/constants'
import styled from 'styled-components'
export default (props) => (
  <StyledContainer>
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      offerCode={ratePlanCodes.pro}
      addonCode="fubotv-basic-showtime"
      video={
        'https://assets.fubo.tv/marketing/lp/videos/compressed/Paramount+_Showtime_LP_Web1280x720_23s_V2.mp4'
      }
      videoMobile={
        'https://assets.fubo.tv/marketing/lp/videos/compressed/Paramount+_Showtime_LP_Mobile720x1280_23s_V2.mp4'
      }
      pageTitle="Enjoy Paramount+ with SHOWTIME® with Fubo"
      pageDescription="Stream all your favorite shows and series live and on-demand on Paramount+ with SHOWTIME with Fubo, along with ABC, CBS, FOX, NBC, ESPN & top channels without cable. DVR included. No contract, cancel anytime."
      title={
        <>
          Enjoy Paramount+ with SHOWTIME<sup>®</sup>
        </>
      }
      subtitle={<>WATCH ORIGINALS, MOVIES, SERIES & MORE.</>}
      tagline={'Stream them live & on demand from any device'}
      networkSet="entertainment"
      customNetworks={['showtime']}
    />
  </StyledContainer>
)

const StyledContainer = styled.div``
