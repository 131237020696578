import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { laDiscountPriceMonthly, laChannelCount } from 'src/constants/laPlanData'
export default (props) => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/tennis.mp4"
      title="Mira el U.S Open en vivo en Fubo"
      subtitle={`Accede ahora por solo $${laDiscountPriceMonthly} el primer mes`}
      tagline={`Más de ${laChannelCount} canales con deportes, noticias, novelas y entretenimiento en español.`}
      networkSet="entertainmentLatino"
    />
  )
}
