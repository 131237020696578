import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
import { laChannelCount } from 'src/constants/laPlanData'
import { useCheckFanduelRSN } from 'src/modules/hooks/useCheckFanduelRSN'
import { getUniqueLeagues } from 'src/modules/utils/league'

export default (props) => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()
  const isMLBIncluded = rsnData?.teams ? getUniqueLeagues(rsnData.teams).includes('mlb') : false

  let title = (
    <>
      Watch MLB live <span className="no-wrap">in Spanish</span>
    </>
  )
  let subtitle = `Enjoy baseball all season long and get access to ${laChannelCount}+ channels of live TV`
  if (isMLBIncluded) {
    title = 'Watch MLB live'
    subtitle = `Follow your local teams with FanDuel Network and get access to ${laChannelCount}+ channels of live TV`
  }

  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.mlbtv.videoUrl}
      title={title}
      subtitle={subtitle}
      networkSet="entertainmentLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/mlb-es/"
      fanduelRSNData={rsnData}
      pageDescription={
        'Stream FanDuel Sports Network, FOX Deportes, ESPN Deportes, beIN SPORTS and more with Fubo.'
      }
    />
  )
}
