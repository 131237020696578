import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { promoCodes } from 'src/constants/promoCodes'
import styled from 'styled-components'

export default (props) => {
  return (
    <StyledContainer>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Stream Live TV & Sports"
        subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
        showRedzone={false}
        offerCode={promoCodes.mymove}
        offerBanner={
          <>
            ✔ Special offer activated.
            <br />
            Enjoy $25 off your first 2 months from MyMove.
          </>
        }
        logo={'https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-mymove.png'}
        logoHeight={'80px'}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div``
