import React from 'react'
import USTemplateDefault from 'src/components/template/us'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/espnsportscenter.mp4"
    title="Watch SportsCenter on ESPN with Fubo"
    subtitle="SPORTS HEADLINES, HIGHLIGHTS, TOP 10 AND MORE"
    tagline="Stream it live from your TV, phone and other devices"
    ctaText="Start Watching"
    networkSet="sports"
  />
)
