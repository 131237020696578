import { DELETE_PARAM, ParamsAction, UPDATE_PARAMS } from './actionTypes'

export interface ParamsState {
  [key: string]: any
}

const initialState: ParamsState = {}

export const paramsReducer = (state = initialState, action: ParamsAction): ParamsState => {
  switch (action.type) {
    case UPDATE_PARAMS:
      return {
        ...state,
        ...action.payload,
      }
    case DELETE_PARAM:
      const { [action.payload]: _, ...rest } = state
      return rest
    default:
      return state
  }
}
