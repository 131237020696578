import React from 'react'
import GolfTemplate from 'src/components/page/golf'
import { promoCodes } from 'src/constants/promoCodes'
import styled from 'styled-components'

export default (props) => {
  return (
    <StyledContainer>
      <GolfTemplate
        location={props.location}
        poster={'https://assets.fubo.tv/marketing/lp/images/posters/golf_Web_v3.png'}
        posterMobile={'https://assets.fubo.tv/marketing/lp/images/posters/golf_Mobile_v3.jpg'}
        title="Fubo has the most golf"
        subtitle={'Stream 120+ live events from the PGA Tour & more'}
        offerBanner={
          <>
            ✔ Special offer activated. <br />
            Enjoy 14 days FREE from Troon.
          </>
        }
        offerCode={promoCodes.troon_14}
        logo={'https://assets.fubo.tv/marketing/lp/images/partners/logo-troon.png'}
        logoHeight={'80px'}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div``
