import { useSelector } from 'react-redux'
import Layout from 'src/components/common/Layout'
import { createUrl } from 'src/modules/utils/createUrl'
import { Link } from 'gatsby'
import { selectAnalyticsParameters } from 'src/modules/reducers/analytics'
import SEO from 'src/components/common/SEO'
import { LogoSyncContextProvider } from 'src/components/common/AnnotationContext'
import HeroSectionNew from 'src/components/common/HeroSectionNew'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import PlatformSection from 'src/components/common/PlatformSection'
import FAQSection from 'src/components/common/FAQSection'
import ChannelSection from 'src/components/common/ChannelSectionNew'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import FanduelPlanCards from 'src/components/common/FanduelPlanCards'

const FuboFreeTemplate = ({
  lang = 'en',
  market = 'us',
  noRedirect,
  miceText,
  // Offer Code
  offerCode,
  offerBanner,
  // Addon Code
  addonCode,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  logo,
  logoHeight,
  title,
  subtitle,
  tagline,
  ctaText,
  video,
  videoMobile,
  staticAnnotation,
  annotations,
  poster,
  posterMobile,
  noteLink,
  heroCtaNote,
  // Network Section
  networkHeadline,
  networkSubtitle,
  networkSet,
  customNetworks,
  // Others
  team,
  // Show offer input for unique codes
  hasOfferInput,
  // AB Test - Video vs Logo Soup
  noVideo = false,
  // Remove references to cable
  removeCable,
  // Charter Plan Cards
  hasCharterPlanCards,

  // Disable Free Trial
  disableFreeTrial,

  // CA Redirect URL
  caRedirectUrl,
  // SVOD Rate Plan
  ratePlan,
  additionalFAQs,
  hasFanduelPlanCards,
  fanduelRSNData,
  redsOffer,
}) => {
  const modifiedCtaText =
    ctaText || (disableFreeTrial && (lang === 'es' ? 'Acceder Ahora' : 'Start Watching'))
  const modifiedNetworkSubtitle =
    networkSubtitle ||
    (disableFreeTrial && (lang === 'es' ? 'Cancela cuando quieras.' : 'Cancel online anytime.'))

  let metaDescription =
    pageDescription ||
    'Stream live TV with ABC, CBS, FOX, NBC, ESPN & top channels without cable. DVR included. No contract, cancel anytime.'

  if (team) {
    metaDescription = `Stream ${team} live online with ABC, CBS, FOX, NBC, and ESPN on Fubo. No contract is required - try it for free and cancel anytime.`
  }

  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const handleLocaleSwitch = () => {
    fireUIInteractionEvent(
      {
        element: noteUrl,
        component: 'lp_locale_switch_clicked',
      },
      stateForProtobuf
    )
  }

  const originalParams = useSelector(selectAnalyticsParameters)
  let noteUrl
  if (noteLink) {
    noteUrl = createUrl(noteLink, originalParams)
  }

  let noteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        To learn more in English, <span>click here</span>.
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Para traducir a español, <span>haga clic aquí</span>.
      </Link>
    )

  let charterNoteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        English
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Español
      </Link>
    )

  const hasAnchor = hasFanduelPlanCards ? '#select-plan' : null

  const hasOffer = offerBanner || offerCode
  if (!hasOffer && fanduelRSNData?.offerCode) {
    offerCode = fanduelRSNData.offerCode
  }

  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      addonCode={addonCode}
      ctaHrefOverride={hasAnchor}
      noteText={noteUrl && hasCharterPlanCards && charterNoteText}
      noRedirect={noRedirect}
      miceText={miceText}
      caRedirectUrl={caRedirectUrl}
    >
      <SEO lang={lang} market={market} title={pageTitle || title} description={metaDescription} />
      <LogoSyncContextProvider annotations={annotations}>
        <HeroSectionNew
          logo={logo}
          logoHeight={logoHeight}
          lang={lang}
          market={market}
          heroBgVideo={video}
          heroBgVideoMobile={videoMobile}
          title={title}
          subtitle={subtitle}
          tagline={tagline}
          ctaText={modifiedCtaText}
          staticAnnotation={staticAnnotation}
          posterImg={poster}
          posterImgMobile={posterMobile}
          noteText={heroCtaNote || (noteUrl && !hasCharterPlanCards && noteText)}
          hasOfferInput={hasOfferInput}
          noVideo={noVideo}
          ctaHrefOverride={hasAnchor}
          offerCode={offerCode}
          addonCode={addonCode}
          charterNoteText={noteUrl && hasCharterPlanCards && charterNoteText}
          disableFreeTrial={disableFreeTrial}
        />
      </LogoSyncContextProvider>

      {hasFanduelPlanCards && (
        <FanduelPlanCards ctaText={ctaText} fanduelRSNData={fanduelRSNData} redsOffer={redsOffer} />
      )}

      <NetworkSection
        lang={lang}
        market={market}
        networkHeadline={networkHeadline}
        headline={`Stream 170+ live TV channels with the Fubo Free plan`}
        subtitle={'No contract. No cable required.'}
        networkSet={'svod'}
        customNetworks={customNetworks}
        ctaText={modifiedCtaText}
        hasOfferInput={hasOfferInput}
        ctaHrefOverride={hasAnchor}
        maxCount={12}
        disableFreeTrial={disableFreeTrial}
      />
      <ChannelSection
        lang={lang}
        market={market}
        title={'Enjoy the sports, shows, news and movies you love'}
        ratePlan={ratePlan}
        fuboFree={true}
      />

      <DealSection
        lang={lang}
        market={'svod'}
        savingsText={
          removeCable &&
          (lang === 'es'
            ? 'Fubo tiene los deportes en vivo que quieres a un precio que te encantará.'
            : 'Fubo has the live sports you want at a price you’ll love.')
        }
      />

      <PlatformSection
        lang={lang}
        market={market}
        ctaText={modifiedCtaText}
        hasOfferInput={hasOfferInput}
        ctaHrefOverride={hasAnchor}
        title={'Stream live and on-demand from anywhere'}
        platformType="svod"
        disableFreeTrial={disableFreeTrial}
      />

      <FAQSection
        lang={lang}
        market={market}
        team={team}
        removeCable={removeCable}
        disableFreeTrial={disableFreeTrial}
        additionalFAQs={additionalFAQs}
        isSvod={true}
      />
    </Layout>
  )
}

export default FuboFreeTemplate
