import React from 'react'
import { FuboLogo } from 'src/components/icons/FuboLogo'
import CompareButton from 'src/components/template/compare/components/modules/CompareButton'
import { typography } from 'src/components/template/compare/constants/typography'
import styled from 'styled-components'

const CompareCard = ({
  hasLogo,
  hasButton,
  competitor,
  ctaPosition = 'top',
  ctaText,
  children,
  logoText,
  ...props
}) => {
  return (
    <StyledCompareCard {...props} competitor={competitor}>
      {hasLogo && (
        <div className="logo">
          {competitor ? (
            <div className="competitor">{competitor}</div>
          ) : (
            <FuboLogo width={70} height={29} />
          )}
          {logoText && <div className="logo-text">{logoText}</div>}
        </div>
      )}
      {children}
      {hasButton && (
        <div className={`cta ${ctaPosition} ${competitor && 'hidden'}`}>
          <CompareButton label={ctaText} />
        </div>
      )}
    </StyledCompareCard>
  )
}

export default CompareCard

const StyledCompareCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 30px 40px;
  gap: 33px;
  border: ${(props) => (props.competitor ? 'none' : '0.828795px solid #e5e6e6')};
  border-radius: 11.6031px;
  background: ${(props) => (props.competitor ? 'none' : '#19273c')};

  > div {
    width: 100%;
    order: 3;
  }

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    order: 1;

    .competitor {
      font-style: normal;
      font-weight: 800;
      font-size: var(--logo-height);
      line-height: 1;
      /* identical to box height, or 159% */

      color: #ffffff;
    }
    svg {
      height: var(--logo-height);
    }
  }

  .cta {
    color: #ffffff;
    width: auto;
    &.hidden {
      visibility: hidden;
    }
    &.top {
      order: 2;
    }
    &.bottom {
      order: 4;
    }
  }

  .logo-text {
    ${typography.heading.md}
    color: #fff
  }
`
