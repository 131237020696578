import React from 'react'
import SpainTemplateDefault from 'src/components/template/es'

const carouselImages = [
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/6_mom.jpg',
    alt: 'Mom',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/7_mtv-ema.jpg',
    alt: 'MTV EMA',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/8_roast-battle.jpg',
    alt: 'Roast Battle',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/9_south-park.jpg',
    alt: 'South Park',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/1_verguenza-ajena.png',
    alt: 'Verguenza Ajena',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/2_catfish.png',
    alt: 'Catfish',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/3_jersey-shore.jpg',
    alt: 'Jersey Shore',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/4_teen-mom.jpg',
    alt: 'Teen Mom',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/keyart/5_acapulco_shore.png',
    alt: 'Acapulco Shore',
  },
]

const annotations = [
  {
    startSecs: '0',
    endSecs: '2.4',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/annotations/VerguenzaAjena.png',
  },
  {
    startSecs: '2.4',
    endSecs: '6.7',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/annotations/catfish.png',
  },
  {
    startSecs: '6.7',
    endSecs: '11.4',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/annotations/laqueseavercina.png',
  },
  {
    startSecs: '11.4',
    endSecs: '17.5',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/annotations/mom-cc.png',
  },
  {
    startSecs: '17.5',
    endSecs: '22.3',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/annotations/southpark.png',
  },
  {
    startSecs: '22.3',
    endSecs: '25.6',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/annotations/roastbattle.png',
  },
  {
    startSecs: '25.6',
    endSecs: '29.5',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/ve-mtv-comedy-central/annotations/mtv-ema.png',
  },
]

const pageTitle = 'Ve MTV y Comedy Central en Directo'
const pageDescription =
  'Disfruta de la mejor TV online. Las mejores series, dibujos infantiles de Nickelodeon y Nick Jr., realities de MTV, programas de entretenimiento para toda la familia y muchos más. En cualquier dispositivo: Ordenador, iOS, Android, Tablet…'

export default (props) => {
  return (
    <SpainTemplateDefault
      location={props.location}
      lang="es"
      market="spain"
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/spain_mtv_cc.mp4"
      title="DESDE SOLO 2,99€/mes"
      subtitle="LOS MEJORES CANALES DE COMEDIA, REALITIES Y PELÍCULAS"
      tagline="Disfrútalos en todos tus dispositivos, en directo y bajo demanda"
      annotations={annotations}
      carouselTitle="DESCUBRE LO MÁS DESTACADO DE CADA CANAL"
      carouselImages={carouselImages}
    />
  )
}
