import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'

export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/MSG_Generic_LP_30s.mp4"
      staticAnnotation={networkLogos.msg}
      title="Watch MSG Networks live"
      subtitle="SEE THE KNICKS, RANGERS, ISLANDERS, DEVILS, SABRES & MORE"
      tagline="Stream live games and shows from any device. Regional restrictions apply."
      networkSet="sports"
      customNetworks={['msg']}
      offerBanner={
        <>
          ✔ Special offer applied! <br />
          Save $30 on your first month of Fubo
        </>
      }
    />
  )
}
