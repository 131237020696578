import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { laChannelCount, laDiscountPriceMonthly } from 'src/constants/laPlanData'
import { useCheckFanduelRSN } from 'src/modules/hooks/useCheckFanduelRSN'
import { getUniqueLeagues } from 'src/modules/utils/league'

export default (props) => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()
  const isMLBIncluded = rsnData?.teams ? getUniqueLeagues(rsnData.teams).includes('mlb') : false

  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      //REMOVED: univision_removed video and annotations
      // video={videos.latino.videoUrl}
      // annotations={videos.latino.annotations}
      //REPLACED: univision_removed poster images
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Web.jpg"
      posterMobile="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Mobile.jpg"
      title="Save big on live TV for your family"
      subtitle={
        <>
          {laChannelCount}+ channels of sports, news, novelas, and{' '}
          <span className="desktop-line-break">
            entertainment in Spanish for only ${laDiscountPriceMonthly} the first month.
          </span>{' '}
        </>
      }
      networkSet="entertainmentLatino"
      noteLink="/latino-es/"
      showPlusBeisbol={isMLBIncluded}
      disablePlanCards={isMLBIncluded}
      fanduelRSNData={rsnData}
      forceAnchorLink={isMLBIncluded}
    />
  )
}
