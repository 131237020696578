import React from 'react'
import FuboFreeTemplate from 'src/components/template/fubofree'
import USTemplateDefault from 'src/components/template/us'
import { fanduelSportsNetworkFAQs } from 'src/constants/faqs'
import { useCheckFanduelRSN } from 'src/modules/hooks/useCheckFanduelRSN'

export default (props) => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()

  const commonProps = {
    location: props.location,
    lang: 'en',
    market: 'us',
    poster: 'https://assets-imgx.fubo.tv/marketing/lp/images/posters/fdsn_poster-1.jpg',
    title: 'Watch FanDuel Sports with Fubo',
    subtitle: 'STREAM LIVE GAMES AND LOCAL TEAM COVERAGE',
    tagline: 'Anytime. Anywhere. From any device. Regional restrictions apply.',
    networkSet: 'sports',
    customNetworks: ['fanduelsports'],
  }

  return showFDPlanCards ? (
    <FuboFreeTemplate
      {...commonProps}
      hasFanduelPlanCards={showFDPlanCards}
      fanduelRSNData={rsnData}
      additionalFAQs={fanduelSportsNetworkFAQs}
    />
  ) : (
    <USTemplateDefault
      {...commonProps}
      hasFanduelPlanCards={showFDPlanCards}
      fanduelRSNData={rsnData}
      additionalFAQs={fanduelSportsNetworkFAQs}
    />
  )
}
