import React from 'react'
import { videos } from 'src/constants/videos'
import { ratePlanCodes } from 'src/constants'
import FuboFreeTemplate from 'src/components/template/fubofree'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectMLBTVTrialFlag } from 'src/modules/reducers/cohorts'
export default (props) => {
  const isMLBTVTrial = useSelector(selectMLBTVTrialFlag)
  const offerCode = isMLBTVTrial ? ratePlanCodes.mlbtv_mo_w_trial : ratePlanCodes.mlbtv_mo
  return (
    <StyledContainer>
      <FuboFreeTemplate
        location={props.location}
        lang="en"
        market="us"
        pageDescription="Watch every out-of-market game live or on demand. Plus get 170+ live TV channels for free, Unlimited Cloud DVR and more."
        offerCode={offerCode}
        title="Get MLB.TV with Fubo"
        subtitle="SEE EVERY OUT-OF-MARKET GAME. ONLY $29.99/MO."
        video={videos.mlbtv.videoUrl}
        staticAnnotation={videos.mlbtv.staticAnnotation}
        heroCtaNote={
          <>
            Blackout and other restrictions apply. Major League Baseball trademarks and copyrights
            are used with permission of Major League Baseball.
          </>
        }
        networkSet="baseball"
        league="mlb"
        showRSN={true}
        caRedirectUrl="/ca/mlb-tv-ca/"
        disableFreeTrial={true}
        additionalFAQs={additionalFAQs}
        customNetworks={['mlbtv']}
        disableFreeTrial={isMLBTVTrial ? false : true}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .content-container .hero-note {
    font-size: 0.8rem;
  }
`

const additionalFAQs = [
  {
    question: 'Can I watch MLB games on Fubo?',
    answer:
      'Yes, you can watch MLB games on Fubo, including live coverage of teams like the Los Angeles Dodgers, Atlanta Braves, Philadelphia Phillies, New York Yankees and Arizona Diamondbacks.',
  },
  {
    question: 'Can I watch out-of-market baseball games on Fubo?',
    answer:
      'Yes, Fubo provides access to out-of-market baseball games through MLB.TV, allowing you to catch games that are not available in your local area.',
  },
  {
    question: "How can I find Shohei Ohtani's games on Fubo?",
    answer:
      "To find Shohei Ohtani's games on Fubo, you can search for the Los Angeles Angels' schedule within the Sports section, where you can view live broadcasts and upcoming matchups with the Los Angeles Dodgers.",
  },
  {
    question: 'Can I watch MLB commentary and analysis on Fubo?',
    answer:
      'Yes, MLB.TV with Fubo includes MLB Network as well as MLB Big Inning for nonstop MLB highlights, commentary and analysis.',
  },
  {
    question: 'How do I find specific MLB teams on Fubo?',
    answer:
      "MLB.TV includes every out-of-market MLB game. If you live outside of your team's local market, you will have access to their games. To find them, just search for their name or scroll the channel guide for your team's channel.",
  },
  {
    question: 'Does Fubo offer live coverage of MLB events?',
    answer:
      "Yes, Fubo provides live coverage of MLB events, including regular season games, playoffs and special events, ensuring you don't miss any action.",
  },
  {
    question: 'Can I watch highlights of MLB games on Fubo?',
    answer:
      'Yes, Fubo includes highlights of MLB games, allowing you to catch up on key moments and performances, including standout plays from stars like Shohei Ohtani.',
  },
]
