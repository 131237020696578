import React from 'react'
import USTemplateDefault from 'src/components/template/us'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/GreysAnatomy2020.mp4"
    title="Watch Grey's Anatomy on ABC"
    subtitle="NEW EPISODES ON THURSDAYS AT 9 PM ET"
    tagline="Stream it live from your TV, phone and other devices"
  />
)
