import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { videos } from 'src/constants/videos'
export default (props) => {
  return (
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      video={videos.canada_epl.videoUrl}
      title="Watch Premier League soccer live"
      subtitle="SEE EVERY MATCH ALL SEASON LONG"
      tagline="Streaming exclusively on Fubo"
      networkSet="canada"
      overrideABTest="control"
    />
  )
}
