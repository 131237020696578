import React from 'react'
import SpainTemplateDefault from 'src/components/template/es'

const carouselImages = [
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/ChicagoPD_566x764.png',
    alt: 'Chicago PD',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/LeyOrdenUVE_566x764.png',
    alt: 'Ley y orden UVE',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Magnum_566x764.png',
    alt: 'Magnum',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/InspectoraMarleau_566x764.png',
    alt: 'Inspectora Marleau',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Balthazar_566x764.png',
    alt: 'Balthazar',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Arrow_566x764.png',
    alt: 'Arrow',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Limetown_566x764.png',
    alt: 'Limetown',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Curfew_566x764.png',
    alt: 'Curfew',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Devils_566x764.png',
    alt: 'Devils',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Tin_Star_566x764.png',
    alt: 'Tin Star',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Yourhonor_566x764.jpg',
    alt: 'Your Honor',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Animal_Kingdom_566x764.jpg',
    alt: 'Animal Kingdom',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/CityonaHill_566x764.jpg',
    alt: 'City on a Hill',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Billions_566x764.jpg',
    alt: 'Billions',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/Blacklist_566x764.jpg',
    alt: 'Blacklist',
  },
]

const annotations = [
  {
    startSecs: '0',
    endSecs: '6.2',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/annotations/ChicagoPD_logo.png',
  },
  {
    startSecs: '6.3',
    endSecs: '14.2',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/annotations/Magnum_logo.png',
  },
  {
    startSecs: '14.3',
    endSecs: '19.2',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/annotations/LaForense_logo.png',
  },
  {
    startSecs: '19.3',
    endSecs: '25.1',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/annotations/Interrogation_logo.png',
  },
  {
    startSecs: '25.2',
    endSecs: '40.0',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/calle-13/annotations/Ley&Orden_logo.png',
  },
]

const pageTitle = 'SERIES INTERNACIONALES DE ACCIÓN Y FICCIÓN'
const pageDescription =
  'Ver Movistar Series en directo y bajo demanda. Fubo es un servicio de TV por Internet que ofrece canales de televisión con programas de entretenimiento en directo y los eventos deportivos más prestigiosos del mundo. El servicio ofrece acceso a canales nacionales y locales a través de TV, tablets, dispositivos móviles y portátiles.'

export default (props) => {
  return (
    <SpainTemplateDefault
      location={props.location}
      lang="es"
      market="spain"
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/Calle13_LP_30s.mp4"
      title="CALLE 13 EN DIRECTO Y A LA CARTA"
      subtitle="DESDE SOLO 3,99€/MES"
      annotations={annotations}
      carouselTitle="BUSCA TUS SERIES FAVORITAS"
      carouselImages={carouselImages}
    />
  )
}
