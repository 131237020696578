import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'

export default (props) => {
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.seasonal_us.videoUrl}
      videoMobile={videos.seasonal_us.videoMobileUrl}
      annotations={videos.seasonal_us.annotations}
      title="Stream Live TV & Sports"
      subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
      tagline="Watch and DVR the channels you love. No contracts."
      showRSN={true}
      showMiniplayer="collapsed"
    />
  )
}
