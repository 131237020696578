import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'

export default (props) => {
  return (
    <LiveTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      callsign="NFLHD"
      title="Watch NFL Network Live with Fubo"
      networkSet="nfl"
      // Remove cable references
      removeCable={true}
      networkName="NFL Network"
      matchtickerSportId="111"
      matchtickerLeagueName="NFL"
      matchtickerLeagueId="191277"
      showRedzone={false}
      sportId={sportIds.Football}
      leagueId={leagueIds.NFL}
    />
  )
}
