import { networkLogos } from 'src/constants/networkLogos'

export const rsnData = {
  'Altitude Sports': {
    name: 'Altitude Sports',
    displayName: 'Altitude Sports',
    bannerName: 'Altitude',
    logo: networkLogos.altitude.logoWhite,
    livePreview: '/live/preview/altitude-sports/',
  },
  'Arizona Diamondbacks (Diamondbacks DMA)': {
    name: '',
    displayName: '',
    logo: '',
  },
  'Chicago Sports Network': {
    name: 'Chicago Sports Network',
    displayName: 'CHSN',
    bannerName: 'Chicago Sports Network',
    logo: networkLogos.chsn.logoWhite,
  },
  'Colorado Rockies (Rockies DMA)': {
    name: '',
    displayName: '',
    logo: '',
  },
  'FanDuel Sports Network': {
    name: 'FanDuel Sports Network',
    displayName: 'FanDuel Sports Network',
    logo: networkLogos.fanduelsports.logoWhite,
  },
  'Gulf Coast Sports & Entertainment Network': {
    name: 'Gulf Coast Sports & Entertainment Network',
    displayName: 'Gulf Coast Sports & Entertainment Network',
    logo: networkLogos.gulfcoastsports.logoWhite,
  },
  'KFAA (Dallas Mavericks)': {
    name: 'KFAA',
    displayName: 'KFAA',
    logo: networkLogos.kfaa.logoWhite,
  },
  'KJZZ (Utah Jazz)': {
    name: 'KJZZ',
    displayName: 'KJZZ',
    logo: networkLogos.kjzz.logoWhite,
  },
  'KMCC (Vegas Golden Knights)': {
    name: 'KMCC',
    displayName: 'KMCC',
    logo: networkLogos.kmcc.logoWhite,
  },
  'KONG (Seattle Kraken)': {
    name: 'KONG',
    displayName: 'KONG',
    logo: networkLogos.kong.logoWhite,
  },
  'KPHE/KTVK (Phoenix Suns)': {
    name: 'KPHE/KTVK',
    displayName: 'KPHE/KTVK',
    logo: networkLogos.arizonasfamily.logoWhite,
  },
  'KTVD (Denver Nuggets & Colorado Avalanche)': {
    name: 'KTVD',
    displayName: 'KTVD',
    logo: '',
  },
  'KUPX (Utah Hockey Club)': {
    name: 'KUPX',
    displayName: 'KUPX',
    logo: networkLogos.kupx.logoWhite,
  },
  'Marquee Sports Network': {
    name: 'Marquee Sports Network',
    displayName: 'Marquee Sports Network',
    logo: networkLogos.marquee.logoWhite,
    livePreview: '/live/preview/marquee-sports-network/',
  },
  MASN: {
    name: 'MASN',
    displayName: 'MASN',
    logo: networkLogos.masn.logoWhite,
    livePreview: '/live/preview/masn/',
  },
  'Monumental Sports Network': {
    name: 'Monumental Sports Network',
    displayName: 'Monumental Sports Network',
    logo: '',
  },
  MSG: {
    name: 'MSG',
    displayName: 'MSG',
    logo: networkLogos.msg.logoWhite,
    livePreview: '/live/preview/msg/',
  },
  'NBCS BA': {
    name: 'NBC Sports Bay Area',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcsbayarea.logoWhite,
  },
  'NBCS Boston': {
    name: 'NBC Sports Boston',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcsboston.logoWhite,
  },
  'NBCS CA': {
    name: 'NBC Sports CA',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcscalifornia.logoWhite,
  },
  'NBCS Philly': {
    name: 'NBC Sports Philly',
    displayName: 'NBCSN',
    bannerName: 'NBC Sports',
    logo: networkLogos.nbcsphiladelphia.logoWhite,
  },
  NESN: {
    name: 'NESN',
    displayName: 'NESN',
    logo: networkLogos.nesn.logoWhite,
    livePreview: '/live/preview/nesn/',
  },
  'Rip City Network': {
    name: 'Rip City Network',
    displayName: 'Rip City Network',
    logo: networkLogos.ripcity.logoWhite,
  },
  'Root Sports Northwest': {
    name: 'Root Sports',
    displayName: 'Root Sports',
    logo: networkLogos.rootsports.logoWhite,
  },
  'San Diego Padres (Padres DMA)': {
    name: '',
    displayName: '',
    logo: '',
  },
  'Space City Home Network': {
    name: 'Space City Home Network',
    displayName: 'Space City Home Network',
    logo: networkLogos.spacecity.logoWhite,
    livePreview: '/live/preview/space-city-home-network/',
  },
  'SportsNet Pittsburgh': {
    name: 'SportsNet Pittsburgh',
    displayName: 'SportsNet Pittsburgh',
    logo: networkLogos.sportsnetpittsburgh.logoWhite,
    livePreview: '/live/preview/sportsnet-pittsburgh/',
  },
  'WSFL (Florida Panthers)': {
    name: '',
    displayName: '',
    logo: '',
  },
  'YES Network': {
    name: 'YES Network',
    displayName: 'YES Network',
    logo: networkLogos.yesnetwork.logoWhite,
    livePreview: '/live/preview/yes-network/',
  },
}
