import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'
export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      video={videos.bally_panthers.videoUrl}
      staticAnnotation={videos.bally_panthers.staticAnnotation}
      pageTitle="Watch Florida Panthers hockey"
      title={<>Watch Florida Panthers hockey</>}
      subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
      tagline="Stream from any device. No cable required."
      networkSet="hockey"
      customNetworks={['scripps_sports']}
      logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-florida-panthers.png?ch=width&auto=format,compress"
      logoHeight="100px"
      sportId={sportIds.Hockey}
      leagueId={leagueIds.NHL}
      teamId={teamIds['Florida Panthers']}
    />
  )
}
