import React from 'react'
import AdcashTemplate from 'src/components/template/adcash'
import { useSelector } from 'react-redux'
import { selectAdcashCtaUrlFlag } from 'src/modules/reducers/cohorts'
import { ratePlanCodes } from 'src/constants'

export default (props) => {
  const isAdcash = useSelector(selectAdcashCtaUrlFlag)

  return (
    <AdcashTemplate
      {...props}
      title={
        <>
          Premier League <span className="desktop-line-break">is better with Fubo</span>
        </>
      }
      ctaURL={
        isAdcash
          ? `https://www.fubo.tv/signup?offer=${ratePlanCodes.canada_sports_mo}`
          : '/stream/ca/epl/'
      }
      image={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/Fubo-4k-web-v2.png'}
      imageMobile={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/Fubo-4k-mobile-v2.png'}
    />
  )
}
