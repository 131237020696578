import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import headerBgImg from 'src/assets/images/stars.png'
import { networkSets } from 'src/constants/networkSets'
import { networkLogos } from 'src/constants/networkLogos'
import {
  defaultCTAURI,
  USDefaultCtaTextShort,
  LatinoDefaultCtaText_ES,
  LatinoDefaultCtaTextShort,
  USDefaultCtaText_ES,
  CanadaDefaultCtaTextShort,
} from 'src/modules/utils/constants'
import { selectCTAParams } from 'src/modules/reducers/params'
import { useSelector } from 'react-redux'
import { createUrl } from 'src/modules/utils/createUrl'
import { Link } from 'react-scroll'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import CTAButton from 'src/components/common/CTAButton'
import LazyImage from '../LazyLoad/LazyImage'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { laChannelCount } from 'src/constants/laPlanData'

const handleNetworks = (networkSet, customNetworks) => {
  let selectedNetworks = []
  if (networkSet) {
    // find the passed network set and make a copy
    selectedNetworks = [...networkSets[networkSet]]
  } else {
    // or else, use the default set
    selectedNetworks = [...networkSets.default]
  }

  if (customNetworks?.length) {
    const addedNetworks = customNetworks
    const additionalNetworks = []
    addedNetworks.forEach((addition) => {
      const networkComponent = networkLogos[addition]
      additionalNetworks.push(networkComponent)
      // check if the custom network already exists in the chosen network set, if so, remove it
      const index = selectedNetworks.findIndex((network) => network === networkComponent)
      if (index > -1) {
        selectedNetworks.splice(index, 1)
      }
    })
    // finally, add the custom networks to the start of the set
    selectedNetworks.unshift(...additionalNetworks)
  }
  return selectedNetworks
}

const NetworkSection = ({
  ctaHrefOverride,
  ctaText,
  customNetworks,
  networkSet,
  headline,
  subtitle,
  maxCount,
  lang = 'en',
  market = 'us',
  noBorder,
  onClick,
  hasOfferInput,
}) => {
  // get ctaParams from redux
  const parsedParams = useSelector(selectCTAParams)
  const stateForProtobuf = useSelector(selectStateForProtobuf)

  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  let defaultNetworks = []

  switch (market) {
    case 'us':
      defaultNetworks = handleNetworks('default', customNetworks)
      break
    case 'canada':
      defaultNetworks = handleNetworks('canadaEssentials', customNetworks)
      break
    case 'latino':
      defaultNetworks = handleNetworks('entertainmentLatino', customNetworks)
      break
    default:
      defaultNetworks = []
  }

  const [networks, setNetworks] = React.useState(defaultNetworks)

  React.useEffect(() => {
    setNetworks(handleNetworks(networkSet, customNetworks))
  }, [networkSet, customNetworks])

  const copyUS = {
    en: {
      headline: 'Stream Live TV from 200+ Channels You Love',
      subtitle: 'Start for free. Cancel online anytime.',
      ctaText: USDefaultCtaTextShort,
    },
    es: {
      headline: 'Más de 200 canales en vivo de la TV que amas ',
      subtitle: 'Prueba gratis. Cancela cuando quieras.',
      ctaText: USDefaultCtaText_ES,
    },
  }

  const copyCA = {
    headline: 'Stream the sports and entertainment you love.',
    subtitle: 'Watch the Premier League, Serie A, Coppa Italia & so much more.',
    ctaText: CanadaDefaultCtaTextShort,
  }

  const copyLatino = {
    en: {
      headline: 'The Best of Sports from the Channels You Love',
      subtitle: 'Cancel online anytime.',
      ctaText: LatinoDefaultCtaTextShort,
    },
    es: {
      headline: `Disfruta más de ${laChannelCount} canales de TV que amas`,
      subtitle: 'Cancela cuando quieras',
      ctaText: LatinoDefaultCtaText_ES,
    },
  }

  let copy = copyUS[lang]

  switch (market) {
    case 'canada':
      copy = copyCA
      break
    case 'latino':
      copy = copyLatino[lang]
      break
    default:
      copy = copyUS[lang]
  }

  const handleOnClick = (e) => {
    fireUIInteractionEvent(
      {
        element: copy.ctaText,
        component: 'network_section_cta_clicked',
      },
      stateForProtobuf
    )
  }

  const handleMoreClick = (e) => {
    fireUIInteractionEvent(
      {
        element: 'More',
        component: 'network_section_more_cta_clicked',
      },
      stateForProtobuf
    )
  }

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'network')

  return (
    <SectionElement noBorder={noBorder}>
      <SectionHeader>
        <h2>{headline || copy.headline}</h2>
        <h3>{subtitle || copy.subtitle}</h3>
      </SectionHeader>

      <SectionContent>
        <ListElement>
          {networks.length &&
            networks.slice(0, maxCount || 7).map((network, i) => {
              const imgSrc = network.logoUrl + '?w=200&ch=width&auto=format,compress'
              return (
                <li key={i}>
                  <ListItem>
                    <LazyImage src={imgSrc} alt={network.alt} />
                  </ListItem>
                </li>
              )
            })}
          <ListItem as="div">
            <Link
              to="channels-section"
              smooth={true}
              duration={500}
              onClick={handleMoreClick}
              className="more-text"
            >
              {lang === 'es' ? 'Y MÁS' : '+ MORE'}
            </Link>
          </ListItem>
        </ListElement>
        <ButtonWrapper>
          <CTAButton
            link={hasOfferInput ? '#' : ctaHrefOverride || ctaURL}
            onClick={onClick || handleOnClick}
          >
            {ctaText || copy.ctaText}
          </CTAButton>
        </ButtonWrapper>
      </SectionContent>
    </SectionElement>
  )
}

export default NetworkSection

NetworkSection.propTypes = {
  lang: PropTypes.oneOf(['en', 'es']),
  plan: PropTypes.oneOf(['us', 'canada', 'latino']),
}

const SectionElement = styled.section`
  background: #fafafa;
  border-top: ${(props) => (props.noBorder ? 'none' : '5px solid #fa4616')};
`

const SectionHeader = styled.div`
  background-color: #242526;
  padding: 65px 3% 45px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  text-align: center;
  background: no-repeat 50% 30px url(${headerBgImg});
  background-color: #242526;

  h2 {
    color: #fff;
    font-weight: 700;
    font-weight: 700;
    font-size: 24.32px;
    line-height: 1.3em;
    margin-bottom: 5px;

    @media (max-width: 600px) {
      max-width: 285px;
      margin: 5px auto;
    }
  }

  h3 {
    color: rgba(255, 255, 255, 0.7);
    font-size: 22.4px;
    line-height: 1.5em;
    font-weight: 400;
  }
`

const SectionContent = styled.div`
  max-width: 1000px;
  margin: -20px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: #fafafa;

  @media screen and (min-width: 320px) and (max-width: 736px) {
    padding: 0 5%;
  }

  @media (max-width: 600px) {
    padding: 0 2%;
  }
`

const ListElement = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 7px;
`

const ListItem = styled.span`
  width: 180px;
  height: 109px;
  background: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  display: inline-block;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 40px;
  box-sizing: border-box;

  @media (max-width: 400px) {
    width: 142px;
  }

  @media (max-width: 605px) {
    width: 160px;
    height: 65px;
    padding: 14px 28px;
  }

  @media (min-width: 768px) {
    padding: 25px 50px;
  }

  @media (min-width: 991px) {
    width: 219px;
  }

  img {
    object-fit: contain;
    ${
      '' /* position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto; */
    }
    width: 100%;
    height: 100%;
  }

  .more-text {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
    white-space: nowrap;
  }
`

const ButtonWrapper = styled.div`
  margin: 30px auto;
`
