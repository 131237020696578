import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PlanCardLatino from 'src/components/common/PlanCardLatino'
import styled from 'styled-components'
import { selectLatinoChannels } from 'src/modules/reducers/products/selectors'
import { laPlanData } from 'src/constants/laPlanData'
import { getCtaText } from 'src/modules/utils/language/buttons'
import { ratePlanCodes } from 'src/constants'
import { teamData } from 'src/constants/teamData'
import { getUniqueLeagues } from 'src/modules/utils/league'
import { fireUIRenderEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { selectStacFlagsReady } from 'src/modules/reducers/cohorts'

const LAPlanCards = ({
  lang,
  ctaText,
  offerCode,
  discountedPrice,
  cardFootnote,
  market,
  disableFreeTrial,
  fanduelRSNData,
}) => {
  const [isEventSent, setIsEventSent] = useState(false)
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const cohortingReady = useSelector(selectStacFlagsReady)

  const title =
    lang === 'es'
      ? 'Disfruta tus canales favoritos en español a un gran precio'
      : 'Enjoy your favorite channels in Spanish for a great price'

  const channels = useSelector(selectLatinoChannels)

  const isMLBIncluded = useMemo(
    () => (fanduelRSNData?.teams ? getUniqueLeagues(fanduelRSNData.teams).includes('mlb') : false),
    [fanduelRSNData?.teams]
  )
  let reorderedFanduelRSNData = null
  if (fanduelRSNData?.teams?.length) {
    const reorderedTeams = [...fanduelRSNData.teams]
    reorderedTeams.sort((a, b) => {
      const aIsMLB = teamData[a]?.league === 'mlb'
      const bIsMLB = teamData[b]?.league === 'mlb'
      if (aIsMLB && !bIsMLB) return -1
      if (!aIsMLB && bIsMLB) return 1
      return 0
    })
    reorderedFanduelRSNData = {
      ...fanduelRSNData,
      teams: reorderedTeams,
    }
  }

  let data = useMemo(
    () => laPlanData(channels, isMLBIncluded ? reorderedFanduelRSNData : null),
    [channels, isMLBIncluded, reorderedFanduelRSNData, lang]
  )

  data = {
    ...data,
    [lang + '_plus_beisbol']: {
      ...data[lang + '_plus_beisbol'],
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/latino/LA_LP_Logotiles_baseball.png',
    },
  }

  let planData = data[isMLBIncluded ? lang + '_plus_beisbol' : lang]
  discountedPrice = discountedPrice || planData.discountPrice
  const length = 'monthly'
  const ctaCopy = useMemo(
    () => ctaText || getCtaText({ lang, market, isTextShort: true, disableFreeTrial }),
    [ctaText, lang, market, disableFreeTrial]
  )

  useEffect(() => {
    if (!isEventSent && planData?.plan && cohortingReady) {
      fireUIRenderEvent(
        {
          element: planData?.plan,
          component: 'latino_plan_cards',
          type: isMLBIncluded ? 'only_standalone' : 'latino',
        },
        stateForProtobuf
      )
      setIsEventSent(true)
    }
  }, [isEventSent, planData?.plan, cohortingReady])

  return (
    <StyledBundleSection id="select-plan">
      <div className="section-header">
        <h2>{title}</h2>
      </div>
      <div className="section-content">
        <PlanCardLatino
          offerCode={
            (discountedPrice || planData.discountedPrice) && offerCode
              ? offerCode
              : ratePlanCodes.latino
          }
          plan={planData.plan}
          title={planData.title}
          subtitle={planData.subtitle}
          // tagline={data.tagline}
          price={planData.price}
          discountedPrice={discountedPrice || planData.discountedPrice}
          listItems={planData.listItems}
          ctaText={ctaCopy}
          footnote={cardFootnote || planData.footnote[length]}
          promoImg={planData.promoImg}
          lang={lang}
        />
      </div>
    </StyledBundleSection>
  )
}

export default LAPlanCards

const StyledBundleSection = styled.section`
  color: #fff;
  padding: 60px 20px 60px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #474f56 0%, #1d1f2a 77.78%);
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .section-header {
    padding: 0 0 55px 0;

    h2 {
      color: rgb(240, 241, 242);
      font-size: 40px;
      font-weight: 700;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
      margin-top: 20px;
    }
  }

  .section-tabs {
    width: 100%;
    max-width: 500px;
    .plan-tabs {
      ${'' /* border: 3px solid #fa4616; */}
      ${'' /* border-radius: 10px; */}
      overflow: hidden;
      margin-bottom: 30px;
      display: flex;
      width: 100%;
      max-width: 500px;
      align-items: flex-end;
      > * {
        flex: 1 0 0;
      }
      .tab {
        position: relative;
      }
      .savings {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .tab:first-child {
        button {
          border-radius: 10px 0 0 10px;
        }
      }
      .tab:last-child {
        button {
          border-radius: 0 10px 10px 0;
        }
      }
      button {
        width: 100%;
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          background: #000;
          color: #fff;
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 1020px) {
      align-items: stretch;
      flex-direction: row;
      gap: 30px;
      margin-bottom: 50px;
    }
  }

  .section-footnote {
    color: #6c6c6c;
    text-align: center;

    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 22.44px */
  }
`
