import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { leagueIds, ratePlanCodes, sportIds, videos } from 'src/constants'
import { useSelector } from 'react-redux'
import { selectMLBTVTrialFlag } from 'src/modules/reducers/cohorts'
import { commonCtaText } from 'src/modules/utils/constants'

export default (props) => {
  const isMLBTVTrial = useSelector(selectMLBTVTrialFlag)
  const offerCode = isMLBTVTrial ? ratePlanCodes.mlbtv_mo_w_trial : ratePlanCodes.mlbtv_mo
  const ctaText = isMLBTVTrial ? commonCtaText.startForFree : commonCtaText.startWatching
  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      title="Watch MLB live with Fubo"
      subtitle="CATCH LIVE GAMES ALL SEASON LONG"
      tagline="Stream them from your TV, phone and other devices"
      networkSet="baseball"
      league="mlb"
      showRSN={true}
      video={videos.mlbtv.videoUrl}
      matchtickerSportId={sportIds.Baseball}
      matchtickerLeagueName="MLB"
      matchtickerLeagueId={leagueIds.MLB}
      highlightImage="https://assets-imgx.fubo.tv/marketing/lp/images/devices/mlb-tv-phone.png?ch=width&auto=format,compress"
      highlightHeadline="Get MLB.TV with Fubo"
      highlightSubtitle="See every out-of-market MLB team in action in over 2,000 live games this season. Watch them all live from your TV, phone & other devices."
      highlightBackground="radial-gradient(98.95% 98.95% at 50% 1.05%, #333F51 0%, #0D1B31 92%)"
      highlightUrlParams={[{ offer: offerCode }]}
      highlightCtaText={ctaText}
      caRedirectUrl="/ca/mlb-tv-ca/"
      sportId={sportIds.Baseball}
      leagueId={leagueIds.MLB}
    />
  )
}
