import React from 'react'
import { videos } from 'src/constants/videos'
import { teamIds } from 'src/constants/personalizationIds'
import BallySportsNBA from 'src/components/page/bally-sports-nba'

export default (props) => (
  <BallySportsNBA
    location={props.location}
    video={videos.bally_bucks.videoUrl}
    staticAnnotation={videos.bally_bucks.staticAnnotation}
    title="Watch Milwaukee Bucks basketball"
    subtitle="GET LIVE GAMES, LOCAL TEAM COVERAGE & MORE"
    tagline="Stream FanDuel Sports from any device. No cable required. Regional restrictions apply."
    teamId={teamIds['Milwaukee Bucks']}
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-milwaukee-bucks-v2.png?ch=width&auto=format,compress"
    logoHeight="120px"
  />
)
