import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import styled from 'styled-components'

export default (props) => {
  return (
    <StyledContainer>
      <USDefaultTemplate
        location={props.location}
        lang="en"
        market="us"
        video={'https://assets.fubo.tv/marketing/lp/videos/compressed/Blackhawks_LP_Web_10s_V2.mp4'}
        videoMobile={
          'https://assets-imgx.fubo.tv/marketing/lp/videos/mobile/Blackhawks_LP_Mobile_10s_V2.mp4'
        }
        title="Watch Chicago hockey live"
        subtitle={
          <>
            {' '}
            STREAM THE BLACKHAWKS AND MORE{' '}
            <span className="desktop-line-break">ON CHICAGO SPORTS NETWORK </span>{' '}
          </>
        }
        tagline="Anytime. Anywhere. From any device. Regional restrictions apply."
        customNetworks={['chsn']}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .desktop-line-break {
    display: inline;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
