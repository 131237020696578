import React from 'react'
import { promoCodes } from './promoCodes'
import { createFootnote } from './caPlanData'
import { ratePlanCodes } from './ratePlanCode'

export const caPlanCardOffers = (data, offerKey) => {
  // Adjust pricing if soccerOffer

  switch (offerKey) {
    case 'cricket':
      data.soccer.title = (
        <>
          Cricket with Sports Plus <br />
        </>
      )

      data.soccer.subtitle = ''
      data.soccer.listItems.splice(0, 0, {
        available: true,
        text: '100s of live cricket matches on Willow TV',
      })

      data.soccer.listItems.splice(1, 1, {
        available: true,
        text: 'Live Indian Premier League matches',
      })

      data.soccer.listItems.splice(2, 1, {
        available: true,
        text: 'Pakistan Super League & other leagues',
      })

      data.soccer.listItems.splice(3, 1, {
        available: true,
        text: 'ICC international events all year long',
      })

      data.soccer.listItems.splice(4, 1)

      data.soccer.listItems.push({
        available: true,
        text: 'Watch on up to 3 screens',
      })

      data.soccer.offerCode = {
        monthly: ratePlanCodes.canada_cricket_mo,
        quarterly: ratePlanCodes.canada_cricket_qt,
        annual: ratePlanCodes.canada_cricket_yr,
      }
      data.soccer.savingsNote = {
        monthly: '',
        quarterly: '',
        annual: '',
      }
      data.soccer.totalPrice = {
        monthly: 9.99,
        quarterly: 26.96,
        annual: 89.93,
      }
      data.soccer.discountedTotalPrice = {
        monthly: null,
        quarterly: null,
        annual: null,
      }

      data.soccer.cardFootnote = {
        monthly: createFootnote(data.soccer, 'Monthly'),
        quarterly: createFootnote(data.soccer, 'Quarterly'),
        annual: createFootnote(data.soccer, 'Annual'),
      }

      data.premium.listItems.splice(1, 1, {
        available: true,
        text: 'All Premier League & Serie A football matches',
      })

      data.premium.listItems.splice(2, 1, {
        available: true,
        text: 'Includes Premier League football in 4K',
      })

      break
    case 'soccerOffer':
      data.soccer.offerCode = {
        monthly: promoCodes.canada_soccer_mo,
        quarterly: promoCodes.canada_soccer_qt,
        annual: promoCodes.canada_soccer_yr,
      }
      data.soccer.savingsNote = {
        monthly: '$5 off your first bill',
        quarterly: '$15 off your first bill',
        annual: '$20 off your first bill',
      }
      data.soccer.discountedTotalPrice = {
        monthly: 24.99,
        quarterly: 64.97,
        annual: 259.99,
      }
      break
    case 'ftfOffer':
      data.soccer.offerCode.quarterly = promoCodes.canada_ftf_canada
      data.soccer.savingsNote.quarterly = '$30 off your first bill'
      data.soccer.discountedTotalPrice.quarterly = 49.97
      break
    case 'superChannelOffer':
      data.entertainment.title = 'Entertainment Plus'
      data.entertainment.price = 24.98
      data.entertainment.offerCode = {
        monthly: promoCodes.canada_entertainment_plus,
        quarterly: promoCodes.canada_entertainment_plus_quarterly,
      }
      data.entertainment.totalPrice = {
        monthly: 24.98,
        quarterly: 69.99,
      }
      data.entertainment.discountedTotalPrice = {
        monthly: null,
        quarterly: 34.99,
      }
      data.entertainment.savingsNote = {
        monthly: '',
        quarterly: '50% off for 3 months',
      }
      data.entertainment.listItems.splice(-1, 0, {
        available: true,
        text: 'All Super Channel networks',
      })
      data.premium.listItems.splice(-1, 0, {
        available: false,
        text: 'Super Channel networks',
      })
      data.entertainment.listItems.splice(-1, 0, data.entertainment.listItems.splice(1, 1)[0])
      break
    case 'mlbtvOffer':
      data.soccer.title = 'Sports & MLB.TV'
      data.soccer.subtitle = 'Best for Sports Fans'
      data.soccer.offerCode = {
        monthly: promoCodes.canada_mlbtv_mo,
        quarterly: promoCodes.canada_mlbtv_qt,
        annual: promoCodes.canada_mlbtv_yr,
      }
      data.soccer.savingsNote = {
        monthly: '$10 off for 2 months',
        quarterly: '$30 off for 2 quarters',
        annual: '$24 off the first year',
      }
      data.soccer.totalPrice = {
        monthly: 49.99,
        quarterly: 149.97,
        annual: 359.99,
      }
      data.soccer.discountedTotalPrice = {
        monthly: 39.99,
        quarterly: 119.97,
        annual: 335.88,
      }
      data.soccer.listItems.splice(1, 0, {
        available: true,
        text: 'MLB.TV, featuring 2,000+ Live Games per year',
      })
      break
    case 'sabresPriceTest':
      data.soccer.listItems.unshift({ available: true, text: 'LIVE Buffalo Sabres games' })
      data.premium.listItems.unshift({ available: true, text: 'LIVE Buffalo Sabres games' })

      data.soccer.offerCode = {
        monthly: promoCodes.canada_sabres_mo,
        quarterly: promoCodes.canada_sabres_qt,
        annual: promoCodes.canada_sabres_yr,
      }
      data.soccer.savingsNote = {
        monthly: '58% off for 12 months',
        quarterly: '56% off for 12 months',
        annual: '54% off for 12 months',
      }
      data.soccer.discountedTotalPrice = {
        monthly: 12.49,
        quarterly: 34.99,
        annual: 129.99,
      }
      break
    case 'mastercard':
      data.soccer.offerCode.quarterly = promoCodes.canada_mastercard
      data.soccer.savingsNote.quarterly = '$30 off your first bill'
      data.soccer.discountedTotalPrice.quarterly = 49.97
      break
    default:
      break
  }

  return data
}
