import React from 'react'
import USTemplateDefault from 'src/components/template/us'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/TMB_TopShows_LP_30s.mp4"
    title="Watch The Pet Collective, FailArmy and more"
    subtitle="ANYTIME. ANYWHERE. FROM ANY DEVICE."
    tagline="Stream shows live and on demand with Fubo"
  />
)
