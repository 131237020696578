import React, { useEffect, useState } from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { laDiscountPriceMonthly } from 'src/constants/laPlanData'
import { getURIParamValue } from 'src/modules/utils/analytics'
import { laChannelCount } from '../../../constants/laPlanData'

export default (props) => {
  const [hasCode, setHasCode] = useState(false)
  const offerCode = getURIParamValue(props.location.search, 'offer')

  useEffect(() => {
    if (offerCode) {
      setHasCode(true)
    }
  }, [offerCode])

  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      ctaText="Acceder Ahora"
      offerBanner={
        hasCode ? (
          <>
            ✔ ¡Oferta Especial Exclusiva de SEIS!
            <br />
            Disfruta de $10 de descuento al mes en los próximos 3 meses
          </>
        ) : null
      }
      //REMOVED: univision_removed video and annotations
      // video={videos.latino.videoUrl}
      // annotations={videos.latino.annotations}
      //REPLACED: univision_removed poster images
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Web.jpg"
      posterMobile="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Mobile.jpg"
      title="Ahorra en TV en vivo para toda la familia"
      subtitle={!hasCode && `Accede ahora por solo $${laDiscountPriceMonthly} el primer mes`}
      tagline={`Más de ${laChannelCount} canales con deportes, noticias, novelas y entretenimiento en español.`}
      networkSet="entertainmentLatino"
      noteLink="/la/learn/"
      offerCode={offerCode}
      disablePlanCards={true}
    />
  )
}
