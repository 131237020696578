import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
export default (props) => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      ctaText="Acceder Ahora"
      video={videos.liga_mx.videoUrl}
      staticAnnotation={videos.liga_mx.staticAnnotation}
      title="Ve la Liga MX en vivo en ESPN Deportes"
      subtitle="ACCEDE DESDE TU SMARTPHONE, TV, COMPUTADORA, O DESDE CUALQUIER OTRO DISPOSITIVO"
      networkSet="soccerLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/liga-mx-on-espn-deportes/"
      disablePlanCards={true}
    />
  )
}
