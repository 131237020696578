// const getSlug = ({ path, node: { name } }) => `${path}/${node}`
const getNetwork = (networkId) => `https://api.fubo.tv/v3/kgraph/v1/display_networks/${networkId}`
const getNetworkSeriesUrl = (networkId) =>
  `https://api.fubo.tv/v3/kgraph/v1/display_networks/${networkId}/series`

// isValidParamValue -
const isValidQueryParam = (param = '') => {
  if (typeof param !== undefined) {
    if (typeof param === 'string' && param.trim().length > 0) {
      return true
    }
  }

  return false
}

const getApi = () => {
  const isProd = process.env.NODE_ENV === 'production'

  return isProd ? 'https://api.fubo.tv' : 'https://dev-api.fubo.tv'
}

module.exports = {
  getNetworkSeriesUrl,
  isValidQueryParam,
  getApi,
}
