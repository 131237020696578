import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.seasonal_us.videoUrl}
    videoMobile={videos.seasonal_us.videoMobileUrl}
    annotations={videos.seasonal_us.annotations}
    title="Watch live TV on your flight"
    subtitle="WITH ACCESS TO 200+ CHANNELS"
    tagline="Stream it right now from a phone or mobile device"
  />
)
