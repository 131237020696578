import React from 'react'
import styled from 'styled-components'

const CTAButton = ({ link, round, children, ...props }) => {
  return (
    <StyledCTAButton href={link} {...props} className="CTAButton">
      {children}
    </StyledCTAButton>
  )
}

export default CTAButton

const StyledCTAButton = styled.a`
  position: relative;
  ${'' /* max-width: 242px; */}
  display: inline-block;
  font-size: 23px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  background: #e03100;
  padding: 18px 50px;
  border-radius: 4px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  transition:
    background 0.5s,
    border-radius 0.5s,
    color 0.5s;
  cursor: pointer;

  :hover {
    background: #b33922;
  }

  @media screen and (max-width: 738px) {
    ${'' /* max-width: 300px; */}
    font-size: 20px;
    padding: 18px 20px;
  }
`
