import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'

//REPLACED: univision_removed
export default (props) => <RedirectTemplate location={props.location} destination="/latino/" />

//REMOVED: univision_removed
// export default props => {
//   return (
//     <LatinoTemplateDefault
//       location={props.location}
//       lang="en"
//       market="latino"
//       video={videos.concacaf_gold_cup.videoUrl}
//       title="Watch the CONCACAF Gold Cup"
//       subtitle="CATCH LIVE GAMES ON TUDN"
//       tagline="Stream them from any device with Fubo"
//       networkSet="soccerLatino"
//     />
//   )
// }
