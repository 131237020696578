import React from 'react'
import LiveTemplateDefault from 'src/components/template/live/canada'
export default (props) => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="canada"
    callsign="BTN"
    title="Watch Big Ten Network Live with Fubo"
    plan="premium"
    networkSet="canadaEssentials"
    customNetworks={['bigten']}
    networkName="Big Ten Network"
  />
)
