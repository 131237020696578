// import { configureSharedRouting } from '@fubotv/routing'
// import { createBrowserHistory, History } from 'history'
import { Action, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { persistStore } from 'redux-persist'

// import createPersistedRootReducer from './rootReducer.js'
import rootEpic from './rootEpic.ts'
import { rootReducer } from './rootReducer.ts'
import { setPersistor } from '../utils/storage/flushStore.ts'
import { AppState, AppStore } from '../reducers/index.js'

// export const VERSION = 5

// const browserHistory = createBrowserHistory()

// initialize history with listener to external redirects, pass it flushStore promise
// to make sure the state is finished writing to localstorage before redirecting
// export const history: History = configureSharedRouting({
//   scope: 'product',
//   history: browserHistory,
//   flush: flushStore,
// })

// initialize the appstore with enhancers and listeners
export default function configureAppStore(makeEnhancers: any): AppStore {
  const epicMiddleware = createEpicMiddleware<Action, Action, AppState>()
  const enhancers = makeEnhancers({
    // history,
    epicMiddleware,
  })

  // Create the store
  const store: AppStore = createStore(rootReducer, undefined, enhancers)
  epicMiddleware.run(rootEpic)
  setPersistor(persistStore(store))
  return store
}
