import type { Results } from '@fubotv/types/api/featureflags'

import { initValues, StacFlags } from './flags.ts'
import { UrlFlagValue } from './types.js'

export const mappedFlags = (
  flags: Results | undefined | {},
  urlFlags: StacFlags | undefined | {}
) =>
  Object.keys(flags || {}).reduce((acc, flagName) => {
    // apply url override flags
    const urlFlag = urlFlags?.[flagName]

    if (urlFlag) {
      acc[flagName] = {
        value: mapUrlFlagValue(urlFlag),
        key: flagName,
        ttl: -1,
      }
      return acc
    }

    // updated supported flags with response values
    if (initValues[flagName]) {
      const { key, found, value, scope } = flags[flagName]
      const ttl = scope?.ttl ?? -1
      acc[flagName] =
        found && value
          ? {
              value: mapUrlFlagValue(value.value),
              key,
              ttl,
            }
          : initValues[flagName]
    }
    return acc
  }, {})

function mapUrlFlagValue(raw: UrlFlagValue): UrlFlagValue {
  switch (raw) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return raw
  }
}
