import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PlanCardWithImage from 'src/components/common/PlanCardWithImage'
import styled from 'styled-components'
import { teamData } from 'src/constants/teamData'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { selectStacFlagsReady } from 'src/modules/reducers/cohorts'
import { selectLatinoChannels } from 'src/modules/reducers/products/selectors'
import { getUniqueLeagues } from 'src/modules/utils/league'
import { laPlanData } from 'src/constants/laPlanData'
import { getCtaText } from 'src/modules/utils/language/buttons'
import { fireUIRenderEvent } from 'src/modules/analytics/v3'

const LAPlanPlusBeisbolCards = ({
  lang,
  ctaText,
  offerCode,
  discountedPrice,
  cardFootnote,
  market,
  disableFreeTrial,
  fanduelRSNData,
}) => {
  const title = lang === 'es' ? 'Elige el plan que más te interesa' : 'Which plan is right for you?'
  const channels = useSelector(selectLatinoChannels)

  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const [isEventSent, setIsEventSent] = useState(false)

  const isMLBIncluded = useMemo(
    () => (fanduelRSNData?.teams ? getUniqueLeagues(fanduelRSNData.teams).includes('mlb') : false),
    [fanduelRSNData?.teams]
  )

  let reorderedFanduelRSNData = null
  if (fanduelRSNData?.teams?.length) {
    const reorderedTeams = [...fanduelRSNData.teams]
    reorderedTeams.sort((a, b) => {
      const aIsMLB = teamData[a]?.league === 'mlb'
      const bIsMLB = teamData[b]?.league === 'mlb'
      if (aIsMLB && !bIsMLB) return -1
      if (!aIsMLB && bIsMLB) return 1
      return 0
    })
    reorderedFanduelRSNData = {
      ...fanduelRSNData,
      teams: reorderedTeams,
    }
  }

  const cohortingReady = useSelector(selectStacFlagsReady)
  let planData = useMemo(
    () => laPlanData(channels, isMLBIncluded ? reorderedFanduelRSNData : null, true),
    [channels, isMLBIncluded, reorderedFanduelRSNData, lang]
  )
  const ctaCopy = useMemo(
    () => ctaText || getCtaText({ lang, market, isTextShort: true, disableFreeTrial }),
    [ctaText, lang, market, disableFreeTrial]
  )

  planData = {
    ...planData,
    [lang]: {
      ...planData[lang],
      promoImg: null,
      subtitle: null,
    },
    [lang + '_plus_beisbol']: {
      ...planData[lang + '_plus_beisbol'],
      subtitle: null,
    },
  }

  useEffect(() => {
    if (!isEventSent && planData && cohortingReady) {
      fireUIRenderEvent(
        {
          element: planData[lang + '_plus_beisbol'].plan,
          component: 'latino_plan_cards',
          type: isMLBIncluded ? 'with_standalone' : 'latino',
        },
        stateForProtobuf
      )
      setIsEventSent(true)
    }
  }, [isEventSent, planData?.[lang + '_plus_beisbol'], cohortingReady])

  const createPlanCard = (data, index, cardClassName) => {
    return (
      <PlanCardWithImage
        key={index}
        promoImg={data?.promoImg}
        plan={data?.plan}
        title={data?.title}
        subtitle={data?.subtitle}
        price={Math.round(data?.price * 1e2) / 1e2}
        discountedPrice={Math.round(data?.discountedPrice * 1e2) / 1e2}
        savingsNote={data?.savingsNote}
        listItems={data.listItems}
        offerCode={data.offerCode}
        asterisk={data.asterisk}
        footnote={data.footnote.monthly}
        networkLogos={data.networkLogos}
        showNetworks={true}
        ctaText={data.ctaText || ctaCopy}
        cardClassName={cardClassName}
        banner={data.banner}
      />
    )
  }

  return (
    <StyledBundleSection id="select-plan">
      <div className="section-header">
        <h2>{title}</h2>
      </div>
      <div className={`section-content fanduel-ab `}>
        <>
          {createPlanCard(planData[lang + '_plus_beisbol'], 0, 'latino_plus_beisbol')}
          {createPlanCard(planData[lang], 1, 'latino')}
        </>
      </div>
    </StyledBundleSection>
  )
}

export default LAPlanPlusBeisbolCards

const StyledBundleSection = styled.section`
  color: #fff;
  padding: 60px 20px 60px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #19273c;
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .inline-block {
    display: inline-block;
  }

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  #select-plan {
    position: absolute;
    top: -80px;
  }

  .planCard {
    .planCard__banner {
      color: #000;
      background-color: #fff;
    }
  }

  .section-header {
    padding: 0 0 30px 0;

    h2 {
      color: rgb(240, 241, 242);
      font-size: 40px;
      font-weight: 700;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      letter-spacing: 1px;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
      margin-top: 20px;
    }
  }

  .section-tabs {
    width: 100%;
    max-width: 500px;
    .plan-tabs {
      ${'' /* border: 3px solid #fa4616; */}
      ${'' /* border-radius: 10px; */}
      overflow: hidden;
      margin-bottom: 30px;
      display: flex;
      width: 100%;
      max-width: 500px;
      align-items: flex-end;
      > * {
        flex: 1 0 0;
      }
      .tab {
        position: relative;
      }
      .savings {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .tab:first-child {
        button {
          border-radius: 10px 0 0 10px;
        }
      }
      .tab:last-child {
        button {
          border-radius: 0 10px 10px 0;
        }
      }
      button {
        width: 100%;
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          background: #000;
          color: #fff;
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 1020px) {
      align-items: stretch;
      flex-direction: row;
      gap: 30px;
      margin-bottom: 50px;
    }
  }

  .section-footnote {
    color: #c2c2c2;
    text-align: center;

    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 22.44px */
    max-width: 530px;
  }

  .planCard-container {
    ${'' /* max-width: 400px; */}
    .planCard {
      background: #040911;
      padding-top: 37%;
      .planCard__image {
        top: 38px;

        &::after {
          background: radial-gradient(200% 66% at 127% 5%, transparent 48%, rgb(4, 9, 17));
        }
      }
      .planCard__price {
        margin-top: 33px;
      }
      .planCard__list {
        margin-top: 40px;
        max-width: 340px;
        .list-item {
          font-size: 18px;
        }
      }
    }
  }

  .showMlb {
    .fanduel {
      .planCard__image {
        &::after {
          background: radial-gradient(140% 95% at right top, transparent 60%, rgb(4, 9, 17));
        }
      }
    }
  }

  .fanduel-ab {
    &.section-content {
      align-items: center;
      @media (min-width: 1020px) {
        align-items: flex-end;
      }
    }
    .planCard {
      height: auto;
      .planCard__content {
        margin-bottom: 45px;
      }
      .planCard__price {
        display: flex;
        align-items: center;
        font-size: 24px;

        .faded-price {
          margin-left: 18px;
          font-size: 24px;
          color: #6c7078;
        }
      }
    }
    .planCard__footnote {
      min-height: 50px;
    }

    .latino {
      .planCard {
        padding-top: 65px;
        .planCard__image {
          height: 280px;
          top: 0;
          background: #ff3b04;
          &::after {
            background: linear-gradient(188deg, rgba(4, 9, 17, 0) 25.04%, rgb(4, 9, 17) 75.16%);
          }
        }
      }
    }
  }
`
