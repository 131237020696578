import * as React from 'react'
import { environment } from 'src/config/env/env'
import { makeSrcSet, getImagePath } from 'src/modules/utils/imgIX'
import LazyImage from '../LazyLoad/LazyImage'

const ImgIX = ({ imgSrc, isCompress, className, alt, isDefaultSrc, ...rest }) => {
  if (isDefaultSrc) {
    return (
      <LazyImage
        src={imgSrc}
        // srcSet={makeSrcSet(imgSrc)}
        className={className}
        alt={alt}
        key={imgSrc}
      />
    )
  } else if (typeof imgSrc === 'string') {
    const source = getImagePath(environment, imgSrc, isCompress, { ...rest })
    if (source.match(/(imgi?x)/)) {
      return (
        <LazyImage
          src={source}
          srcSet={makeSrcSet(source)}
          className={className}
          alt={alt}
          key={imgSrc}
        />
      )
    } else {
      return <LazyImage src={source} className={className} alt={alt} key={imgSrc} />
    }
  } else {
    if (!imgSrc || imgSrc.length === 0) return null

    const srcSet = imgSrc
      .map((item) => `${getImagePath(environment, item.path)} ${item.density}`)
      .join()
    return (
      <LazyImage
        src={getImagePath(environment, imgSrc[0].path)}
        srcSet={srcSet}
        className={className}
        alt={alt}
        key={imgSrc[0].path}
      />
    )
  }
}

export default ImgIX
