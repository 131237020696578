import React, { useEffect } from 'react'
import styled from 'styled-components'
import Footer from 'src/components/common/Footer'

import 'src/styles/lp-main.scss'
import { navigate } from 'gatsby'
import OfferBanner from 'src/components/common/OfferBanner'
import { useDispatch, useSelector } from 'react-redux'
import { deleteParam, selectParams, updateParams } from 'src/modules/reducers/params'

import { selectCountryCode } from 'src/modules/reducers/market'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import useTrackScroll from 'src/modules/hooks/useTrackScroll'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import AnalyticsV2 from '../AnalyticsV2'
import { initTranscend } from 'src/modules/reducers/transcend/actions'
import { ratePlanCodes } from 'src/constants'

const Layout = ({
  lang,
  children,
  market,
  offerCode,
  offerBanner,
  addonCode,
  ctaHrefOverride,
  // Everything below should eventually be replaced
  miceText,
  noFooter,
  // Charter Locale Switcher
  noteText,
  // Disabled Geo redirect
  noRedirect,
  // Add lang param
  forceLang,
  // CA Redirect URL
  caRedirectUrl,
  // US Redirect URL
  usRedirectUrl,
  // Personalization Params
  sportId,
  leagueId,
  teamId,
  noIndex,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initTranscend())
  }, [])

  const [showBanner, setShowBanner] = React.useState(false)
  const [bannerTitle, setBannerTitle] = React.useState('')

  const location = useLocation() || (typeof window !== 'undefined' && window.location)
  React.useEffect(() => {
    if (location.search.includes('offer_banner')) {
      setBannerTitle('✔ Special Offer Applied')
      setShowBanner(true)
    } else if (offerBanner) {
      setBannerTitle(offerBanner)
      setShowBanner(true)
    } else {
      setShowBanner(false)
    }
  }, [offerBanner, location])

  // Add preselect param for overriding offer code preselect
  const params = useSelector(selectParams)
  const preselect = params?.preselect

  // Try to avoid race conditions here
  // I will want to seperate this offer param to a separate object or store
  React.useEffect(() => {
    if (preselect && ratePlanCodes[preselect]) {
      const offerParam = {
        offer: ratePlanCodes[preselect],
      }
      dispatch(updateParams(offerParam))
    } else if (offerCode) {
      const offerParam = {
        offer: offerCode,
      }
      dispatch(updateParams(offerParam))
    }
  }, [offerCode, preselect])

  React.useEffect(() => {
    if (addonCode) {
      const addonParam = {
        'addons[]': addonCode,
      }
      dispatch(updateParams(addonParam))
    }
  }, [addonCode])

  React.useEffect(() => {
    if (forceLang) {
      const addonParam = {
        lang: forceLang,
      }
      dispatch(updateParams(addonParam))
    } else {
      dispatch(deleteParam('lang'))
    }
  }, [forceLang])

  // Track scroll event
  useTrackScroll()

  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const countryCode = useSelector(selectCountryCode)

  // BE REALLY CAREFUL WITH THIS REDIRECT, OR ELSE CERTAIN LPs CAN RECEIVE THE WRONG MARKET
  React.useEffect(() => {
    // check market and country code and redirect if needed
    if (
      !noRedirect &&
      countryCode &&
      market?.toLowerCase() !== 'ca' &&
      market?.toLowerCase() !== 'canada' &&
      market?.toLowerCase() !== 'all' &&
      countryCode.toLowerCase() === 'ca'
    ) {
      const redirectDestination = caRedirectUrl || '/ca/redirect/'

      fireUIInteractionEvent(
        {
          component: 'lp_redirect_us_to_ca',
        },
        stateForProtobuf
      )
      navigate(redirectDestination + window.location.search, { replace: true })
    }
    if (
      !noRedirect &&
      countryCode &&
      market?.toLowerCase() !== 'us' &&
      market?.toLowerCase() !== 'all' &&
      countryCode.toLowerCase() === 'us' &&
      usRedirectUrl
    ) {
      const redirectDestination = usRedirectUrl || '/stream/tv/'

      fireUIInteractionEvent(
        {
          component: 'lp_redirect_ca_to_us',
        },
        stateForProtobuf
      )
      navigate(redirectDestination + window.location.search, { replace: true })
    }
  }, [countryCode])

  // Update personalization params
  useEffect(() => {
    if (sportId) {
      dispatch(updateParams({ sportId: sportId }))
    }
    if (leagueId) {
      dispatch(updateParams({ leagueId: leagueId }))
    }
    if (teamId) {
      dispatch(updateParams({ teamId: teamId }))
    }
  }, [sportId, leagueId, teamId])

  // get base url of domain and page slug
  const baseUrl = 'https://www.fubo.tv'
  const pageSlug = location.pathname
  const pageUrl = baseUrl + pageSlug
  return (
    <LayoutWrapper>
      {/* <Helmet>
        <script type="text/javascript">
          {`(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "evtxowp8jx");`}
        </script>
      </Helmet> */}
      <Helmet>
        <link rel="canonical" href={pageUrl} />
        <meta property="og:url" content={pageUrl} />
        {/* <link rel="preconnect" crossOrigin="anonymous" href="https://static.fubo.tv" />, */}
        {/* <link rel="preconnect" crossOrigin="anonymous" href="https://imgx.fubo.tv" />, */}
        {/* <link rel="preconnect" crossOrigin="anonymous" href="https://assets-imgx.fubo.tv" />, */}
        {/* <link rel="preconnect" crossOrigin="anonymous" href="https://www.googletagmanager.com" />, */}
        {/* <link rel="preconnect" crossOrigin="anonymous" href="https://cdn.transcend.io" />, */}
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://amolio.fubo.tv" />,
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://static.fubo.tv" />,
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://imgx.fubo.tv" />,
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://assets-imgx.fubo.tv" />,
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://fubotv-gtm.fubo.tv" />,
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://www.googletagmanager.com" />,
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://cdn.transcend.io" />,
        <link rel="dns-prefetch" crossOrigin="anonymous" href="https://api.fubo.tv/" />,
        {noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <AnalyticsV2
        lang={lang}
        market={market}
        sportId={sportId}
        leagueId={leagueId}
        teamId={teamId}
      />
      {showBanner && (
        <OfferBanner lang={lang} title={bannerTitle} ctaHrefOverride={ctaHrefOverride} />
      )}
      <main>{children}</main>
      {!noFooter && <Footer miceText={miceText} lang={lang} />}
    </LayoutWrapper>
  )
}

export default Layout

const LayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
  > main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`
