import React from 'react'
import NFL from 'src/pages/nfl'
import { teamIds } from 'src/constants/personalizationIds'
export default (props) => (
  <NFL
    {...props}
    team="Arizona Cardinals"
    teamShort="Cardinals"
    teamId={teamIds['Arizona Cardinals']}
  />
)
