export const addOnCodes = {
  canada_sports_plus: 'sports-plus-ca',
  cricket: 'intl-sports-cricket',
  mgm_plus: 'epix',
  mlb: 'mlb',
  nbalp: 'nbalp',
  nfl_redzone: 'fubotv-basic-deportes-extra',
  showtime: 'fubotv-basic-showtime',
  starz: 'starz',
}
