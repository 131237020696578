import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { networkLogos } from 'src/constants/networkLogos'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video="https://assets.fubo.tv/marketing/lp/videos/compressed/BachelorParadise2022_1280x720_LP_11s.mp4"
    staticAnnotation={networkLogos.abc}
    title="Watch Bachelor in Paradise on ABC"
    subtitle="CATCH NEW EPISODES LIVE AND ON DEMAND"
    tagline="Stream on your TV, phone and other devices"
  />
)
