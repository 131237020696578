import React from 'react'
import LiveTemplateDefault from 'src/components/template/live'
export default (props) => (
  <LiveTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    callsign="USAN"
    title="Watch USA Network Live with Fubo"
    networkName="USA Network"
  />
)
