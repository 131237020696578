import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CTAButton from 'src/components/common/PlanCardCTA'
import { selectCTAParams } from 'src/modules/reducers/params'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import styled from 'styled-components'
import Image from '../Image'

const PlanCard = ({
  offerCode,
  plan,
  title,
  subtitle,
  price,
  discountedPrice,
  tagline,
  listItems,
  ctaText,
  footnote,
  promoImg,
  lang = 'en',
}) => {
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)

  ctaURL = replaceUrlParam(ctaURL, 'offer', offerCode)
  ctaURL = replaceUrlParam(ctaURL, 'comp', 'plan-card')

  const monthLang = lang === 'es' ? 'mes' : 'mo'

  //REMOVED: univision_removed promoImg
  // const promoImg =
  //   'https://assets-imgx.fubo.tv/marketing/lp/images/card/latino/LA_PlanCardRefresh_8-14.png?ch=width&auto=format,compress'

  //REPLACED: univision_removed promoImg
  const promoImage = promoImg
    ? promoImg
    : 'https://assets-imgx.fubo.tv/marketing/lp/images/card/latino/LA_LP_LogoTiles_03-2025.png?ch=width&auto=format,compress'

  const [imageWidth, setImageWidth] = useState(0)
  const [cardWidth, setCardWidth] = useState(0)
  const imageRef = React.useRef(null)
  const cardRef = React.useRef(null)

  const handleImage = (event) => {
    // Capture the width of the image
    const width = event.target.offsetWidth
    // Set the container width to 2/3 of the image width
    setImageWidth((2 / 3) * width)
  }

  useEffect(() => {
    if (imageRef.current) {
      // If the image has already loaded, capture the width
      if (imageRef.current.complete) {
        handleImage({ target: imageRef.current })
      }
    }
  }, [imageRef])

  useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth)
    }
  }, [cardRef])

  useEffect(() => {
    function handleResize() {
      if (imageRef.current) {
        // If the image has already loaded, capture the width
        if (imageRef.current.complete) {
          handleImage({ target: imageRef.current })
        }
      }
      if (cardRef.current) {
        setCardWidth(cardRef.current.offsetWidth)
      }
    }

    // Attach the event listener to the window object
    window.addEventListener('resize', handleResize)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <StyledPlanCard imageWidth={imageWidth} cardWidth={cardWidth}>
      <a className="planCard" href={ctaURL}>
        <div className="planCard__main" ref={cardRef}>
          <div className="planCard__content">
            <div className="planCard__header">
              <div className="planCard__title">{title}</div>
              <div className="planCard__subtitle">{subtitle}</div>
              <div className="planCard__divider"></div>
              <div className={`planCard__price`}>
                {discountedPrice ? (
                  <>
                    ${discountedPrice}/{monthLang}
                    {footnote && '*'}{' '}
                    <span className="faded-price">
                      ${price}/{monthLang}
                    </span>
                  </>
                ) : (
                  <>
                    ${price}/{monthLang}
                    {footnote && '*'}
                  </>
                )}
              </div>
              {tagline && <div className="discount__note">{tagline}</div>}
            </div>
            <div className="planCard__list">
              {listItems?.map((item, index) => {
                if (item === null) return null
                const check = item.available ? (
                  <span className="bullet check">✔&#xfe0e;</span>
                ) : (
                  <span className="bullet cross">✘&#xfe0e;</span>
                )

                return (
                  <div key={index} className="list-item">
                    {check} {item.text}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="planCard_cta_container">
            <div className="planCard_cta">
              <CTAButton link={ctaURL}>{ctaText}</CTAButton>
            </div>
          </div>
        </div>
        {promoImage && (
          <>
            <div className="planCard__promoImg__container">
              <div class="triangle"></div>
              <div className="planCard__promoImg">
                <Image src={promoImage} onLoad={handleImage} ref={imageRef} />
                {/* {promoImg.map((url, i) => {
                const img = urlPrefix + url + urlSuffix
                return ( */}
                {/* <div className="image-wrapper">
                <img src={promoImg} loading="lazy" />
              </div> */}
                {/* )
              })} */}
              </div>
            </div>
          </>
        )}
      </a>
      {footnote && <div className="planCard__footnote">{footnote}</div>}
    </StyledPlanCard>
  )
}

export default PlanCard

const cardBreakpoint = 992
const desktopBreak = cardBreakpoint + 'px'
const mobileBreak = cardBreakpoint - 1 + 'px'

const StyledPlanCard = styled.div`
  /* Reset CSS for <button /> */
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  position: relative;

  .planCard__tab {
    width: 100%;
    height: 60px;
    border-radius: 12.5px 12.5px 0px 0px;
    background: #49f296;
  }
  .planCard {
    margin: 0 auto;
    color: #424242;
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
    background: #fff;
    cursor: pointer;
    transition: transform 0.4s;
    @media (max-width: ${mobileBreak}) {
      flex-direction: column;
      align-items: center;
      max-width: 400px;
      .triangle {
        display: none;
      }
    }
    .planCard__main {
      position: relative;
      width: 100%;
      max-width: 400px;
      box-sizing: border-box;
      flex: 0 0 400px;
      padding: 40px 20px 40px 40px;
      z-index: 3;
      background: #fff;
      @media (min-width: ${desktopBreak}) {
        width: 460px;
        max-width: 460px;
        flex: 0 0 460px;
      }
    }
    .triangle {
      position: absolute;
      background: #fff;
      content: '';
      ${'' /* width: 120px; */}
      aspect-ratio: 1 / 7;
      height: 120%;
      left: 0;
      bottom: 0;
      margin-left: auto;
      margin-right: auto;
      flex-shrink: 0;
      transform: translateX(-55%) translateY(10%) rotate(9deg);
      z-index: 2;
      box-shadow: 0 0 70px 30px rgba(0, 0, 0, 0.5);
      @media (max-width: ${mobileBreak}) {
        .triangle {
          display: none;
        }
      }
    }

    .planCard__content {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 3;
      flex: 1;
      .planCard__header {
        .planCard__title {
          color: #0f1010;
          font-size: 31px;
          font-weight: 700;
          margin-bottom: 16px;
          @media (max-width: ${mobileBreak}) {
            font-size: 24px;
          }
        }
        .planCard__subtitle {
          color: #6c7078;
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 16px;
          @media (max-width: ${mobileBreak}) {
            font-size: 18px;
          }
        }
        .planCard__divider {
          background: #555858;
          height: 1.5px;
          width: 75px;
          border: 0;
          margin: 25px 0;
        }
        .planCard__price {
          color: #0f1010;
          font-size: 31px;
          font-weight: 700;
          @media (max-width: ${mobileBreak}) {
            font-size: 24px;
          }
        }
        .faded-price {
          margin-top: 4px;
          font-size: 0.7em;
          color: rgba(66, 66, 66, 0.7);
          text-decoration: line-through;
          font-weight: 400;
          -webkit-text-decoration-color: #fa4616;
          text-decoration-color: #fa4616;
        }
        .discount__note {
          font-size: 1rem;
          font-weight: 700;
          letter-spacing: 0.01em;
          line-height: 1.25;
          text-decoration: none;
          text-transform: none;
          color: #ffffff;
          display: inline-block;
          background-color: #2a2e38;
          border-radius: 4px;
          padding: 8px 8px;
          margin-top: 10px;
          span {
            color: #49f296;
          }
        }
      }
      .planCard__list {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        margin-bottom: 25px;
        gap: 20px;
        @media (max-width: ${mobileBreak}) {
          margin-top: 25px;
          margin-bottom: 50px;
        }
        .list-item {
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0px;
          color: #414444;
          ${'' /* margin-bottom: 14px; */}
          display: flex;
          @media (max-width: ${mobileBreak}) {
            font-size: 18px;
          }
          .bullet {
            width: 20px;
            margin-right: 8px;
            line-height: 1;
            flex: 0 0 20px;
          }
          .check {
            color: #04a928;
            ${'' /* color: blue; */}
            font-size: 18px;
          }
          .cross {
            color: #ff0000;
            font-size: 20px;
          }
        }
      }
    }
    .planCard_cta_container {
      position: relative;
      z-index: 3;
    }
    .planCard_cta {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding-left: 6%;
      padding-right: 6%;
      ${'' /* height: 100%; */}
      justify-content: flex-end;
      color: #fff;
      text-transform: uppercase;
    }
    .learnMore_cta {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding-left: 6%;
      padding-right: 6%;
      ${'' /* height: 100%; */}
      justify-content: flex-end;
      ${'' /* color: #fff; */}
      text-transform: uppercase;

      margin-top: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #000000;
    }
  }
  .planCard__footnote {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
    @media (min-width: ${desktopBreak}) {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .planCard__promoImg__container {
    background: linear-gradient(180deg, #474f56 0%, #1d1f2a 100%);
    width: 100%;
    @media (min-width: ${desktopBreak}) {
      justify-content: center;
      flex: 0 0 ${(props) => props.imageWidth + 'px' || '0'};
      overflow: hidden;
      position: relative;
    }
  }

  .planCard__promoImg {
    width: ${(props) => props.cardWidth + 'px' || '0'};
    height: ${(props) => (props.cardWidth * 855) / 705 + 'px' || '0'};
    @media (min-width: ${desktopBreak}) {
      height: 100%;
      width: ${(props) => props.imageWidth + 'px' || '0'};
    }
    img {
      transform: translateX(-19.5%) scale(1.05);
      height: 100%;
      @media (min-width: ${desktopBreak}) {
        position: absolute;
      }
      @media (max-width: ${mobileBreak}) {
      }
    }
  }
`
