import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds } from 'src/constants/personalizationIds'
export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-uefa.png?w=300&ch=width&auto=format,compress"
    logoHeight="90px"
    video={videos.uefa_nations_league.videoUrl}
    title="Watch the UEFA Nations League"
    subtitle="SEE FRANCE, ENGLAND AND MORE"
    tagline="Stream matches live across all your devices"
    highlightImage="https://assets-imgx.fubo.tv/marketing/lp/images/devices/devices-UEFA-v3.png?ch=width&auto=format,compress"
    highlightHeadline={
      <>
        All matches live.
        <br />
        Only with Fubo.
      </>
    }
    highlightSubtitle="Catch every minute, every team and every match from the 2024 UEFA Nations League."
    networkSet="soccer"
    customNetworks={['fsn']}
    sportId={sportIds.Soccer}
  />
)
