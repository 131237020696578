// import React from 'react'
// import LiveTemplateDefault from 'src/components/template/live'
// export default props => (
//   <LiveTemplateDefault
//     location={props.location}
//     lang="en"
//     market="us"
//     callsign="SNY"
//     title="Watch SNY Live with Fubo"
//     networkName="SNY"
//   />
// )

import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'
export default (props) => (
  <RedirectTemplate location={props.location} destination="/live/preview/mlb/" />
)
