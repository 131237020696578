import React, { forwardRef } from 'react'
import useLazyLoad from 'src/modules/hooks/useLazyLoad'
import Image from '../Image'

const LazyImage = forwardRef(({ src, alt, ...props }, ref) => {
  const loadedSrc = useLazyLoad(src)
  return (
    <Image
      src={loadedSrc || ''} // Placeholder image URL
      alt={alt}
      data-src={src}
      {...props}
      ref={ref}
    />
  )
})

export default LazyImage
