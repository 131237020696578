import React from 'react'
import SpainTemplateDefault from 'src/components/template/es'

const carouselImages = [
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Limetown_566x764.png',
    alt: 'Limetown',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Curfew_566x764.png',
    alt: 'Curfew',
  },
  { url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Evil_566x764.png', alt: 'Evil' },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Resident_Alien_566x764.png',
    alt: 'Resident Alien',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Vagrant_Queen_566x764.png',
    alt: 'Vagrant Queen',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Arrow_566x764.png',
    alt: 'Arrow',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Los_100_566x764.png',
    alt: 'Los 100',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Missions_566x764.png',
    alt: 'Missions',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/The_Outpost_566x764.png',
    alt: 'The Outpost',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Wynonna_Earp_566x764.png',
    alt: 'Wynonna Earp',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/El_Descrubrimiento_de_las_Brujas_566x764.png',
    alt: 'El descubrimiento de las brujas',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Riverdale_566x764.jpg',
    alt: 'Riverdale',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Alex_Rider_566x764.png',
    alt: 'Alex Rider',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Emergence_566x764.png',
    alt: 'Emergence',
  },
  {
    url: 'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/Castle_Rock_566x764.png',
    alt: 'Castle Rock',
  },
]

const annotations = [
  {
    startSecs: '0',
    endSecs: '5.1',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/annotations/Arrow_logo_white.png',
  },
  {
    startSecs: '5.11',
    endSecs: '11.1',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/annotations/Limetown_logo_white.png',
  },
  {
    startSecs: '11.11',
    endSecs: '16.1',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/annotations/Missions_logo_white.png',
  },
  {
    startSecs: '16.11',
    endSecs: '22.1',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/annotations/ResidentAlien_logo_white.png',
  },
  {
    startSecs: '22.11',
    endSecs: '40.0',
    logoUrl:
      'https://assets-imgx.fubo.tv/marketing/lp/images/es/syfy/annotations/VagrantQueen_logo_white.png',
  },
]

const pageTitle = 'SERIES INTERNACIONALES DE ACCIÓN Y FICCIÓN'
const pageDescription =
  'Ver Movistar Series en directo y bajo demanda. Fubo es un servicio de TV por Internet que ofrece canales de televisión con programas de entretenimiento en directo y los eventos deportivos más prestigiosos del mundo. El servicio ofrece acceso a canales nacionales y locales a través de TV, tablets, dispositivos móviles y portátiles.'

export default (props) => {
  return (
    <SpainTemplateDefault
      location={props.location}
      lang="es"
      market="spain"
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      video="https://assets.fubo.tv/marketing/lp/videos/compressed/Syfy_LP_30s.mp4"
      title="SYFY EN DIRECTO Y A LA CARTA"
      subtitle="DESDE SOLO 3,99€/MES"
      annotations={annotations}
      carouselTitle="TUS SERIES FAVORITAS EN DIRECTO Y A LA CARTA"
      carouselImages={carouselImages}
    />
  )
}
