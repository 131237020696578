import React from 'react'
import styled from 'styled-components'

const Footnote = ({ children, ...props }) => {
  return (
    <StyledFootnote {...props}>
      <div className="footnote">{children}</div>
    </StyledFootnote>
  )
}

export default Footnote

const StyledFootnote = styled.div`
  max-width: 550px;
  margin: 0 auto;
  .footnote {
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    /* identical to box height, or 133% */

    text-align: center;

    /* Grey / Grey-400 */

    color: #e5e6e6;

    text-shadow: 0px 0px 100px rgba(0, 0, 0, 0.5);
  }
`
