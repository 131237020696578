import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { leagueIds, sportIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      lang="en"
      market="us"
      video={'https://assets-imgx.fubo.tv/marketing/lp/videos/compressed/Jazz_1280x720_LP_10s.mp4'}
      title={
        <>
          {' '}
          Watch <br /> Utah Jazz <br />
          basketball live
        </>
      }
      pageTitle={'Watch Utah Jazz basketball live'}
      subtitle={
        <>
          Anytime, anywhere, from any device on KJZZ. <br /> Regional restrictions apply.{' '}
        </>
      }
      sportId={sportIds.Basketball}
      leagueId={leagueIds.NBA}
      teamId={teamIds['Utah Jazz']}
      networkSet="basketball"
      customNetworks={['kjzz']}
    />
  )
}
