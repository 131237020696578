// Query String Params
export const FTV_CAMPAIGN = 'ftv_campaign'
export const COUPON_PARAM = 'coupon'
export const HEADLINE = 'headline'
export const HEADLINE2 = 'headline2'
export const PACK = 'pack'
export const OFFER = 'offer'
export const IRAD = 'irad'
export const IRMP = 'irmp'
export const SHARED_ID = 'sharedid'
export const PROMO = 'promo'
export const ADDONS = 'addons' // NTS: maybe switch back to addons[]
export const AL = 'al'
export const TALKABLE_VISITOR_UUID = 'talkable_visitor_uuid'
export const PROMO_CODE = 'promocode'
export const PARAMS = 'params'
export const AB_CAMPAIGN = 'ab_campaign'
export const APP_SOURCE = 'app_source'
export const SPORT_ID = 'sportid'
export const LEAGUE_ID = 'leagueid'
export const TEAM_ID = 'teamid'

export const footballCTAURI = '/football'
export const streamTVCTAURI = '/streamtv'
export const defaultCTAURI = '/signup'

export const imgixURI = 'https://assets-imgx.fubo.tv/marketing/lp/images/'
export const videoURI = 'https://assets.fubo.tv/marketing/lp/videos/'

export const ctaParamList = [
  FTV_CAMPAIGN,
  COUPON_PARAM,
  HEADLINE,
  HEADLINE2,
  PACK,
  OFFER,
  IRAD,
  IRMP,
  SHARED_ID,
  PROMO,
  ADDONS,
  AL,
  TALKABLE_VISITOR_UUID,
  PROMO_CODE,
  PARAMS,
  AB_CAMPAIGN,
  APP_SOURCE,
  SPORT_ID,
  LEAGUE_ID,
  TEAM_ID,
]

export const commonCtaText = {
  getAccess: 'Get Access',
  startYourFreeTrial: 'Start your free trial',
  startFreeTrial: 'Start Free Trial', //TODO: no longer used, remove
  startWatching: 'Start Watching',
  startForFree: 'Start For Free',
  accederAhora: 'Acceder Ahora',
  pruebaloGratis: 'Pruébalo Gratis',
}

// Free Trial Flags
export const isUSFreeTrial = true
export const isCAFreeTrial = false
export const isLAFreeTrial = true

export const defaultCTA = {
  us: {
    freeTrial: {
      text: commonCtaText.startYourFreeTrial,
      text_es: commonCtaText.pruebaloGratis,
      textShort: commonCtaText.startForFree,
    },
    noTrial: {
      text: commonCtaText.startWatching,
      text_es: commonCtaText.accederAhora,
      textShort: commonCtaText.startWatching,
    },
  },
  canada: {
    freeTrial: {
      text: commonCtaText.startYourFreeTrial,
      text_es: commonCtaText.pruebaloGratis,
      textShort: commonCtaText.startForFree,
    },
    noTrial: {
      text: commonCtaText.getAccess,
      text_es: commonCtaText.accederAhora,
      textShort: commonCtaText.getAccess,
    },
  },
  latino: {
    freeTrial: {
      text: commonCtaText.startYourFreeTrial,
      text_es: commonCtaText.pruebaloGratis,
      textShort: commonCtaText.startForFree,
    },
    noTrial: {
      text: commonCtaText.getAccess,
      text_es: commonCtaText.accederAhora,
      textShort: commonCtaText.getAccess,
    },
  },
}

// US CTAs
export const USDefaultCtaText = isUSFreeTrial
  ? defaultCTA.us.freeTrial.text
  : defaultCTA.us.noTrial.text

export const USDefaultCtaText_ES = isUSFreeTrial
  ? defaultCTA.us.freeTrial.text_es
  : defaultCTA.us.noTrial.text_es

export const USDefaultCtaTextShort = isUSFreeTrial
  ? defaultCTA.us.freeTrial.textShort
  : defaultCTA.us.noTrial.textShort

// Remove all Free Trial Sections.
// Use regex in search.

// Notable exeptions with Free Trial text
// src/components/page/movers/components/SideBySideSection/index.js
// src/components/page/live/components/HeroLiveFeed/components/hero.js, const cta_subhead & preview_cta_text
// src/components/page/fubotv-plus-aaa/index.js, remove <PlanSection/> and <TrialReminder/> components

// Canada EPL Pages
export const CaEplDefaultCtaText = commonCtaText.getAccess

// Latino CTAs
export const LatinoDefaultCtaText = isLAFreeTrial
  ? defaultCTA.latino.freeTrial.text
  : defaultCTA.latino.noTrial.text

export const LatinoDefaultCtaTextShort = isLAFreeTrial
  ? defaultCTA.latino.freeTrial.textShort
  : defaultCTA.latino.noTrial.textShort

export const LatinoDefaultCtaText_ES = isLAFreeTrial
  ? defaultCTA.latino.freeTrial.text_es
  : defaultCTA.latino.noTrial.text_es

// Canada CTAs
export const CanadaDefaultCtaText = isCAFreeTrial
  ? defaultCTA.canada.freeTrial.text
  : defaultCTA.canada.noTrial.text

export const CanadaDefaultCtaTextShort = isCAFreeTrial
  ? defaultCTA.canada.freeTrial.textShort
  : defaultCTA.canada.noTrial.textShort

//Spain CTAs
export const SpainDefaultCtaText = commonCtaText.pruebaloGratis
