import React from 'react'
import USDefaultTemplate from 'src/components/template/us'

export default (props) => {
  return (
    <USDefaultTemplate
      location={props.location}
      video={'https://assets.fubo.tv/marketing/lp/videos/compressed/NFLNetworkTrainingCamp2021.mp4'}
      title="Watch NFL Training Camp on NFL Network with Fubo"
      subtitle="IN-DEPTH COVERAGE, INTERVIEWS AND MORE FOR ALL 32 TEAMS"
      tagline="Stream live on your TV, phone and other devices"
      networkSet="nfl"
      // Remove cable references
      removeCable={true}
      showRedzone={false}
    />
  )
}
