import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default (props) => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.baltimore_orioles.videoUrl}
    staticAnnotation={videos.baltimore_orioles.staticAnnotation}
    pageTitle="Watch Baltimore Orioles baseball"
    title={
      <>
        Watch Baltimore Orioles<sup>®</sup> baseball
      </>
    }
    subtitle="GET LIVE GAMES, TEAM NEWS & MORE"
    tagline="Stream it all on MASN from any device"
    networkSet="baseball"
    league="mlb"
    customNetworks={['masn']}
    sportId={sportIds.Baseball}
    leagueId={leagueIds.MLB}
    teamId={teamIds['Baltimore Orioles']}
  />
)
